import Vue from 'vue'
import App from './App.vue'
import router from "./plugins/router";
import 'animate.css';
import i18n from './locales/i18n'
import store from "./plugins/vuex/store"
import VueGeolocation from "vue-browser-geolocation";
import '../src/plugins/apexchart'
import '../src/plugins/vueTouchEvents'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'

Vue.use(VueGeolocation);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    data: {
        showOverlay: false,
    },

    i18n,
    router,
    store
}).$mount('#app')

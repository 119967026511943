import fetchModels from "../../mixin/store/api/fetchModels";
import fetchModel from '../../mixin/store/api/fetchModel'

export default {
    actions: {
        fetchBooks(context, page) {
            return fetchModels(context, 'books', page, 'updateBooks');
        },
        fetchSearchAnyBooks(context, data) {
            return fetchModels(context, `books/search?name=${data}`, null, 'updateSearchedAllBooks');
        },
        fetchTodayRecommendsBooks(context) {
            return fetchModels(context, 'books/today_recommends', null, 'updateTodayRecommendsBooks')
        },
        fetchSimilarBooks(context, id) {
            return fetchModels(context, 'books/similar/' + id, null, 'updateSimilarBooks')
        },
        checkBookPurchasedOrNot(context, id) {
            return fetchModels(context, 'bought_books?book=' + id, null, 'updateBoughtBook')
        },
        fetchBoughtBook(context, id) {
            return fetchModel(context, 'books/bought-book', id, 'updateUserBoughtBook')
        },
        fetchAllBooksCount(ctx) {
            return fetchModels(ctx, 'books/count', null, 'updateAllBooksCount')
        }
    },
    mutations: {
        updateBooks(state, data) {
            state.books = data
        },
        updateSearchedAllBooks(state, data) {
            state.searchedAllBooks = data
        },
        updateSimilarBooks(state, data) {
            state.similarBooks = data
        },
        updateTodayRecommendsBooks(state, data) {
            state.todayRecommendsBooks = data
        },
        updateBoughtBook(state, data) {
            if (data['totalItems']) {
                state.isPurchasedBook = true
            }
        },
        updateUserBoughtBook(state, data) {
            state.userBoughtBook = data
        },
        updateAllBooksCount(state, data) {
            state.countAllBooks = data
        }
    },
    state: {
        books: {
            models: [],
            totalItems: 0,
        },
        searchedAllBooks: {
            models: [],
            totalItems: 0,
        },
        todayRecommendsBooks: {
            models: [],
            totalItems: 0,
        },
        userBoughtBook: {},
        isPurchasedBook: false,
        similarBooks: {
            models: [],
            totalItems: 0,
        },
        countAllBooks: null
    },
    getters: {
        getBooks(state) {
            return state.books.models
        },
        getSearchedAllBooks(state) {
            return state.searchedAllBooks.models
        },
        getSimilarBooks(state) {
            state.similarBooks.models.map((el) => {
                if(el.coverSourceUrl) {
                    el.coverSourceUrl = el.coverSourceUrl.replace('cover', 'cover_100')
                }
            })

            return state.similarBooks.models
        },
        getCountOfAllBooks(state) {
            return state.countAllBooks
        },
        getTodayRecommendsBooks(state) {
            return state.todayRecommendsBooks.models
        },
        getIsPurchasedBook(state) {
            return state.isPurchasedBook
        },
        getUserBoughtBook(state) {
            return state.userBoughtBook
        }
    },
}
<template>
    <div class="row">
        <div class="col container-col">

            <div class="row">
                <div class="col fixed-top">
                    <div class="row header-name-row">
                        <div class="col-2 ps-2">
                            <go-back-button class="arrow-back"/>
                        </div>
                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{ $t('aboutSubs') }}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center">
                <div class="col-11 center-block">

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import GoBackButton from "@/components/modals/GoBackButton.vue";

export default {
    name: "setting-ab-subs",
    components: {GoBackButton}
}
</script>

<style scoped>
.container-col {
    font-family: 'Montserrat', sans-serif !important;
    background-color: var(--color-container);
    border-radius: 0 0 15px 15px;
    height: 100vh;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.arrow-back {
    margin-top: 65px;
}

svg > path {
    fill: var(--color-text);
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.center-block {
    border-radius: 15px;
    margin: 167px 0 22px 0;
    background-color: var(--color-block);
    height: 75vh;
}

</style>

import pushModel from "../../mixin/store/api/pushModel";

export default {
    actions: {
        createVerification(context, data) {
            return pushModel(context, 'verifications', data, 'updateVerification')
        },
        checkVerificationCode(context, data) {
            return pushModel(context, 'verifications/check', data, 'updateCheckVerificationCode')
        },
        checkVerifiedStatus(context, data) {
            return pushModel(context, 'verifications/status', data, 'updateIsVerified')
        }
    },
    mutations: {
        updateVerification(state, data) {
            state.verification = data
        },
        updateCheckVerificationCode(state, data) {
            state.checkCode = data
        },
        updateIsVerified(state, data) {
            state.isVerified = data
        },
    },
    state: {
        verification: null,
        checkCode: {
            models: [],
            totalItems: 0
        },
        isVerified: null,
    },
    getters: {
        getVerification(state) {
            return state.verification
        },
        getCheckCodeStatus(state) {
            return state.checkCode
        },
        getVerifiedStatus(state) {
            return state.isVerified
        },
    }
}
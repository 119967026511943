<template>
    <div class="range-audio">
        <div class="range-audio__start-time">{{ formatTime(sliderValue) }}</div>
        <div class="range-audio__range">
            <range-slider
                v-model="sliderVal"
                @input="$emit('onChangePosition', sliderVal)"
                :max="durationSeconds"
                class="slider"
                min="0"
                step="0.1"
                id="seek"
            />
        </div>
        <div class="range-audio__end-time">{{ formatTime(durationSeconds) }}</div>
    </div>
</template>

<script>
import RangeSlider from "vue-range-slider";
import "@/assets/range-slider-audio.css";

export default {
    name: "listen-range",
    components: {
        RangeSlider
    },
    props: {
        sliderValue: Number,
        durationSeconds: Number
    },
    data() {
        return {
            sliderVal: this.sliderValue
        }
    },
    methods: {
        formatTime(seconds) {
            let minutes = Math.floor(seconds / 60);
            let remainingSeconds = seconds % 60;

            remainingSeconds = remainingSeconds.toFixed(0)

            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
            }

            return minutes + ":" + remainingSeconds;
        },
    },
    watch: {
        'sliderValue'() {
            this.sliderVal  = this.sliderValue
        }
    }
}
</script>

<style scoped>
.range-audio {
    margin-top: 34px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: var(--color-textDarkGray-To-Blue);
}

.range-audio__range {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    margin-top: -26px;
}

.range-audio__range input {
    width: 100%;
}
</style>
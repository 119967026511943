<script>
import MaskedInput from "vue-masked-input";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "login_fifth",
    components: {MaskedInput},
    computed: {
        ...mapGetters(['getPhoneNumber']),
        isWidthBig330px() {
            return this.bodyClientWidth > 330
        }
    },
    data() {
        return {
            bodyClientWidth: 0,
            bodyClientHeight: 0,
            phone: '',
            correctedPhoneNumber: '',
            password: '',
            repeatPassword: '',
            isNotMatchPasswords: false,
            isTypePassword: true,
            isTypePassword2: true,
            isLoading: false,
        }
    },
    mounted() {
        this.bodyClientWidth = this.$refs.contentRow.clientWidth
        this.bodyClientHeight = document.body.clientHeight

        if (localStorage.getItem('phone')) {
            this.phone = localStorage.getItem('phone')
        }
    },
    methods: {
        ...mapActions(['createVerification', 'fetchPhoneNumber']),
        isValidNumber() {
            this.correctedPhoneNumber = this.phone.split(/\W+/).join('')

            return this.correctedPhoneNumber.length === 12;
        },
        setLanguage() {
            if (this.$i18n.locale === 'uz') {
                this.$i18n.locale = 'ru'

                try {
                    window.pushLanguageToAndroid('ru')
                } catch {
                    console.log('push language to android')
                }
            } else {
                this.$i18n.locale = 'uz'

                try {
                    window.pushLanguageToAndroid('uz')
                } catch {
                    console.log('push language to android')
                }
            }

            localStorage.setItem('language', this.$i18n.locale)
        },
        createUser() {
            this.isLoading = true

            if (this.isValidNumber() && this.password === this.repeatPassword) {
                this.createVerification({phoneNumber: this.correctedPhoneNumber})
                    .then(() => {
                        this.isLoading = false
                        localStorage.setItem('phone', this.phone)
                        localStorage.setItem('password', this.password)
                        this.$router.push('/login_sixth')
                    })
                    .catch(() => {
                        this.isLoading = false
                        this.isNotMatchPasswords = true
                    })
            } else {
                this.isLoading = false
                this.isNotMatchPasswords = true
            }
        }
    }
}
</script>

<template>
    <div class="row content__row" ref="contentRow">
        <div class="content__bg"></div>
        <div class="content__bg-opacity" :style="{'height': bodyClientHeight + 'px'}"></div>
        <div class="col-12 content__col">
            <div class="row" :style="{'height': bodyClientHeight + 'px'}">
                <div class="col-12 align-self-center">
                    <div class="row">
                        <div class="col-9 mx-auto">
                            <svg viewBox="0 0 228 39" fill="none">
                                <path d="M12.4558 0V38.8427H5.38411V6.91935H0V0H12.4558Z" fill="var(--color-logo)"/>
                                <path
                                    d="M63.2467 3.53831C65.5504 5.73992 66.7022 8.41331 66.7022 11.5585C66.7022 14.7036 65.604 17.377 63.4075 19.5786C65.604 21.7278 66.7022 24.375 66.7022 27.5202C66.7022 30.6653 65.5504 33.3649 63.2467 35.619C60.9967 37.8206 58.2644 38.9214 55.05 38.9214H21.4711V32.002H55.05C56.3358 32.002 57.4073 31.5827 58.2644 30.744C59.1752 29.8528 59.6305 28.7782 59.6305 27.5202C59.6305 26.2621 59.1752 25.1875 58.2644 24.2964C57.4073 23.4052 56.3358 22.9597 55.05 22.9597H21.4711V16.119H55.05C56.3358 16.119 57.4073 15.6734 58.2644 14.7823C59.1752 13.8911 59.6305 12.8165 59.6305 11.5585C59.6305 10.3004 59.1752 9.25202 58.2644 8.41331C57.4073 7.52218 56.3358 7.07661 55.05 7.07661H21.4711V0.157256H55.05C58.2644 0.157256 60.9967 1.28427 63.2467 3.53831Z"
                                    fill="var(--color-logo)"/>
                                <path
                                    d="M80.5354 5.81855C84.4462 2.04435 89.1339 0.157256 94.5984 0.157256C100.116 0.157256 104.804 2.04435 108.661 5.81855C112.519 9.59274 114.447 14.1794 114.447 19.5786C114.447 24.9254 112.519 29.5121 108.661 33.3387C104.804 37.1129 100.116 39 94.5984 39V32.0806C98.1342 32.0806 101.161 30.875 103.679 28.4637C106.197 26 107.456 23.0383 107.456 19.5786C107.456 16.1189 106.197 13.1573 103.679 10.6935C101.161 8.22984 98.1342 6.99798 94.5984 6.99798C91.0625 6.99798 88.0356 8.22984 85.5177 10.6935C83.0533 13.1573 81.8212 16.1189 81.8212 19.5786H74.7495C74.7495 14.1794 76.6781 9.59274 80.5354 5.81855Z"
                                    fill="var(--color-logo)"/>
                                <path
                                    d="M129.034 5.81855C132.945 2.04435 137.632 0.157256 143.097 0.157256C148.615 0.157256 153.302 2.04435 157.16 5.81855C161.017 9.59274 162.946 14.1794 162.946 19.5786C162.946 24.9254 161.017 29.5121 157.16 33.3387C153.302 37.1129 148.615 39 143.097 39V32.0806C146.633 32.0806 149.659 30.875 152.177 28.4637C154.695 26 155.954 23.0383 155.954 19.5786C155.954 16.1189 154.695 13.1573 152.177 10.6935C149.659 8.22984 146.633 6.99798 143.097 6.99798C139.561 6.99798 136.534 8.22984 134.016 10.6935C131.552 13.1573 130.32 16.1189 130.32 19.5786H123.248C123.248 14.1794 125.177 9.59274 129.034 5.81855Z"
                                    fill="var(--color-logo)"/>
                                <path
                                    d="M187.336 39L171.827 17.2984L186.693 0.157256H195.935L180.747 17.6915L195.935 39H187.336Z"
                                    fill="var(--color-logo)"/>
                                <path
                                    d="M216.91 15.8831C218.143 16.3024 219.294 16.8266 220.366 17.4556C221.491 18.0323 222.669 18.7923 223.902 19.7359C225.134 20.627 226.098 21.754 226.795 23.1169C227.545 24.4274 227.946 25.8689 228 27.4415C228 30.4819 226.821 33.1814 224.464 35.5403C222.161 37.8468 219.053 39 215.142 39H214.741V32.0806H215.142C215.892 32.0806 216.589 32.002 217.232 31.8448C217.875 31.6875 218.384 31.4778 218.759 31.2157C219.187 30.9536 219.535 30.6653 219.803 30.3508C220.125 29.9839 220.339 29.6694 220.446 29.4073C220.607 29.0927 220.714 28.7782 220.768 28.4637C220.875 28.1492 220.928 27.9395 220.928 27.8347C220.928 27.6774 220.928 27.5464 220.928 27.4415C220.928 25.502 218.812 23.7722 214.58 22.252C212.973 21.7278 211.58 21.1774 210.401 20.6008C209.276 19.9718 208.151 19.2379 207.026 18.3992C205.901 17.5081 205.044 16.4597 204.455 15.254C203.865 13.996 203.571 12.6069 203.571 11.0867C203.571 8.25605 204.562 5.79234 206.544 3.69556C208.848 1.33669 211.901 0.157256 215.705 0.157256C218.759 0.157256 221.303 0.969756 223.339 2.59476C225.428 4.16734 226.902 6.05444 227.759 8.25605L221.169 10.6935C220.312 8.28226 218.491 7.07661 215.705 7.07661C214.741 7.07661 213.883 7.20766 213.133 7.46976C212.437 7.73185 211.901 8.09879 211.526 8.57056C211.205 8.98992 210.964 9.43548 210.803 9.90726C210.642 10.3266 210.562 10.746 210.562 11.1653C210.562 11.375 210.562 11.5585 210.562 11.7157C210.616 11.873 210.749 12.1351 210.964 12.502C211.178 12.869 211.473 13.2097 211.848 13.5242C212.276 13.7863 212.919 14.1532 213.776 14.625C214.633 15.0444 215.678 15.4637 216.91 15.8831Z"
                                    fill="var(--color-logo)"/>
                            </svg>
                        </div>
                    </div>
                    <div class="row mt-2 mb-3">
                        <div :class="{'col-11': isWidthBig330px}" class="content__description col-12 mx-auto">
                            <span style="color: #0481f4">1books</span>
                            {{ $t('login.about1book') }}
                        </div>
                    </div>
                    <form @submit.prevent="createUser" class="row">
                        <div class="col-12">
                            <div class="input__box py-3" :class="{'mx-2': isWidthBig330px}">
                                <img
                                    :class="isWidthBig330px ? 'mx-3' : 'mx-2'"
                                    src="../../components/images/tel-logo.png" alt=""
                                    class="fa fa-envelope" aria-hidden="true"
                                >
                                <masked-input
                                    mask="\+\998 (11) 111-11-11"
                                    :placeholder="phone"
                                    placeholderChar="-"
                                    class="input__form"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-12 password__row">
                            <div class="input__box py-3" :class="{'mx-2': isWidthBig330px}">
                                <span @click="isTypePassword = !isTypePassword" :class="isWidthBig330px ? 'mx-3' : 'mx-2'">
                                    <svg v-if="isTypePassword" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.75 8V10.1C18.31 10.04 17.81 10.01 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8Z"
                                            fill="#0481F4"/>
                                        <path
                                            d="M18.75 10.1C18.31 10.04 17.81 10.01 17.25 10H6.75C6.19 10.01 5.69 10.04 5.25 10.1C2.7 10.41 2 11.66 2 15V17C2 21 3 22 7 22H17C21 22 22 21 22 17V15C22 11.66 21.3 10.41 18.75 10.1ZM8.71 16.71C8.52 16.89 8.26 17 8 17C7.87 17 7.74 16.97 7.62 16.92C7.49 16.87 7.39 16.8 7.29 16.71C7.11 16.52 7 16.26 7 16C7 15.87 7.03 15.74 7.08 15.62C7.13 15.5 7.2 15.39 7.29 15.29C7.39 15.2 7.49 15.13 7.62 15.08C7.99 14.92 8.43 15.01 8.71 15.29C8.8 15.39 8.87 15.5 8.92 15.62C8.97 15.74 9 15.87 9 16C9 16.26 8.89 16.52 8.71 16.71ZM12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38ZM16.71 16.71C16.52 16.89 16.26 17 16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71Z"
                                            fill="#0481F4"/>
                                    </svg>
                                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19 6.5C18.56 6.44 18.06 6.51 17.5 6.5C17.5 3.35 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 19 3.6 19 6.5Z"
                                            fill="#0481F4"/>
                                        <path
                                            d="M18.75 10.1C18.31 10.04 17.81 10.01 17.25 10H6.75C6.19 10.01 5.69 10.04 5.25 10.1C2.7 10.41 2 11.66 2 15V17C2 21 3 22 7 22H17C21 22 22 21 22 17V15C22 11.66 21.3 10.41 18.75 10.1ZM8.71 16.71C8.52 16.89 8.26 17 8 17C7.87 17 7.74 16.97 7.62 16.92C7.49 16.87 7.39 16.8 7.29 16.71C7.11 16.52 7 16.26 7 16C7 15.87 7.03 15.74 7.08 15.62C7.13 15.5 7.2 15.39 7.29 15.29C7.39 15.2 7.49 15.13 7.62 15.08C7.99 14.92 8.43 15.01 8.71 15.29C8.8 15.39 8.87 15.5 8.92 15.62C8.97 15.74 9 15.87 9 16C9 16.26 8.89 16.52 8.71 16.71ZM12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38ZM16.71 16.71C16.52 16.89 16.26 17 16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71Z"
                                            fill="#0481F4"/>
                                    </svg>
                                </span>
                                <input
                                    v-model="password"
                                    :type="isTypePassword ? 'password' : 'text'"
                                    @input="repeatPassword === password ? isNotMatchPasswords = false : isNotMatchPasswords = true"
                                    class="input__form"
                                    :placeholder="$t('security.newPassword')"
                                    required
                                >
                            </div>
                        </div>
                        <div class="col-12 password__row">
                            <div class="input__box py-3" :class="{'mx-2': isWidthBig330px}">
                                <span @click="isTypePassword2 = !isTypePassword2"
                                      :class="isWidthBig330px ? 'mx-3' : 'mx-2'">
                                    <svg v-if="isTypePassword2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.75 8V10.1C18.31 10.04 17.81 10.01 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8Z"
                                            fill="#0481F4"/>
                                        <path
                                            d="M18.75 10.1C18.31 10.04 17.81 10.01 17.25 10H6.75C6.19 10.01 5.69 10.04 5.25 10.1C2.7 10.41 2 11.66 2 15V17C2 21 3 22 7 22H17C21 22 22 21 22 17V15C22 11.66 21.3 10.41 18.75 10.1ZM8.71 16.71C8.52 16.89 8.26 17 8 17C7.87 17 7.74 16.97 7.62 16.92C7.49 16.87 7.39 16.8 7.29 16.71C7.11 16.52 7 16.26 7 16C7 15.87 7.03 15.74 7.08 15.62C7.13 15.5 7.2 15.39 7.29 15.29C7.39 15.2 7.49 15.13 7.62 15.08C7.99 14.92 8.43 15.01 8.71 15.29C8.8 15.39 8.87 15.5 8.92 15.62C8.97 15.74 9 15.87 9 16C9 16.26 8.89 16.52 8.71 16.71ZM12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38ZM16.71 16.71C16.52 16.89 16.26 17 16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71Z"
                                            fill="#0481F4"/>
                                    </svg>
                                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19 6.5C18.56 6.44 18.06 6.51 17.5 6.5C17.5 3.35 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 19 3.6 19 6.5Z"
                                            fill="#0481F4"/>
                                        <path
                                            d="M18.75 10.1C18.31 10.04 17.81 10.01 17.25 10H6.75C6.19 10.01 5.69 10.04 5.25 10.1C2.7 10.41 2 11.66 2 15V17C2 21 3 22 7 22H17C21 22 22 21 22 17V15C22 11.66 21.3 10.41 18.75 10.1ZM8.71 16.71C8.52 16.89 8.26 17 8 17C7.87 17 7.74 16.97 7.62 16.92C7.49 16.87 7.39 16.8 7.29 16.71C7.11 16.52 7 16.26 7 16C7 15.87 7.03 15.74 7.08 15.62C7.13 15.5 7.2 15.39 7.29 15.29C7.39 15.2 7.49 15.13 7.62 15.08C7.99 14.92 8.43 15.01 8.71 15.29C8.8 15.39 8.87 15.5 8.92 15.62C8.97 15.74 9 15.87 9 16C9 16.26 8.89 16.52 8.71 16.71ZM12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38ZM16.71 16.71C16.52 16.89 16.26 17 16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71Z"
                                            fill="#0481F4"/>
                                    </svg>
                                </span>
                                <input
                                    v-model="repeatPassword"
                                    :type="isTypePassword2 ? 'password' : 'text'"
                                    @input="repeatPassword === password ? isNotMatchPasswords = false : isNotMatchPasswords = true"
                                    class="input__form"
                                    :placeholder="$t('security.repeatNewPassword')"
                                    required
                                >
                            </div>
                        </div>

                        <div v-if="isNotMatchPasswords" class="col-12 errorInputMessage">
                            <div :class="{'mx-2': isWidthBig330px}">{{ $t('security.passwordsNotEquals') }}</div>
                        </div>

                        <div class="col-12 mt-3">
                            <div :class="{'mx-2': isWidthBig330px}">
                                <button class="form__button py-3" type="submit">
                                    <span>{{ $t('logIn') }}</span>
                                    <span v-if="isLoading" class="spinner-border spinner-border-sm ms-1"></span>
                                </button>
                            </div>
                        </div>
                        <div class="agreement__row col-12 mt-2">
                            <div class="mx-3">
                                {{ $t('login.agreement.first') }}
                                <span class="agreement__text-blue">{{ $t('login.agreement.second') }}</span>
                                {{ $t('login.agreement.third') }}
                                <span class="agreement__text-blue">{{ $t('login.agreement.fourth') }}</span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-12 align-self-end py-4">
                    <div class="language__menu">
                        {{ $t('login.selectLanguage.press') }}
                        <span @click="setLanguage" class="language__UZ">{{
                                $t('login.selectLanguage.lanLogo')
                            }}</span><br/>
                        {{ $t('login.selectLanguage.for') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.content__row {
    background-color: var(--color-container);
    min-height: 100vh;
    border-radius: 0 0 15px 15px;
    position: relative;
}

.content__col {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.content__bg {
    background: url("../../components/images/login-fourth-logo2.png") 0 0/100% auto no-repeat;
    position: absolute;
    height: 50%;
    left: 0;
    top: 0;
}

.content__bg-opacity {
    background: var(--linear-login-page);
    position: absolute;
    left: 0;
    top: 0;
}

.content__description {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0;
    text-align: center;
    color: var(--color-textGray-To-white);
}

.input__box {
    border: 1px solid #0481f4;
    border-radius: 12px;
    display: flex;
    align-items: center;
}

.input__form {
    padding: 0;
    border: none;
    background: transparent;
    color: var(--color-textDarkGray-To-white);
    max-width: 80%;
    font-weight: 400;
}

.input__form:focus {
    outline: none;
}

.input__form::placeholder {
    color: var(--color-textDarkGray-To-white);
}

.errorInputMessage {
    color: red;
    font-size: 0.8rem;
}

.password__row {
    margin-top: 0.7rem;
}

.form__button {
    width: 100%;
    border: 1px solid #0481f4;
    border-radius: 12px;
    background-color: #0481F4;
    color: white;
    outline: none;
    font-size: 1.1rem;
    font-weight: 500;
}

.agreement__row {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1rem;
    font-style: normal;
    text-align: center;
    color: var(--color-textGray-To-white);
}

.agreement__text-blue {
    color: #0481f4;
    text-decoration: underline;
}

.language__menu {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    color: var(--color-blue-to-gray);
    line-height: 1rem;
}

.language__UZ {
    color: #0481F4;
    text-decoration: underline;
    font-weight: 500;
}
</style>

import fetchModels from "../../mixin/store/api/fetchModels";
import deleteModel from "../../mixin/store/api/deleteModel";

export default {
    actions: {
        fetchUserBookmarks(context, id) {
            return fetchModels(context, 'audio_book_times?&audioBook=' + id, null, 'updateUserBookmarks')
        },
        deleteUserBookmark(context, id) {
            return deleteModel(context, 'audio_book_times', id)
        }
    },
    mutations: {
        updateUserBookmarks(state, data) {
            state.userBookmarks = data
        }
    },
    state: {
        userBookmarks: {
            models: [],
            totalItems: 0
        }
    },
    getters: {
        getUserBookmarks(state) {
            return state.userBookmarks.models
        }
    }
}
import fetchModels from "../../../mixin/store/api/fetchModels";

export default {
    actions: {
        fetchRecommendedBooks(context, page) {
            return fetchModels(context, 'recommended/books', page, 'updateRecommendedBooks')
        }
    },
    mutations: {
        updateRecommendedBooks(state, data) {
            state.recommendedBooks = data
        }
    },
    state: {
        recommendedBooks: []
    },
    getters: {
        getRecommendedBooks(state) {
            return state.recommendedBooks
        }
    },
}

import axios from "../../../plugins/vuex/axios";

export default function (context, url, id = null, mutationName = null) {
    if (id) {
        url += '/' + id
    }

    return new Promise((resolve, reject) => {
        axios.get('/' + url)
            .then(response => {
                if (mutationName !== null) {
                    context.commit(mutationName, response.data)
                }
                resolve()
            })
            .catch((error) => {
                if (error === 'connectionRefused') {
                    reject(error)
                }
                reject(error.response.data['hydra:description'])
            })
    })
}

import fetchModel from "../../mixin/store/api/fetchModel";

export default {
    actions: {
        fetchBookId(context, id) {
            return fetchModel(context, 'books/' + id + '/get', null, 'updateBook');
        },
    },
    mutations: {
        updateBook(state, data) {
            state.book = data
        },
        clearBook(state) {
            state.book = {
                adult: null,
                author: null,
                categories: [],
                chat: {},
                createdAt: null,
                copyright: null,
                coverSourceUrl: null,
                description: null,
                id: null,
                image: {},
                name: null,
                price: null,
                text: null,
                viewsCount: null,
                votes: null,
                writtenDate: null
            }
        }
    },
    state: {
        book: {
            adult: null,
            author: null,
            categories: [],
            chat: {},
            createdAt: null,
            copyright: null,
            coverSourceUrl: null,
            description: null,
            id: null,
            image: {},
            name: null,
            price: null,
            text: null,
            viewsCount: null,
            votes: null,
            writtenDate: null
        },
    },
    getters: {
        getBookId(state) {
            return state.book
        },
    },
}

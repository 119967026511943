import fetchModels from "../../mixin/store/api/fetchModels";
import pushModel from "../../mixin/store/api/pushModel";
import deleteModel from "../../mixin/store/api/deleteModel";

export default {
    actions: {
        fetchFavoriteBooks(context, page) {
            return fetchModels(context, 'favorites/get_user_favorite_books', page, 'updateFavoriteBooks');
        },
        fetchFavoriteBookCurrentUser(context, bookId) {
            return fetchModels(context, 'favorites/book_user?book=' + bookId, null, 'updateFavoriteBookUser', true)
        },
        fetchFavoriteAudioBookCurrentUser(context, audioBookId) {
            return fetchModels(context, 'favorites/audioBook_user?audioBook=' +  audioBookId, null, 'updateFavoriteAudioBookUser')
        },
        pushFavoriteAudioBook(context, data) {
            return pushModel(context, 'favorites/audioBooks', data, 'updateFavoriteAudioBook')
        },
        pushFavoriteBook(context, data) {
            return pushModel(context, 'favorites/books', data)
        },
        deleteFavorite(context, id) {
            return deleteModel(context, 'favorites', id)
        }
    },

    mutations: {
        updateFavoriteBooks(state, data) {
            state.favoriteBooks = data
        },
        updateFavoriteAudioBook(state, data) {
            state.favoriteAudioBook = data
        },
        updateFavoriteBookUser(state, data) {
            state.favoriteBookCurrentUser = data
        },
        updateFavoriteAudioBookUser(state, data) {
            state.favoriteAudioBookCurrentUser = data
        },
    },
    state: {
        favoriteBooks: {
            models: [],
            totalItems: 0,
        },
        favoriteAudioBook: {
            book: null,
            audioBook: null
        },
        favoriteBookCurrentUser: null,
        favoriteAudioBookCurrentUser: {
            models: [],
            totalItems: 0,
        },
    },
    getters: {
        getFavoriteBooks(state) {
            return state.favoriteBooks.models
        },
        getFavoriteBookCurrentUser(state) {
            return state.favoriteBookCurrentUser
        },
        getFavoriteAudioBookCurrentUser(state) {
            return state.favoriteAudioBookCurrentUser.models
        },
    },
}

import fetchModel from "@/mixin/store/api/fetchModel";
import changeModel from "@/mixin/store/api/changeModel";

export default {
    actions: {
        fetchNotification(context) {
            return fetchModel(context, 'notifications', null, 'updateNotification')
        },
        pushNotification(context, data) {
            return changeModel(context, 'notifications/' + data.id, data, null)
        }
    },
    mutations: {
        updateNotification(state, data) {
            state.notification = data
        }
    },
    state: {
        notification: {
            saleAndDiscount: null,
            bookRecommendation: null,
            gameContestTest: null
        }
    },
    getters: {
        getNotification(state) {
            return state.notification
        }
    }
}

import pushModel from "../../mixin/store/api/pushModel";
import fetchModels from "../../mixin/store/api/fetchModels";
import fetchModel from "../../mixin/store/api/fetchModel";
import changeOrPushmodel from '../../mixin/store/api/changeOrPushmodel'

export default {
    actions: {
        pushMyShelf (context, data) {
            return pushModel(context, 'my_shelves', data, 'responseMyShelf')
        },
        fetchMyShelves (context) {
            return fetchModels(context, 'my_shelves/current-user', null, 'updateMyShelves')
        },
        fetchMyShelf (context, id) {
            return fetchModel(context, 'my_shelves/get_shelf_books', id, 'updateMyShelf')
        },
        fetchLibraryDataCount (context) {
            return fetchModels(context, 'user/library', null, 'updateLibrary')
        },
        addAudioBookToShelf(context, data) {
            return changeOrPushmodel(context, 'my_shelves/add_audio_book', data)
        },
        addBookToShelf(context, data) {
            return changeOrPushmodel(context, 'my_shelves/add_book', data)
        },
    },

    mutations: {
        responseMyShelf(state, data) {
            state.data = data
        },
        updateMyShelves(state, data) {
            state.shelves = data
        },
        updateMyShelf(state, data) {
            state.shelf = data['hydra:member']
        },
        updateLibrary(state, data) {
            state.library = data
        }
    },

    state: {
        data: null,
        shelves: {
            models: [],
            totalItems: null
        },
        shelf: null,
        library: {
            favorite: null,
            finishedBook: null,
            savedBook: null,
            quote: null,
            readingBook: null,
            vote: null
        }
    },

    getters: {
        getMyShelves(state) {
            return state.shelves
        },
        getMyShelf(state) {
            return state.shelf
        },
        getLibraryDataCount(state) {
            return state.library
        }
    }
}
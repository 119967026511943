<template>
    <div>
        <div class="row" v-if="!isVisible">
            <div class="col container-col">
                <!-- header start -->
                <div class="row">
                    <div class="col fixed-top">
                        <div class="row header-name-row">
                            <div class="col-2 ps-2">
                                <go-back-button class="arrow-back"/>
                            </div>
                            <div class="col-8 header-name d-flex justify-content-center p-0">
                                {{ $t('security.security') }}
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                </div>
                <!-- header end -->

                <!-- content block row start -->
                <form>
                    <div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-11 center-block">
                                <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                    </symbol>
                                    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                                        <path
                                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                    </symbol>
                                    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                        <path
                                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </symbol>
                                </svg>

                                <div id="validationServer04Feedback" class="invalid-feedback">
                                    {{ $t('security.passwordWrong') }}
                                </div>
                                <label>{{ $t('security.enterNewPasswordText') }}</label>
                                <input
                                    type="password"
                                    :placeholder="$t('security.newPassword')"
                                    class="form-control input-size-2 "
                                    v-model="checkNewPassword.first"
                                />

                                <input
                                    type="password"
                                    :placeholder="$t('security.repeatNewPassword')"
                                    class="form-control input-size-3"
                                    :class="{'is-invalid': equalIsActive, 'form-control-red':equalIsActive,}"
                                    v-model="checkNewPassword.password"
                                    @blur="checkToEqual()"
                                />
                                <div class="feedback" v-if="equalIsActive">
                                    {{ $t('security.passwordsNotEquals') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex justify-content-center fixed-bottom">
                                <Button text="save" class="save-button" style="width: 104%" :onClick="changePassword"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div> <!--container col -->
        </div>

        <div class="row" v-if="isVisible">
            <div class="col logo d-flex justify-content-center">
                <img src="../../components/images/login-sixth-logo.png" alt="">
            </div>
        </div>

        <div class="row" v-if="isVisible">
            <div class="col text-one">
                {{ $t('verificationCode') }}
            </div>
        </div>

        <div class="row" v-if="isVisible">
            <div class="col text-two">
                {{$t('security.sendCodeToPhone')}} <br />
                <span
                class="number">
                {{'(' + getUser.phone.slice(3, 5) + ')' + ' ' + getUser.phone.slice(5, 8) + '-' + getUser.phone.slice(8, 10) + '-' + getUser.phone.slice(10, 12) }}
                </span>
            </div>
        </div>

        <div class="row" v-if="isVisible">
            <div class="col">
                <input
                    type="text"
                    placeholder="- - - - - -"
                    maxlength="6"
                    min="6"
                    class="form-control verification_code"
                    v-model="smsCode"
                    :class="{'is-invalid': isError}"
                >
            </div>
            <div class="errorMessage" v-if="errorMessage">{{$t('security.passwordWrong')}}</div>
        </div>

        <Button :onClick="checkSmsCode" text="next" v-if="isVisible" />

        <div class="text-primary text-center mt-5" v-if="isResend && isVisible" >{{$t('security.againSendCode')}}</div>

        <Button class="" :onClick="resendCode" text="send" v-if="isResend && isVisible"/>

    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Button from "../../components/button/Button.vue";
import GoBackButton from '../../components/modals/GoBackButton.vue'

export default {
    name: "reset-password-verification",
    components: { GoBackButton, Button},
    computed: {
        ...mapGetters(['getCheckCodeStatus', 'getUser'])
    },
    data() {
        return {
            smsCode: null,
            errorMessage: false,
            isError: false,
            isResend: false,
            checkNewPassword: {
                first: '',
                password: '',
            },
            equalIsActive: false,
            isVisible: true
        }
    },
    methods: {
        ...mapActions([
            'checkVerificationCode',
            'createVerification',
            'fetchUser',
            'pushChangePassword'
        ]),
        checkSmsCode() {
            this.$root.showOverlay = true
            this.checkVerificationCode({phone: this.getUser.phone, smsCode: this.smsCode})
                .then(() => {
                    if (this.getCheckCodeStatus) {
                        this.isVisible = false
                    } else {
                        this.errorMessage = true
                        this.isError = true
                    }
                })
                .finally(()=>{
                    this.$root.showOverlay = false
                })
        },
        resendCode(){
            this.$root.showOverlay = true
            this.createVerification({phoneNumber: this.getUser.phone})
                .then(() => {
                    this.$root.showOverlay = false
                })
            this.isResend = false
            this.smsCode = ''
            setTimeout(()=>{
                this.isResend = true
            }, 60000)
        },
        checkToEqual() {
            this.equalIsActive = this.checkNewPassword.first !== this.checkNewPassword.password;
        },
        changePassword() {
            if (this.checkNewPassword.first === this.checkNewPassword.password && this.getCheckCodeStatus) {
                this.correctPasswordIsActive = false
                this.pushChangePassword({
                    id: this.getUser.id,
                    password: this.checkNewPassword.password
                })
                    .then(() => {
                        this.$router.push('/setting')
                    })
            }
            if (this.checkNewPassword.first !== this.checkNewPassword.password) {
                this.equalIsActive = true
            }
        },
    },
    mounted() {
        this.fetchUser()
        setTimeout(()=>{
            this.isResend = true
        }, 60000)
    }
}
</script>

<style scoped>
.container-col {
    background-color: var(--color-container) !important;
    border-radius: 0 0 15px 15px;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.center-block {
    border-radius: 15px;
    margin: 167px 0 20px 0;
    background-color: var(--color-block);
    padding-bottom: 19px;
}

.form-control {
    width: 100%;
    height: 58px;
    border-radius: 12px;
    font-size: 14px;
    padding-left: 26px !important;
    border: 1px solid #0481F4;
    /*background-color: var(--color-input);*/
}

.form-control-red {
    border: 1px solid #f40404;
}

.input-size-2::placeholder, .input-size-3::placeholder {
    color: var(--color-textDarkGray-To-white);
}

.input-size-3 {
    margin: 8px 0 2px 0;
}

#validationServer04Feedback {
    margin-top: -8px;
    margin-bottom: 8px;
}

.feedback {
    color: #dc3545;
    font-size: .875em;

}

.save-button {
    margin-bottom: 26px;
}

.logo {
    margin-top: 82px;
}

.text-one {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 38px;
    font-weight: 500;
    color: var(--color-text);
}

.text-two {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    margin-top: 16px;
    color: var(--color-textGray-To-lightGray);
    align-items: center !important;
}

.number {
    color: #177EE6;
}

input {
    text-align: start !important;
    color: var(--color-text);
    font-size: 40px;
    width: 100%;
    height: 70px;
    border-radius: 12px;
    margin-top: 28px;
    border: 2px solid #177EE6;
}

.errorMessage {
    color: red;
    font-size: 14px;
}

label {
    padding: 27px 0 0 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.verification_code {
    text-align: center !important;
    font-size: 24px;
}
</style>

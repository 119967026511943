import pushModel from "@/mixin/store/api/pushModel";

export default {
    actions: {
        pushImage(context, data) {
            return pushModel(context, 'media_objects', data, 'pushImage')
        }
    },
    mutations: {
        pushImage(state, model) {
            state.mediaObject = model
        }
    },
    state: {
        mediaObject: {}
    },
    getters: {
        getImage(state) {
            return state.mediaObject
        }
    }
}
<template>
    <div class="bookmarks">
        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
            <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5 7.58329V18.4166C23.5 21.6666 21.875 23.8333 18.0833 23.8333H9.41667C5.625 23.8333 4 21.6666 4 18.4166V7.58329C4 4.33329 5.625 2.16663 9.41667 2.16663H18.0833C21.875 2.16663 23.5 4.33329 23.5 7.58329Z" fill="var(--fadedBlueToDark)"/>
                <path d="M17.5423 2.16663V10.6816C17.5423 11.1583 16.979 11.3966 16.6323 11.0825L14.119 8.76412C13.9132 8.56912 13.5881 8.56912 13.3823 8.76412L10.869 11.0825C10.5223 11.4075 9.95898 11.1583 9.95898 10.6816V2.16663H17.5423Z" fill="var(--blueToFaded)"/>
                <path d="M19.7077 15.9791H15.1035C14.6593 15.9791 14.291 15.6108 14.291 15.1666C14.291 14.7225 14.6593 14.3541 15.1035 14.3541H19.7077C20.1518 14.3541 20.5202 14.7225 20.5202 15.1666C20.5202 15.6108 20.1518 15.9791 19.7077 15.9791Z" fill="var(--blueToFaded)"/>
                <path d="M19.7083 20.3125H10.5C10.0558 20.3125 9.6875 19.9442 9.6875 19.5C9.6875 19.0558 10.0558 18.6875 10.5 18.6875H19.7083C20.1525 18.6875 20.5208 19.0558 20.5208 19.5C20.5208 19.9442 20.1525 20.3125 19.7083 20.3125Z" fill="var(--blueToFaded)"/>
            </svg>
        </button>
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
            <button class="close__btn" ref="close__btn" data-bs-dismiss="offcanvas"></button>
            <div class="line"></div>
            <div class="tabs">
                <div class="tab" :class="{'tab_active': isActiveChaptersTab}" @click="isActiveChaptersTab = true">Оглавление</div>
                <div class="tab" :class="{'tab_active': !isActiveChaptersTab}" @click="isActiveChaptersTab = false">Закладки</div>
            </div>
            <div class="offcanvas__chapters" v-if="isActiveChaptersTab">
                <div
                    class="chapter"
                    :class="{'chapter_active': index === currentAudio}"
                    v-for="(chapter, index) in audioBookChapters"
                    :key="index"
                    @click="$emit('onChangeChapter', index)"
                    data-bs-dismiss="offcanvas"
                >
                    <div class="chapter__name">{{ chapter.name }}</div>
                    <div class="chapter__duration">{{ getTimeOfChapter(chapter.duration) }}</div>
                </div>
            </div>
            <div class="offcanvas__bookmarks" v-if="!isActiveChaptersTab">
                <div
                    class="bookmark"
                    v-for="(bookmark, index) in audioBookBookmarks"
                    :key="index"
                >
                    <div class="bookmark__name" data-bs-dismiss="offcanvas" @click="$emit('onSelectBookmark', bookmark)">
                        <div class="name">{{bookmark.name}}</div>
                        <div class="time">{{ getTimeOfChapter(bookmark.time) }}</div>
                    </div>
                    <div class="bookmark__time">
                        <div class="bookmark__delete-btn" @click="deleteBookmark(bookmark.id)">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8032 3.9225C14.5957 3.8025 13.3882 3.7125 12.1732 3.645V3.6375L12.0082 2.6625C11.8957 1.9725 11.7307 0.9375 9.97573 0.9375H8.01073C6.26323 0.9375 6.09823 1.9275 5.97823 2.655L5.82073 3.615C5.12323 3.66 4.42573 3.705 3.72823 3.7725L2.19823 3.9225C1.88323 3.9525 1.65823 4.23 1.68823 4.5375C1.71823 4.845 1.98823 5.07 2.30323 5.04L3.83323 4.89C7.76323 4.5 11.7232 4.65 15.6982 5.0475C15.7207 5.0475 15.7357 5.0475 15.7582 5.0475C16.0432 5.0475 16.2907 4.83 16.3207 4.5375C16.3432 4.23 16.1182 3.9525 15.8032 3.9225Z" fill="#0481F4"/><path d="M14.4218 6.105C14.2418 5.9175 13.9943 5.8125 13.7393 5.8125H4.25932C4.00432 5.8125 3.74932 5.9175 3.57682 6.105C3.40432 6.2925 3.30682 6.5475 3.32182 6.81L3.78682 14.505C3.86932 15.645 3.97432 17.07 6.59182 17.07H11.4068C14.0243 17.07 14.1293 15.6525 14.2118 14.505L14.6768 6.8175C14.6918 6.5475 14.5943 6.2925 14.4218 6.105ZM10.2443 13.3125H7.74682C7.43932 13.3125 7.18432 13.0575 7.18432 12.75C7.18432 12.4425 7.43932 12.1875 7.74682 12.1875H10.2443C10.5518 12.1875 10.8068 12.4425 10.8068 12.75C10.8068 13.0575 10.5518 13.3125 10.2443 13.3125ZM10.8743 10.3125H7.12432C6.81682 10.3125 6.56182 10.0575 6.56182 9.75C6.56182 9.4425 6.81682 9.1875 7.12432 9.1875H10.8743C11.1818 9.1875 11.4368 9.4425 11.4368 9.75C11.4368 10.0575 11.1818 10.3125 10.8743 10.3125Z" fill="#0481F4"/></svg>
                        </div>
                        <div class="bookmark__date">{{ bookmark.createdAt.slice(0, 10) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "listen-bookmarks",
    props: {
        audioBookChapters: Array,
        audioBookBookmarks: Array,
        currentAudio: Number
    },
    data() {
        return {
            isActiveChaptersTab: true,
        }
    },
    methods: {
        ...mapActions(['deleteUserBookmark','fetchUserBookmarks']),
        getTimeOfChapter(sec) {
            let hour = Math.floor(sec / 3600)
            let min = Math.floor((sec - hour * 3600) / 60)
            let second = Math.floor(sec - (hour * 3600 + min * 60))

            hour < 10 ? hour = '0' + hour : hour
            min < 10 ? min = '0' + min : min
            second < 10 ? second = '0' + second : second

            if (hour > 0) {
                return `${hour}:${min}:${second}`
            }

            return `${min}:${second}`
        },
        deleteBookmark(id) {
            this.deleteUserBookmark(id)
                .then(() => {
                    this.fetchUserBookmarks(this.$route.params.id)
                })
        },
        closeModal() {
            this.$refs['close__btn'].click()
        }
    },
    mounted() {
        this.$parent.$on('parent-route-leave', this.closeModal);
    },
}
</script>

<style scoped>
.bookmarks button {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
}
.offcanvas {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 80vh;
    padding: 9px 26px 9px 26px;
    overflow: scroll;
    background: var(--color-block);
}
.line {
    background: var(--color-light-dark-gray);
    width: 101px;
    margin: 0 auto;
    height: 2px;
}
.tabs {
    margin-top: 20px;
    height: 38px;
    background: var(--color-light-dark-gray);
    display: flex;
    gap: 5px;
    padding: 5px;
    border-radius: 6px;
}

.tab {
    height: 28px;
    border-radius: 4px;
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-to-blue);
    font-size: 12px;
    line-height: 15px;
}

.tab_active {
    background: var(--white-to-dark);
    color: #0481F4;
}

.offcanvas__chapters {
    margin-top: 20px;
}

.chapter {
    display: flex;
    justify-content: space-between;
    height: 39px;
    align-items: center;
    padding: 11px;
    border-radius: 6px;
    gap: 5px;
}

.chapter_active {
    background: var(--color-light-dark-gray);
}

.chapter__name {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-Black-To-White);
}

.chapter__duration {
    font-weight: 400;
    color: var(--color-textGray-To-lightGray);
    font-size: 14px;
    line-height: 17px;
}

.offcanvas__bookmarks {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.bookmark {
    min-height: 75px;
    background: #F4F6FB;
    display: flex;
    padding: 14px 18px;
    border-radius: 12px;
    background: var(--color-light-dark-gray);
}

.bookmark__name {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 0 1 70%;
    overflow: hidden;
}

.name {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-Black-To-White);
    width: 100%;
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden;
}

.time {
    font-size: 12px;
    line-height: 14px;
    color: var(--color-textGray-To-lightGray);
}

.bookmark__time {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 0 1 30%;
}

.bookmark__delete-btn {
    align-self: flex-end;
    width: 18px;
    height: 18px;
}

.bookmark__date {
    font-size: 12px;
    line-height: 14px;
    color: var(--color-textGray-To-lightGray);
    align-self: flex-end;
}
</style>
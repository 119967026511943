<template>
    <div v-if="showTimer" class="row">
        <div class="col">
            <div class="text-end me-3">
                <button @click="stopTimer" class="btn btn-danger px-1 py-0">
                    <svg width="15" height="15" fill="currentColor" class="bi bi-x-lg mb-1" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>
            <h2 class="text-center started__time" :style="h2FontSize">{{ timeLeft }}</h2>
        </div>
    </div>

    <div v-else class="row mb-3 d-flex align-items-end">
        <div class="col-6">
            <div class="row">
                <div class="col-8 mx-auto">
                    <input @focus="isError = false" type="number" v-model="time.hour" :class="isError ? 'input-error' : ''" class="w-100" :placeholder="$t('hour')"/>
                </div>
            </div>
            <div class="row">
                <div class="col-8 mx-auto">
                    <input @focus="isError = false" type="number" v-model="time.min" :class="isError ? 'input-error' : ''" class="w-100 mt-3" :placeholder="$t('minutes')"/>
                </div>
            </div>
        </div>
        <div class="col-5">
            <button @click="setTime(time.hour, time.min)" class="w-100 btn btn-primary" type="button">{{$t('start')}}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "TimerMenu",
    data() {
        return {
            intervalTimer: null,
            timeLeft: '00:00:00',
            time: {
                min: null,
                hour: null
            },
            styles: {
                fontSizeH2: ''
            },
            showTimer: false,
            isError: false,
        }
    },
    computed: {
        h2FontSize() {
            return {'font-size': this.styles.fontSizeH2}
        }
    },
    methods: {
        setTime(hour, min) {
            if (hour <= 0 && min <= 0) {
                this.isError = true
            } else if (hour > 12 || min > 59) {
                this.isError = true
            } else {
                this.showTimer = true
                const seconds = (hour * 3600) + (min * 60)

                clearInterval(this.intervalTimer);
                this.countdown(seconds)
                this.displayTimeLeft(seconds);
            }
        },
        stopTimer() {
            this.showTimer = false
            this.time.hour = null
            this.time.min = null
            clearInterval(this.intervalTimer);
        },
        countdown(seconds) {
            this.intervalTimer = setInterval(() => {
                if (seconds === 0) {
                    clearInterval(this.intervalTimer);

                    try {
                        window.closeApk(true)
                    } catch {
                        console.log('Bu web')
                    }

                    return;
                }

                this.displayTimeLeft(seconds -= 1)
            }, 1000);
        },
        displayTimeLeft(seconds) {
            const hour = Math.floor(seconds / 3600);
            const min = Math.floor(seconds / 60) - (hour * 60)
            const sec = seconds % 60;

            this.timeLeft = this.zeroPadded(hour) + ':' + this.zeroPadded(min) + ':' + this.zeroPadded(sec)
        },
        zeroPadded(num) {
            return num < 10 ? ('0' + num) : num;
        }
    },
    mounted() {
        this.styles.fontSizeH2 = Math.floor(innerWidth / 7) + 'px'
    },
}
</script>

<style scoped>

input[type="number"] {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--color-Black-To-White);
    padding: 0 0 0 5px;
    -moz-appearance: textfield;
    background-color: transparent;
    color: var(--color-Black-To-White);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"]:focus {
    border: none;
    border-bottom: 1px solid green;
}

.input-error {
    border-bottom: 1px solid red !important;
    color: red;
}

.started__time {
    color: var(--color-Black-To-White);
}
</style>
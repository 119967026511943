import pushModel from "../../mixin/store/api/pushModel";
import fetchModels from "@/mixin/store/api/fetchModels";
import changeModel from "../../mixin/store/api/changeModel";
import deleteModel from "../../mixin/store/api/deleteModel";

export default {
    actions: {
        pushQuote(context, data) {
            return pushModel(context, 'quotes', data)
        },
        fetchQuotes(context) {
            return fetchModels(context, 'quotes/getUserQuotes', null, 'updateQuotes')
        },
        changeQuote(context, data) {
            return changeModel(context, 'quotes/' + data.id, data)
        },
        deleteQuote(context, id) {
            return deleteModel(context, 'quotes', id)
        }
    },
    mutations: {
        updateQuotes(state, data) {
            state.quotes = data
        }
    },
    state: {
        quotes: {}
    },
    getters: {
        getQuotes(state) {
            return state.quotes
        }
    }
}
<template>
    <div class="player">
        <div class="player__prev" @click="$emit('onClickPrev')"><svg  width="30" height="31" viewBox="0 0 30 31" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" class="forward-left">
            <path class="fadedBlueToFadeBlue"
                  d="M27.5 10.925V20.075C27.5 21.95 25.4625 23.125 23.8375 22.1875L19.875 19.9L15.9125 17.6125L15.3 17.2625V13.7375L15.9125 13.3875L19.875 11.1L23.8375 8.81253C25.4625 7.87503 27.5 9.05003 27.5 10.925Z"
                  fill="#C7E2FD"/>
            <path
                d="M15.3008 10.925V20.075C15.3008 21.95 13.2633 23.125 11.6508 22.1875L7.67581 19.9L3.71329 17.6125C2.10079 16.675 2.10079 14.325 3.71329 13.3875L7.67581 11.1L11.6508 8.81253C13.2633 7.87503 15.3008 9.05003 15.3008 10.925Z"
                fill="#0481F4"/>
        </svg></div>
        <div class="player__prev-time" @click="$emit('onClickPrevTime')"><svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="forward-15-left">
            <path class="blueToFade"
                  d="M18.0373 21.65H15.1748C14.6623 21.65 14.2373 21.225 14.2373 20.7125C14.2373 20.2 14.6623 19.775 15.1748 19.775H18.0373C18.5748 19.775 19.0123 19.3375 19.0123 18.8C19.0123 18.2625 18.5748 17.825 18.0373 17.825H15.1748C14.8748 17.825 14.5873 17.675 14.4123 17.4375C14.2373 17.2 14.1873 16.875 14.2873 16.5875L15.2373 13.725C15.3623 13.3375 15.7248 13.0875 16.1248 13.0875H19.9498C20.4623 13.0875 20.8873 13.5125 20.8873 14.025C20.8873 14.5375 20.4623 14.9625 19.9498 14.9625H16.7998L16.4748 15.95H18.0373C19.6123 15.95 20.8873 17.225 20.8873 18.8C20.8873 20.375 19.5998 21.65 18.0373 21.65Z"
                  fill="#0481F4"/>
            <path class="blueToFade"
                  d="M11.9259 21.65C11.4134 21.65 10.9884 21.225 10.9884 20.7125V16.4751L10.7509 16.75C10.4009 17.1375 9.8134 17.1625 9.4259 16.825C9.0384 16.475 9.0134 15.8875 9.3509 15.5L11.2259 13.4126C11.4884 13.1251 11.9009 13.025 12.2634 13.1625C12.6259 13.3 12.8634 13.6501 12.8634 14.0376V20.725C12.8634 21.2375 12.4509 21.65 11.9259 21.65Z"
                  fill="#0481F4"/>
            <path class="fadedBlueToFadeBlue"
                  d="M15.0007 4.85004C14.9007 4.85004 14.8008 4.86256 14.7008 4.86256L15.7258 3.58753C16.0508 3.18753 15.9883 2.58755 15.5758 2.27505C15.1633 1.96255 14.5882 2.01254 14.2632 2.42504L11.7882 5.51258C11.7757 5.52508 11.7757 5.55005 11.7632 5.56255C11.7257 5.61255 11.7008 5.67507 11.6758 5.73757C11.6508 5.78757 11.6257 5.83758 11.6132 5.90008C11.6007 5.96258 11.6008 6.0126 11.6008 6.0751C11.6008 6.1376 11.6008 6.20005 11.6008 6.26255C11.6008 6.28755 11.6008 6.30004 11.6008 6.32504C11.6133 6.36254 11.6383 6.40007 11.6508 6.43757C11.6758 6.50007 11.7008 6.55009 11.7258 6.61259C11.7633 6.66259 11.8007 6.71257 11.8507 6.75007C11.8757 6.77507 11.9007 6.81259 11.9257 6.85009C11.9382 6.86259 11.9633 6.86254 11.9758 6.87504C12.0133 6.90004 12.0507 6.9126 12.0882 6.9376C12.1507 6.9751 12.2258 7.0001 12.2883 7.0126C12.3258 7.0251 12.3632 7.02504 12.4132 7.03754C12.4507 7.03754 12.4758 7.05006 12.5133 7.05006C12.5508 7.05006 12.5758 7.03753 12.6008 7.02503C12.6383 7.02503 12.6757 7.03753 12.7132 7.02503C13.5132 6.83753 14.2632 6.75007 14.9757 6.75007C20.5882 6.75007 25.1507 11.3126 25.1507 16.9251C25.1507 22.5376 20.5882 27.1001 14.9757 27.1001C9.36322 27.1001 4.80078 22.5376 4.80078 16.9251C4.80078 14.7501 5.51324 12.6501 6.86324 10.8501C7.17574 10.4376 7.08828 9.85004 6.67578 9.53754C6.26328 9.22504 5.67572 9.31258 5.36322 9.72508C3.76322 11.8501 2.92578 14.3376 2.92578 16.9251C2.92578 23.5626 8.32572 28.9751 14.9757 28.9751C21.6257 28.9751 27.0257 23.5751 27.0257 16.9251C27.0257 10.2751 21.6382 4.85004 15.0007 4.85004Z"
                  fill="#C7E2FD"/>
        </svg></div>
        <div class="player__play">
            <svg v-if="!isPlaying" width="61" height="61" viewBox="0 0 38 38" fill="none"
                 @click="$emit('onPlayAudio')"
                 xmlns="http://www.w3.org/2000/svg">
                <circle cx="19" cy="19" r="19" fill="#0481F4"/>
                <path class="whiteToBlack"
                      d="M25.1656 17.0991C26.2985 17.7789 26.2985 19.4207 25.1656 20.1004L17.6504 24.6095C16.484 25.3094 15 24.4692 15 23.1089L15 14.0906C15 12.7303 16.484 11.8901 17.6504 12.59L25.1656 17.0991Z"
                      fill="white"/>
            </svg>
            <svg v-if="isPlaying" @click="$emit('onStopAudio')" width="61" height="61" viewBox="0 0 61 61" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <circle cx="30.5" cy="30.5" r="30.5" fill="#0481F4"/>
                <rect class="whiteToBlack" x="22" y="20" width="6" height="21" rx="2" fill="white"/>
                <rect class="whiteToBlack" x="32" y="20" width="6" height="21" rx="2" fill="white"/>
            </svg>
        </div>
        <div class="player__next-time" @click="$emit('onClickNextTime')"><svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="forward-15-right">
            <path
                class="blueToFade"
                d="M18.0392 21.65H15.1768C14.6643 21.65 14.2393 21.225 14.2393 20.7125C14.2393 20.2 14.6643 19.775 15.1768 19.775H18.0392C18.5767 19.775 19.0143 19.3375 19.0143 18.8C19.0143 18.2625 18.5767 17.825 18.0392 17.825H15.1768C14.8768 17.825 14.5893 17.675 14.4143 17.4375C14.2393 17.2 14.1892 16.875 14.2892 16.5875L15.2392 13.725C15.3642 13.3375 15.7268 13.0875 16.1268 13.0875H19.9518C20.4643 13.0875 20.8893 13.5125 20.8893 14.025C20.8893 14.5375 20.4643 14.9625 19.9518 14.9625H16.8017L16.4767 15.95H18.0392C19.6142 15.95 20.8893 17.225 20.8893 18.8C20.8893 20.375 19.6017 21.65 18.0392 21.65Z"
                fill="#0481F4"/>
            <path
                class="blueToFade"
                d="M11.9259 21.6489C11.4134 21.6489 10.9884 21.2239 10.9884 20.7114V16.474L10.7509 16.7489C10.4009 17.1364 9.8134 17.1614 9.4259 16.8239C9.0384 16.4739 9.0134 15.8864 9.3509 15.4989L11.2259 13.4115C11.4884 13.124 11.9009 13.0239 12.2634 13.1614C12.6259 13.2989 12.8634 13.649 12.8634 14.0365V20.7239C12.8634 21.2364 12.4509 21.6489 11.9259 21.6489Z"
                fill="#0481F4"/>
            <path class="fadedBlueToFadeBlue"
                  d="M24.6133 9.68752C24.3008 9.27502 23.7133 9.18757 23.3008 9.50007C22.8883 9.81257 22.8007 10.4001 23.1132 10.8126C24.4632 12.6126 25.1758 14.7125 25.1758 16.8875C25.1758 22.5 20.6132 27.0626 15.0007 27.0626C9.38825 27.0626 4.82573 22.5 4.82573 16.8875C4.82573 11.275 9.38825 6.71251 15.0007 6.71251C15.7257 6.71251 16.4632 6.80005 17.2632 6.98755C17.3007 7.00005 17.3383 6.98755 17.3758 6.98755C17.4133 6.98755 17.4382 7.01258 17.4632 7.01258C17.5007 7.01258 17.5257 7.00007 17.5632 7.00007C17.6007 7.00007 17.6383 6.98754 17.6883 6.97504C17.7633 6.96254 17.8257 6.92504 17.8882 6.90004C17.9257 6.87504 17.9633 6.86256 18.0008 6.83756C18.0133 6.82506 18.0383 6.82504 18.0508 6.81254C18.0883 6.78754 18.1007 6.75003 18.1257 6.72503C18.1757 6.67503 18.2132 6.63753 18.2507 6.57503C18.2882 6.52503 18.3007 6.46251 18.3257 6.40001C18.3382 6.36251 18.3633 6.32506 18.3758 6.28756C18.3758 6.26256 18.3758 6.25007 18.3758 6.22507C18.3883 6.16257 18.3883 6.10004 18.3758 6.03754C18.3758 5.97504 18.3758 5.92502 18.3633 5.86252C18.3508 5.81252 18.3258 5.76252 18.3008 5.70002C18.2758 5.63752 18.2508 5.57507 18.2133 5.52507C18.2008 5.51257 18.2007 5.48753 18.1882 5.47503L15.7133 2.38756C15.3883 1.98756 14.8008 1.91257 14.4008 2.23757C14.0008 2.56257 13.9383 3.15005 14.2508 3.55005L15.2757 4.82508C15.1757 4.82508 15.0757 4.81257 14.9757 4.81257C8.33822 4.81257 2.92578 10.2126 2.92578 16.8626C2.92578 23.5126 8.32572 28.9125 14.9757 28.9125C21.6257 28.9125 27.0257 23.5126 27.0257 16.8626C27.0507 14.3001 26.2008 11.8125 24.6133 9.68752Z"
                  fill="#C7E2FD"/>
        </svg></div>
        <div class="player__next" @click="$emit('onClickNext')"><svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                                       xmlns="http://www.w3.org/2000/svg" class="forward-right">
            <path class="fadedBlueToFadeBlue"
                  d="M2.5 10.925V20.075C2.5 21.95 4.53749 23.125 6.16249 22.1875L10.125 19.9L14.0875 17.6125L14.7 17.2625V13.7375L14.0875 13.3875L10.125 11.1L6.16249 8.81253C4.53749 7.87503 2.5 9.05003 2.5 10.925Z"
                  fill="#0481F4"/>
            <path
                d="M14.6992 10.925V20.075C14.6992 21.95 16.7367 23.125 18.3492 22.1875L22.3242 19.9L26.2867 17.6125C27.8992 16.675 27.8992 14.325 26.2867 13.3875L22.3242 11.1L18.3492 8.81253C16.7367 7.87503 14.6992 9.05003 14.6992 10.925Z"
                fill="#0481F4"/>
        </svg></div>
    </div>
</template>

<script>
export default {
    name: "listen-player",
    props: {
        isPlaying: Boolean,
    }
}
</script>

<style scoped>
.player {
    display: flex;
    gap: 26px;
    align-items: center;
    margin: 35px auto 39px auto;
}
</style>
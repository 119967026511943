<template>
    <div class="save">
        <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom1" aria-controls="offcanvasBottom">
            <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.15"
                      d="M18.2885 2H9.22102C5.26685 2 2.91602 4.35083 2.91602 8.29416V17.3617C2.91602 21.305 5.26685 23.6558 9.21018 23.6558H18.2777C22.221 23.6558 24.5718 21.305 24.5718 17.3617V8.29416C24.5827 4.35083 22.2319 2 18.2885 2Z"
                      fill="var(--blueToFade)"/>
                <path
                    d="M13.1751 18.0533C13.2509 18.1292 13.3376 18.1833 13.4351 18.2267C13.5326 18.27 13.6409 18.2917 13.7492 18.2917C13.8576 18.2917 13.9551 18.27 14.0634 18.2267C14.1609 18.1833 14.2476 18.1292 14.3234 18.0533L17.5734 14.8033C17.8876 14.4892 17.8876 13.9692 17.5734 13.655C17.2592 13.3408 16.7392 13.3408 16.4251 13.655L14.5617 15.5183V8.8125C14.5617 8.36833 14.1934 8 13.7492 8C13.3051 8 12.9367 8.36833 12.9367 8.8125V15.5183L11.0734 13.655C10.7592 13.3408 10.2392 13.3408 9.92508 13.655C9.61091 13.9692 9.61091 14.4892 9.92508 14.8033L13.1751 18.0533Z"
                    fill="var(--blueToFade)"/>
            </svg>
        </button>
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom1"
             aria-labelledby="offcanvasBottomLabel">
            <button class="close__btn" data-bs-dismiss="offcanvas" ref="close__btn">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.1" cx="14" cy="14" r="14" fill="#9597A1"/>
                    <path d="M18.456 9.54546L9.54688 18.4545" stroke="#9597A1" stroke-width="1.33333"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.54688 9.54546L18.456 18.4545" stroke="#9597A1" stroke-width="1.33333"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div class="save-columns">
                <div class="save__column" @click="addToFavorite">
                    <div class="save__icon">
                        <svg v-if="!getFavoriteAudioBookCurrentUser.length" width="24" height="25" viewBox="0 0 24 25"
                             fill="none" class="logo">
                            <path
                                d="M16.8203 2.11987H7.18031C5.06031 2.11987 3.32031 3.85987 3.32031 5.97987V20.0699C3.32031 21.8699 4.61031 22.6299 6.19031 21.7599L11.0703 19.0499C11.5903 18.7599 12.4303 18.7599 12.9403 19.0499L17.8203 21.7599C19.4003 22.6399 20.6903 21.8799 20.6903 20.0699V5.97987C20.6803 3.85987 18.9503 2.11987 16.8203 2.11987ZM15.6203 9.23987L11.6203 13.2399C11.4703 13.3899 11.2803 13.4599 11.0903 13.4599C10.9003 13.4599 10.7103 13.3899 10.5603 13.2399L9.06031 11.7399C8.77031 11.4499 8.77031 10.9699 9.06031 10.6799C9.35031 10.3899 9.83031 10.3899 10.1203 10.6799L11.0903 11.6499L14.5603 8.17987C14.8503 7.88987 15.3303 7.88987 15.6203 8.17987C15.9103 8.46987 15.9103 8.94987 15.6203 9.23987Z"
                                fill="var(--blueToWhite)"/>
                        </svg>
                        <svg v-else width="24" height="25" viewBox="0 0 24 25" fill="none" class="logo">
                            <path
                                d="M16.8203 2.11987H7.18031C5.06031 2.11987 3.32031 3.85987 3.32031 5.97987V20.0699C3.32031 21.8699 4.61031 22.6299 6.19031 21.7599L11.0703 19.0499C11.5903 18.7599 12.4303 18.7599 12.9403 19.0499L17.8203 21.7599C19.4003 22.6399 20.6903 21.8799 20.6903 20.0699V5.97987C20.6803 3.85987 18.9503 2.11987 16.8203 2.11987ZM15.6203 9.23987L11.6203 13.2399C11.4703 13.3899 11.2803 13.4599 11.0903 13.4599C10.9003 13.4599 10.7103 13.3899 10.5603 13.2399L9.06031 11.7399C8.77031 11.4499 8.77031 10.9699 9.06031 10.6799C9.35031 10.3899 9.83031 10.3899 10.1203 10.6799L11.0903 11.6499L14.5603 8.17987C14.8503 7.88987 15.3303 7.88987 15.6203 8.17987C15.9103 8.46987 15.9103 8.94987 15.6203 9.23987Z"
                                fill="var(--color-white-blue)"/>
                        </svg>
                    </div>
                    <div class="save__title">{{ $t('language.save') }}</div>
                </div>
                <div class="save__column" @click="pushToSaved">
                    <div class="save__icon">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" class="logo">
                            <path  class="blueToWhite" d="M16.19 2.20996H7.81C4.17 2.20996 2 4.37996 2 8.01996V16.39C2 20.04 4.17 22.21 7.81 22.21H16.18C19.82 22.21 21.99 20.04 21.99 16.4V8.01996C22 4.37996 19.83 2.20996 16.19 2.20996ZM8.47 12.19C8.76 11.9 9.24 11.9 9.53 12.19L11.25 13.91V7.71996C11.25 7.30996 11.59 6.96996 12 6.96996C12.41 6.96996 12.75 7.30996 12.75 7.71996V13.91L14.47 12.19C14.76 11.9 15.24 11.9 15.53 12.19C15.82 12.48 15.82 12.96 15.53 13.25L12.53 16.25C12.46 16.32 12.38 16.37 12.29 16.41C12.2 16.45 12.1 16.47 12 16.47C11.9 16.47 11.81 16.45 11.71 16.41C11.62 16.37 11.54 16.32 11.47 16.25L8.47 13.25C8.18 12.96 8.18 12.49 8.47 12.19Z" fill="var(--blueToWhite)"/>
                        </svg>
                    </div>
                    <div class="save__title">{{ $t('download') }}</div>
                </div>
                <div class="save__column save__column_btn">
                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path class="blueToWhite"
                                  d="M11.25 6V19.91C11.25 20.62 10.53 21.11 9.88 20.84C8.02 20.06 5.71 19.38 4.07 19.16L3.76 19.12C2.79 19 2 18.11 2 17.14V5.25C2 4.05 2.97 3.08 4.17 3.08H4.24C6.12 3.24 8.89 4.12 10.72 5.11C11.05 5.29 11.25 5.62 11.25 6Z"
                                  fill="var(--blueToWhite)"/>
                            <path class="blueToWhite"
                                  d="M19.83 3.08H19.77C19.53 3.1 19.27 3.13 19 3.18C17.77 3.38 16.31 3.8 15 4.32C14.38 4.57 13.8 4.84 13.29 5.11C12.96 5.29 12.75 5.63 12.75 6V19.91C12.75 20.62 13.47 21.11 14.12 20.84C15.98 20.06 18.29 19.38 19.93 19.16L20.24 19.12C21.21 19 22 18.11 22 17.14V5.25C22 4.05 21.03 3.08 19.83 3.08ZM19.27 13.73C19.27 14.12 18.99 14.29 18.65 14.1L17.59 13.51C17.48 13.45 17.3 13.45 17.18 13.51L16.12 14.1C15.78 14.29 15.5 14.12 15.5 13.73V10.66C15.5 10.2 15.88 9.82 16.34 9.82H18.44C18.9 9.82 19.28 10.2 19.28 10.66V13.73H19.27Z"
                                  fill="var(--blueToWhite)"/>
                        </svg>
                        <span style="color: var(--color-text)">{{ $t('addAShelf') }}</span>
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="shelf in getMyShelves.models" :key="shelf.id">
                            <a class="dropdown-item" @click="pushToShelf(shelf.id)" type="button">{{ shelf.name }}</a>
                        </li>

                        <li v-if="!getMyShelves.models.length"><a class="dropdown-item"
                                                                  href="#">{{ $t('shelf.youDoNotHaveSingleShelf') }}</a>
                        </li>
                    </ul>
                </div>
                <div class="save__column" @click="deleteFavoriteAudioBook">
                    <div class="save__icon">
                        <svg v-if="getFavoriteAudioBookCurrentUser.length" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" class="trash-icon">
                            <path class="blueToWhite"
                                  d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z"
                                  fill="var(--blueToWhite)"/>
                            <path class="blueToWhite"
                                  d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z"
                                  fill="var(--blueToWhite)"/>
                        </svg>
                        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" class="trash-icon">
                            <path
                                d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z"
                                fill="var(--color-white-blue)"/>
                            <path
                                d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z"
                                fill="var(--color-white-blue)"/>
                        </svg>
                    </div>
                    <div class="save__title">{{ $t('deleteFromMyBook') }}</div>
                </div>
                <notify-success :success-message-text="notifyMessage" :is-show-success="isShowSuccess"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NotifySuccess from "../../pages/library/notify-success";

export default {
    name: "listen-save",
    components: {NotifySuccess},
    computed: {
        ...mapGetters(['getIsSavedAudioBook', 'getFavoriteAudioBookCurrentUser', 'getMyShelves'])
    },
    data() {
        return {
            isShowSuccess: true,
            notifyMessage: '',
        }
    },
    methods: {
        ...mapActions([
            'fetchIsUserSavedAudioBook',
            'fetchFavoriteAudioBookCurrentUser',
            'fetchMyShelves', 'pushFavoriteAudioBook',
            'deleteFavorite',
            'addAudioBookToShelf',
            'pushSavedAudioBook'
        ]),
        addToFavorite() {
            if (!this.getFavoriteAudioBookCurrentUser.length) {
                this.pushFavoriteAudioBook({audioBook: '/api/audio_books/' + this.$route.params.id})
                    .then(() => {
                        this.fetchFavoriteAudioBookCurrentUser(this.$route.params.id)
                        this.notifyMessage = this.$t('bookAddedToFavorite')
                        this.isShowSuccess = !this.isShowSuccess
                    })
                    .catch(() => {
                        console.log('Something went wrong while adding')
                    })
            }
        },
        deleteFavoriteAudioBook() {
            this.fetchFavoriteAudioBookCurrentUser(this.$route.params.id)
                .then(() => {
                    if (this.getFavoriteAudioBookCurrentUser.length > 0) {
                        this.deleteFavorite(this.getFavoriteAudioBookCurrentUser[0].id).then(() => {
                            this.notifyMessage = this.$t('bookRemovedFromFavorite')
                            this.isShowSuccess = !this.isShowSuccess
                            this.fetchFavoriteAudioBookCurrentUser(this.$route.params.id)
                        })
                    }
                })
        },
        pushToShelf(id) {
            this.addAudioBookToShelf({
                ShelfId: id,
                audioBook: '/api/audio_books/' + this.$route.params.id
            }).then(() => {
                this.notifyMessage = this.$t('addedToShelf')
                this.isShowSuccess = !this.isShowSuccess
            })
        },
        pushToSaved() {
            try {
                window.bookId(this.$route.params.id)
            } catch (e) {
                console.log(e)
            }

            if (!this.getIsSavedAudioBook) {
                this.pushSavedAudioBook({audioBook: '/api/audio_books/' + this.$route.params.id})
                    .then(() => {
                        this.fetchIsUserSavedAudioBook(this.$route.params.bookId)
                    })
                    .catch(() => {
                        console.log('Something went wrong while pushingToSaved')
                    })
            }
        },
        closeModal() {
            this.$refs['close__btn'].click()
        }
    },
    mounted() {
        this.fetchIsUserSavedAudioBook(this.$route.params.id)
        this.fetchFavoriteAudioBookCurrentUser(this.$route.params.id)
        this.fetchMyShelves()
        this.$parent.$on('parent-route-leave', this.closeModal);
    }
}
</script>

<style scoped>
.save > button {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
}

.offcanvas {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 50vh;
    padding: 9px 26px 9px 26px;
    overflow: scroll;
    background: var(--color-block);
}

.close__btn {
    background: transparent;
    border: none;
    text-align: end;
    width: 50px;
    align-self: flex-end;
    margin-bottom: 8px;
}

.save__column_btn button {
    background: transparent;
    border: none;
    padding: 0;
}

.save__column_btn button svg {
    margin-right: 16px;
}

.save-columns {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.save__column {
    display: flex;
    gap: 16px;
    align-items: center;
}

.save__title {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: var(--color-text);
}

.dropdown-menu {
    /*max-width: 100%;*/
}
</style>
export default function (pageNumber) {
    if (pageNumber === null) {
        return 'pagination=false'
    }

    if (pageNumber === undefined) {
        return 'page=1'
    }

    if (pageNumber === 0) {
        return ''
    }

    return 'page=' + pageNumber
}
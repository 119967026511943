import pushModel from "../../mixin/store/api/pushModel";
import fetchModels from "../../mixin/store/api/fetchModels";

export default {
    actions: {
        fetchRecentRequest(context) {
            return fetchModels(context, 'recent_requests', null, 'updateRecentRequest')
        },
        pushRequest(context, data) {
            return pushModel(context, 'recent_requests', data)
        },
    },
    mutations: {
        updateRecentRequest(state, data) {
            state.recentRequest = data
        }
    },
    state: {
        recentRequest: {
            models: [],
            totalItems: 0
        }
    },
    getters: {
        getRecentRequest(state) {
            return state.recentRequest.models
        },

    }
}

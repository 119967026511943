<template>
    <div class="moment moment__container">
        <div class="moment__img" v-if="getContentMoments[currentMoment]"
             :class="{'video-content': !getContentMoments[currentMoment].type}">
            <img v-if="getContentMoments[currentMoment].type"
                 :src="localhost + getContentMoments[currentMoment].mediaObject.contentUrl" alt="girl">
            <video v-else :src="localhost + getContentMoments[currentMoment].mediaObject.contentUrl" autoplay/>
        </div>
        <div class="moment__progress progress">
            <div class="progress__rows">
                <div class="progress__row" v-for="(moment, index) in getContentMoments" :key="index">
                    <div
                        class="progress__progress"
                        :ref="'moment-' + index"
                        :class="{
                        'progress__progress-active': index === currentMoment,
                        'progress__full': index < currentMoment
                    }">
                    </div>
                </div>
            </div>
            <button class="progress__close-btn" @click="closeMoment">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="48" viewBox="0 96 960 960" width="48">
                    <path
                        d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/>
                </svg>
            </button>
        </div>
        <div class="moment__text-primary text" ref="primaryText">
            {{ primaryText }}
        </div>
        <div class="moment__text-secondary text" ref="secondaryText">
            {{ secondaryText }}
        </div>
        <div class="moment__change change">
            <div class="change__to-prev" @click="prevMoment"></div>
            <div class="change__to-next" @click="nextMoment"></div>
        </div>
        <div class="moment__quiz quiz" ref="moment__quiz">
            <div class="quiz__question"> {{ question }}</div>
            <div class="quiz__answers answers">
                <div class="answer__first answer">
                    <div class="progress" v-show="isAccepted">
                        <div class="answer__first-progress answer-progress" ref="answer__first-progress"></div>
                        <span>{{ Math.round(firstAnswerPercent) + '%' }}</span>
                    </div>
                    <span class="answer-text" v-show="!isAccepted" @click="selectFirstVariant"> {{ firstAnswer }}</span>
                </div>
                <div class="answer__second answer">
                    <div class="progress" v-show="isAccepted">
                        <div class="answer__second-progress answer-progress" ref="answer__second-progress"></div>
                        <span>{{ Math.round(secondAnswerPercent) + '%' }}</span>
                    </div>
                    <div class="answer-text" v-show="!isAccepted" @click="selectSecondVariant">{{ secondAnswer }}</div>
                </div>
            </div>
        </div>
        <div class="moment__btn">
            <button ref="moment__button" @click="goTo()">{{ momentButtonText }}</button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "moment",
    computed: {
        ...mapGetters(['getContentMoments', 'getMoments', 'getSearchAnswerMoment'])
    },
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
            currentMoment: 0,
            elementWidth: 0,
            isAccepted: false,
            primaryText: '',
            secondaryText: '',
            momentButtonText: '',
            question: '',
            firstAnswer: '',
            secondAnswer: '',
            firstAnswerPercent: 0,
            secondAnswerPercent: 0,
            isQuestionAccepted: false,
            countFirstAnswer: 0,
            countSecondAnswer: 0
        }
    },
    methods: {
        ...mapActions(['fetchContentMoment', 'fetchMoments', 'fetchSearchAnswerMoment', 'fetchAnswerMoment']),
        nextMoment() {
            if (this.currentMoment < this.getContentMoments.length - 1) {
                this.currentMoment++
                this.setAllComponents()
            } else {
                this.goToNextPage()
            }
        },
        prevMoment() {
            if (this.currentMoment > 0) {
                this.currentMoment--
                this.setAllComponents()
            } else {
                this.goToPrevPage()
            }
        },
        changeMoment() {
            if (this.currentMoment < this.getContentMoments.length - 1) {
                this.currentMoment++
                this.setAllComponents()
            } else {
                this.goToNextPage()
            }
        },
        setMomentDuration() {
            let progresses = document.querySelectorAll('.progress__progress')
            progresses[this.currentMoment].style.animationDuration = this.getContentMoments[this.currentMoment].duration + 'ms'
        },
        closeMoment() {
            this.$router.push('/')
        },
        playMoment() {
            let progressRows = document.querySelectorAll('.progress__progress')

            progressRows.forEach((el) => {
                el.addEventListener('animationend', this.changeMoment)
            })

            this.setMomentDuration()
        },
        goToNextPage() {
            let index = this.getMoments.findIndex((obj) => obj.id === parseInt(this.$route.params.id));

            if (this.getMoments[index + 1] !== null && index !== this.getMoments.length - 1) {
                this.clearData()
                this.currentMoment = -100
                this.$router.push('/moment/' + this.getMoments[index + 1].id)
            } else {
                this.$router.push('/')
            }
        },
        goToPrevPage() {
            let index = this.getMoments.findIndex((obj) => obj.id === parseInt(this.$route.params.id));

            if (this.getMoments[index - 1] !== null && index !== 0) {
                this.clearData()
                this.currentMoment = -100
                this.$router.push('/moment/' + this.getMoments[index - 1].id)
            } else {
                this.$router.push('/')
            }
        },
        goTo() {
            const url = this.getContentMoments[this.currentMoment].momentButtons[0].buttonUrl
            const regex = new RegExp(/^\/.*\/[0-9]*/g)

            if(regex.test(url)) {
                this.$router.push(url.replace('books', 'book'))
            } else {
                window.open('https://' + url);
            }
        },
        selectFirstVariant() {
            this.fetchAnswerMoment({
                firstOption: true,
                contentMoment: '/api/content_moments/' + this.getContentMoments[this.currentMoment].id
            }).then(() => {
                this.countFirstAnswer++
                this.isAccepted = true
                this.calcAnswersPercent()
                this.fetchContentMoment(this.$route.params.id)
            })
        },
        clearData() {
            this.$refs.moment__quiz.style.display = 'none'
            this.$refs.moment__button.style.display = 'none'
            this.primaryText = ''
            this.secondaryText = ''
            this.isAccepted = false
        },
        selectSecondVariant() {
            this.fetchAnswerMoment({
                secondOption: true,
                contentMoment: '/api/content_moments/' + this.getContentMoments[this.currentMoment].id
            }).then(() => {
                this.countSecondAnswer++
                this.isAccepted = true
                this.calcAnswersPercent()
                this.fetchContentMoment(this.$route.params.id)
            })
        },
        setMomentTexts() {
            if (this.getContentMoments[this.currentMoment].momentTexts[0]) {
                this.$refs.primaryText.style.top = this.getContentMoments[this.currentMoment].momentTexts[0].primaryTextTop + '%'
                this.$refs.primaryText.style.left = this.getContentMoments[this.currentMoment].momentTexts[0].primaryTextLeft + '%'
                this.primaryText = this.getContentMoments[this.currentMoment].momentTexts[0].primaryText
            } else {
                this.primaryText = ''
            }

            if (this.getContentMoments[this.currentMoment].momentTexts[0]) {
                this.$refs.secondaryText.style.top = this.getContentMoments[this.currentMoment].momentTexts[0].secondaryTextTop + '%'
                this.$refs.secondaryText.style.left = this.getContentMoments[this.currentMoment].momentTexts[0].secondaryTextLeft + '%'
                this.secondaryText = this.getContentMoments[this.currentMoment].momentTexts[0].secondaryText
            } else {
                this.secondaryText = ''
            }
        },
        setMomentButton() {
            if (this.getContentMoments[this.currentMoment].momentButtons[0]) {
                this.$refs.moment__button.style.display = 'block'
                this.$refs.moment__button.style.top = this.getContentMoments[this.currentMoment].momentButtons[0].customButtonTop + '%'
                this.momentButtonText = this.getContentMoments[this.currentMoment].momentButtons[0].buttonText
            } else {
                this.$refs.moment__button.style.display = 'none'
                this.momentButtonText = ''
            }
        },
        setQuiz() {
            this.countFirstAnswer = 0
            this.countSecondAnswer = 0
            this.firstAnswerPercent = 0
            this.secondAnswerPercent = 0

            if (this.getContentMoments[this.currentMoment].momentQuestions[0]) {
                this.$refs.moment__quiz.style.display = 'block'
                this.$refs.moment__quiz.style.top = this.getContentMoments[this.currentMoment].momentQuestions[0].customQuestionTop + '%'
                this.$refs.moment__quiz.style.left = this.getContentMoments[this.currentMoment].momentQuestions[0].customQuestionLeft + '%'

                this.question = this.getContentMoments[this.currentMoment].momentQuestions[0].question
                this.firstAnswer = this.getContentMoments[this.currentMoment].momentQuestions[0].firstOption
                this.secondAnswer = this.getContentMoments[this.currentMoment].momentQuestions[0].secondOption
                this.countFirstAnswer = this.getContentMoments[this.currentMoment].momentQuestions[0].countFirstOption
                this.countSecondAnswer = this.getContentMoments[this.currentMoment].momentQuestions[0].countSecondOption

                this.checkQuestionsAccepted()
                this.calcAnswersPercent()
            } else {
                this.$refs.moment__quiz.style.display = 'none'
                this.question = ''
                this.firstAnswer = ''
                this.secondAnswer = ''
            }
        },
        calcAnswersPercent() {
            let totalAnswers = this.countFirstAnswer + this.countSecondAnswer

            if (this.countFirstAnswer !== 0) {
                this.firstAnswerPercent = (this.countFirstAnswer / totalAnswers) * 100
            }

            if (this.countSecondAnswer !== 0) {
                this.secondAnswerPercent = (this.countSecondAnswer / totalAnswers) * 100
            }

            this.$refs["answer__first-progress"].style.width = `${this.firstAnswerPercent}%`
            this.$refs["answer__second-progress"].style.width = `${this.secondAnswerPercent}%`
        },
        checkQuestionsAccepted() {
            this.fetchSearchAnswerMoment(this.getContentMoments[this.currentMoment].id)
                .then(() => {
                    this.isAccepted = this.getSearchAnswerMoment.length !== 0;
                    this.calcAnswersPercent()
                })
        },
        setAllComponents() {
            this.playMoment()
            this.setMomentTexts()
            this.setMomentButton()
            this.setQuiz()
        }
    },
    mounted() {
        this.fetchContentMoment(this.$route.params.id)
            .then(() => {
                this.setAllComponents()
            })
        this.fetchMoments(1)
    },
    beforeDestroy() {
        this.$store.commit("clearContentMoment");
    },
    watch: {
        '$route.params.id'() {
            this.fetchContentMoment(this.$route.params.id)
                .then(() => {
                    this.$refs.moment__quiz.style.display = 'none'
                    this.currentMoment = 0
                    this.setAllComponents()
                })
        }
    }
}
</script>

<style scoped>
.moment__container {
    margin: 0 -12px;
    min-height: 100vh;
    font-family: 'Montserrat', serif;
    color: #FFFFFF;
}

.moment__img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.moment__img video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 3;
}

.moment__img::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #0D0D0D 74.48%);
    z-index: 2;
}

.moment__img.video-content::before {
    background: #000000;
}

.moment__progress {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: transparent;
    margin-top: 30px;
    padding: 0 14px;
    height: 50px;
}

.progress__rows {
    display: flex;
    gap: 3px;
    height: 3px;
    width: 100%;
}

.progress__row {
    width: 100%;
    background: #F4F6FB;
    border-radius: 3px;
}

.progress__progress {
    height: 100%;
    background: #177EE6;
    border-radius: 3px;
    width: 100%;
    display: none;
}

.progress__progress-active {
    animation: progressAnim ease 5s;
    display: block;
}

.progress__full {
    width: 100%;
    display: block;
}

@keyframes progressAnim {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.progress__close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background: transparent;
    border: none;
}

.progress__close-btn svg {
    width: 30px;
    height: 30px;
}

.text {
    z-index: 3;
    padding: 0 20px;
}

.moment__text-primary {
    position: absolute;
    top: 10%;
    left: 0;
    font-weight: 800;
    font-size: 26.5px;
    line-height: 32px;
}

.moment__text-secondary {
    position: absolute;
    top: 20%;
    left: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

}

.moment__change {
    position: absolute;
    z-index: 13;
    width: 100%;
    top: 12%;
    left: 0;
    height: 70%;
    display: flex;

}

.change__to-next {
    width: 100%;
    height: 100%;
}

.change__to-prev {
    width: 100%;
    height: 100%;
}

.moment__quiz {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    width: 200px;
    border-radius: 10px;
    background: #FFFFFF;
    display: none;
}

.quiz__question {
    background: #0481f4;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    font-size: 12px;
    text-align: center;
}

.answers {
    padding: 20px 10px 20px 10px;
    color: #0D0D0D;
}

.answer {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    position: relative;
    min-height: 30px;
}

.progress {
    background: transparent;
}

.answer-progress {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #0481f4;
    border-radius: 3px;
    align-items: center;
    width: 0;
}

.progress span {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding-left: 5px;
    background: transparent;
}

.answer-text {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.moment__btn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.moment__btn button {
    position: relative;
    top: 85%;
    width: 100%;
    background: #177EE6;
    border-radius: 12px;
    border: none;
    height: 65px;
    color: #FFFFFF;
    display: none;
}
</style>
import fetchModels from "../../mixin/store/api/fetchModels";
import deleteModel from "../../mixin/store/api/deleteModel";
import changeModel from "../../mixin/store/api/changeModel";
import pushModel from "@/mixin/store/api/pushModel";
import fetchModel from "@/mixin/store/api/fetchModel";

export default {
    actions: {
        createDevice(context, data) {
            return pushModel(context, 'devices', data, null)
        },
        fetchCurrentUserDevices(context) {
            return fetchModels(context, 'devices/current-user', 0, 'updateDevices')
        },
        fetchCurrentUserLastDevice(context) {
            return fetchModel(context, `devices/last/current-user`, null, 'updateLastDevice')
        },
        fetchDevice(context, id) {
            return fetchModel(context, 'devices', id, 'updateDevice')
        },
        changeDeviceBan(context, data) {
            return changeModel(context, 'devices/' + data.id, data)
        },
        deleteDevice(context, id) {
            return deleteModel(context, 'devices', id)
        }
    },
    mutations: {
        updateDevice(state, data) {
            state.device = data
        },
        updateDevices(state, data) {
            state.devices = data
        },
        updateLastDevice(state, data) {
            state.userLastDevice = data
        }
    },
    state: {
        device: {
            id: null,
            operationSystem: null,
            createdAt: null,
            updatedAt: null,
            region: null,
            name: null,
            ipAddress: null,
            city: null,
            ban: false
        },
        devices: {
            models: [],
            totalItems: 0
        },
        userLastDevice: {
            id: null,
            operationSystem: null,
            createdAt: null,
            updatedAt: null,
            region: null,
            name: null,
            ipAddress: null,
            city: null,
            ban: false
        }
    },
    getters: {
        getDevice(state) {
            return state.device
        },
        getDevices(state) {
            return state.devices.models
        },
        getUserLastDevice(state) {
            return state.userLastDevice
        }
    }
}

<template>
    <div class="row">
        <div class="col container-col">

            <div class="row">
                <div class="col fixed-top">
                    <div class="row header-name-row">
                        <div class="col-2 ps-2">
                            <go-back-button class="arrow-back"/>
                        </div>
                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{ $t('language.language') }}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center">
                <div class="col-11 center-block px-3">
                    <div v-if="this.language === 'ru'">
                        <button class="button-b mt-3">Русский</button>
                        <svg class="icon" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.57143L4.6 9L13 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <button v-else class="ru-btn mt-3" @click="language = 'ru'">Русский</button>

                    <div v-if="this.language === 'uz'">
                        <button class="button-b mt-3">O'zbek</button>
                        <svg class="icon" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.57143L4.6 9L13 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <button v-else class="ru-btn mt-3" @click="language = 'uz'">O'zbek</button>

                    <div v-if="this.language === 'en'">
                        <button class="button-b my-3">English</button>
                        <svg class="icon" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.57143L4.6 9L13 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <button v-else class="ru-btn my-3" @click="language = 'en'">English</button>
                </div>
            </div>

            <div class="row">
                <div class="col d-flex justify-content-center fixed-bottom">
                    <Button type="submit" text="save" style="width: 104%" :onClick="changeLanguage"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Button from "../../components/button/Button.vue";
import GoBackButton from "@/components/modals/GoBackButton.vue";

export default {
    name: "setting-language",
    components: {GoBackButton, Button},
    data() {
        return {
            language: this.$i18n.locale
        }
    },
    methods: {
        changeLanguage() {
            switch (this.language) {
                case 'uz':
                    this.setLanguage('uz')
                    break

                case 'en':
                    this.setLanguage('en')
                    break

                case 'ru':
                    this.setLanguage('ru')
                    break
            }

            this.$router.push('/setting')
        },
        setLanguage(lang) {
            this.$i18n.locale = lang
            localStorage.setItem('language', lang)

            try {
                window.pushLanguageToAndroid(lang)
            } catch {
                console.log('push language to android')
            }
        }
    },
}
</script>

<style scoped>
.fixed-bottom {
    margin-bottom: 18px;
}

.container-col {
    font-family: 'Montserrat', sans-serif !important;
    background-color: var(--color-container);
    border-radius: 0 0 15px 15px;
    padding-top: 147px;
    height: 100vh;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.center-block {
    border-radius: 15px;
    margin-top: 20px;
    background-color: var(--color-block);
}

.ru-btn {
    width: 100%;
    padding-left: 0;
    border-radius: 12px;
    height: 65px;
    color: #0481F4;
    border: none;
    background-color: var(--bg-button-row-lighter) !important;
}

.button-b {
    width: 100%;
    padding-left: 0;
    border-radius: 12px;
    height: 65px;
    color: white;
    border: none;
    background-color: #177EE6 !important;
}
.icon {
    position: absolute;
    margin: 45px 0 0 -40px;
}

</style>

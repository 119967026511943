<template>
    <div class="row all">
        <div class="col">
            <div class="row hashtag-name-row ps-2">
                <div class="col ps-4">
                    <router-link to="/related-hashtag">
                        <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="back-arrow">
                            <path d="M7.8175 18.6375C7.6275 18.6375 7.4375 18.5675 7.2875 18.4175L1.2175 12.3475C0.9275 12.0575 0.9275 11.5775 1.2175 11.2875L7.2875 5.2175C7.5775 4.9275 8.0575 4.9275 8.3475 5.2175C8.6375 5.5075 8.6375 5.9875 8.3475 6.2775L2.8075 11.8175L8.3475 17.3575C8.6375 17.6475 8.6375 18.1275 8.3475 18.4175C8.2075 18.5675 8.0075 18.6375 7.8175 18.6375Z" fill="white"/>
                            <path d="M27.748 12.5675H2.91797C2.50797 12.5675 2.16797 12.2275 2.16797 11.8175C2.16797 11.4075 2.50797 11.0675 2.91797 11.0675H27.748C28.158 11.0675 28.498 11.4075 28.498 11.8175C28.498 12.2275 28.158 12.5675 27.748 12.5675Z" fill="white"/>
                        </svg>
                    </router-link>
                </div>
            </div>

            <div class="row">
                <div class="col hashtag-name p-0 d-flex justify-content-center">
                    Полная Ж
                </div>
            </div>

            <div class="row d-flex justify-content-center people-row">

        <span class="col-2 p-0  d-flex justify-content-center">
            <figure class="profiles">
                <picture>
                    <img src="../../components/images/live-room/lola.png" width="42" height="42" alt="" class="visitor"/>
                </picture>
            <figcaption class="name-visitors ps-1">Евгения Попова</figcaption>
            </figure>
        </span>

                <span class="col-2 p-0 d-flex justify-content-center">
            <figure class="profiles">
                <picture>
                    <img src="../../components/images/live-room/radis.png" width="58" height="58" alt="" class="invitor"/>
                </picture>
                <figcaption class="name ps-1">Радислав Гандапас</figcaption>
            </figure>
        </span>

                <span class="col-2 ps-4 d-flex justify-content-center">
            <figure class="profiles">
                <picture>
                    <img src="../../components/images/live-room/maxim.png" width="42" height="42" alt="" class="visitor"/>
                </picture>
                <figcaption class="name-visitors ps-1"> Максим Батрыев</figcaption>
            </figure>
        </span>
            </div>

            <div class="row">
                <div class="col scrollable-row">
                    <!--profile row start -->
                    <div class="row mt-4">
                        <div class="col-2 profile-img-width">
                            <img src="../../components/images/live-room/lola.png" alt="">
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col commentator-name">
                                    No name
                                </div>
                            </div>
                            <div class="row">
                                <div class="col comment">
                                    когда начинается ?
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-end pe-5 time">
                            13:37
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-2 profile-img-width">
                            <img src="../../components/images/live-room/lola.png" alt="">
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col commentator-name">
                                    No name
                                </div>
                            </div>
                            <div class="row">
                                <div class="col comment">
                                    когда начинается ?
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-end pe-5 time">
                            13:37
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-2 profile-img-width">
                            <img src="../../components/images/live-room/lola.png" alt="">
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col commentator-name">
                                    No name
                                </div>
                            </div>
                            <div class="row">
                                <div class="col comment">
                                    когда начинается ?
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-end pe-5 time">
                            13:37
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-2 profile-img-width">
                            <img src="../../components/images/live-room/lola.png" alt="">
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col commentator-name">
                                    No name
                                </div>
                            </div>
                            <div class="row">
                                <div class="col comment">
                                    когда начинается ?
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-end pe-5 time">
                            13:37
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-2 profile-img-width">
                            <img src="../../components/images/live-room/lola.png" alt="">
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col commentator-name">
                                    No name
                                </div>
                            </div>
                            <div class="row">
                                <div class="col comment">
                                    когда начинается ?
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-end pe-5 time">
                            13:37
                        </div>
                    </div>

                    <!--profile row end -->
                </div>
            </div>

            <div class="row d-flex justify-content-center pb-4 pt-1">
                <div class="col-10 d-flex justify-content-center input">
                    <input type="text" class="form-control" placeholder="Сообщение">
                </div>
                <div class="col-2 ps-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="send-btn">
                        <path
                            d="M20.0937 7.34666L8.68035 1.64C1.01369 -2.2 -2.13298 0.946663 1.70702 8.61333L2.86702 10.9333C3.20035 11.6133 3.20035 12.4 2.86702 13.08L1.70702 15.3867C-2.13298 23.0533 1.00035 26.2 8.68035 22.36L20.0937 16.6533C25.2137 14.0933 25.2137 9.90666 20.0937 7.34666ZM15.787 13H8.58702C8.04035 13 7.58702 12.5467 7.58702 12C7.58702 11.4533 8.04035 11 8.58702 11H15.787C16.3337 11 16.787 11.4533 16.787 12C16.787 12.5467 16.3337 13 15.787 13Z"
                            fill="#F4F6FB"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "liveAudio"
}
</script>

<style scoped>
.all {
    font-family: 'Montserrat', sans-serif !important;
    background: url("../../components/images/live-room/BG.png");
}

.hashtag-name-row {
    padding-top: 67px;
}

.hashtag-name {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 17px;
    margin-top: -22px;
}

.invitor, .visitor {
    border-radius: 50%;
    border: 5px solid white;
}

.name-visitors {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #FFFFFF;
    font-style: normal;
    padding-top: 5px;
}

.visitor {
    margin-top: 8px;
}

.name {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    font-style: normal;
    padding-top: 5px;
}

figcaption {
    width: 50px;
}

.people-row {
    margin-top: 37px;
}

.scrollable-row {
    overflow-x: hidden;
    height: 288px;
    margin-top: 297px;
}

.form-control {
    height: 52px;
    border-radius: 40px;
    background: #F4F6FB;
    padding-left: 23px;
}

.send-btn {
    margin-top: 15px;
}

.input {
    padding-left: 40px;
}

::placeholder {
    color: #97CAFB;
    font-size: 14px;
}

.profile-img-width {
    margin-left: 44px !important;
    width: 55px;
}

.commentator-name {
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
}

.comment {
    color: #FFFFFF;
    font-size: 11px;
}

.time {
    color: #E5E5E5;
    font-size: 9px;
    margin-top: 7px;
}

</style>

<template>
    <div ref="footerRow" class="row">
        <div :style="{'gap': getGap}" class="col-12 footer d-flex justify-content-center align-items-center fixed-bottom">
            <router-link to="/">
                <svg v-if="$route.path === '/'" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M25.0501 8.52489L17.85 3.48739C15.8876 2.11239 12.875 2.18739 10.9875 3.64989L4.72505 8.53739C3.47505 9.51239 2.48755 11.5124 2.48755 13.0874V21.7124C2.48755 24.8999 5.07505 27.4999 8.26255 27.4999H21.7376C24.9251 27.4999 27.5126 24.9124 27.5126 21.7249V13.2499C27.5126 11.5624 26.4251 9.48739 25.0501 8.52489Z" fill="#0481F4"/>
                    <path d="M15 23.4375C14.4875 23.4375 14.0625 23.0125 14.0625 22.5V18.75C14.0625 18.2375 14.4875 17.8125 15 17.8125C15.5125 17.8125 15.9375 18.2375 15.9375 18.75V22.5C15.9375 23.0125 15.5125 23.4375 15 23.4375Z" fill="var(--color-block)"/>
                </svg>
                <svg v-else @click="$emit('clickFooterHome')" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M27.5 13.125C27.5 11.6125 26.4875 9.67496 25.25 8.81246L17.525 3.39996C15.775 2.17496 12.9625 2.23746 11.275 3.54996L4.5375 8.79996C3.4125 9.67496 2.5 11.5375 2.5 12.95V22.2125C2.5 25.1125 4.8625 27.4875 7.7625 27.4875H22.2375C25.1375 27.4875 27.5 25.1125 27.5 22.225V18.35" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 22.4875V18.7375" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>

            <router-link to="/support-service">
                <svg v-if="$route.path === '/support-service'" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M21.25 3.0376H8.75C5 3.0376 2.5 5.5376 2.5 9.2876V16.7876C2.5 20.5376 5 23.0376 8.75 23.0376V25.7001C8.75 26.7001 9.8625 27.3001 10.6875 26.7376L16.25 23.0376H21.25C25 23.0376 27.5 20.5376 27.5 16.7876V9.2876C27.5 5.5376 25 3.0376 21.25 3.0376ZM15 18.2501C14.475 18.2501 14.0625 17.8251 14.0625 17.3126C14.0625 16.8001 14.475 16.3751 15 16.3751C15.525 16.3751 15.9375 16.8001 15.9375 17.3126C15.9375 17.8251 15.525 18.2501 15 18.2501ZM16.575 13.0626C16.0875 13.3876 15.9375 13.6001 15.9375 13.9501V14.2126C15.9375 14.7251 15.5125 15.1501 15 15.1501C14.4875 15.1501 14.0625 14.7251 14.0625 14.2126V13.9501C14.0625 12.5001 15.125 11.7876 15.525 11.5126C15.9875 11.2001 16.1375 10.9876 16.1375 10.6626C16.1375 10.0376 15.625 9.5251 15 9.5251C14.375 9.5251 13.8625 10.0376 13.8625 10.6626C13.8625 11.1751 13.4375 11.6001 12.925 11.6001C12.4125 11.6001 11.9875 11.1751 11.9875 10.6626C11.9875 9.0001 13.3375 7.6501 15 7.6501C16.6625 7.6501 18.0125 9.0001 18.0125 10.6626C18.0125 12.0876 16.9625 12.8001 16.575 13.0626Z" fill="#0481F4"/>
                </svg>
                <svg v-else @click="$emit('clickFooterSupport')" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M2.5 14.45V16.7876C2.5 20.5376 5 23.0376 8.75 23.0376H13.75L19.3125 26.7375C20.1375 27.2875 21.25 26.7001 21.25 25.7001V23.0376C25 23.0376 27.5 20.5376 27.5 16.7876V9.28748C27.5 5.53748 25 3.03748 21.25 3.03748H8.75C5 3.03748 2.5 5.53748 2.5 9.28748" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.0007 14.2V13.9375C15.0007 13.0875 15.5258 12.6375 16.0508 12.275C16.5633 11.925 17.0757 11.475 17.0757 10.65C17.0757 9.49999 16.1507 8.57495 15.0007 8.57495C13.8507 8.57495 12.9258 9.49999 12.9258 10.65" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.9944 17.1875H15.0056" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>

            <router-link to="/library">
                <svg v-if="$route.path === '/library'" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M27.5 6.0626V20.9251C27.5 22.1376 26.5125 23.2501 25.3 23.4001L24.9125 23.4501C22.8625 23.7251 19.975 24.5751 17.65 25.5501C16.8375 25.8876 15.9375 25.2751 15.9375 24.3876V7.0001C15.9375 6.5376 16.2 6.1126 16.6125 5.8876C18.9 4.6501 22.3625 3.5501 24.7125 3.3501H24.7875C26.2875 3.3501 27.5 4.5626 27.5 6.0626Z" fill="#0481F4"/>
                    <path d="M13.3883 5.8876C11.1008 4.6501 7.63828 3.5501 5.28828 3.3501H5.20078C3.70078 3.3501 2.48828 4.5626 2.48828 6.0626V20.9251C2.48828 22.1376 3.47578 23.2501 4.68828 23.4001L5.07578 23.4501C7.12578 23.7251 10.0133 24.5751 12.3383 25.5501C13.1508 25.8876 14.0508 25.2751 14.0508 24.3876V7.0001C14.0508 6.5251 13.8008 6.1126 13.3883 5.8876ZM6.25078 9.6751H9.06328C9.57578 9.6751 10.0008 10.1001 10.0008 10.6126C10.0008 11.1376 9.57578 11.5501 9.06328 11.5501H6.25078C5.73828 11.5501 5.31328 11.1376 5.31328 10.6126C5.31328 10.1001 5.73828 9.6751 6.25078 9.6751ZM10.0008 15.3001H6.25078C5.73828 15.3001 5.31328 14.8876 5.31328 14.3626C5.31328 13.8501 5.73828 13.4251 6.25078 13.4251H10.0008C10.5133 13.4251 10.9383 13.8501 10.9383 14.3626C10.9383 14.8876 10.5133 15.3001 10.0008 15.3001Z" fill="#0481F4"/>
                </svg>
                <svg v-else @click="$emit('clickFooterLibrary')" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M27.5 13.7375V5.83749C27.5 4.33749 26.275 3.22499 24.7875 3.34999H24.7125C22.0875 3.57499 18.1 4.91249 15.875 6.31249L15.6625 6.44999C15.3 6.67499 14.7 6.67499 14.3375 6.44999L14.025 6.26249C11.8 4.87499 7.825 3.54999 5.2 3.33749C3.7125 3.21249 2.5 4.33749 2.5 5.82499V20.925C2.5 22.125 3.475 23.25 4.675 23.4L5.0375 23.45C7.75 23.8125 11.9375 25.1875 14.3375 26.5L14.3875 26.525C14.725 26.7125 15.2625 26.7125 15.5875 26.525C17.9875 25.2 22.1875 23.8125 24.9125 23.45L25.325 23.4C26.525 23.25 27.5 22.125 27.5 20.925V18.775" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 6.86249V25.6125" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.6875 10.6125H6.875" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.625 14.3625H6.875" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>

            <router-link to="/search">
                <svg v-if="$route.path === '/search'" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M13.7625 25.025C19.9826 25.025 25.025 19.9826 25.025 13.7625C25.025 7.54239 19.9826 2.5 13.7625 2.5C7.54239 2.5 2.5 7.54239 2.5 13.7625C2.5 19.9826 7.54239 25.025 13.7625 25.025Z" fill="#0481F4"/>
                    <path d="M27.4876 23.6875C27.0751 22.925 26.2001 22.5 25.0251 22.5C24.1376 22.5 23.3751 22.8625 22.9251 23.4875C22.4751 24.1125 22.3751 24.95 22.6501 25.7875C23.1876 27.4125 24.1251 27.775 24.6376 27.8375C24.7126 27.85 24.7876 27.85 24.8751 27.85C25.4251 27.85 26.2751 27.6125 27.1001 26.375C27.7626 25.4125 27.8876 24.45 27.4876 23.6875Z" fill="#0481F4"/>
                </svg>
                <svg v-else @click="$emit('clickFooterSearch')" width="30" height="30" viewBox="0 0 30 30" fill="none" >
                    <path d="M13.75 2.5C19.9625 2.5 25 7.5375 25 13.75C25 19.9625 19.9625 25 13.75 25C7.5375 25 2.5 19.9625 2.5 13.75C2.5 9.375 5 5.575 8.6625 3.7125" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M23.8382 26.2125C24.5007 28.2125 26.0132 28.4125 27.1757 26.6625C28.2507 25.0625 27.5507 23.75 25.6257 23.75C24.1882 23.75 23.3882 24.8625 23.8382 26.2125Z" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>

            <router-link to="/profile">
                <svg v-if="$route.path === '/profile'" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M24.3879 7.3125L16.9629 3.025C15.7504 2.325 14.2504 2.325 13.0254 3.025L5.61289 7.3125C4.40039 8.0125 3.65039 9.3125 3.65039 10.725V19.275C3.65039 20.675 4.40039 21.975 5.61289 22.6875L13.0379 26.975C14.2504 27.675 15.7504 27.675 16.9754 26.975L24.4004 22.6875C25.6129 21.9875 26.3629 20.6875 26.3629 19.275V10.725C26.3504 9.3125 25.6004 8.025 24.3879 7.3125ZM15.0004 9.175C16.6129 9.175 17.9129 10.475 17.9129 12.0875C17.9129 13.7 16.6129 15 15.0004 15C13.3879 15 12.0879 13.7 12.0879 12.0875C12.0879 10.4875 13.3879 9.175 15.0004 9.175ZM18.3504 20.825H11.6504C10.6379 20.825 10.0504 19.7 10.6129 18.8625C11.4629 17.6 13.1129 16.75 15.0004 16.75C16.8879 16.75 18.5379 17.6 19.3879 18.8625C19.9504 19.6875 19.3504 20.825 18.3504 20.825Z" fill="#0481F4"/>
                </svg>
                <svg v-else @click="$emit('clickFooterProfile')" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M13.0379 3.025C14.2504 2.325 15.7504 2.325 16.9754 3.025L24.4004 7.31249C25.6129 8.01249 26.3629 9.31254 26.3629 10.725V19.275C26.3629 20.675 25.6129 21.9751 24.4004 22.6876L16.9754 26.975C15.7629 27.675 14.2629 27.675 13.0379 26.975L5.61289 22.6876C4.40039 21.9876 3.65039 20.6875 3.65039 19.275V10.725C3.65039 9.32504 4.40039 8.02499 5.61289 7.31249L7.98789 5.93752" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.0004 13.75C16.6089 13.75 17.9129 12.446 17.9129 10.8375C17.9129 9.22893 16.6089 7.92502 15.0004 7.92502C13.3919 7.92502 12.0879 9.22893 12.0879 10.8375C12.0879 12.446 13.3919 13.75 15.0004 13.75Z" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 20.825C20 18.575 17.7625 16.75 15 16.75C12.2375 16.75 10 18.575 10 20.825" stroke="var(--color-65B-to-5e7)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "footerMenu",
    data() {
        return {
            isWidthBig330px: false
        }
    },
    computed: {
        getGap() {
            return this.isWidthBig330px ? '11%' : '8%'
        }
    },
    mounted() {
        if (this.$refs.footerRow.clientWidth > 330) {
            this.isWidthBig330px = true
        }
    }
}
</script>

<style scoped>

.footer {
    height: 70px;
    background: var(--color-block);
    border-radius: 12px 12px 0 0;
    margin-bottom: -1px;
}

</style>

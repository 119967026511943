<script>
import FooterMenu from "@/components/footer/footer.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "library",
    components: {FooterMenu},
    computed: {
        ...mapGetters(['getImage', 'getLibraryDataCount', 'getMyShelves'])
    },
    mounted() {
        this.isWidthBig330px = document.body.clientWidth > 330

        this.fetchLibraryDataCount()
        this.fetchMyShelves()
    },
    data() {
        return {
            isWidthBig330px: false,
            isShowShelfMenu: true,
            isLoading: false,
            isBlankInput: false,
            isSelectedImage: false,
            imgUrl: '',
            selectedImage: null,
            shelf: {
                name: '',
                description: '',
                mediaObject: null
            },
            host: process.env.VUE_APP_API_URL
        }
    },
    methods: {
        ...mapActions(['pushImage', 'pushMyShelf', 'fetchLibraryDataCount', 'fetchMyShelves']),
        goToCreateShelf() {
            this.isShowShelfMenu = false

            this.$refs.selectImage.addEventListener('change', ev => {
                if (ev.target.files.length) {
                    this.selectedImage = ev.target.files[0]
                    this.imgUrl = URL.createObjectURL(ev.target.files[0]);
                    this.isSelectedImage = true
                }
            })
        },
        createShelf() {
            this.isLoading = true

            if (this.shelf.name === '' || this.shelf.description === '') {
                this.isLoading = false
                this.isBlankInput = true
            } else {
                if (this.selectedImage === null) {
                    this.pushMyShelf(this.shelf)
                        .then(() => {
                            this.fetchMyShelves()
                            this.isLoading = false
                            this.isShowShelfMenu = true
                        })
                        .catch(() => {this.isLoading = false})
                } else {
                    const data = new FormData()
                    data.append('file', this.selectedImage)

                    this.pushImage(data)
                        .then(() => {
                            this.shelf.mediaObject = this.getImage['@id']
                            this.pushMyShelf(this.shelf)
                                .then(() => {
                                    this.fetchMyShelves()
                                    this.isLoading = false
                                    this.isShowShelfMenu = true
                                })
                                .catch(() => {this.isLoading = false})
                        })
                        .catch(() => {this.isLoading = false})
                }
            }
        }
    }
}
</script>

<template>
    <div class="row pb-5 content">
        <div class="col-12">
            <div class="row header__row py-5">
                <div class="col-3 text-center">
                    <svg @click="$router.back()" width="29" height="24" viewBox="0 0 29 24" fill="none">
                        <path
                              d="M7.8175 18.6375C7.6275 18.6375 7.4375 18.5675 7.2875 18.4175L1.2175 12.3475C0.9275 12.0575 0.9275 11.5775 1.2175 11.2875L7.2875 5.2175C7.5775 4.9275 8.0575 4.9275 8.3475 5.2175C8.6375 5.5075 8.6375 5.9875 8.3475 6.2775L2.8075 11.8175L8.3475 17.3575C8.6375 17.6475 8.6375 18.1275 8.3475 18.4175C8.2075 18.5675 8.0075 18.6375 7.8175 18.6375Z"
                              fill="var(--color-text)"/>
                        <path
                              d="M27.748 12.5675H2.91797C2.50797 12.5675 2.16797 12.2275 2.16797 11.8175C2.16797 11.4075 2.50797 11.0675 2.91797 11.0675H27.748C28.158 11.0675 28.498 11.4075 28.498 11.8175C28.498 12.2275 28.158 12.5675 27.748 12.5675Z"
                              fill="var(--color-text)"/>
                    </svg>
                </div>
                <div class="col-6 header__text">{{ $t('library.library') }}</div>
                <div class="col-3"></div>
            </div>

            <div :class="isWidthBig330px ? 'px-3' : 'px-2'" class="row mb-5">
                <div class="col-12 mt-3 px-4 menu__block">
                    <div
                        @click="getLibraryDataCount.favorite > 0 ? $router.push('/saved') : ''"
                        class="d-flex justify-content-between py-4"
                    >
                        <span>
                            <svg class="me-3" fill="none" height="25" viewBox="0 0 24 25" width="24">
                                <path d="M16.8203 2.11987H7.18031C5.06031 2.11987 3.32031 3.85987 3.32031 5.97987V20.0699C3.32031 21.8699 4.61031 22.6299 6.19031 21.7599L11.0703 19.0499C11.5903 18.7599 12.4303 18.7599 12.9403 19.0499L17.8203 21.7599C19.4003 22.6399 20.6903 21.8799 20.6903 20.0699V5.97987C20.6803 3.85987 18.9503 2.11987 16.8203 2.11987ZM15.6203 9.23987L11.6203 13.2399C11.4703 13.3899 11.2803 13.4599 11.0903 13.4599C10.9003 13.4599 10.7103 13.3899 10.5603 13.2399L9.06031 11.7399C8.77031 11.4499 8.77031 10.9699 9.06031 10.6799C9.35031 10.3899 9.83031 10.3899 10.1203 10.6799L11.0903 11.6499L14.5603 8.17987C14.8503 7.88987 15.3303 7.88987 15.6203 8.17987C15.9103 8.46987 15.9103 8.94987 15.6203 9.23987Z"
                                      fill="var(--blueToWhite)"/>
                            </svg>
                            <span class="block__text">{{ $t('library.saved') }}</span>
                        </span>

                        <span class="pe-1 block__number">{{getLibraryDataCount.favorite}}</span>
                    </div>
                    <div class="block__line"></div>

                    <div
                        @click="getLibraryDataCount.readingBook > 0 ? $router.push('/currently-reading') : ''"
                        class="d-flex justify-content-between py-4"
                    >
                        <span>
                            <svg class="me-3" fill="none" height="25" viewBox="0 0 24 25" width="24">
                                <path d="M22 5.06014V16.9501C22 17.9201 21.21 18.8101 20.24 18.9301L19.93 18.9701C18.29 19.1901 15.98 19.8701 14.12 20.6501C13.47 20.9201 12.75 20.4301 12.75 19.7201V5.81014C12.75 5.44014 12.96 5.10014 13.29 4.92014C15.12 3.93014 17.89 3.05014 19.77 2.89014H19.83C21.03 2.89014 22 3.86014 22 5.06014Z"
                                      fill="var(--blueToWhite)"/>
                                <path d="M10.7102 4.92014C8.88023 3.93014 6.11023 3.05014 4.23023 2.89014H4.16023C2.96023 2.89014 1.99023 3.86014 1.99023 5.06014V16.9501C1.99023 17.9201 2.78023 18.8101 3.75023 18.9301L4.06023 18.9701C5.70023 19.1901 8.01023 19.8701 9.87023 20.6501C10.5202 20.9201 11.2402 20.4301 11.2402 19.7201V5.81014C11.2402 5.43014 11.0402 5.10014 10.7102 4.92014ZM5.00023 7.95014H7.25023C7.66023 7.95014 8.00023 8.29014 8.00023 8.70014C8.00023 9.12014 7.66023 9.45014 7.25023 9.45014H5.00023C4.59023 9.45014 4.25023 9.12014 4.25023 8.70014C4.25023 8.29014 4.59023 7.95014 5.00023 7.95014ZM8.00023 12.4501H5.00023C4.59023 12.4501 4.25023 12.1201 4.25023 11.7001C4.25023 11.2901 4.59023 10.9501 5.00023 10.9501H8.00023C8.41023 10.9501 8.75023 11.2901 8.75023 11.7001C8.75023 12.1201 8.41023 12.4501 8.00023 12.4501Z"
                                      fill="var(--blueToWhite)"/>
                            </svg>
                            <span class="block__text">{{ $t('library.readingNow') }}</span>
                        </span>

                        <span class="pe-1 block__number">{{getLibraryDataCount.readingBook}}</span>
                    </div>
                    <div class="block__line"></div>

                    <div
                        @click="getLibraryDataCount.finishedBook > 0 ? $router.push('/finished-book') : ''"
                        class="d-flex justify-content-between py-4"
                    >
                        <span>
                            <svg class="me-3" fill="none" height="25" viewBox="0 0 24 25" width="24">
                                <path d="M18.0204 12.54L16.8004 11.32C16.5104 11.07 16.3404 10.7 16.3304 10.29C16.3104 9.83996 16.4904 9.38996 16.8204 9.05996L18.0204 7.85996C19.0604 6.81996 19.4504 5.81996 19.1204 5.02996C18.8004 4.24996 17.8104 3.81996 16.3504 3.81996H5.90039V2.95996C5.90039 2.54996 5.56039 2.20996 5.15039 2.20996C4.74039 2.20996 4.40039 2.54996 4.40039 2.95996V21.46C4.40039 21.87 4.74039 22.21 5.15039 22.21C5.56039 22.21 5.90039 21.87 5.90039 21.46V16.58H16.3504C17.7904 16.58 18.7604 16.14 19.0904 15.35C19.4204 14.56 19.0404 13.57 18.0204 12.54Z"
                                      fill="var(--blueToWhite)"/>
                            </svg>
                            <span class="block__text">{{ $t('library.finished') }}</span>
                        </span>

                        <span class="pe-1 block__number">{{getLibraryDataCount.finishedBook}}</span>
                    </div>
                    <div class="block__line"></div>

                    <div
                        @click="getLibraryDataCount.savedBook > 0 ? $router.push('/off-line') : ''"
                        class="d-flex justify-content-between py-4"
                    >
                        <span>
                            <svg class="me-3" fill="none" height="25" viewBox="0 0 24 25" width="24">
                                <path d="M16.19 2.20996H7.81C4.17 2.20996 2 4.37996 2 8.01996V16.39C2 20.04 4.17 22.21 7.81 22.21H16.18C19.82 22.21 21.99 20.04 21.99 16.4V8.01996C22 4.37996 19.83 2.20996 16.19 2.20996ZM8.47 12.19C8.76 11.9 9.24 11.9 9.53 12.19L11.25 13.91V7.71996C11.25 7.30996 11.59 6.96996 12 6.96996C12.41 6.96996 12.75 7.30996 12.75 7.71996V13.91L14.47 12.19C14.76 11.9 15.24 11.9 15.53 12.19C15.82 12.48 15.82 12.96 15.53 13.25L12.53 16.25C12.46 16.32 12.38 16.37 12.29 16.41C12.2 16.45 12.1 16.47 12 16.47C11.9 16.47 11.81 16.45 11.71 16.41C11.62 16.37 11.54 16.32 11.47 16.25L8.47 13.25C8.18 12.96 8.18 12.49 8.47 12.19Z"
                                      fill="var(--blueToWhite)"/>
                            </svg>
                            <span class="block__text">{{ $t('library.offline') }}</span>
                        </span>

                        <span class="pe-1 block__number">{{getLibraryDataCount.savedBook}}</span>
                    </div>
                </div>
                <div v-if="getMyShelves.totalItems !== null" class="col-12 mt-3 py-3 menu__block">
                    <div class="px-3 shelf__section-text">{{ $t('shelf.myShelves') }}</div>

                    <div v-if="isShowShelfMenu">
                        <div v-if="getMyShelves.totalItems">
                            <div
                                v-for="shelf in getMyShelves.models"
                                :key="shelf.id"
                                class="my-3 shelf__section-img-box"
                            >
                                <span class="shelf__name">{{shelf.name}}</span>
                                <span class="shelf__book-count">
                                    {{ shelf.books.length + shelf.audioBooks.length }}
                                    <span>{{ $t('shelf.numberBooks') }}</span>
                                </span>

                                <div v-if="shelf.mediaObject === null" class="d-flex justify-content-center h-100">
                                    <svg class="logo__svg" viewBox="0 0 280 49" fill="none">
                                        <path d="M15.2966 0V48.8024H6.61206V8.69355H0V0H15.2966Z" fill="var(--color-logo)"/>
                                        <path d="M77.6714 4.44557C80.5005 7.21169 81.915 10.5706 81.915 14.5222C81.915 18.4738 80.5663 21.8327 77.8688 24.5988C80.5663 27.2991 81.915 30.625 81.915 34.5766C81.915 38.5282 80.5005 41.92 77.6714 44.752C74.9082 47.5181 71.5528 48.9012 67.6053 48.9012H26.3681V40.2077H67.6053C69.1843 40.2077 70.5002 39.6808 71.5528 38.627C72.6713 37.5074 73.2305 36.1573 73.2305 34.5766C73.2305 32.996 72.6713 31.6458 71.5528 30.5262C70.5002 29.4066 69.1843 28.8468 67.6053 28.8468H26.3681V20.252H67.6053C69.1843 20.252 70.5002 19.6922 71.5528 18.5726C72.6713 17.453 73.2305 16.1028 73.2305 14.5222C73.2305 12.9415 72.6713 11.6243 71.5528 10.5706C70.5002 9.45094 69.1843 8.89113 67.6053 8.89113H26.3681V0.197578H67.6053C71.5528 0.197578 74.9082 1.61357 77.6714 4.44557Z" fill="var(--color-logo)"/>
                                        <path d="M98.9031 7.31048C103.706 2.56855 109.463 0.197578 116.173 0.197578C122.95 0.197578 128.707 2.56855 133.444 7.31048C138.181 12.0524 140.549 17.8152 140.549 24.5988C140.549 31.3165 138.181 37.0793 133.444 41.8871C128.707 46.629 122.95 49 116.173 49V40.3065C120.516 40.3065 124.233 38.7917 127.325 35.7621C130.417 32.6667 131.963 28.9456 131.963 24.5988C131.963 20.252 130.417 16.5309 127.325 13.4355C124.233 10.3401 120.516 8.79234 116.173 8.79234C111.831 8.79234 108.114 10.3401 105.022 13.4355C101.995 16.5309 100.482 20.252 100.482 24.5988H91.7976C91.7976 17.8152 94.1661 12.0524 98.9031 7.31048Z" fill="var(--color-logo)"/>
                                        <path d="M158.463 7.31048C163.265 2.56855 169.022 0.197578 175.733 0.197578C182.509 0.197578 188.266 2.56855 193.003 7.31048C197.74 12.0524 200.109 17.8152 200.109 24.5988C200.109 31.3165 197.74 37.0793 193.003 41.8871C188.266 46.629 182.509 49 175.733 49V40.3065C180.075 40.3065 183.792 38.7917 186.885 35.7621C189.977 32.6667 191.523 28.9456 191.523 24.5988C191.523 20.252 189.977 16.5309 186.885 13.4355C183.792 10.3401 180.075 8.79234 175.733 8.79234C171.391 8.79234 167.673 10.3401 164.581 13.4355C161.555 16.5309 160.042 20.252 160.042 24.5988H151.357C151.357 17.8152 153.726 12.0524 158.463 7.31048Z" fill="var(--color-logo)"/>
                                        <path d="M230.062 49L211.015 21.7339L229.272 0.197578H240.621L221.969 22.2278L240.621 49H230.062Z" fill="var(--color-logo)"/>
                                        <path d="M266.381 19.9556C267.894 20.4825 269.309 21.1411 270.625 21.9315C272.006 22.6559 273.454 23.6109 274.967 24.7964C276.48 25.916 277.664 27.332 278.52 29.0444C279.441 30.6909 279.934 32.502 280 34.4778C280 38.2977 278.553 41.6895 275.658 44.6532C272.829 47.5511 269.013 49 264.21 49H263.717V40.3065H264.21C265.131 40.3065 265.986 40.2077 266.776 40.0101C267.565 39.8125 268.19 39.5491 268.651 39.2198C269.177 38.8905 269.605 38.5282 269.934 38.1331C270.329 37.672 270.592 37.2769 270.723 36.9476C270.921 36.5524 271.052 36.1573 271.118 35.7621C271.25 35.3669 271.316 35.1035 271.316 34.9718C271.316 34.7742 271.316 34.6095 271.316 34.4778C271.316 32.041 268.717 29.8676 263.519 27.9577C261.545 27.2991 259.835 26.6075 258.387 25.8831C257.006 25.0927 255.624 24.1707 254.243 23.1169C252.861 21.9973 251.808 20.6801 251.085 19.1653C250.361 17.5847 249.999 15.8394 249.999 13.9294C249.999 10.373 251.216 7.27755 253.65 4.64314C256.479 1.67943 260.23 0.197578 264.901 0.197578C268.651 0.197578 271.776 1.21841 274.276 3.26008C276.842 5.23589 278.651 7.60685 279.704 10.373L271.612 13.4355C270.559 10.4059 268.322 8.89113 264.901 8.89113C263.717 8.89113 262.664 9.05578 261.743 9.38508C260.888 9.71438 260.23 10.1754 259.769 10.7681C259.374 11.295 259.078 11.8548 258.881 12.4476C258.684 12.9745 258.585 13.5013 258.585 14.0282C258.585 14.2917 258.585 14.5222 258.585 14.7198C258.651 14.9173 258.815 15.2466 259.078 15.7077C259.341 16.1687 259.703 16.5968 260.164 16.9919C260.69 17.3212 261.48 17.7823 262.532 18.375C263.585 18.9019 264.868 19.4288 266.381 19.9556Z" fill="var(--color-logo)"/>
                                    </svg>
                                </div>
                                <img v-else :src="host + shelf.mediaObject.contentUrl" alt=". . ." class="shelf__img">
                            </div>
                        </div>

                        <div v-else class="px-3 py-3 shelf__section-description">{{ $t('shelf.youDoNotHaveSingleShelf') }}</div>

                        <button @click="goToCreateShelf" class="submit__btn py-3 w-100">{{ $t('shelf.createANewShelf') }}</button>
                    </div>

                    <div v-show="!isShowShelfMenu">
                        <label for="image" v-if="isSelectedImage" class="mt-3 shelf__selected-image">
                            <img :src="imgUrl" alt=". . ." class="shelf__img">
                        </label>

                        <label for="image" v-else class="my-3 p-4 shelf__select-img-box">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M9 10.9598C7.48 10.9598 6.25 9.72984 6.25 8.20984C6.25 6.68984 7.48 5.45984 9 5.45984C10.52 5.45984 11.75 6.68984 11.75 8.20984C11.75 9.72984 10.52 10.9598 9 10.9598ZM9 6.95984C8.31 6.95984 7.75 7.51984 7.75 8.20984C7.75 8.89984 8.31 9.45984 9 9.45984C9.69 9.45984 10.25 8.89984 10.25 8.20984C10.25 7.51984 9.69 6.95984 9 6.95984Z" fill="var(--blueToFade)"/>
                                <path d="M15 22.9598H9C3.57 22.9598 1.25 20.6398 1.25 15.2098V9.20984C1.25 3.77984 3.57 1.45984 9 1.45984H13C13.41 1.45984 13.75 1.79984 13.75 2.20984C13.75 2.61984 13.41 2.95984 13 2.95984H9C4.39 2.95984 2.75 4.59984 2.75 9.20984V15.2098C2.75 19.8198 4.39 21.4598 9 21.4598H15C19.61 21.4598 21.25 19.8198 21.25 15.2098V10.2098C21.25 9.79984 21.59 9.45984 22 9.45984C22.41 9.45984 22.75 9.79984 22.75 10.2098V15.2098C22.75 20.6398 20.43 22.9598 15 22.9598Z" fill="var(--blueToFade)"/>
                                <path d="M21.25 5.95984H15.75C15.34 5.95984 15 5.61984 15 5.20984C15 4.79984 15.34 4.45984 15.75 4.45984H21.25C21.66 4.45984 22 4.79984 22 5.20984C22 5.61984 21.66 5.95984 21.25 5.95984Z" fill="var(--blueToFade)"/>
                                <path d="M18.5 8.70984C18.09 8.70984 17.75 8.36984 17.75 7.95984V2.45984C17.75 2.04984 18.09 1.70984 18.5 1.70984C18.91 1.70984 19.25 2.04984 19.25 2.45984V7.95984C19.25 8.36984 18.91 8.70984 18.5 8.70984Z" fill="var(--blueToFade)"/>
                                <path d="M2.67005 19.9098C2.43005 19.9098 2.19005 19.7898 2.05005 19.5798C1.82005 19.2398 1.91005 18.7698 2.25005 18.5398L7.18005 15.2298C8.26005 14.5098 9.75005 14.5898 10.73 15.4198L11.06 15.7098C11.56 16.1398 12.41 16.1398 12.9 15.7098L17.06 12.1398C18.13 11.2298 19.7901 11.2298 20.8601 12.1398L22.49 13.5398C22.8 13.8098 22.84 14.2798 22.57 14.5998C22.3 14.9098 21.83 14.9498 21.51 14.6798L19.88 13.2798C19.38 12.8498 18.5301 12.8498 18.0401 13.2798L13.88 16.8498C12.82 17.7598 11.15 17.7598 10.08 16.8498L9.75005 16.5598C9.29005 16.1698 8.53005 16.1298 8.02005 16.4798L3.10005 19.7898C2.96005 19.8698 2.81005 19.9098 2.67005 19.9098Z" fill="var(--blueToFade)"/>
                            </svg>
                        </label>
                        <input id="image" ref="selectImage" accept="image/png,image/jpeg,image/jpg" style="display: none" type="file">

                        <input
                            v-if="!isShowShelfMenu"
                            v-model="shelf.name"
                            type="text"
                            :placeholder="$t('shelf.shelfName')"
                            class="form-control form__input my-3 py-3 ps-4"
                            @focus="isBlankInput = false"
                        />
                        <input
                            v-if="!isShowShelfMenu"
                            v-model="shelf.description"
                            type="text"
                            :placeholder="$t('shelf.describeShelf')"
                            class="form-control form__input py-3 ps-4"
                            @focus="isBlankInput = false"
                        />

                        <div v-if="isBlankInput" class="errorMessage">{{ $t('fillInFields') }}</div>

                        <button v-if="!isShowShelfMenu" @click="createShelf" class="submit__btn mt-3 py-3 w-100" type="button">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
                            <span v-else>{{ $t('create') }}</span>
                        </button>
                    </div>
                </div>
                <div class="col-12 mt-3 px-4 menu__block">
                    <div
                        @click="getLibraryDataCount.quote > 0 ? $router.push('/my-quotes') : ''"
                        class="d-flex justify-content-between py-4"
                    >
                        <span>
                            <svg class="me-3" fill="none" height="25" viewBox="0 0 24 25" width="24">
                                <path d="M16.19 2.20996H7.81C4.17 2.20996 2 4.37996 2 8.01996V16.39C2 20.04 4.17 22.21 7.81 22.21H16.18C19.82 22.21 21.99 20.04 21.99 16.4V8.01996C22 4.37996 19.83 2.20996 16.19 2.20996ZM11.61 15.05C11.61 16.14 10.76 16.99 9.67 16.99H8.19C7.12 16.99 6.25 16.12 6.25 15.05V12.38C6.25 9.29996 6.92 8.60996 8.71 7.54996C8.83 7.47996 8.96 7.44996 9.09 7.44996C9.35 7.44996 9.6 7.57996 9.74 7.81996C9.95 8.17996 9.83 8.63996 9.48 8.84996C8.27 9.54996 7.85 9.80996 7.77 11.62H9.68C10.77 11.62 11.62 12.47 11.62 13.56V15.05H11.61ZM17.75 15.05C17.75 16.14 16.9 16.99 15.81 16.99H14.32C13.25 16.99 12.38 16.12 12.38 15.05V12.38C12.38 9.29996 13.05 8.60996 14.84 7.54996C14.96 7.47996 15.09 7.44996 15.22 7.44996C15.48 7.44996 15.73 7.57996 15.87 7.81996C16.08 8.17996 15.96 8.63996 15.61 8.84996C14.4 9.56996 13.98 9.82996 13.9 11.64H15.81C16.9 11.64 17.75 12.49 17.75 13.58V15.05Z"
                                      fill="var(--blueToWhite)"/>
                            </svg>
                            <span class="block__text">{{ $t('library.myQuotes') }}</span>
                        </span>

                        <span class="pe-1 block__number">{{getLibraryDataCount.quote}}</span>
                    </div>
                    <div class="block__line"></div>

                    <div
                        @click="getLibraryDataCount.vote > 0 ? $router.push('/my-feedback') : ''"
                        class="d-flex justify-content-between py-4"
                    >
                        <span>
                            <svg class="me-3" fill="none" height="25" viewBox="0 0 24 25" width="24">
                                <path d="M13.19 6.20996H6.79C6.53 6.20996 6.28 6.21996 6.04 6.24996C3.35 6.47996 2 8.06996 2 11V15C2 19 3.6 19.79 6.79 19.79H7.19C7.41 19.79 7.7 19.94 7.83 20.11L9.03 21.71C9.56 22.42 10.42 22.42 10.95 21.71L12.15 20.11C12.3 19.91 12.54 19.79 12.79 19.79H13.19C16.12 19.79 17.71 18.45 17.94 15.75C17.97 15.51 17.98 15.26 17.98 15V11C17.98 7.80996 16.38 6.20996 13.19 6.20996ZM6.5 14.21C5.94 14.21 5.5 13.76 5.5 13.21C5.5 12.66 5.95 12.21 6.5 12.21C7.05 12.21 7.5 12.66 7.5 13.21C7.5 13.76 7.05 14.21 6.5 14.21ZM9.99 14.21C9.43 14.21 8.99 13.76 8.99 13.21C8.99 12.66 9.44 12.21 9.99 12.21C10.54 12.21 10.99 12.66 10.99 13.21C10.99 13.76 10.55 14.21 9.99 14.21ZM13.49 14.21C12.93 14.21 12.49 13.76 12.49 13.21C12.49 12.66 12.94 12.21 13.49 12.21C14.04 12.21 14.49 12.66 14.49 13.21C14.49 13.76 14.04 14.21 13.49 14.21Z"
                                      fill="var(--blueToWhite)"/>
                                <path d="M21.9802 6.99996V11C21.9802 13 21.3602 14.36 20.1202 15.11C19.8202 15.29 19.4702 15.05 19.4702 14.7L19.4802 11C19.4802 6.99996 17.1902 4.70996 13.1902 4.70996L7.10025 4.71996C6.75025 4.71996 6.51025 4.36996 6.69025 4.06996C7.44025 2.82996 8.80025 2.20996 10.7902 2.20996H17.1902C20.3802 2.20996 21.9802 3.80996 21.9802 6.99996Z"
                                      fill="var(--blueToWhite)"/>
                            </svg>
                            <span class="block__text">{{ $t('library.myVotes') }}</span>
                        </span>

                        <span class="pe-1 block__number">{{getLibraryDataCount.vote}}</span>
                    </div>
                </div>
            </div>
        </div>

        <footer-menu />
    </div>
</template>

<style scoped>
.content {
    background-color: var(--color-container);
    border-radius: 15px;
    min-height: 100vh;
}

.header__row {
    border-radius: 0 0 15px 15px;
    background-color: var(--color-header);
    color: var(--color-text);
}

.header__text {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: var(--color-textDarkGray-To-white);
}

.menu__block {
    border-radius: 1rem;
    background-color: var(--color-block);
}

.block__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-text);
}

.block__number {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: var(--blueToFade);
}

.block__line {
    border-bottom: 1px solid #C3E2FF;
    width: 100%;
}

.shelf__section-text, .shelf__section-description {
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
}

.shelf__section-text {
    color: var(--color-text);
}

.shelf__section-description {
    color: #828282;
}

.shelf__section-img-box, .shelf__selected-image {
    position: relative;
    height: 11vh;
}

.shelf__section-img-box:after, .shelf__selected-image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(90deg, #0481F4 0%, rgba(4, 129, 244, 0.00) 140%);
    z-index: 1;
}

.shelf__selected-image, .shelf__select-img-box {
    height: 12vh;
    width: 100%;
}

.shelf__select-img-box {
    border: 2px dashed var(--blueToFade);
    border-radius: 12px;
    background: var(--bg-button-row-lighter2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.shelf__name, .shelf__book-count {
    position: absolute;
    color: white;
    left: 5%;
    font-style: normal;
    z-index: 3;
}

.shelf__name {
    top: 10%;
    font-size: 0.9rem;
    font-weight: 500;
}

.shelf__book-count {
    top: 32%;
    font-size: 0.7rem;
    font-weight: 400;
}

.shelf__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.logo__svg {
    width: 85%;
}

.form__input {
    border-radius: 12px;
    font-weight: 500;
    font-size: 0.9rem;
    border: 1px solid #0481F4;
    background-color: var(--color-block);
    color: var(--color-textDarkGray-To-white);
}

.form__input::placeholder {
    color: var(--day-calendar);
}

.form__input:focus {
    box-shadow: none;
}

.errorMessage {
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #EB5757;
}

.submit__btn {
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 12px;
    background-color: #177ee6;
    color: white;
    vertical-align: middle !important;
}
</style>

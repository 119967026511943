import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
         fetchReadingBook(context) {
            return fetchModels(context, 'reading_books/get_user_reading_book', null, 'updateReadingBook')
        }
    },

    mutations: {
        updateReadingBook(state, data) {
            state.readingBook = data
        }
    },

    state: {
        readingBook: {
            models: [],
            totalItems: 0,
        },
    },

    getters: {
        getReadingBook(state) {
            return state.readingBook.models
        }
    }
}
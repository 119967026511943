<template>
    <div class="success">
        <div class="success__massage">
            {{ successMessageText }}
        </div>
    </div>
</template>

<script>
export default {
    name: "notify-success",
    methods: {
        showSuccessMessage() {
            let notify = document.querySelector('.success')
            notify.classList.add('_active')
            setTimeout(() => {
                notify.classList.remove('_active')
            }, 2000)
        },
    },
    props: {
        successMessageText: {
            type: String,
            required: true
        },
        isShowSuccess: {
            type: Boolean,
            default: false,
            required: true,
        }
    },
    watch: {
        isShowSuccess: function () {
           this.showSuccessMessage()
        }
    }
}
</script>

<style scoped>

.success {
    display: flex;
    width: 100%;
    justify-content: center;
    position: fixed;
    bottom: -100px;
    left: 0;
    transition: all 2s ease 0s;
}

.success__massage {
    padding: 10px;
    height: 40px;
    color: white;
    font-size: 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: background-color 2s ease 0s;
}

.success._active .success__massage {
    background-color: var(--blueToFade);
}

.success._active {
    bottom: 100px
}
</style>
<template>
    <div class="row">
        <div class="col container-col">

            <div class="row">
                <div class="col fixed-top">
                    <div class="row header-name-row">
                        <div class="col-2 ps-2">
                            <go-back-button class="arrow-back"/>
                        </div>
                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{ $t('notification') }}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center">
                <div class="col-11 center-block">
                    <div class="row">
                        <div class="col-9">
                            <div class="row">
                                <div class="col row-text-1 p-0">
                                    {{ $t('promotion') }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col row-text-2 p-0">
                                    {{ $t('distribution') }}
                                </div>
                            </div>
                        </div>

                        <div class="col-3 d-flex justify-content-end">
                            <div
                                @click="notification.saleAndDiscount = !notification.saleAndDiscount;"
                                class="checkbox__area"
                            >
                                <input
                                    @click="notification.saleAndDiscount = !notification.saleAndDiscount;"
                                    :checked="notification.saleAndDiscount"
                                    type="checkbox"
                                    id="slideThree"
                                />
                                <label for="slideThree" class="checkbox__label"/>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center ">
                        <div class="col  hr-width">
                            <div class="hr"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-9">
                            <div class="row">
                                <div class="col row-text-1 p-0">
                                    {{ $t('bookRecommendations') }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col row-text-2 p-0">
                                    {{ $t('distribution') }}
                                </div>
                            </div>
                        </div>

                        <div class="col-3 d-flex justify-content-end">
                            <div
                                @click="notification.bookRecommendation = !notification.bookRecommendation;"
                                class="checkbox__area"
                            >
                                <input
                                    @click="notification.bookRecommendation = !notification.bookRecommendation;"
                                    :checked="notification.bookRecommendation"
                                    type="checkbox"
                                    id="slideThree"
                                />
                                <label for="slideThree" class="checkbox__label"/>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center ">
                        <div class="col  hr-width">
                            <div class="hr"></div>
                        </div>
                    </div>

                    <div class="row pb-4">
                        <div class="col-9">
                            <div class="row">
                                <div class="col row-text-1 p-0">
                                    {{ $t('game') }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col row-text-2 p-0">
                                    {{ $t('distribution') }}
                                </div>
                            </div>
                        </div>

                        <div class="col-3 d-flex justify-content-end">
                            <div
                                @click="notification.gameContestTest = !notification.gameContestTest;"
                                class="checkbox__area"
                            >
                                <input
                                    @click="notification.gameContestTest = !notification.gameContestTest;"
                                    :checked="notification.gameContestTest"
                                    type="checkbox"
                                    id="slideThree"
                                />
                                <label for="slideThree" class="checkbox__label"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col d-flex justify-content-center fixed-bottom">
                    <Button type="submit" text="save" class="save-button" :onClick="pushNotificationChanges"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Button from "../../components/button/Button.vue";
import GoBackButton from "@/components/modals/GoBackButton.vue";
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "setting-notification",
    components: {GoBackButton, Button},
    data() {
        return {
            notification: {
                id: null,
                saleAndDiscount: this.isTrue('notification-sale'),
                bookRecommendation: this.isTrue('notification-recommended-books'),
                gameContestTest: this.isTrue('notification-game-contest')
            }
        }
    },
    computed: {
        ...mapGetters(['getUser', 'getNotification'])
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchNotification', 'pushNotification']),
        changeNotificationValueAtAndroid() {
            try {
                window.promotionNotificate(this.notification.saleAndDiscount)
                window.recommendBooksNotificate(this.notification.bookRecommendation)
            } catch {
                console.log()
            }
        },
        pushNotificationChanges() {
            this.pushNotification(this.notification)
                .then(() => {
                    localStorage.setItem('notification-sale', this.notification.saleAndDiscount)
                    localStorage.setItem('notification-recommended-books', this.notification.bookRecommendation)
                    localStorage.setItem('notification-game-contest', this.notification.gameContestTest)
                    this.changeNotificationValueAtAndroid()
                    this.$router.push('/setting')
                })
        },
        isTrue(arg) {
            return localStorage.getItem(arg) === 'true'
        }
    },
    created() {
        this.fetchNotification()
            .then(() => {
                this.notification.id = this.getNotification.id

                if (localStorage.getItem('notification-sale') === null) {
                    localStorage.setItem('notification-sale', this.getNotification.saleAndDiscount)
                    localStorage.setItem('notification-recommended-books', this.getNotification.bookRecommendation)
                    localStorage.setItem('notification-game-contest', this.getNotification.gameContestTest)

                    this.notification.saleAndDiscount = this.getNotification.saleAndDiscount
                    this.notification.bookRecommendation = this.getNotification.bookRecommendation
                    this.notification.gameContestTest = this.getNotification.gameContestTest
                }
            })
    }
}
</script>

<style scoped>
.container-col {
    font-family: 'Montserrat', sans-serif !important;
    background-color: var(--color-container) !important;
    border-radius: 0 0 15px 15px;
    height: 100vh;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.center-block {
    border-radius: 15px;
    background-color: var(--color-block);
    margin: 167px 0 20px 0;
}

.row-text-1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    color: var(--color-text);
    margin: 28px 0 0 26px;
}

.row-text-2 {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--color-textGray-To-lightGray);
    margin: 7px 0 0 26px;
}

.checkbox__area {
    position: relative;
    width: 46px;
    height: 24px;
    background: var(--bg-input-checkbox);
    margin-top: 33px;
    margin-right: 13px;
    border-radius: 50px;
}

.checkbox__label {
    width: 39%;
    height: 72%;
    border-radius: 50%;
    transition: all .15s linear;
    position: absolute;
    top: 16%;
    left: 8%;
    background: var(--input-checkbox-uncheck);
}

input[type=checkbox]:checked + .checkbox__label {
    left: 54%;
    background-color: #0481f4;
}

input[type=checkbox] {
    visibility: hidden;
}

.hr {
    border-bottom: 1px solid #C4E2FF;
    width: 100%;
}

.hr-width {
    padding-left: 26px !important;
    padding-right: 30px !important;
    margin-top: 27px;
}

.save-button {
    width: 104%;
    margin-bottom: 18px;
}

</style>

import fetchModels from "../../mixin/store/api/fetchModels";

export default {
    actions: {
        fetchCategories(context, page) {
            return fetchModels(context, 'categories', page, 'updateCategories');
        },
        fetchBooksByCategory(context, data) {
            return fetchModels(context, 'category/books?id=' + data.id, data.page, 'updateBooksByCategory')
        }
    },
    mutations: {
        updateCategories(state, data) {
            state.categories = data
        },
        updateBooksByCategory(state, data) {
            state.allBooks = data
        }
    },
    state: {
        categories: {
            models: [],
            totalItems: 0,
        },
        allBooks: {
            models: [],
            totalItems: 0
        }
    },
    getters: {
        getCategories(state) {
            return state.categories.models
        },
        getBooksByCategory(state) {
            return state.allBooks.models
        }
    },
}
<template>
    <div style="position: relative">
        <div class="book_wrapper">
            <div class="header">
                <div class="col">
                    <div class="row pt-5">
                        <div class="col-2 back-button">
                            <go-back-button/>
                        </div>
                        <div class="col-8 d-flex justify-content-center">
                            <span class="book-name">{{ this.getBookId.name }}</span>
                        </div>
                        <div class="col-2" :class="bodyClientWidth < 330 ? 'ps-2' : ''">
                            <svg
                                v-if="getFavoriteBookCurrentUser === undefined"
                                :width="bodyClientWidth < 330 ? 25 : 30"
                                :height="bodyClientWidth < 330 ? 25 : 30"
                                viewBox="0 0 30 30" fill="none"
                            >
                                <path d="M18.125 13.3125H11.875" stroke="var(--color-text)" stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M15 10.2625V16.5125" stroke="var(--color-text)" stroke-width="1.5" stroke-miterlimit="10"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M4.15039 12.55V24.9375C4.15039 27.1875 5.76289 28.1375 7.73789 27.05L13.8379 23.6625C14.4879 23.3 15.5379 23.3 16.1754 23.6625L22.2754 27.05C24.2504 28.15 25.8629 27.2 25.8629 24.9375V7.325C25.8629 4.675 23.6879 2.5 21.0379 2.5H8.97539C6.31289 2.5 4.15039 4.675 4.15039 7.325"
                                    stroke="var(--color-text)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <svg
                                v-else
                                :width="bodyClientWidth < 330 ? 25 : 30"
                                :height="bodyClientWidth < 330 ? 25 : 30"
                                viewBox="0 0 30 30" fill="none" class="saved"
                            >
                                <path class="archive"
                                      d="M21.0254 2.38751H8.97539C6.32539 2.38751 4.15039 4.56251 4.15039 7.21251V24.825C4.15039
                                27.075 5.76289 28.025 7.73789 26.9375L13.8379 23.55C14.4879 23.1875 15.5379 23.1875 16.1754
                                23.55L22.2754 26.9375C24.2504 28.0375 25.8629 27.0875 25.8629 24.825V7.21251C25.8504
                                4.56251 23.6879 2.38751 21.0254 2.38751ZM19.5254 11.2875L14.5254 16.2875C14.3379 16.475
                                14.1004 16.5625 13.8629 16.5625C13.6254 16.5625 13.3879 16.475 13.2004 16.2875L11.3254
                                14.4125C10.9629 14.05 10.9629 13.45 11.3254 13.0875C11.6879 12.725 12.2879 12.725 12.6504
                                13.0875L13.8629 14.3L18.2004 9.96251C18.5629 9.60001 19.1629 9.60001 19.5254
                                9.96251C19.8879 10.325 19.8879 10.925 19.5254 11.2875Z" fill="white"
                                />
                            </svg>
                        </div>
                    </div>

                    <div class="row bg-book-author-row">
                        <div class="col-2"></div>
                        <div class="col-8 d-flex justify-content-center">
                            <span class="book-author">
                            {{ this.getBookId.author }}
                            </span>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
            <div class="content" id="book-content">
                <span class="book-text text-break" ref="text2"></span>
                <div id="my-page" class="my-page"></div>
            </div>
            <div class="footer">
                <div class="col">
                    <div class="row p-0">
                        <div class="col-1 number-1 d-flex justify-content-end p-0">
                            {{ value + 1 }}
                        </div>
                        <div class="col-10">
                            <div class="wrapper">
                                <range-slider class="slider" min="0" :max="totalPageNumber - 1" step="1" v-model="value"
                                              @change="changePage()" id="seek">
                                </range-slider>
                            </div>
                        </div>
                        <div class="col-1 number-2 d-flex justify-content-start p-0">
                            {{ totalPageNumber }}
                        </div>
                    </div>

                    <div class="row pt-2 pb-4">
                        <div class="last-row__item">
                            <svg
                                @click="$emit('darkTheme'); changeToDark()"
                                v-if="this.theme === 'light'"
                                width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path
                                    d="M16.5318 23.8334C16.4668 23.8334 16.3909 23.8226 16.3259 23.8009C14.1484 23.1834 11.8626 23.1834 9.68508 23.8009C9.28425 23.9092 8.86175 23.6817 8.75342 23.2809C8.63425 22.8801 8.87259 22.4576 9.27342 22.3492C11.7218 21.6559 14.3001 21.6559 16.7484 22.3492C17.1493 22.4684 17.3876 22.8801 17.2684 23.2809C17.1601 23.6167 16.8568 23.8334 16.5318 23.8334Z"
                                    fill="#97CAFB"/>
                                <path
                                    d="M20.8108 6.89001C19.6841 4.61501 17.5066 2.93585 14.9824 2.38335C12.3391 1.79835 9.63078 2.42668 7.56161 4.09501C5.48161 5.75251 4.30078 8.23335 4.30078 10.8875C4.30078 13.6933 5.97995 16.6292 8.51495 18.33V19.2292C8.50411 19.5325 8.49328 19.9983 8.86161 20.3775C9.24078 20.7675 9.80411 20.8108 10.2483 20.8108H15.8058C16.3908 20.8108 16.8349 20.6483 17.1383 20.345C17.5499 19.9225 17.5391 19.3808 17.5283 19.0883V18.33C20.8866 16.0658 22.9991 11.2883 20.8108 6.89001ZM14.8633 12.5883L13.7041 14.6033C13.5524 14.8633 13.2816 15.015 12.9999 15.015C12.8591 15.015 12.7183 14.9825 12.5991 14.9067C12.2091 14.6792 12.0791 14.1808 12.2958 13.8017L13.2166 12.1983H12.3066C11.7649 12.1983 11.3208 11.96 11.0824 11.5592C10.8441 11.1475 10.8658 10.6492 11.1366 10.1725L12.2958 8.15751C12.5233 7.76751 13.0216 7.63751 13.4008 7.85418C13.7908 8.08168 13.9208 8.58001 13.7041 8.95918L12.7833 10.5625H13.6933C14.2349 10.5625 14.6791 10.8008 14.9174 11.2017C15.1558 11.6133 15.1341 12.1225 14.8633 12.5883Z"
                                    fill="#97CAFB"/>
                            </svg>
                            <svg v-else
                                 @click="$emit('lightTheme'); changeToLight()"
                                 width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path
                                    d="M16.5318 23.8334C16.4668 23.8334 16.3909 23.8226 16.3259 23.8009C14.1484 23.1834 11.8626 23.1834 9.68508 23.8009C9.28425 23.9092 8.86175 23.6817 8.75342 23.2809C8.63425 22.8801 8.87259 22.4576 9.27342 22.3492C11.7218 21.6559 14.3001 21.6559 16.7484 22.3492C17.1493 22.4684 17.3876 22.8801 17.2684 23.2809C17.1601 23.6167 16.8568 23.8334 16.5318 23.8334Z"
                                    fill="#97CAFB"/>
                                <path
                                    d="M18.0158 3.70495L5.94745 15.7733C4.90745 14.2999 4.30078 12.5666 4.30078 10.8875C4.30078 8.23328 5.48161 5.75245 7.55078 4.08412C9.61995 2.41578 12.3283 1.79828 14.9824 2.38328C16.0766 2.62162 17.1058 3.07662 18.0158 3.70495Z"
                                    fill="#97CAFB"/>
                                <path
                                    d="M17.5168 18.3301V19.0884C17.5277 19.3701 17.5493 19.9117 17.1268 20.3451C16.8235 20.6484 16.3793 20.8109 15.7943 20.8109H10.2368C9.79266 20.8109 9.22932 20.7567 8.85016 20.3776C8.49266 19.9984 8.50349 19.5326 8.51432 19.2292V18.3301C8.02682 18.0051 7.57182 17.6367 7.16016 17.2251L19.4452 4.94006C19.9868 5.51423 20.4527 6.17506 20.8102 6.89006C22.9985 11.2884 20.886 16.0659 17.5168 18.3301Z"
                                    fill="#97CAFB"/>
                                <path
                                    d="M4.52854 20.6701C4.32271 20.6701 4.11688 20.5942 3.95438 20.4317C3.64021 20.1176 3.64021 19.5976 3.95438 19.2834L21.1252 2.11258C21.4394 1.79841 21.9594 1.79841 22.2735 2.11258C22.5877 2.42674 22.5877 2.94674 22.2735 3.26091L5.10271 20.4317C4.94021 20.5942 4.73437 20.6701 4.52854 20.6701Z"
                                    fill="#97CAFB"/>
                            </svg>

                            <button type="button" class="archive-offcanvas message-icon" data-bs-toggle="offcanvas"
                                    @click="openChat()"
                                    data-bs-target="#offcanvasBottom2" aria-controls="offcanvasBottom">
                                <svg width="27" height="26" viewBox="0 0 27 26" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M2.66602 7.57246V14.0508V15.1341C2.66602 18.1133 5.09268 20.5291 8.08268 20.5291H9.70768C10.011 20.5291 10.401 20.7241 10.5743 20.9625L12.1993 23.1183C12.9143 24.0716 14.0843 24.0716 14.7993 23.1183L16.4243 20.9625C16.6302 20.6916 16.9552 20.5291 17.291 20.5291H18.916C21.906 20.5291 24.3327 18.1133 24.3327 15.1341V7.57246C24.3327 4.58246 21.906 2.16663 18.916 2.16663H8.08268C5.09268 2.16663 2.66602 4.58246 2.66602 7.57246ZM8.0827 11.9167C8.0827 12.5125 8.55937 13 9.16604 13C9.7727 13 10.2494 12.5125 10.2494 11.9167C10.2494 11.3209 9.76187 10.8334 9.16604 10.8334C8.5702 10.8334 8.0827 11.3209 8.0827 11.9167ZM13.4994 13C12.8927 13 12.416 12.5125 12.416 11.9167C12.416 11.3209 12.9035 10.8334 13.4994 10.8334C14.0952 10.8334 14.5827 11.3209 14.5827 11.9167C14.5827 12.5125 14.106 13 13.4994 13ZM17.8327 13C17.226 13 16.7494 12.5125 16.7494 11.9167C16.7494 11.3209 17.2369 10.8334 17.8327 10.8334C18.4285 10.8334 18.916 11.3209 18.916 11.9167C18.916 12.5125 18.4394 13 17.8327 13Z"
                                          fill="#97CAFB"/>
                                </svg>
                            </button>

                            <div class="offcanvas offcanvas-bottom offcanvas-bottom2" tabindex="-1" id="offcanvasBottom2"
                                 aria-labelledby="offcanvasBottomLabel">
                                <button v-show="false" ref="close-btn__chat" aria-label="Close" type="button" class="btn-close text-white" data-bs-dismiss="offcanvas"></button>
                                <div class="offcanvas-body small" ref="scrollToMe" @scroll="getOldMessages">
                                    <div class="row">
                                        <div class="col chat-box" ref="chatBox">

                                            <!-- top close btn -->
                                            <div class="row">
                                                <div class="col line d-flex justify-content-center">
                                                    <button type="button" class="swiper2" data-bs-dismiss="offcanvas"
                                                            aria-label="Close">
                                                        <svg width="104" height="3" viewBox="0 0 104 3" fill="none">
                                                            <path d="M1 1.5H102.5" stroke="var(--color-light-dark-gray)" stroke-width="2"
                                                                  stroke-linecap="round"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- top close btn end-->

                                            <div v-for="message in chat.messages" :key="message.id">
                                                <div class="row d-flex justify-content-center px-3 my-4"
                                                     v-if="message.user.id === getUser.id">
                                                    <div class="col swiper2-input-border pb-2">
                                                        <div class="row">
                                                            <div class="col-9">
                                                                <div class="row">
                                                                    <div class="col-12 p-0 swiper2-text-1">
                                                                        {{ message.message }}
                                                                    </div>
                                                                    <div class="col-12 p-0 swiper2-time-2">
                                                                        {{ message.createdAt.slice(11, 16) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col d-flex justify-content-end p-2 avatar">
                                                                <img v-if="message.user.image !== null" :src="localhost + message.user.image.contentUrl"
                                                                     alt="" width="40" height="41"
                                                                     style="border-radius: 50%">
                                                                <img v-else-if="message.user.gender === 0" src="../../components/images/page-9/man.svg" alt="" width="41"
                                                                     height="41" style="border-radius: 50%; background-color: var(--human-img-bg)">
                                                                <svg v-else style="border-radius: 50%; background-color: var(--human-img-bg)" width="41" height="41" viewBox="0 0 56 56" fill="none">
                                                                    <mask id="mask0_105_7101" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                                                                        <rect x="0.101074" width="55.8989" height="55.8973" rx="27.9487" fill="white"/>
                                                                    </mask>
                                                                    <g mask="url(#mask0_105_7101)">
                                                                        <path d="M13.9877 46.3638C17.5877 13.5638 13.5 20.5 14.5 15C15.5 9.49996 17.0878 12.6638 17.4878 13.8638C17.9878 15.3638 23.5 17.3638 25 21.3638C26.5 25.3638 24.5 25.3638 24.5 26.3638C24.5 27.3638 30.5122 31.6361 30.5122 34.6361C30.5122 37.6361 24 43 23.5 47C23.1 50.2 19.6545 52.0305 19.4878 53.3638L13.9877 53.8638C12.4877 65.0305 10.3877 79.1638 13.9877 46.3638Z" fill="#192734"/>
                                                                        <path d="M22.1333 36.1265V36.2051C22.1121 37.8439 20.7307 39.0765 17.9891 39.9029C21.2935 42.2993 24.5979 44.2163 27.8995 44.2163C31.2011 44.2163 34.5051 42.297 37.8094 39.9009C35.0865 39.0743 33.7053 37.8404 33.666 36.1992C33.666 35.9066 33.6683 35.4911 33.6699 34.7111C33.6699 34.5873 33.6699 34.4604 33.6699 34.3303C33.8666 34.3582 33.8509 34.3405 33.6699 34.291C33.6754 32.2387 33.6841 29.4788 33.6963 26.6744C36.2788 23.3358 35.33 19.4213 34.5224 19.5192C33.5295 19.6373 24.9338 11.5034 23.2852 11.0872C21.6365 10.671 17.4486 12.016 16.7276 15.7684C16.0065 19.5208 15.7048 28.9768 18.4391 32.7521C19.217 33.8273 20.4421 34.2469 22.1145 34.0109C22.1168 34.7976 22.1215 35.2366 22.1329 36.1261L22.1333 36.1265Z" fill="url(#paint0_linear_105_7101)"/>
                                                                        <path opacity="0.228" d="M22.1311 34.02C24.4258 33.7878 26.6409 33.0514 28.6179 31.8635C28.6179 31.8635 25.8898 35.479 22.1311 36.1768V34.02Z" fill="#DB844C"/>
                                                                        <path d="M46.7959 44.8505C48.5051 48.3229 49.4889 56.3316 49.4889 56.3316H6.396C6.396 56.3316 7.37944 48.3221 9.08906 44.8505C10.7987 41.379 20.7775 38.1576 20.7775 38.1576C22.7011 42.104 33.2574 42.1036 35.1004 38.1576C35.1004 38.1576 45.0847 41.3778 46.7959 44.8505Z" fill="#0481F4"/>
                                                                        <path d="M39.5002 16.4999C39.5002 16.4999 38.8531 13.6341 38.5002 12.5C36.4963 6.06019 28.5001 4 23.5001 5C19.5001 5.99998 17.5002 6.99998 15.0001 11.5C12 17 16.6039 32.4999 16.302 20.4999C16.0001 8.49998 20.6188 20.7285 29.0001 17C30.1075 16.8718 29.757 21.0259 30.3487 21.3905C31.2365 21.9393 31.9257 18.4894 34.3611 19.5251C36.7965 20.5609 35.3509 25.3644 32.7381 25.3644C31.8372 25.3644 31.3868 27.8349 33.8191 29.0032C35.5822 29.8698 38.6937 18.435 39.5002 16.4999Z" fill="#192734"/>
                                                                        <path d="M41.0002 25.5C40.6002 23.9 39.8335 18.8333 39.5001 16.5H38.5002C38.1002 16.5 38.0002 16.8333 38.0002 17V18L36.5002 23L34.0002 26.5L33.5002 27.5L33.0002 28L32.0002 26.5C32.0002 28.5 32.5744 36.6111 33.0002 43C33.5002 50.5 31.5002 57.6667 32.5002 59C35.0002 58.1667 44.6002 52.4 45.0002 52C45.5002 51.5 50.0002 50.5 50.5002 50.5C50.9002 50.5 54.3336 47.5 56.0002 46C54.6669 45.5 51.3002 43.8 48.5002 41C43.0002 33.5 41.5002 27.5 41.0002 25.5Z" fill="#192734"/>
                                                                    </g>
                                                                    <defs>
                                                                        <linearGradient id="paint0_linear_105_7101" x1="16.2305" y1="11.0145" x2="16.2305" y2="44.2163" gradientUnits="userSpaceOnUse">
                                                                            <stop stop-color="#FFE2CF"/>
                                                                            <stop offset="1" stop-color="#FFE2CF"/>
                                                                        </linearGradient>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row my-4 px-3" v-else>
                                                    <div class="col-2">
                                                        <img v-if="message.user.image !== null" :src="localhost + message.user.image.contentUrl" alt=""
                                                             width="40" height="41" style="border-radius: 50%">
                                                        <img v-else-if="message.user.gender === 0" src="../../components/images/page-9/man.svg" alt="" width="41"
                                                             height="41" style="border-radius: 50%; background-color: var(--human-img-bg)">

                                                        <svg v-else style="border-radius: 50%; background-color: var(--human-img-bg)" width="41" height="41" viewBox="0 0 56 56" fill="none">
                                                            <mask id="mask0_105_7101" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                                                                <rect x="0.101074" width="55.8989" height="55.8973" rx="27.9487" fill="white"/>
                                                            </mask>
                                                            <g mask="url(#mask0_105_7101)">
                                                                <path d="M13.9877 46.3638C17.5877 13.5638 13.5 20.5 14.5 15C15.5 9.49996 17.0878 12.6638 17.4878 13.8638C17.9878 15.3638 23.5 17.3638 25 21.3638C26.5 25.3638 24.5 25.3638 24.5 26.3638C24.5 27.3638 30.5122 31.6361 30.5122 34.6361C30.5122 37.6361 24 43 23.5 47C23.1 50.2 19.6545 52.0305 19.4878 53.3638L13.9877 53.8638C12.4877 65.0305 10.3877 79.1638 13.9877 46.3638Z" fill="#192734"/>
                                                                <path d="M22.1333 36.1265V36.2051C22.1121 37.8439 20.7307 39.0765 17.9891 39.9029C21.2935 42.2993 24.5979 44.2163 27.8995 44.2163C31.2011 44.2163 34.5051 42.297 37.8094 39.9009C35.0865 39.0743 33.7053 37.8404 33.666 36.1992C33.666 35.9066 33.6683 35.4911 33.6699 34.7111C33.6699 34.5873 33.6699 34.4604 33.6699 34.3303C33.8666 34.3582 33.8509 34.3405 33.6699 34.291C33.6754 32.2387 33.6841 29.4788 33.6963 26.6744C36.2788 23.3358 35.33 19.4213 34.5224 19.5192C33.5295 19.6373 24.9338 11.5034 23.2852 11.0872C21.6365 10.671 17.4486 12.016 16.7276 15.7684C16.0065 19.5208 15.7048 28.9768 18.4391 32.7521C19.217 33.8273 20.4421 34.2469 22.1145 34.0109C22.1168 34.7976 22.1215 35.2366 22.1329 36.1261L22.1333 36.1265Z" fill="url(#paint0_linear_105_7101)"/>
                                                                <path opacity="0.228" d="M22.1311 34.02C24.4258 33.7878 26.6409 33.0514 28.6179 31.8635C28.6179 31.8635 25.8898 35.479 22.1311 36.1768V34.02Z" fill="#DB844C"/>
                                                                <path d="M46.7959 44.8505C48.5051 48.3229 49.4889 56.3316 49.4889 56.3316H6.396C6.396 56.3316 7.37944 48.3221 9.08906 44.8505C10.7987 41.379 20.7775 38.1576 20.7775 38.1576C22.7011 42.104 33.2574 42.1036 35.1004 38.1576C35.1004 38.1576 45.0847 41.3778 46.7959 44.8505Z" fill="#0481F4"/>
                                                                <path d="M39.5002 16.4999C39.5002 16.4999 38.8531 13.6341 38.5002 12.5C36.4963 6.06019 28.5001 4 23.5001 5C19.5001 5.99998 17.5002 6.99998 15.0001 11.5C12 17 16.6039 32.4999 16.302 20.4999C16.0001 8.49998 20.6188 20.7285 29.0001 17C30.1075 16.8718 29.757 21.0259 30.3487 21.3905C31.2365 21.9393 31.9257 18.4894 34.3611 19.5251C36.7965 20.5609 35.3509 25.3644 32.7381 25.3644C31.8372 25.3644 31.3868 27.8349 33.8191 29.0032C35.5822 29.8698 38.6937 18.435 39.5002 16.4999Z" fill="#192734"/>
                                                                <path d="M41.0002 25.5C40.6002 23.9 39.8335 18.8333 39.5001 16.5H38.5002C38.1002 16.5 38.0002 16.8333 38.0002 17V18L36.5002 23L34.0002 26.5L33.5002 27.5L33.0002 28L32.0002 26.5C32.0002 28.5 32.5744 36.6111 33.0002 43C33.5002 50.5 31.5002 57.6667 32.5002 59C35.0002 58.1667 44.6002 52.4 45.0002 52C45.5002 51.5 50.0002 50.5 50.5002 50.5C50.9002 50.5 54.3336 47.5 56.0002 46C54.6669 45.5 51.3002 43.8 48.5002 41C43.0002 33.5 41.5002 27.5 41.0002 25.5Z" fill="#192734"/>
                                                            </g>
                                                            <defs>
                                                                <linearGradient id="paint0_linear_105_7101" x1="16.2305" y1="11.0145" x2="16.2305" y2="44.2163" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#FFE2CF"/>
                                                                    <stop offset="1" stop-color="#FFE2CF"/>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    <div class="col-8">
                                                        <div class="row">
                                                            <div
                                                                class="col-12 swiper2-name"
                                                                v-if="message.user.givenName !== null & message.user.familyName !== null"
                                                            >
                                                                {{ message.user.givenName + ' ' + message.user.familyName }}
                                                            </div>
                                                            <div class="col-12 swiper2-name" v-else>
                                                                No name
                                                            </div>
                                                            <div class="col-12 swiper2-comment-text">
                                                                {{ message.message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col swiper2-time">
                                                        {{ message.createdAt.slice(11, 16) }}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Chat send -->
                                            <div class="row fixed-bottom">
                                                <div class="col">
                                                    <div class="row chat-bottom-row py-3">
                                                        <div class="col input-box">
                                                            <input @keyup.enter="sendMessage" type="text" class="form-control"
                                                                   v-model="message" :placeholder="$t('message')">
                                                        </div>
                                                        <div class="col d-flex justify-content-center send-btn"
                                                             @click="sendMessage">
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                                <path
                                                                    d="M24.0937 11.3467L12.6804 5.64C5.01369 1.8 1.86702 4.94666 5.70702 12.6133L6.86702 14.9333C7.20035 15.6133 7.20035 16.4 6.86702 17.08L5.70702 19.3867C1.86702 27.0533 5.00035 30.2 12.6804 26.36L24.0937 20.6533C29.2137 18.0933 29.2137 13.9067 24.0937 11.3467ZM19.787 17H12.587C12.0404 17 11.587 16.5467 11.587 16C11.587 15.4533 12.0404 15 12.587 15H19.787C20.3337 15 20.787 15.4533 20.787 16C20.787 16.5467 20.3337 17 19.787 17Z"
                                                                    fill="#0481F4"/>
                                                            </svg>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button" class="button-offcanvas message-icon" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
                                    <path
                                        d="M21.7743 9.98835C19.8135 9.98835 19.0118 8.60168 19.9868 6.90085C20.5502 5.91501 20.2143 4.65835 19.2285 4.09501L17.3543 3.02251C16.4985 2.51335 15.3935 2.81668 14.8843 3.67251L14.7652 3.87835C13.7902 5.57918 12.1868 5.57918 11.201 3.87835L11.0818 3.67251C10.5943 2.81668 9.48935 2.51335 8.63351 3.02251L6.75935 4.09501C5.77351 4.65835 5.43768 5.92585 6.00102 6.91168C6.98685 8.60168 6.18518 9.98835 4.22435 9.98835C3.09768 9.98835 2.16602 10.9092 2.16602 12.0467V13.9533C2.16602 15.08 3.08685 16.0117 4.22435 16.0117C6.18518 16.0117 6.98685 17.3983 6.00102 19.0992C5.43768 20.085 5.77351 21.3417 6.75935 21.905L8.63351 22.9775C9.48935 23.4867 10.5943 23.1833 11.1035 22.3275L11.2227 22.1217C12.1977 20.4208 13.801 20.4208 14.7868 22.1217L14.906 22.3275C15.4152 23.1833 16.5202 23.4867 17.376 22.9775L19.2502 21.905C20.236 21.3417 20.5718 20.0742 20.0085 19.0992C19.0227 17.3983 19.8243 16.0117 21.7852 16.0117C22.9118 16.0117 23.8435 15.0908 23.8435 13.9533V12.0467C23.8327 10.92 22.9118 9.98835 21.7743 9.98835ZM12.9993 16.5208C11.0602 16.5208 9.47852 14.9392 9.47852 13C9.47852 11.0608 11.0602 9.47918 12.9993 9.47918C14.9385 9.47918 16.5202 11.0608 16.5202 13C16.5202 14.9392 14.9385 16.5208 12.9993 16.5208Z"
                                        fill="#97CAFB"/>
                                </svg>
                            </button>

                            <div class="offcanvas offcanvas-bottom setting__modal" tabindex="-1" id="offcanvasBottom"
                                 aria-labelledby="offcanvasBottomLabel">

                                <div class="offcanvas-body pt-1">
                                    <div class="row">
                                        <div class="col all">
                                            <button v-show="false" ref="close-btn__setting" aria-label="Close" type="button" class="btn-close text-white" data-bs-dismiss="offcanvas"></button>

                                            <!-- top close btn -->
                                            <div class="row">
                                                <div class="col line d-flex justify-content-center">
                                                    <button type="button" class="swiper" data-bs-dismiss="offcanvas"
                                                            aria-label="Close">
                                                        <svg width="104" height="3" viewBox="0 0 104 3" fill="none">
                                                            <path d="M1 1.5H102.5" stroke="#F4F6FB" stroke-width="2"
                                                                  stroke-linecap="round"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- top close btn end-->

                                            <div class="row mt-2 mb-4">
                                                <div class="col text-1 d-flex justify-content-center">
                                                    {{$t('settings')}}
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-2 logo-width">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path class="blueToWhite"
                                                              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
                                                              fill="#0481F4"/>
                                                        <path class="blueToWhite"
                                                              d="M12 22.96C11.45 22.96 11 22.55 11 22V21.92C11 21.37 11.45 20.92 12 20.92C12.55 20.92 13 21.37 13 21.92C13 22.47 12.55 22.96 12 22.96ZM19.14 20.14C18.88 20.14 18.63 20.04 18.43 19.85L18.3 19.72C17.91 19.33 17.91 18.7 18.3 18.31C18.69 17.92 19.32 17.92 19.71 18.31L19.84 18.44C20.23 18.83 20.23 19.46 19.84 19.85C19.65 20.04 19.4 20.14 19.14 20.14ZM4.86 20.14C4.6 20.14 4.35 20.04 4.15 19.85C3.76 19.46 3.76 18.83 4.15 18.44L4.28 18.31C4.67 17.92 5.3 17.92 5.69 18.31C6.08 18.7 6.08 19.33 5.69 19.72L5.56 19.85C5.37 20.04 5.11 20.14 4.86 20.14ZM22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z"
                                                              fill="#0481F4"/>
                                                    </svg>
                                                </div>
                                                <div class="col text-2 p-0">
                                                    {{$t('brightness')}}
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col mt-1">
                                                    <range-slider
                                                            :min="0"
                                                            :max="100"
                                                            :value="getBrightness"
                                                            @change="sendBrightness"
                                                    >
                                                    </range-slider>
                                                </div>
                                            </div>
<!--                                            &lt;!&ndash; hr row start &ndash;&gt;-->
<!--                                            <div class="row d-flex justify-content-center ">-->
<!--                                                <div class="col  hr-width">-->
<!--                                                    <div class="hr"></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            &lt;!&ndash; hr row end &ndash;&gt;-->

<!--                                            <div class="row mt-3">-->
<!--                                                <div class="col-3 logo-width">-->
<!--                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--                                                         xmlns="http://www.w3.org/2000/svg">-->
<!--                                                        <path class="blueToWhite"-->
<!--                                                              d="M20.95 4.13C20.66 3.71 20.29 3.34 19.87 3.05C18.92 2.36 17.68 2 16.19 2H7.81C7.61 2 7.41 2.01 7.22 2.03C3.94 2.24 2 4.37 2 7.81V16.19C2 17.68 2.36 18.92 3.05 19.87C3.34 20.29 3.71 20.66 4.13 20.95C4.95 21.55 5.99 21.9 7.22 21.98C7.41 21.99 7.61 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 6.32 21.64 5.08 20.95 4.13ZM11.39 15.88H8.52C8.11 15.88 7.77 15.54 7.77 15.13C7.77 14.72 8.11 14.38 8.52 14.38H9.52V7.91H6.95C6.84 7.91 6.75 8 6.75 8.11V8.89C6.75 9.3 6.41 9.63 6 9.63C5.59 9.63 5.25 9.3 5.25 8.88V8.1C5.25 7.16 6.01 6.4 6.95 6.4H13.59C14.53 6.4 15.29 7.16 15.29 8.1V8.88C15.29 9.29 14.95 9.63 14.54 9.63C14.13 9.63 13.79 9.29 13.79 8.88V8.1C13.79 7.99 13.7 7.9 13.59 7.9H11.02V14.38H11.39C11.8 14.38 12.14 14.72 12.14 15.13C12.14 15.54 11.8 15.88 11.39 15.88ZM18.75 12.23C18.75 12.64 18.41 12.98 18 12.98C17.59 12.98 17.25 12.64 17.25 12.23V11.9H15.7V16.1H16.05C16.46 16.1 16.8 16.44 16.8 16.85C16.8 17.26 16.46 17.6 16.05 17.6H13.85C13.44 17.6 13.1 17.26 13.1 16.85C13.1 16.44 13.44 16.1 13.85 16.1H14.2V11.9H13.72C13.31 11.9 12.97 11.56 12.97 11.15C12.97 10.74 13.31 10.4 13.72 10.4H17.32C18.11 10.4 18.75 11.04 18.75 11.83V12.23Z"-->
<!--                                                              fill="#0481F4"/>-->
<!--                                                    </svg>-->
<!--                                                </div>-->
<!--                                                <div class="col text-2 p-0">-->
<!--                                                    {{$t('fontSize')}}-->
<!--                                                </div>-->
<!--                                            </div>-->

<!--                                            <div class="row">-->
<!--                                                <div class="col">-->
<!--                                                    <range-slider class="slider" min="0" max="100" value="30">-->
<!--                                                    </range-slider>-->
<!--                                                </div>-->
<!--                                            </div>-->

<!--                                            &lt;!&ndash; hr row start &ndash;&gt;-->
<!--                                            <div class="row d-flex justify-content-center ">-->
<!--                                                <div class="col  hr-width">-->
<!--                                                    <div class="hr"></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            &lt;!&ndash; hr row end &ndash;&gt;-->

<!--                                            <div class="row mt-3">-->
<!--                                                <div class="col-3 logo-width">-->
<!--                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">-->
<!--                                                        <path class="blueToWhite"-->
<!--                                                              d="M16.24 2H7.76C5 2 4 3 4 5.81V18.19C4 21 5 22 7.76 22H16.23C19 22 20 21 20 18.19V5.81C20 3 19 2 16.24 2ZM12 19.3C11.04 19.3 10.25 18.51 10.25 17.55C10.25 16.59 11.04 15.8 12 15.8C12.96 15.8 13.75 16.59 13.75 17.55C13.75 18.51 12.96 19.3 12 19.3ZM14 6.25H10C9.59 6.25 9.25 5.91 9.25 5.5C9.25 5.09 9.59 4.75 10 4.75H14C14.41 4.75 14.75 5.09 14.75 5.5C14.75 5.91 14.41 6.25 14 6.25Z"-->
<!--                                                              fill="#0481F4"/>-->
<!--                                                    </svg>-->
<!--                                                </div>-->
<!--                                                <div class="col text-2 p-0">-->
<!--                                                    {{ $t('fullScreen') }}-->
<!--                                                </div>-->
<!--                                                <div class="col d-flex justify-content-end p-0">-->
<!--                                                    <div class="form-check form-switch">-->
<!--                                                        <input class="form-check-input" type="checkbox" role="switch">-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->

<!--                                            <div class="row mt-3">-->
<!--                                                <div class="col-2 logo-width">-->
<!--                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">-->
<!--                                                        <path class="blueToWhite"-->
<!--                                                              d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2ZM14 15.25H7C6.59 15.25 6.25 14.91 6.25 14.5C6.25 14.09 6.59 13.75 7 13.75H14C14.41 13.75 14.75 14.09 14.75 14.5C14.75 14.91 14.41 15.25 14 15.25ZM17 10.25H7C6.59 10.25 6.25 9.91 6.25 9.5C6.25 9.09 6.59 8.75 7 8.75H17C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25Z"-->
<!--                                                              fill="#0481F4"/>-->
<!--                                                    </svg>-->
<!--                                                </div>-->
<!--                                                <div class="col text-2 p-0">-->
<!--                                                    {{ $t('pageAnimation') }}-->
<!--                                                </div>-->
<!--                                                <div class="col-1 d-flex justify-content-end p-0 mb-4">-->
<!--                                                    <div class="form-check form-switch">-->
<!--                                                        <input class="form-check-input" type="checkbox" role="switch">-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button" class="archive-offcanvas message-icon" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasBottom3" aria-controls="offcanvasBottom">

                                <svg width="27" height="26" viewBox="0 0 27 26" fill="none">
                                    <path
                                          d="M18.2885 2H9.22102C5.26685 2 2.91602 4.35083 2.91602 8.29416V17.3617C2.91602 21.305 5.26685 23.6558 9.21018 23.6558H18.2777C22.221 23.6558 24.5718 21.305 24.5718 17.3617V8.29416C24.5827 4.35083 22.2319 2 18.2885 2Z"
                                          fill="#97CAFB"/>
                                    <path
                                          d="M13.1751 18.0533C13.2509 18.1292 13.3376 18.1833 13.4351 18.2267C13.5326 18.27 13.6409 18.2917 13.7492 18.2917C13.8576 18.2917 13.9551 18.27 14.0634 18.2267C14.1609 18.1833 14.2476 18.1292 14.3234 18.0533L17.5734 14.8033C17.8876 14.4892 17.8876 13.9692 17.5734 13.655C17.2592 13.3408 16.7392 13.3408 16.4251 13.655L14.5617 15.5183V8.8125C14.5617 8.36833 14.1934 8 13.7492 8C13.3051 8 12.9367 8.36833 12.9367 8.8125V15.5183L11.0734 13.655C10.7592 13.3408 10.2392 13.3408 9.92508 13.655C9.61091 13.9692 9.61091 14.4892 9.92508 14.8033L13.1751 18.0533Z"
                                          fill="#0481F4"/>
                                </svg>
                            </button>
                            <div class="offcanvas offcanvas-bottom offcanvas-bottom3 library__modal" tabindex="-1" id="offcanvasBottom3"
                                 aria-labelledby="offcanvasBottomLabel">

                                <div class="offcanvas-body small">

                                    <div class="row">
                                        <div class="col all">
                                            <button v-show="false" ref="close-btn__library" aria-label="Close" type="button" class="btn-close text-white" data-bs-dismiss="offcanvas"></button>

                                            <!-- top close btn -->
                                            <div class="row">
                                                <div class="col d-flex justify-content-end p-0 swiper3-close-btn">
                                                    <button type="button" class="swiper2" data-bs-dismiss="offcanvas"
                                                            aria-label="Close">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                            <circle opacity="0.1" cx="14" cy="14" r="14" fill="#9597A1"/>
                                                            <path d="M18.456 9.54541L9.54688 18.4545" stroke="#9597A1"
                                                                  stroke-width="1.33333" stroke-linecap="round"
                                                                  stroke-linejoin="round"/>
                                                            <path d="M9.54688 9.54541L18.456 18.4545" stroke="#9597A1"
                                                                  stroke-width="1.33333" stroke-linecap="round"
                                                                  stroke-linejoin="round"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- top close btn end-->

                                            <div class="row" v-if="!this.getFavoriteBookCurrentUser">
                                                <div class="col-2 swiper3-logo-width p-0">
                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" class="logo">
                                                        <path
                                                            d="M16.8203 2.11987H7.18031C5.06031 2.11987 3.32031 3.85987 3.32031 5.97987V20.0699C3.32031 21.8699 4.61031 22.6299 6.19031 21.7599L11.0703 19.0499C11.5903 18.7599 12.4303 18.7599 12.9403 19.0499L17.8203 21.7599C19.4003 22.6399 20.6903 21.8799 20.6903 20.0699V5.97987C20.6803 3.85987 18.9503 2.11987 16.8203 2.11987ZM15.6203 9.23987L11.6203 13.2399C11.4703 13.3899 11.2803 13.4599 11.0903 13.4599C10.9003 13.4599 10.7103 13.3899 10.5603 13.2399L9.06031 11.7399C8.77031 11.4499 8.77031 10.9699 9.06031 10.6799C9.35031 10.3899 9.83031 10.3899 10.1203 10.6799L11.0903 11.6499L14.5603 8.17987C14.8503 7.88987 15.3303 7.88987 15.6203 8.17987C15.9103 8.46987 15.9103 8.94987 15.6203 9.23987Z"
                                                            fill="#0481F4"/>
                                                    </svg>
                                                </div>

                                                <div class="col d-flex justify-content-start row-text-1 p-0">
                                                    <div @click="addToFavorite">{{ $t('language.save') }}</div>
                                                </div>
                                            </div>

                                            <div class="row" v-else>
                                                <div class="col-2 swiper3-logo-width p-0">
                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" class="logo">
                                                        <path
                                                            d="M16.8203 2.11987H7.18031C5.06031 2.11987 3.32031 3.85987 3.32031 5.97987V20.0699C3.32031 21.8699 4.61031 22.6299 6.19031 21.7599L11.0703 19.0499C11.5903 18.7599 12.4303 18.7599 12.9403 19.0499L17.8203 21.7599C19.4003 22.6399 20.6903 21.8799 20.6903 20.0699V5.97987C20.6803 3.85987 18.9503 2.11987 16.8203 2.11987ZM15.6203 9.23987L11.6203 13.2399C11.4703 13.3899 11.2803 13.4599 11.0903 13.4599C10.9003 13.4599 10.7103 13.3899 10.5603 13.2399L9.06031 11.7399C8.77031 11.4499 8.77031 10.9699 9.06031 10.6799C9.35031 10.3899 9.83031 10.3899 10.1203 10.6799L11.0903 11.6499L14.5603 8.17987C14.8503 7.88987 15.3303 7.88987 15.6203 8.17987C15.9103 8.46987 15.9103 8.94987 15.6203 9.23987Z"
                                                            fill="#BDBDBD"/>
                                                    </svg>
                                                </div>

                                                <div class="col d-flex justify-content-start row-text-1 p-0">
                                                    <div>{{ $t('language.save') }}</div>
                                                </div>
                                            </div>

                                            <div class="row rows" @click="pushToSaved">
                                                <div class="col-2 swiper3-logo-width p-0">
                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" class="logo">
                                                        <path v-if="getIsUserSavedBook"
                                                              d="M16.19 2.20996H7.81C4.17 2.20996 2 4.37996 2 8.01996V16.39C2 20.04 4.17 22.21 7.81 22.21H16.18C19.82 22.21 21.99 20.04 21.99 16.4V8.01996C22 4.37996 19.83 2.20996 16.19 2.20996ZM8.47 12.19C8.76 11.9 9.24 11.9 9.53 12.19L11.25 13.91V7.71996C11.25 7.30996 11.59 6.96996 12 6.96996C12.41 6.96996 12.75 7.30996 12.75 7.71996V13.91L14.47 12.19C14.76 11.9 15.24 11.9 15.53 12.19C15.82 12.48 15.82 12.96 15.53 13.25L12.53 16.25C12.46 16.32 12.38 16.37 12.29 16.41C12.2 16.45 12.1 16.47 12 16.47C11.9 16.47 11.81 16.45 11.71 16.41C11.62 16.37 11.54 16.32 11.47 16.25L8.47 13.25C8.18 12.96 8.18 12.49 8.47 12.19Z"
                                                              fill="#BDBDBD"
                                                        />
                                                      <path v-else
                                                            d="M16.19 2.20996H7.81C4.17 2.20996 2 4.37996 2 8.01996V16.39C2 20.04 4.17 22.21 7.81 22.21H16.18C19.82 22.21 21.99 20.04 21.99 16.4V8.01996C22 4.37996 19.83 2.20996 16.19 2.20996ZM8.47 12.19C8.76 11.9 9.24 11.9 9.53 12.19L11.25 13.91V7.71996C11.25 7.30996 11.59 6.96996 12 6.96996C12.41 6.96996 12.75 7.30996 12.75 7.71996V13.91L14.47 12.19C14.76 11.9 15.24 11.9 15.53 12.19C15.82 12.48 15.82 12.96 15.53 13.25L12.53 16.25C12.46 16.32 12.38 16.37 12.29 16.41C12.2 16.45 12.1 16.47 12 16.47C11.9 16.47 11.81 16.45 11.71 16.41C11.62 16.37 11.54 16.32 11.47 16.25L8.47 13.25C8.18 12.96 8.18 12.49 8.47 12.19Z"
                                                            fill="#0481F4"
                                                      />
                                                    </svg>
                                                </div>

                                                <div class="col d-flex justify-content-start row-text-1 p-0">
                                                    <div> {{ $t('download') }}</div>
                                                </div>
                                            </div>

                                            <div class="row rows">
                                                <div class="col-2 swiper3-logo-width p-0">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path class="blueToWhite"
                                                              d="M11.25 6V19.91C11.25 20.62 10.53 21.11 9.88 20.84C8.02 20.06 5.71 19.38 4.07 19.16L3.76 19.12C2.79 19 2 18.11 2 17.14V5.25C2 4.05 2.97 3.08 4.17 3.08H4.24C6.12 3.24 8.89 4.12 10.72 5.11C11.05 5.29 11.25 5.62 11.25 6Z"
                                                              fill="#0481F4"/>
                                                        <path class="blueToWhite"
                                                              d="M19.83 3.08H19.77C19.53 3.1 19.27 3.13 19 3.18C17.77 3.38 16.31 3.8 15 4.32C14.38 4.57 13.8 4.84 13.29 5.11C12.96 5.29 12.75 5.63 12.75 6V19.91C12.75 20.62 13.47 21.11 14.12 20.84C15.98 20.06 18.29 19.38 19.93 19.16L20.24 19.12C21.21 19 22 18.11 22 17.14V5.25C22 4.05 21.03 3.08 19.83 3.08ZM19.27 13.73C19.27 14.12 18.99 14.29 18.65 14.1L17.59 13.51C17.48 13.45 17.3 13.45 17.18 13.51L16.12 14.1C15.78 14.29 15.5 14.12 15.5 13.73V10.66C15.5 10.2 15.88 9.82 16.34 9.82H18.44C18.9 9.82 19.28 10.2 19.28 10.66V13.73H19.27Z"
                                                              fill="#0481F4"/>
                                                    </svg>
                                                </div>

                                                <div class="col d-flex justify-content-start row-text-1 p-0">
                                                    <div @click="isVisible = !isVisible; checkShelf()">
                                                        <span class="link"> {{ $t('addAShelf') }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row rows justify-content-center" v-if="linkForLibrary">
                                                <div class="col-10 alert alert-warning" role="alert">
                                                    {{ $t('shelf.youDoNotHaveSingleShelf') }}
                                                    <router-link to="/library" class="alert-link">
                                                        {{ $t('shelf.createANewShelf') }}
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div class="row justify-content-center" v-if="isVisible">
                                                <div class="col-8 d-flex justify-content-start row-text-1 p-0">
                                                    <select v-model="shelfId" class="form-select"
                                                            aria-label="Default select example">
                                                        <option :value=shelf.id v-for="shelf in getMyShelves.models"
                                                                :key="shelf.id"> {{ shelf.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-2 d-flex justify-content-center mt-3">
                                                    <div @click="pushToShelf()">
                                                        <svg width="38" height="38" color="var(--blueToFade)" fill="currentColor"
                                                             class="bi bi-plus-lg" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd"
                                                                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row rows last" @click="deleteSavedBook"
                                                 v-if="this.getFavoriteBookCurrentUser">
                                                <div class="col-2 swiper3-logo-width p-0">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="trash-icon">
                                                        <path class="blueToWhite"
                                                              d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z"
                                                              fill="#0481F4"/>
                                                        <path class="blueToWhite"
                                                              d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z"
                                                              fill="#0481F4"/>
                                                    </svg>
                                                </div>

                                                <div class="col d-flex justify-content-start row-text-1 p-0">
                                                    <div>
                                                        <span class="link">{{ $t('deleteFromMyBook') }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row rows last" @click="deleteSavedBook" v-else>
                                                <div class="col-2 swiper3-logo-width p-0">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="trash-icon">
                                                        <path
                                                            d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z"
                                                            fill="#BDBDBD"/>
                                                        <path
                                                            d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z"
                                                            fill="#BDBDBD"/>
                                                    </svg>
                                                </div>

                                                <div class="col d-flex justify-content-start row-text-1 p-0">
                                                    <div>
                                                        <span class="link">{{ $t('deleteFromMyBook') }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="selected__container">
            <div class="selected_text_container" v-if="selectBoxVisible">
                <div class="selected_item_container">
                    <div class="row">
                        <div class="col-3 selected_item" @click="addQuote">
                            <div class="selected_item_svg">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M8.09 11.63H3.4C3.48 6.95997 4.4 6.18997 7.27 4.48997C7.6 4.28997 7.71 3.86997 7.51 3.52997C7.32 3.19997 6.89 3.08997 6.56 3.28997C3.18 5.28997 2 6.50997 2 12.32V17.71C2 19.42 3.39 20.8 5.09 20.8H8.09C9.85 20.8 11.18 19.47 11.18 17.71V14.71C11.18 12.96 9.85 11.63 8.09 11.63Z"
                                        fill="white"/>
                                    <path
                                        d="M18.9105 11.63H14.2205C14.3005 6.95997 15.2205 6.18997 18.0905 4.48997C18.4205 4.28997 18.5305 3.86997 18.3305 3.52997C18.1305 3.19997 17.7105 3.08997 17.3705 3.28997C13.9905 5.28997 12.8105 6.50997 12.8105 12.33V17.72C12.8105 19.43 14.2005 20.81 15.9005 20.81H18.9005C20.6605 20.81 21.9905 19.48 21.9905 17.72V14.72C22.0005 12.96 20.6705 11.63 18.9105 11.63Z"
                                        fill="white"/>
                                </svg>
                            </div>
                            <div class="selected_item_text">
                                {{ $t('selectedText.quote') }}
                            </div>
                        </div>
                        <div class="col-3 selected_item" @click="copyText">
                            <div class="selected_item_svg">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                                        fill="white"/>
                                    <path
                                        d="M17.0998 2H12.8998C9.81668 2 8.37074 3.09409 8.06951 5.73901C8.00649 6.29235 8.46476 6.75 9.02167 6.75H11.0998C15.2998 6.75 17.2498 8.7 17.2498 12.9V14.9781C17.2498 15.535 17.7074 15.9933 18.2608 15.9303C20.9057 15.629 21.9998 14.1831 21.9998 11.1V6.9C21.9998 3.4 20.5998 2 17.0998 2Z"
                                        fill="white"/>
                                </svg>
                            </div>
                            <div class="selected_item_text">
                                {{ $t('selectedText.copy') }}
                            </div>
                        </div>
                        <div class="col-3 selected_item" >
                            <div class="selected_item_svg">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM12 15.88C9.86 15.88 8.12 14.14 8.12 12C8.12 9.86 9.86 8.12 12 8.12C14.14 8.12 15.88 9.86 15.88 12C15.88 14.14 14.14 15.88 12 15.88ZM17.92 6.88C17.87 7 17.8 7.11 17.71 7.21C17.61 7.3 17.5 7.37 17.38 7.42C17.26 7.47 17.13 7.5 17 7.5C16.73 7.5 16.48 7.4 16.29 7.21C16.2 7.11 16.13 7 16.08 6.88C16.03 6.76 16 6.63 16 6.5C16 6.37 16.03 6.24 16.08 6.12C16.13 5.99 16.2 5.89 16.29 5.79C16.52 5.56 16.87 5.45 17.19 5.52C17.26 5.53 17.32 5.55 17.38 5.58C17.44 5.6 17.5 5.63 17.56 5.67C17.61 5.7 17.66 5.75 17.71 5.79C17.8 5.89 17.87 5.99 17.92 6.12C17.97 6.24 18 6.37 18 6.5C18 6.63 17.97 6.76 17.92 6.88Z"
                                        fill="white"/>
                                </svg>
                            </div>
                            <div class="selected_item_text">
                                {{ $t('selectedText.share') }}
                            </div>
                        </div>
                        <div class="col-3 selected_item" @click="selectColor">
                            <div class="selected_item_svg">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM10.95 17.51C10.66 17.8 10.11 18.08 9.71 18.14L7.25 18.49C7.16 18.5 7.07 18.51 6.98 18.51C6.57 18.51 6.19 18.37 5.92 18.1C5.59 17.77 5.45 17.29 5.53 16.76L5.88 14.3C5.94 13.89 6.21 13.35 6.51 13.06L10.97 8.6C11.05 8.81 11.13 9.02 11.24 9.26C11.34 9.47 11.45 9.69 11.57 9.89C11.67 10.06 11.78 10.22 11.87 10.34C11.98 10.51 12.11 10.67 12.19 10.76C12.24 10.83 12.28 10.88 12.3 10.9C12.55 11.2 12.84 11.48 13.09 11.69C13.16 11.76 13.2 11.8 13.22 11.81C13.37 11.93 13.52 12.05 13.65 12.14C13.81 12.26 13.97 12.37 14.14 12.46C14.34 12.58 14.56 12.69 14.78 12.8C15.01 12.9 15.22 12.99 15.43 13.06L10.95 17.51ZM17.37 11.09L16.45 12.02C16.39 12.08 16.31 12.11 16.23 12.11C16.2 12.11 16.16 12.11 16.14 12.1C14.11 11.52 12.49 9.9 11.91 7.87C11.88 7.76 11.91 7.64 11.99 7.57L12.92 6.64C14.44 5.12 15.89 5.15 17.38 6.64C18.14 7.4 18.51 8.13 18.51 8.89C18.5 9.61 18.13 10.33 17.37 11.09Z"
                                        fill="white"/>
                                </svg>
                            </div>
                            <div class="selected_item_text">
                                {{ $t('selectedText.note') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="selected_text_container" v-if="selectColorVisible">
                <div class="row">
                    <div class="col color_box" @click="selectColor1('rgba(26, 26, 26, 1)')">
                        <div class="color_item box_1">

                        </div>
                    </div>
                    <div class="col color_box" @click="selectColor1('rgba(100, 180, 255, 1)')">
                        <div class="color_item box_2">

                        </div>
                    </div>
                    <div class="col color_box" @click="selectColor1('rgba(255, 207, 37, 1)')">
                        <div class="color_item box_3">

                        </div>
                    </div>
                    <div class="col color_box" @click="selectColor1('rgba(51, 255, 0, 1)')">
                        <div class="color_item box_4">

                        </div>
                    </div>
                    <div class="col color_box" @click="selectColor1('rgba(255, 56, 56, 1)')">
                        <div class="color_item box_5">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import GoBackButton from '@/components/modals/GoBackButton.vue'
import RangeSlider from 'vue-range-slider'
import '@/assets/range-slider.css'
import {PageFlip} from "page-flip/dist/js/page-flip.module";
import {EventSourcePolyfill} from 'event-source-polyfill'

export default {
    name: 'read-book',
    components: {GoBackButton, RangeSlider},
    data() {
        return {
            readingBooks: [],
            textArray: [],
            text: '',
            width: 0,
            height: 0,
            scrollHeight: 0,
            value: 0,
            totalPageNumber: 1,
            selectedRange: null,
            selectColorVisible: false,
            selectBoxVisible: false,
            selectedText: '',
            selectedColor: '',
            message: '',
            localhost: process.env.VUE_APP_API_URL,
            words: [],
            theme: localStorage.getItem('theme'),
            linkForLibrary: false,
            isVisible: false,
            shelfId: 0,
            readBookData: {
                book: "/api/books/" + this.$route.params.id,
                countReadPage: 0
            },
            chat: {
                mercureUrl: process.env.VUE_APP_MERCURE_URL,
                lastMessageId: null,
                isLastPage: false,
                messages: [],
            },
            eventSource: null,
            jwt: process.env.VUE_APP_MERCURE_JWT,
            bodyClientWidth: 0,
            pageAnimation: null
        }
    },
    computed: {
        ...mapGetters([
            'getBookId',
            'getMessages',
            "getCreatedChat",
            'getUser',
            'getMyShelves',
            'getFavoriteBookCurrentUser',
            'getUserBoughtBook',
            'getIsUserSavedBook'
        ]),
        getBrightness() {
            if (parseInt(localStorage.getItem('brightness')) >= 0) {
                return parseInt(localStorage.getItem('brightness'))
            }

            return 40
        }
    },
    methods: {
        ...mapActions([
            'fetchUser',
            'fetchBookId',
            'pushQuote',
            'pushSavedBook',
            'pushMessage',
            'fetchMessages',
            'createChat',
            'pushFavoriteBook',
            'fetchFavoriteBookCurrentUser',
            'deleteFavorite',
            'addBookToShelf',
            'fetchBoughtBook',
            'fetchIsUserSavedBook',
            'pushReadBook',
            'fetchMyShelves'
        ]),
        sendBrightness(event) {
            try {
                window.pushBrightness(true, event)
            } catch {
                console.log('save brightness')
            }

            localStorage.setItem('brightness', event)
        },
        getBookText() {
            this.textArray = []

            for (let i = 0; i < this.words.length; i++) {
                this.text += " " + this.words[i];
                this.$refs.text2.innerHTML = this.text + " " + this.words[i + 1];

                if (this.$refs.text2.scrollHeight > (this.height) || i + 1 === this.words.length) {
                    this.textArray.push(this.text);
                    this.text = ''
                }
            }

            this.$refs.text2.innerHTML = ''
            this.totalPageNumber = this.textArray.length
        },
        changePage() {
            this.pageAnimation.flip(this.value, 'bottom')
        },
        createPages() {
            const bookContent = document.getElementById('book-content')
            bookContent.removeChild(bookContent.firstChild)

            this.textArray.forEach((item) => {
                let div = document.getElementById('my-page').cloneNode(true)
                let text = document.createTextNode(item)
                div.appendChild(text)
                bookContent.appendChild(div)
            })

            bookContent.removeChild(bookContent.firstChild)
        },
        pushPagesToPageFlip() {
            this.pageAnimation = new PageFlip(document.getElementById('book-content'),
                {
                    width: document.getElementById('book-content').clientWidth,
                    height: this.height,
                    drawShadow: true,
                    maxShadowOpacity: 0.2,
                    useMouseEvents: true
                }
            );

            this.pageAnimation.loadFromHTML(document.querySelectorAll('.my-page'))
            this.pageAnimation.on('flip', (e) => {this.value = e.data})

            // Ushbu funksiya text'ni belgilaganda ochiladigan menu'ni ishga tushiradi.
            // this.screenTouch()
        },
        screenTouch() {
            const bookContent = document.getElementById('book-content')

            let touchTime, time, isTouchMove = false;

            bookContent.addEventListener("touchstart", () => {
                isTouchMove = false;
                touchTime = setTimeout(() => {time = 700}, 700);
            });

            bookContent.addEventListener("touchmove", () => {
                isTouchMove = true
            });

            bookContent.addEventListener("touchend", () => {
                if (time === 700 && !isTouchMove) {
                    this.pageAnimation.clear()
                    this.selectBoxVisible = true
                }
                time = null;
                clearTimeout(touchTime)
            });
        },
        async parseText() {
            this.height = Math.floor(document.getElementById('book-content').clientHeight * .90)
            this.words = this.getUserBoughtBook.text.replace(/\\t/g, '&emsp;').split(" ")

            await this.getBookText()
            await this.createPages()
            await this.pushPagesToPageFlip()
        },
        sendMessage() {
            if (this.message !== '') {
                this.pushMessage({chat: this.getBookId.chat['@id'], message: this.message})
                    .then(() => {
                        this.message = ''
                    })
            }
        },
        openChat() {
            if (this.getBookId.chat === null) {
                this.createChat({book: this.getBookId['@id']})
                    .then(() => {
                        this.connectMercure(this.getCreatedChat.id)
                    })
            } else {
                this.fetchMessages('chat=' + this.getBookId.chat.id)
                    .then(async () => {
                        this.chat.lastMessageId = this.getMessages[this.getMessages.length - 1].id
                        this.chat.messages = this.getMessages.reverse()

                        await this.connectMercure(this.getBookId.chat.id)
                        await this.$refs.scrollToMe.scrollTo(0, this.$refs.scrollToMe.scrollHeight + 100)
                    })
            }
        },
        connectMercure(chatId) {
            const url = new URL(this.chat.mercureUrl)
            url.searchParams.append('topic', 'chat/' + chatId)
            this.eventSource = new EventSourcePolyfill(url, {
                headers: {
                    Authorization: 'Bearer ' + this.jwt
                }
            })

            this.eventSource.addEventListener('message', async (event) => {
                await this.addNewMessageToArray(event)
                this.$refs.scrollToMe.scrollTop = this.$refs.scrollToMe.scrollHeight
            })
        },
        addNewMessageToArray(event) {
            this.chat.messages.push(JSON.parse(event.data))
        },
        getOldMessages() {
            if (this.getBookId.chat !== null && this.$refs.scrollToMe.scrollTop === 0 && !this.chat.isLastPage) {
                this.fetchMessages('chat=' + this.getBookId.chat.id + '&id[lt]=' + this.chat.lastMessageId)
                    .then(async () => {
                        this.chat.lastMessageId = this.getMessages[this.getMessages.length - 1].id

                        await this.unshiftOldMessages()

                        if (this.getMessages.length < 10) {
                            this.chat.isLastPage = true
                        }

                        this.$refs.scrollToMe.scrollTo(0, 900)
                    })
            }
        },
        unshiftOldMessages() {this.chat.messages.unshift(...this.getMessages.reverse())},
        changeToDark() {
            this.theme = 'dark'
        },
        changeToLight() {
            this.theme = 'light'
        },
        async addToFavorite() {
            await this.pushFavoriteBook({book: '/api/books/' + this.$route.params.id})
            await this.fetchFavoriteBookCurrentUser(this.$route.params.id)
        },
        pushToSaved() {
            try {
                window.bookId(this.$route.params.id)
            } catch (e) {
                console.log(e)
            }

            if(!this.getIsUserSavedBook) {
                this.pushSavedBook({book: '/api/books/' + this.$route.params.id})
                .then(()=> {
                    this.fetchIsUserSavedBook({bookId: this.$route.params.id, userId: this.getUser.id})
                })
            }
        },
        checkShelf() {
            if (!this.getMyShelves.models.length) {
                this.linkForLibrary = true
                this.isVisible = false
            }
        },
        pushToShelf() {
            this.addBookToShelf({
                shelfId: parseInt(this.shelfId),
                book: '/api/books/' + this.$route.params.id
            })
                .then(() => {
                    this.isVisible = false
                })
        },
        async deleteSavedBook() {
            await this.fetchFavoriteBookCurrentUser(this.$route.params.id)

            if(this.getFavoriteBookCurrentUser) {
                await this.deleteFavorite(this.getFavoriteBookCurrentUser.id)
            }

            await this.fetchFavoriteBookCurrentUser(this.$route.params.id)
        },
        addQuote() {
            this.pushQuote({
                text: this.selectedText,
                book: '/api/books/' + this.$route.params.id
            })
        },
        copyText() {
            navigator.clipboard.writeText(this.selectedText)
        },
        selectColor() {
            this.selectBoxVisible = !this.selectBoxVisible
            this.selectColorVisible = !this.selectColorVisible
        },
        selectColor1(color) {
            this.selectColorVisible = !this.selectColorVisible
            this.selectedColor = color
            this.surroundSelection()
        },
        getSelectedText() {
            if (window.getSelection) {
                let sel = window.getSelection()
                if (sel.getRangeAt && sel.rangeCount) {
                    this.selectedRange = sel.getRangeAt(0)
                }
                return window.getSelection().toString()
            } else {
                if (document.selection) {
                    this.selectedRange = document.selection.createRange()
                    return document.selection.createRange().text
                }
            }

            return ''
        },
        surroundSelection() {
            let span = document.createElement('div')
            span.style.display = 'inline'
            span.className = 'highlight'
            span.style.background = this.selectedColor
            if (window.getSelection) {
                let sel = window.getSelection()
                if (sel.rangeCount) {
                    let range = this.selectedRange.cloneRange()
                    range.surroundContents(span)
                    sel.removeAllRanges()
                    sel.addRange(range)
                }
            }
        },
        highlight() {
            let text = this.getSelectedText()
            this.selectedText = text
            this.selectBoxVisible = text.length > 3;
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.value > 0) {
            if (this.readingBooks.find(obj => obj.id === parseInt(this.$route.params.id)) === undefined) {
                this.readingBooks.push({
                    id: parseInt(this.$route.params.id),
                    page: this.value
                })
            } else {
                this.readingBooks.forEach((item) => {
                    if (item.id === parseInt(this.$route.params.id)) {
                        item.page = this.value
                    }
                })
            }

            localStorage.setItem('readingBooks', JSON.stringify(this.readingBooks))
        }

        this.$refs["close-btn__chat"].click()
        this.$refs["close-btn__setting"].click()
        this.$refs["close-btn__library"].click()
        next()
    },
    async mounted() {
        this.bodyClientWidth = document.body.clientWidth
        this.fetchUser()

        // Ushbu kod text ustiga bosilganda touchstart ishga tushishini oldini oladi.
        document.getElementById('book-content').addEventListener("touchstart", (ev) => {
            ev.preventDefault()
        }, false);

        let modal = document.getElementById('offcanvasBottom2')
        modal.addEventListener('hide.bs.offcanvas', () => {
            if (this.eventSource !== null) {
                this.eventSource.close()
            }
        })

        await this.fetchBoughtBook(this.$route.params.id)
            .then(() => {this.parseText()})
            .catch(() => {this.$router.push('/book/' + this.$route.params.id)})
        await this.fetchBookId(this.$route.params.id)
        await this.fetchFavoriteBookCurrentUser(this.$route.params.id)
        await this.fetchIsUserSavedBook(this.$route.params.id)
        await this.fetchMyShelves()
        setInterval(() => {
            this.pushReadBook(this.readBookData)
                .then(() => {this.readBookData.countReadPage = 0})
        }, 60000)
        addEventListener("resize", () => {this.parseText()});

        if (localStorage.getItem('readingBooks')) {
            this.readingBooks = JSON.parse(localStorage.getItem('readingBooks'))
        }

        if (this.readingBooks.length > 0) {
            this.readingBooks.forEach((item) => {
                if (item.id === parseInt(this.$route.params.id)) {
                    this.value = item.page
                    this.changePage()
                }
            })
        }
    },
}
</script>

<style scoped>

.book_wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    margin-top: 20px;
    flex: 1 1 auto;
    overflow: hidden;
}

.footer {
    position: relative;
    z-index: 10;
    background: transparent;
}

.blueToWhite {
    fill: var(--blueToWhite);
}

.archive-offcanvas {
    padding: 0;
    border: none;
    background-color: var(--color-container);
}

.library__modal {
    border-radius: 16px 16px 0 0;
    height: 50vh;
    background-color: var(--color-header);
}

.swiper3-close-btn {
    margin: 13px 13px 0 0;
}

.swiper3-logo-width {
    width: 39px;
    margin: 10px 0 0 40px;
}

.row-text-1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
    margin-top: 16px;
}

.link {
    text-decoration: none;
    color: var(--color-text);
}

.rows {
    margin-top: 28px;
}

.last {
    padding-bottom: 15px;
}

.setting__modal {
    background-color: var(--color-header);
    border-radius: 16px 16px 0 0;
    height: 25vh;
}

.back-button {
    padding-left: 12px;
}

.swiper, .swiper2 {
    background-color: var(--color-header);
    padding: 0;
    border: none;
}

.swiper > svg > path {
    stroke: var(--color-swiper);
}

.button-offcanvas {
    padding: 0;
    border: none;
    background-color: var(--color-container);
}

.text-1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-textDarkGray-To-white);
}

.text-2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
    margin-top: 5px;
}

.logo-width {
    max-width: 57px;
    margin: 0 0 0 28px;
}

.wrapper {
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
}

input[type=range] {
    width: 100%;
    height: 2px;
}

.hr {
    border-bottom: 1px solid #C4E2FF;
    width: 100%;
}

.hr-width {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin: 21px 5px 10px 5px;
}

.form-check-input {
    width: 46px;
    height: 24px;
    margin: -1px 27px 0 0;
    background-color: #E2F1FF;
    border: none;
}

.form-check-input:checked {
    background-color: #0481F4;
}

input[type=range] {
    width: 100%;
    height: 2px;
    color: #0481F4;
}

.book-name {
    color: var(--color-Black-To-White);
    font-weight: 600;
    font-size: 16px;
    line-height: 19.5px;
    margin-top: -5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px 0 10px;
}

.archive {
    fill: var(--blueToWhite);
}

.book-author {
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 14.6px;
    color: var(--color-text-to-blue);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.bg-book-author-row {
    margin-top: -8px;
    padding: 0 20px 0 20px;
}

/* book-text class'ini ekran xajmiga qarab matnlarni joylashtirish uchun aloqasi bor o'chirib yubormang */
.book-text {
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    position: absolute;
    width: 90%;
}

/* bu class kitob text'i uchun ishlayapti */
.stf__parent {
    color: var(--color-book-text);
    text-align: justify;
    font-size: 12px;
    line-height: 15px;
}

.my-page {
    background-color: var(--color-container);
}

.number-1, .number-2 {
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 18px;
    color: var(--color-text);
}

.message-icon {
    background-color: transparent;
}

/*second swipe offcanvas (chat) */
.offcanvas-bottom2 {
    border-radius: 16px 16px 0 0;
    height: 80vh;
    background-color: var(--color-header);
}

@keyframes scroll {
    from { top: 0; }
    to { top: -100%; }
}

.swiper2-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-Blacktext-To-Blue);
}

.swiper2-comment-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--color-Black-To-White);
    margin-top: 6px;
}

.swiper2-time {
    font-size: 10px;
    color: #9597A1;
}

.swiper2-input-border {
    border-radius: 12px 0 12px 12px;
    background-color: var(--bg-button-row-lighter);
}

.swiper2-text-1 {
    font-size: 14px;
    line-height: 20px;
    margin: 11px 0 0 17px;
    overflow-wrap: break-word;
    color: var(--color-Black-To-White);
}

.swiper2-time-2 {
    font-size: 10px;
    line-height: 12px;
    color: #9597A1;
    margin: 10px 0 0 17px;
}

.chat-bottom-row {
    background-color: var(--color-header);
}

.chat-box {
    margin-bottom: 70px;
}

.input-box {
    min-width: 85%;
    padding-left: 30px;
}

.form-control {
    height: 52px;
    border-radius: 40px;
    padding-left: 23px;
    background-color: var(--color-input) !important;
    color: var(--color-text);
    border: none;
    box-shadow: none;
    font-size: 14px;
}

.form-control::placeholder {
    line-height: 20px;
    color: #97CAFB;
}

.send-btn {
    min-width: 15%;
    margin-left: -25px;
    margin-top: 10px;
}

.last-row__item {
    display: flex;
    justify-content: center;
    gap: 36px;
}

.selected_text_container {
    position: absolute;
    top: 50vh;
    width: 85%;
    left: 7%;
    height: 60px;
    background: rgba(4, 129, 244, 1);
    border-radius: 12px
}

.selected_item_container {
    margin: 9px 19px;
    height: 43px
}

.selected_item {
    border-right: solid 1px rgba(57, 153, 241, 1);
}

.selected_item:last-child {
    border-right: none;
}

.selected_item_svg {
    justify-content: center;
    display: flex
}

.selected_item_text {
    font-size: 10px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    padding-top: 5px;
}

.color_box {
    display: flex;
    justify-content: center;
}

.color_item {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-top: 18px;
}

.box_1 {
    background: rgba(26, 26, 26, 1);
}

.box_2 {
    background: rgba(100, 180, 255, 1);
}

.box_3 {
    background: rgba(255, 207, 37, 1);
}

.box_4 {
    background: rgba(51, 255, 0, 1);
}

.box_5 {
    background: rgba(255, 56, 56, 1);
}
</style>

import fetchModels from "../../mixin/store/api/fetchModels";
import pushModel from "../../mixin/store/api/pushModel";
import fetchModel from "../../mixin/store/api/fetchModel";
import changeModel from "../../mixin/store/api/changeModel";

export default {
    actions: {
        fetchMoments(context, page) {
            return fetchModels(context, 'moments', page, 'updateMoments')
        },
        fetchContentMoment(context, momentId, page) {
            return fetchModels(context, 'content_moments?moment=' + momentId, page, 'updateContentMoments')
        },
        fetchAnswerMoment(context, data) {
            return pushModel(context, 'moment_answers', data, 'updateAnswerMoment')
        },
        fetchSearchAnswerMoment(context, id) {
            return fetchModel(context, `moment_answers?contentMoment=${id}`, null, 'updateSearchAnswerMoment')
        },
        pushCountContentMoment(context, data) {
            changeModel(context, 'moment_questions/' + data.questionMoment, data, 'updateCountContentMoment')
        }
    },
    mutations: {
        updateMoments(state, data) {
            state.moments = data
        },
        updateContentMoments(state, data) {
            state.contentMoments = data
        },
        updateAnswerMoment(state, data) {
            state.answerMoment = data
        },
        updateSearchAnswerMoment(state, data) {
            state.searchAnswerMoment = data
        },
        updateCountContentMoment(state, data) {
            state.countContentMoment = data
        },
        clearContentMoment(state) {
            state.contentMoments = {
                models: [],
                totalItems: 0
            }
        }
    },
    state: {
        moments: {
            models: [],
            totalItems: 0,
        },
        contentMoments: {
            models: [],
            totalItems: 0,
        },
        answerMoment: {
            models: [],
            totalItems: 0,
        },
        searchAnswerMoment: {
            models: [],
            totalItems: 0,
        },
        countContentMoment: {
            models: [],
            totalItems: 0,
        }
    },
    getters: {
        getMoments(state) {
            return state.moments.models
        },
        getContentMoments(state) {
            return state.contentMoments.models
        },
        getAnswerMoment(state) {
            return state.answerMoment.models
        },
        getSearchAnswerMoment(state) {
            return state.searchAnswerMoment['hydra:member']
        },
        getCountContentMoment(state) {
            return state.countContentMoment.models
        }
    }
}

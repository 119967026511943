const ACCESS_NAME = 'access-token'
const REFRESH_NAME = 'refresh-token'

export default {
    getAccess() {
        return localStorage.getItem(ACCESS_NAME) || ''
    },
    setAccess(token) {
        localStorage.setItem(ACCESS_NAME, token)
    },
    removeAccess() {
        localStorage.removeItem(ACCESS_NAME)
    },
    getRefresh() {
        return localStorage.getItem(REFRESH_NAME) || ''
    },
    setRefresh(token) {
        localStorage.setItem(REFRESH_NAME, token)
    },
    removeRefresh() {
        localStorage.removeItem(REFRESH_NAME)
    },
    clear() {
        this.removeAccess()
        this.removeRefresh()
    }
}

import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
        fetchCities(context) {
            return fetchModels(context, 'cities?pagination=false', null, 'updateCities')
        }
    },
    mutations: {
        updateCities(state, data) {
            state.cities = data
        }
    },
    state: {
        cities: {
            models: [],
            totalItems: 0
        }
    },
    getters: {
        getCities(state) {
            return state.cities.models
        }
    }
}
<template>
    <div class="sleep">
        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom2" aria-controls="offcanvasBottom">
            <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99993 20.5834C9.99993 21.4934 10.1408 22.3817 10.4008 23.205C6.24076 21.7642 3.09909 17.94 2.77409 13.4659C2.44909 8.71003 5.18993 4.26836 9.62076 2.40502C10.7691 1.92836 11.3541 2.27502 11.6033 2.52419C11.8416 2.76252 12.1774 3.33669 11.7008 4.43086C11.2133 5.55752 10.9749 6.74919 10.9749 7.98419C10.9858 10.1942 11.8524 12.2417 13.2608 13.8125C11.2783 15.3942 9.99993 17.8425 9.99993 20.5834Z" fill="var(--fadedBlueToDark)"/>
                <path d="M23.2274 19.1967C21.0824 22.1109 17.6808 23.8225 14.0516 23.8225C13.8783 23.8225 13.7049 23.8117 13.5316 23.8009C12.4483 23.7575 11.3974 23.5517 10.4008 23.205C10.1408 22.3817 9.99993 21.4934 9.99993 20.5834C9.99993 17.8425 11.2783 15.3942 13.2608 13.8125C14.8533 15.6 17.1391 16.7592 19.6633 16.8675C20.3458 16.9 21.0283 16.8459 21.6999 16.7267C22.9133 16.51 23.4008 16.965 23.5741 17.2575C23.7583 17.55 23.9533 18.1892 23.2274 19.1967Z" fill="var(--fadedBlueToDark)"/>
            </svg>
        </button>
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom2" aria-labelledby="offcanvasBottomLabel">
            <button class="close__btn" ref="close__btn" data-bs-dismiss="offcanvas" hidden="hidden"></button>
            <timer-menu />
        </div>
    </div>
</template>

<script>
import TimerMenu from "../modals/TimerMenu";
export default {
    name: "listen-sleep",
    components: {TimerMenu},
    methods: {
        closeModal() {
            this.$refs['close__btn'].click()
        }
    },
    mounted() {
        this.$parent.$on('parent-route-leave', this.closeModal);
    }
}
</script>

<style scoped>
.sleep > button {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
}
.offcanvas {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 20vh;
    padding: 9px 26px 9px 26px;
    overflow: scroll;
    display: flex;
    align-items: center;
    background: var(--color-block);
    justify-content: center;
}
</style>
<template>
    <div class="all" ref="scroll">

        <div class="row">
            <div class="col bg-first-content" :class="{'pb-3' : isSearching}">
                <div class="row">
                    <div class="col mt-3 d-flex justify-content-center">
                        <div class="form-input">
                            <span class="icon">
                              <img src="../../components/images/Search.png" class="fa fa-envelope" aria-hidden="true" alt="">
                            </span>
                            <input type="text" :placeholder="$t('home.search')" class="form-control" v-model="text"
                                   @keyup="search">
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!isSearching">
                    <div class="col position-text ps-4">
                        {{ $t('home.moments') }}
                    </div>
                </div>

                <div class="row" v-if="!isSearching">
                    <div class="col-12 pe-0">
                        <div class="stories-container">
                            <figure class="profiles" v-for="item in getMoments" :key="item.id">
                                <div>
                                    <router-link :to="'/moment/' + item.id">
                                        <img :src="localhost + item.image.contentUrl" class="photo" alt=""/>
                                    </router-link>
                                </div>
                                <figcaption class="story-name">{{ item.name }}</figcaption>
                            </figure>
                        </div>
                    </div>
                </div>

                <div class="row mt-1 carousel-row" style="height: 48.4vw" v-if="!isSearching">
                    <div class="col px-0">
                        <hooper
                            :auto-play="false"
                            :transition="1200"
                            :playSpeed="5000"
                            :itemsToShow="1.1"
                            :infiniteScroll="true"
                            :centerMode="true"
                        >
                            <slide
                                class="pt-4"
                                v-for="(item, index) in getBanners"
                                :key="index" :index="index"
                            >
                                <img
                                    class="slide-content py-2 px-1"
                                    :src="localhost + item.image.contentUrl"
                                    @click="pushRelatedPage(item)"
                                    alt="...">
                            </slide>
                            <hooper-pagination style="" slot="hooper-addons"/>
                        </hooper>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="!isSearching">
            <div class="col bg">
                <div class="row">
                    <div class="col category-text ps-4">
                        {{ $t('home.categories') }}
                    </div>
                    <div class="col category-text all-text me-2 text-end">
                        <router-link to="/categories" class="text-primary" style="text-decoration: none">
                            {{ $t('home.all') }} >
                        </router-link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-0">
                        <div class="stories-container-2 pe-3">

                            <div v-if="getUserRole.isAdmin && !isAdminClick" @click="isAdminClick = true"
                                 class="profiles-2">
                                <div class="photo-2">
                                    <img src="../../components/images/live.png" alt="rasm"/>
                                </div>
                                <div class="category-name">{{ $t('broadcast') }}</div>
                            </div>

                            <div v-else-if="getUserRole.isAdmin && isAdminClick" @click="isAdminClick = false"
                                 class="broadcast">
                                <span @click="pushToPage()">{{ $t('create') }}</span>
                                <hr class="broadcast__hr">
                                <span @click="$router.push('/live-video')">{{ $t('join') }}</span>
                            </div>

                            <div v-else class="profiles-2" @click="$router.push('/live-video')">
                                <div class="photo-2">
                                    <img src="../../components/images/live.png" alt="rasm"/>
                                </div>
                                <div class="category-name">{{ $t('broadcast') }}</div>
                            </div>

                            <div class="profiles-2" @click="$router.push('/categories')">
                                <div class="photo-2">
                                    <img src="../../components/images/categories.png" alt="rasm"/>
                                </div>
                                <div class="category-name">{{ $t('genres') }}</div>
                            </div>

                            <div class="profiles-2">
                                <div class="photo-2">
                                    <img src="../../components/images/podcast.png" alt="rasm"/>
                                </div>
                                <div class="category-name">{{ $t('soon') }}</div>
                            </div>

                            <div class="profiles-2">
                                <div class="photo-2">
                                    <img src="../../components/images/audioBook.png" alt="rasm"/>
                                </div>
                                <div class="category-name">{{ $t('soon') }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col category-text ps-4">
                        {{ $t('home.recommend') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row body d-flex justify-content-center" :class="{'mt-3' : isSearching}">
            <div class="col-11">
                <div class="row book-row" v-for="book in isSearching ? searchedAllBooks : recommendedBooks"
                     :key="book.index" @click="pushRelatedBook(book)">
                    <div class="col-2">
                        <img
                                v-if="book.type === undefined && book.image"
                                :src="localhost + book.image.contentUrl"
                                alt="rasm" class="book-img"
                        >
                        <img
                                v-else-if="book['@type'] === undefined && book.filePath"
                                :src="localhost + '/media/' + book.filePath"
                                alt="rasm" class="book-img"
                        >
                        <img
                                v-else-if="book.coverSourceUrl"
                                :src="changeHeightImg(book.coverSourceUrl)"
                                @error="setDefaultImg"
                                alt="rasm" class="book-img"
                        >
                        <svg v-if="book.type === 'audioBook' || book['@type'] === 'AudioBook'" width="10" height="10"
                             viewBox="0 0 16 16" fill="none" class="head-phone">
                            <rect width="16" height="16" rx="8" fill="white"/>
                            <path
                                    d="M4.14527 10.7709C3.97444 10.7709 3.83277 10.6292 3.83277 10.4584V8.08335C3.81194 6.95419 4.23277 5.88752 5.0161 5.08752C5.79944 4.29169 6.84944 3.85419 7.9786 3.85419C10.2869 3.85419 12.1661 5.73335 12.1661 8.04169V10.4167C12.1661 10.5875 12.0244 10.7292 11.8536 10.7292C11.6828 10.7292 11.5411 10.5875 11.5411 10.4167V8.04169C11.5411 6.07919 9.94527 4.47919 7.9786 4.47919C7.0161 4.47919 6.12444 4.85002 5.46194 5.52502C4.79527 6.20419 4.4411 7.10835 4.45777 8.07502V10.4542C4.45777 10.6292 4.32027 10.7709 4.14527 10.7709Z"
                                    fill="#0481F4"/>
                            <path
                                    d="M5.47565 8.1875H5.42148C4.54648 8.1875 3.83398 8.9 3.83398 9.775V10.5583C3.83398 11.4333 4.54648 12.1458 5.42148 12.1458H5.47565C6.35065 12.1458 7.06315 11.4333 7.06315 10.5583V9.775C7.06315 8.9 6.35065 8.1875 5.47565 8.1875Z"
                                    fill="#0481F4"/>
                            <path
                                    d="M10.5792 8.1875H10.525C9.65 8.1875 8.9375 8.9 8.9375 9.775V10.5583C8.9375 11.4333 9.65 12.1458 10.525 12.1458H10.5792C11.4542 12.1458 12.1667 11.4333 12.1667 10.5583V9.775C12.1667 8.9 11.4542 8.1875 10.5792 8.1875Z"
                                    fill="#0481F4"/>
                        </svg>
                        <svg v-else width="10" height="10" viewBox="0 0 16 16" fill="none" class="head-phone">
                            <rect width="16" height="16" rx="8" fill="white"/>
                            <path
                                    d="M7.68754 5.49999V11.2958C7.68754 11.5917 7.38754 11.7958 7.11671 11.6833C6.34171 11.3583 5.37921 11.075 4.69587 10.9833L4.56671 10.9667C4.16254 10.9167 3.83337 10.5458 3.83337 10.1417V5.18749C3.83337 4.68749 4.23754 4.28333 4.73754 4.28333H4.76671C5.55004 4.34999 6.70421 4.71666 7.46671 5.12916C7.60421 5.20416 7.68754 5.34166 7.68754 5.49999Z"
                                    fill="#0481F4"/>
                            <path
                                    d="M11.2625 4.28333H11.2375C11.1375 4.29166 11.0292 4.30416 10.9167 4.32499C10.4042 4.40833 9.79583 4.58333 9.25 4.79999C8.99167 4.90416 8.75 5.01666 8.5375 5.12916C8.4 5.20416 8.3125 5.34583 8.3125 5.49999V11.2958C8.3125 11.5917 8.6125 11.7958 8.88333 11.6833C9.65833 11.3583 10.6208 11.075 11.3042 10.9833L11.4333 10.9667C11.8375 10.9167 12.1667 10.5458 12.1667 10.1417V5.18749C12.1667 4.68749 11.7625 4.28333 11.2625 4.28333ZM11.0292 8.72083C11.0292 8.88333 10.9125 8.95416 10.7708 8.87499L10.3292 8.62916C10.2833 8.60416 10.2083 8.60416 10.1583 8.62916L9.71667 8.87499C9.575 8.95416 9.45833 8.88333 9.45833 8.72083V7.44166C9.45833 7.24999 9.61667 7.09166 9.80833 7.09166H10.6833C10.875 7.09166 11.0333 7.24999 11.0333 7.44166V8.72083H11.0292Z"
                                    fill="#0481F4"/>
                        </svg>
                    </div>
                    <div class="col ps-0" style="min-width: 50%">
                        <div class="row">
                            <div class="col book-name">
                                {{ book.name }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col book-author me-3">
                                {{ book.author }}
                            </div>
                        </div>
                        <div class="row" v-if="book.votesTotalCount && book.votesTotalUser">
                            <div class="col star-row">
                                <span>
                                    <img src="../../components/images/star-gold.png" alt="" class="star"
                                         v-for="star in Math.floor(book.votesTotalCount / book.votesTotalUser)"
                                         :key="star.index">
                                    <img src="../../components/images/star-grey.png" alt="" class="star"
                                         v-for="emptyStar in 5 - (Math.floor(book.votesTotalCount / book.votesTotalUser))"
                                         :key="emptyStar.index">
                                </span>
                                <span class="download-count">
                                    {{ (book.votesTotalCount / book.votesTotalUser).toFixed(1) }}
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <img v-for="emptyStar in 5" :key="emptyStar.index" src="../../components/images/star-grey.png" alt=""
                                 class="star">
                            <span class="download-count">0</span>
                        </div>
                    </div>
                    <div class="col book-price-row d-flex justify-content-end">
                        <span class="book-price">{{ book.price }} {{ $t('price.currency') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <FooterMenu @clickFooterHome="clearSessionStorage"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FooterMenu from "@/components/footer/footer.vue";
import {Hooper, Pagination as HooperPagination, Slide} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: "home",
    components: {
        FooterMenu,
        Hooper,
        HooperPagination,
        Slide
    },
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
            recommendedBooks: [],
            recommendedBooksPage: 1,
            imgUrl: '',
            isSearching: false,
            text: '',
            moments: [],
            banners: [],
            searchedAllBooks: [],
            isAdminClick: false,
        }
    },
    computed: {
        ...mapGetters([
            'getMoments',
            'getUserLastDevice',
            'getDevice',
            'getBanners',
            'getSearchedAllBooks',
            'getRecommendedBooks',
            'getUserRole'
        ])
    },
    methods: {
        ...mapActions([
            'fetchMoments',
            'fetchCurrentUserLastDevice',
            'fetchDevice',
            'deleteDevice',
            'fetchBanners',
            'fetchSearchAnyBooks',
            'fetchRecommendedBooks',
            'fetchUserRole',
            'clearTokens'
        ]),
        setDefaultImg(event) {
            event.target.src = this.localhost + '/book.png'
        },
        pushToPage() {
            try {
                window.createStream(this.getUserRole.isAdmin)
            } catch {
                console.log('Bu web')
            }
        },
        pushRelatedPage(item) {
            if (item.book.length > 0) {
                this.$router.push('/banner-books/' + item.id)
            } else if (item.audioBook.length > 0) {
                this.$router.push('/banner-audio-books/' + item.id)
            } else if (item.category !== null) {
                this.$router.push('/category-books/' + item.category.id)
            } else if (item.socialNetLink !== null) {
                window.open(item.socialNetLink, '_blank');
            }
        },
        changeHeightImg(url) {
            return url.replace('cover', 'cover_100')
        },
        clearRefreshToken() {
            this.deleteDevice(parseInt(localStorage.getItem('deviceId')))
                .then(() => {
                    this.clearTokens()
                    localStorage.clear()
                    sessionStorage.clear()
                    localStorage.setItem('pushToLogin', 'true')
                    this.$router.push('/login-fourth')
                })
        },
        search(event) {
            if (event.key === "Backspace" || event.key === "Delete") {
                if (this.text.length === 0) {
                    this.clearSessionStorage()
                }
            } else {
                if (this.text.length > 2) {
                    this.fetchSearchAnyBooks(this.text)
                        .then(() => {
                            if (this.getSearchedAllBooks.length > 0) {
                                this.isSearching = true
                                this.searchedAllBooks = this.getSearchedAllBooks
                                sessionStorage.setItem('isSearched', 'true')
                                sessionStorage.setItem('searchedBookText', this.text)
                                sessionStorage.setItem('foundedBooks', JSON.stringify(this.getSearchedAllBooks))
                            }
                        })
                }
            }
        },
        async onScroll() {
            let scroll = (this.$refs.scroll.scrollHeight - Math.floor(window.scrollY))
            let height = document.querySelector('html').clientHeight
            let isOnlyOneType = true

            if (scroll === height) {
                for await (let item of this.getRecommendedBooks) {
                    if (item.type !== this.getRecommendedBooks[0].type) {
                        isOnlyOneType = false
                        break;
                    }
                }

                if (isOnlyOneType) {
                    this.recommendedBooksPage++
                }

                await this.recommendedBooksForUser(++this.recommendedBooksPage)
            }
        },
        pushRelatedBook(book) {
            if (book.type === undefined) {
                this.$router.push(book['@type'] === 'Book' ? '/book/' + book.id : '/audio-book/' + book.id)
            } else {
                this.$router.push(book.type === 'book' ? '/book/' + book.id : '/audio-book/' + book.id)
            }
        },
        recommendedBooksForUser(page) {
            return new Promise(((resolve, reject) => {
                this.fetchRecommendedBooks(page)
                    .then(() => {
                        this.recommendedBooks.push(...this.getRecommendedBooks)
                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }))
        },
        isVisitedToday() {
            let date = new Date().toLocaleDateString()

            if (!localStorage.getItem('lastVisitedDay')) {
                localStorage.setItem('lastVisitedDay', date)
                return false
            }

            if (localStorage.getItem('lastVisitedDay') === date) {
                return true
            } else {
                localStorage.setItem('lastVisitedDay', date)
                return false
            }
        },
        clearSessionStorage() {
            window.scroll(0, 0)
            if (sessionStorage.getItem('isSearched') !== undefined) {
                sessionStorage.removeItem('isSearched')
            }

            if (sessionStorage.getItem('foundedBooks') !== undefined) {
                sessionStorage.removeItem('foundedBooks')
            }

            if (sessionStorage.getItem('searchedBookText') !== undefined) {
                sessionStorage.removeItem('searchedBookText')
            }

            this.isSearching = false
            this.text = ''
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === null) {
            sessionStorage.setItem('reload', 'true')
        } else {
            sessionStorage.setItem('reload', 'false')
        }

        if (localStorage.getItem('isLogin') !== null) {
            localStorage.removeItem('isLogin')
            localStorage.setItem('isVisitLogin', 'true')
        }

        next()
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === undefined || (to.name !== 'AudioBook' && to.name !== 'Book')) {
            if (sessionStorage.getItem('isSearched') !== undefined) {
                sessionStorage.removeItem('isSearched')
            }
        }

        localStorage.removeItem('isVisitLogin')

        if (
            localStorage.getItem('pushToLogin') === 'true' ||
            (to.name !== 'LoginFourth' && to.name !== 'LoginSixth')
        ) {
            localStorage.removeItem('pushToLogin')
            next()
        } else {
            try {
                window.closeApk(true)
            } catch {
                console.log("App'dan chiqildi")
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll, true)
    },
    mounted() {
        this.$root.showOverlay = true
        this.fetchUserRole()
        window.addEventListener("scroll", this.onScroll, true)

        if (localStorage.getItem('isVisitLogin') === 'true') {
            this.fetchCurrentUserLastDevice()
                .then(() => {
                    if (this.getUserLastDevice !== null) {
                        localStorage.setItem('deviceId', this.getUserLastDevice.id)
                    }
                })
        } else {
            this.fetchDevice(parseInt(localStorage.getItem('deviceId')))
                .then(() => {
                    if (this.getDevice.ban) {
                        this.clearRefreshToken()
                    }
                })
                .catch((reason) => {
                    if (reason === 'Not Found') {
                        this.clearTokens()
                        localStorage.clear()
                        sessionStorage.clear()
                        localStorage.setItem('pushToLogin', 'true')
                        this.$router.push('/login-fourth')
                    }
                })
        }

        let isVisitedToday = this.isVisitedToday()

        if (!this.getBanners.length > 0) {
            this.fetchBanners()
        }

        if (!this.getMoments.length > 0) {
            this.fetchMoments(1)
        }

        if (!localStorage.getItem('recommendedBooks') || !isVisitedToday) {
            this.recommendedBooksForUser(this.recommendedBooksPage).then(() => {
                localStorage.setItem('recommendedBooks', JSON.stringify(this.getRecommendedBooks))
                this.recommendedBooks = JSON.parse(localStorage.getItem('recommendedBooks'))
            })
        } else {
            this.recommendedBooks = JSON.parse(localStorage.getItem('recommendedBooks'))
        }

        if (sessionStorage.getItem('isSearched') !== null && sessionStorage.getItem('reload') === 'false') {
            this.isSearching = true
            this.text = sessionStorage.getItem('searchedBookText')
            this.searchedAllBooks = JSON.parse(sessionStorage.getItem('foundedBooks'))
        }

        this.$root.showOverlay = false
    },
}
</script>

<style scoped>
.all {
    font-family: 'Montserrat', sans-serif !important;
    min-height: 100vh;
    background-color: var(--color-container);
}

.bg-first-content {
    background-color: var(--color-block);
    border-radius: 0 0 15px 15px;
}

.star-row {
    width: 105px !important;
    padding-left: 4px;
    margin-left: 9px;
}

.star {
    padding-right: 6px;
}

.download-count {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #828282;
    font-size: 12px;
    line-height: 15px;
    font-style: normal;
    margin-top: 6px;
    margin-left: 2px;
}

.book-author {
    font-size: 12px;
    color: #9597A1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.book-price-row {
    display: inline;
    padding-right: 15px;

}

.book-price {
    color: #0481F4;
    margin: 36px 0 0 0;
    position: absolute;
}

.col-2 {
    width: 71px !important;
}

.book-name {
    font-weight: 500;
    font-size: 14px;
    margin-top: 13px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-Black-To-White);
}

.link {
    text-decoration: none !important;
    color: var(--color-Black-To-White);
}

.book-img {
    margin-top: 9px;
    width: 43px;
    height: 64px;
    border-radius: 5px;
}

.book-row {
    border-radius: 12px;
    background: var(--color-header);
    height: 86px;
    border: 1px solid var(--color-header);
    margin-bottom: 8px;
}

.bg, .body {
    background-color: var(--color-container);
}

.body {
    padding-bottom: 85px !important;
}

.form-input {
    margin-top: 41px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
}

input {
    border: none !important;
    padding: 5px 10px 5px 45px;
    font-size: 12px;
    color: var(--color-textGray-To-lightGray) !important;
    font-family: Montserrat, 'sans-serif';
    font-weight: 400;
    line-height: 30px;
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background-color: var(--home-input-bg) !important;
}

.icon {
    position: absolute;
    left: 20px;
    top: 0;
    font-size: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stories-container {
    display: flex;
    flex: 74px;
    gap: 1rem;
    background: var(--color-header);
    margin-bottom: -10px !important;
    border-radius: 3px;
    overflow-x: scroll;
    user-select: none;
    width: 100%;
    padding-left: 10px;
}

.stories-container::-webkit-scrollbar {
    width: 0;
}

.stories-container-2 {
    display: flex;
    flex: 74px;
    gap: 14px;
    background: var(--color-container);
    border-radius: 3px;
    overflow-x: scroll;
    user-select: none;
    width: 100%;
    margin: 5px 0 -20px 0 !important;
    padding-left: 18px;
}

.stories-container-2::-webkit-scrollbar {
    width: 0;
}

.profiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    transition: transform .2s ease-in-out;
    cursor: pointer;
}

.profiles-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    transition: transform .2s ease-in-out;
    border-radius: 12px;
    background-color: var(--color-header);
    padding: 9px 14px 9px 13px;
}

.broadcast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 12px;
    background-color: var(--color-header);
    color: var(--color-textDarkGray-To-white);
    padding: 9px 14px 9px 13px;
    font-size: 12px;
    font-weight: 400;
    min-width: 101px;
}

.broadcast__hr {
    width: 90%;
    margin: 0;
}

.story-name {
    max-width: 55px;
    font-size: 8px;
    font-weight: 500;
    font-style: normal;
    line-height: 10px;
    text-align: center;
    color: var(--color-text);
}

.category-name {
    max-width: 74px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color: var(--color-textDarkGray-To-white) !important;
}

.photo:hover, .photo-2:hover {
    transform: scale(1.03);
}

.photo {
    display: flex;
    align-items: center;
    border: 2px solid #0481F4;
    padding: 3px;
    border-radius: 50%;
    width: 56px !important;
    height: 56px !important;
}

.photo > img {
    width: 54px;
    object-fit: cover;
    border: 1px solid white;
    border-radius: 50%;
}

.photo-2 > img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
}

.position-text, .category-text {
    font-weight: 500;
    color: var(--color-text);
    font-size: 14px;
    line-height: 17px;
}

.position-text {
    margin: 18px 0 12px 0;
}

.category-text {
    margin: 20px 0 16px 0;
}

.head-phone {
    margin: -40px 0 0 2px;
}

.slide-content {
    width: 100% !important;
    height: 29.6vw;
    border-radius: 18px !important;
    object-fit: cover;
}

.hooper {
    height: 100%;
}

.hooper-pagination {
    bottom: 5%;
}

.hooper-slide.is-active.is-current {
    transform: scaleY(1.19);
    transition: transform ease 1s;
}

</style>

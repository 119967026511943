<template>
    <div class="main" :class="{'main-book_active': !isActiveText}">
        <div class="main__book main-book">
            <div class="main-book__name">{{ bookName }}</div>
            <div class="main-book__author">{{ bookAuthor }}</div>
            <div class="main-book__img">
                <img :src="bookImg" alt="rasm">
            </div>
        </div>
        <div class="main__book-text" :class="{'main__book-text_active': isActiveText}">
            {{text}}
        </div>
    </div>
</template>

<script>
export default {
    name: "listen-main",
    props: {
        isActiveText: Boolean,
        bookName: String,
        bookAuthor: String,
        bookImg: String,
        text: String
    }
}
</script>

<style scoped>
.main {
    flex: 1 1 auto;
    position: relative;
    min-height: 300px;
}

.main__book {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 31px;
    display: none;
    flex-direction: column;
}

.main.main-book_active .main__book{
    display: flex;
}

.main-book__name {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: var(--color-text);
    text-align: center;
    margin-bottom: 6px;
}

.main-book__author {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-textDarkGray-To-Blue);
    text-align: center;
    margin-bottom: 29px;
}

.main-book__img {
    margin: 0 auto;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.main-book__img img {
    width: 150px;
    height: 200px;
    object-position: top center;
    object-fit: cover;
    z-index: 2;
    border-radius: 12px;
    position: relative;
}

@media (min-height: 744px) {
    .main-book__img img {
        width: 250px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.main-book__img img::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    left: 12px;
    z-index: 1;
    border-radius: 12px;
}

.main__book-text {
    position: absolute;
    height: 100%;
    width: 100%;
    margin-top: 21px;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-textGray-To-white);
    overflow: scroll;
    display: none;
}

.main__book-text.main__book-text_active {
    display: block;
}

</style>
<template>
    <!--row container start -->
    <div class="row all">
        <div class="col container-col">

            <!-- header start -->
            <div class="row">
                <div class="col fixed-top">
                    <div class="row header-name-row">
                        <div class="col-2 ps-2">
                            <GoBackButton class="arrow-back"/>
                        </div>

                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            Бизнес
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>
            <!-- header end -->

            <div class="row">
                <div class="col colored-container">
                    <div class="row input" style="background-color: white">
                        <div class="col-2 img-col">
                            <router-link to="/live-room">
                                <img src="../../components/images/hashtag/ilon-mask.png" alt="" class="profile-photo">
                            </router-link>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col profile-name">
                                    Полная Ж
                                </div>
                            </div>
                            <div class="row">
                                <div class="col profile-surname">
                                    Радислав Гандапас
                                </div>
                            </div>
                            <div class="row">
                                <div class="col time">
                                    16:58
                                </div>
                            </div>
                        </div>
                        <div class="col-1 d-flex justify-content-end p-0 live">
                            Live
                        </div>
                    </div>

                    <div class="row input-3">
                        <div class="col-2 img-col">
                            <img src="../../components/images/hashtag/profile-2.png" alt="" class="profile-photo-3">
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col profile-name">
                                    Трёх Н
                                </div>
                            </div>
                            <div class="row">
                                <div class="col profile-surname">
                                    Евгения Попова
                                </div>
                            </div>
                            <div class="row">
                                <div class="col time-3">
                                    архив: 09/10/2021 15:30
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row input-3">
                        <div class="col-2 img-col">
                            <img src="../../components/images/hashtag/profile-2.png" alt="" class="profile-photo-3">
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col profile-name">
                                    45 татуировок менеждера
                                </div>
                            </div>
                            <div class="row">
                                <div class="col profile-surname">
                                    Радислав Гандапас
                                </div>
                            </div>
                            <div class="row">
                                <div class="col time-3">
                                    архив: 21/8/2021 17:13
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    </div>
</template>

<script>
import Footer from "../../components/footer/footer.vue";
import GoBackButton from "@/components/modals/GoBackButton.vue";
export default {
    name: "broadcast",
    components: {GoBackButton, Footer}
}
</script>

<style scoped>
.container-col {
    font-family: 'Montserrat', sans-serif !important;
    margin-top: 147px;
}

.all {
    margin-bottom: 100px
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.colored-container {
    background-color: var(--color-container);
    margin-bottom: 20px;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.arrow-back {
    margin-top: 65px;
}

.profile-photo {
    border-radius: 50%;
    padding: 2px;
    margin: 14px 0 0 15px;
    background-color: #0481F4;;
}

.profile-photo-3 {
    border-radius: 50%;
    margin: 14px 0 0 15px;
}

.input {
    height: 86px;
    border: 1px solid #97CAFB;
    border-radius: 12px;
    background: var(--liner-gray);
    margin-left: 3.8%;
    width: 92%;
    margin-top: 20px;
}

.input-3 {
    height: 86px;
    border: none;
    border-radius: 12px;
    margin-left: 3.8%;
    width: 92%;
    margin-top: 20px;
    background-color: var(--color-header);
}

.img-col {
    width: 90px;
}

.profile-name {
    margin-top: 15px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: var(--color-text);
}

.profile-surname {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.6px;
    color: var(--color-lightGray-to-lightBlue);
    margin-top: 4px;
}

.time {
    font-size: 10px;
    color: #0481F4;
    margin-top: 4px;
}

.time-3 {
    font-size: 10px;
    color: #65B1F9;
    margin-top: 4px;
}

.live {
    margin: 33px 20px 0 0;
    color: white;
    font-size: 16px;
    line-height: 19.5px;
}

</style>

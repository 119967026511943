<template>
    <div class="header">
        <button class="header__back-btn" @click="$router.go(-1)">
            <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.8175 18.6375C7.6275 18.6375 7.4375 18.5675 7.2875 18.4175L1.2175 12.3475C0.9275 12.0575 0.9275 11.5775 1.2175 11.2875L7.2875 5.2175C7.5775 4.9275 8.0575 4.9275 8.3475 5.2175C8.6375 5.5075 8.6375 5.9875 8.3475 6.2775L2.8075 11.8175L8.3475 17.3575C8.6375 17.6475 8.6375 18.1275 8.3475 18.4175C8.2075 18.5675 8.0075 18.6375 7.8175 18.6375Z"
                    fill="var(--color-text)"/>
                <path
                    d="M27.748 12.5675H2.91797C2.50797 12.5675 2.16797 12.2275 2.16797 11.8175C2.16797 11.4075 2.50797 11.0675 2.91797 11.0675H27.748C28.158 11.0675 28.498 11.4075 28.498 11.8175C28.498 12.2275 28.158 12.5675 27.748 12.5675Z"
                    fill="var(--color-text)"/>
            </svg>
        </button>
        <div class="header__about-book about-book" :class="{'about-book_active': isActiveText}">
            <div class="about-book__img">
                <img :src="bookImg" alt="rasm">
            </div>
            <div class="about-book__info">
                <div class="about-book__name">{{ bookName }}</div>
                <div class="about-book__author">{{ bookAuthor }}</div>
            </div>
        </div>
        <div class="header__buttons">
            <div class="button__read-text" @click="$emit('onChangeTextRead')">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!isActiveText">
                    <path
                        d="M18.8249 24.2375C18.0499 24.7625 17.0749 25.2 15.8874 25.5875L13.9124 26.2375C8.94994 27.8375 6.33744 26.5 4.72494 21.5375L3.12494 16.6C1.52494 11.6375 2.84994 9.01247 7.81244 7.41247L9.78744 6.76247C10.2999 6.59997 10.7874 6.46247 11.2499 6.37497C10.8749 7.13747 10.5749 8.06247 10.3249 9.12497L9.09994 14.3625C7.87494 19.5875 9.48744 22.1625 14.6999 23.4L16.7999 23.9C17.5249 24.075 18.1999 24.1875 18.8249 24.2375Z"
                        stroke="var(--color-text)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.8008 10.6625L21.8633 12.2" stroke="var(--color-text)" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.5742 15.5L18.1992 16.425" stroke="var(--color-text)" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M26.2002 6.37501C27.5502 7.87501 27.7877 10.025 27.0752 13.05L25.8502 18.275C24.8002 22.7875 22.7252 24.6125 18.8252 24.2375C18.2002 24.1875 17.5252 24.075 16.8002 23.9L14.7002 23.4C9.48772 22.1625 7.87522 19.5875 9.10022 14.3625L10.3252 9.12501C10.5752 8.06251 10.8752 7.13751 11.2502 6.37501C12.7127 3.35001 15.2002 2.53751 19.3752 3.52501L21.4627 4.01251"
                        stroke="var(--color-text)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                     v-else>
                    <path
                        d="M15.8471 24.9463C16.1905 25.0262 16.2219 25.476 15.8874 25.5875L13.9124 26.2375C8.94994 27.8375 6.33744 26.5 4.72494 21.5375L3.12494 16.6C1.52494 11.6375 2.84994 9.01246 7.81244 7.41246L8.72144 7.11143C9.1243 6.97802 9.51286 7.38074 9.39217 7.78759C9.29426 8.11762 9.20193 8.46758 9.11244 8.83746L7.88744 14.075C6.51244 19.9625 8.52494 23.2125 14.4124 24.6125L15.8471 24.9463Z"
                        fill="#0481F4"/>
                    <path
                        d="M21.4627 4.01251L19.3752 3.52501C15.2002 2.53751 12.7127 3.35001 11.2502 6.37501C10.8752 7.13751 10.5752 8.06251 10.3252 9.12501L9.10022 14.3625C7.87522 19.5875 9.48772 22.1625 14.7002 23.4L16.8002 23.9C17.5252 24.075 18.2002 24.1875 18.8252 24.2375C22.7252 24.6125 24.8002 22.7875 25.8502 18.275L27.0752 13.05C28.3002 7.82501 26.7002 5.23751 21.4627 4.01251ZM19.1127 16.6625C19.0002 17.0875 18.6252 17.3625 18.2002 17.3625C18.1252 17.3625 18.0502 17.35 17.9627 17.3375L14.3252 16.4125C13.8252 16.2875 13.5252 15.775 13.6502 15.275C13.7752 14.775 14.2877 14.475 14.7877 14.6L18.4252 15.525C18.9377 15.65 19.2377 16.1625 19.1127 16.6625ZM22.7752 12.4375C22.6627 12.8625 22.2877 13.1375 21.8627 13.1375C21.7877 13.1375 21.7127 13.125 21.6252 13.1125L15.5627 11.575C15.0627 11.45 14.7627 10.9375 14.8877 10.4375C15.0127 9.93751 15.5252 9.63751 16.0252 9.76251L22.0877 11.3C22.6002 11.4125 22.9002 11.925 22.7752 12.4375Z"
                        fill="#0481F4"/>
                </svg>
            </div>
            <div class="button__save-bookmark" @click="fixTime" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.125 13.3125H11.875" stroke="var(--color-text)" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 10.2625V16.5125" stroke="var(--color-text)" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M4.15039 12.55V24.9375C4.15039 27.1875 5.76289 28.1375 7.73789 27.05L13.8379 23.6625C14.4879 23.3 15.5379 23.3 16.1754 23.6625L22.2754 27.05C24.2504 28.15 25.8629 27.2 25.8629 24.9375V7.325C25.8629 4.675 23.6879 2.5 21.0379 2.5H8.97539C6.31289 2.5 4.15039 4.675 4.15039 7.325"
                        stroke="var(--color-text)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('addBookmark') }}</h1>
                        <button type="button" @click="cleanData" ref="close-btn" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="mb-3">
                                <label for="recipient-name" class="col-form-label">{{ $t('bookmarkName') }}:</label>
                                <input type="text" class="form-control" id="recipient-name" v-model="formCurrentSecond.name">
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-bs-dismiss="modal" class="btn btn-primary" @click="sendCurrentSecond">{{ $t('save') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <notify-success :is-show-success="isShowSuccess" :success-message-text="notifyMessage"/>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import NotifySuccess from "../../pages/library/notify-success";

export default {
    name: "listen-header",
    components: {NotifySuccess},
    props: {
        isActiveText: Boolean,
        bookName: String,
        bookAuthor: String,
        bookImg: String,
        currentTime: Number,
        audioBookChapter: String
    },
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
            formCurrentSecond: {
                name: '',
                time: '',
                audioBookChapter: '',
                audioBook: ''
            },
            isShowSuccess: true,
            notifyMessage: '',
        }
    },
    methods: {
        ...mapActions(['sendSavedCurrentTime', 'fetchUserBookmarks']),
        sendCurrentSecond() {
            this.formCurrentSecond.audioBook = '/api/audio_books/' + this.$route.params.id
            this.formCurrentSecond.audioBookChapter = this.audioBookChapter

            this.sendSavedCurrentTime(this.formCurrentSecond)
                .then(() => {
                    this.notifyMessage = this.$t('bookmarkSaved')
                    this.isShowSuccess = !this.isShowSuccess
                    this.fetchUserBookmarks(this.$route.params.id)
                    this.formCurrentSecond = {
                        name: '',
                            time: '',
                            audioBookChapter: '',
                            audioBook: ''
                    }
                })
        },
        fixTime() {
            this.formCurrentSecond.time = Math.floor(this.currentTime)
        },
        cleanData() {
            this.formCurrentSecond = {
                name: '',
                time: '',
                audioBookChapter: '',
                audioBook: ''
            }
        },
        closeModal() {
            this.$refs["close-btn"].click()
        }
    },
    mounted() {
        this.$parent.$on('parent-route-leave', this.closeModal);
    },
    beforeDestroy() {
        this.$parent.$off('parent-route-leave', this.closeModal);
    },
}
</script>

<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 28px;
}

.header__back-btn {
    background: transparent;
    border: none;
}

.header__about-book {
    align-items: center;
    gap: 17px;
    display: none;
}

.header__about-book.about-book_active {
    display: flex;
}

.about-book__img {
    flex: 0 0 auto;
    width: 23px;
    height: 33px;
}

.about-book__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
}

.about-book__info {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.about-book__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);
}

.about-book__author {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-textDarkGray-To-Blue);
}

.header__buttons {
    display: flex;
    gap: 8px;
}

</style>
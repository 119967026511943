import pushModel from "../../mixin/store/api/pushModel";
import fetchModels from "../../mixin/store/api/fetchModels";

export default {
    actions: {
        pushSavedAudioBook(context, data) {
            return pushModel(context, 'saved_audio_books', data, 'updateSavedAudioBook')
        },
        fetchIsUserSavedAudioBook(context, audioBookId) {
            return fetchModels(context, 'saved_audio_books?audioBook.id=' + audioBookId, null, 'updateIsUserSavedAudioBook')
        }
    },

    mutations: {
        updateSavedAudioBook(state, data) {
            state.savedAudioBook = data
        },
        updateIsUserSavedAudioBook(state, data) {
            if (data.totalItems) {
                state.isUserSavedAudioBook = true
            }
        }
    },

    state: {
        savedAudioBook: {
            audioBook: null
        },
        isUserSavedAudioBook: false
    },
    getters: {
        getIsSavedAudioBook(state) {
            return state.isUserSavedAudioBook
        }
    }
}

import fetchModels from "../../mixin/store/api/fetchModels";
import pushModel from "../../mixin/store/api/pushModel";

export default {
    actions: {
        fetchSavedBooks(context) {
            return fetchModels(context, 'saved_books/getUserSavedBooks', null, 'updateSavedBooks')
        },
        fetchIsUserSavedBook(context, bookId) {
           return fetchModels(context, 'saved_books?book=' + bookId, null, 'updateUserSavedBook', true)
        },
        pushSavedBook(context, data) {
            return pushModel(context, 'saved_books', data)
        },
    },

    mutations: {
        updateSavedBooks(state, data) {
            state.savedBooks = data
        },
        updateUserSavedBook(state, data) {
            if(data) {
                state.isSavedBook = true
            }
        }
    },

    state: {
        savedBooks: {},
        isSavedBook: false
    },

    getters: {
        getSavedBooks(state) {
            return state.savedBooks
        },
        getIsUserSavedBook(state) {
            return state.isSavedBook
        }
    }
}
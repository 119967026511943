<template>
    <div class="row">
        <div class="col container-col">

            <!-- header start -->
            <div class="row header-row">
                <div class="col fixed-top">
                    <div class="row header-name-row">
                        <div class="col-2 ps-2">
                            <go-back-button class="arrow-back"/>
                        </div>
                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{ $t('device.myDevice') }}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>
            <!-- header end -->

            <div v-for="device in devices" :key="device.id" class="row d-flex justify-content-center">
                <div class="col-11 center-block mb-3">
                    <div class="row">
                        <div class="col-9">
                            <div class="row">
                                <div class="col p-0 phone-model">
                                    {{ device.name }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col p-0 phone-address">
                                    {{ device.ipAddress }} - {{ device.region }} ,
                                    {{ device.city }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col p-0 edit-device"
                                    v-if="device.id === getCurrentDeviceId"
                                >
                                    {{ $t('device.thisDevice') }}
                                </div>
                                <div v-else class="col p-0 device-visit-time">
                                    {{ device.createdAt.slice(0, 10) }}
                                    {{ device.createdAt.slice(11, 19) }}
                                </div>
                            </div>
                        </div>
                        <div v-if="device.id !== getCurrentDeviceId" class="col-3 d-flex justify-content-center align-items-center">
                            <button class="btn_svg" @click="deleteItem(device.id)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z"
                                        fill="#0481F4"/>
                                    <path
                                        d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z"
                                        fill="#0481F4"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center">
                <div class="col-12 d-flex justify-content-center fixed-bottom">
                    <Button type="submit" text="save" class="save-btn" :onClick="saveChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../components/button/Button.vue";
import {mapActions, mapGetters} from "vuex";
import GoBackButton from "@/components/modals/GoBackButton.vue";

export default {
    name: "setting-devices",
    components: {GoBackButton, Button},
    data() {
        return {
            devices: [],
            deviceId: null
        }
    },
    computed: {
        ...mapGetters(['getDevices']),
        getCurrentDeviceId() {
            return parseInt(localStorage.getItem('deviceId'))
        }
    },
    methods: {
        ...mapActions(['fetchCurrentUserDevices', 'changeDeviceBan']),
        saveChange() {
            this.changeDeviceBan({
                id: this.deviceId,
                ban: true
            })
            this.$router.push('/setting')
        },
        deleteItem(id) {
            this.deviceId = id
            this.devices.splice(this.devices.findIndex(foo => {return foo.id === id}), 1)
        }
    },
    mounted() {
        this.fetchCurrentUserDevices()
            .then(() => {
                this.devices = this.getDevices
            })
    }
}
</script>

<style scoped>
.container-col {
    font-family: 'Montserrat', sans-serif !important;
    background-color: var(--color-container) !important;
    border-radius: 0 0 15px 15px;
    height: 100vh;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 500;
    color: var(--color-text);
}

.center-block {
    border-radius: 15px;
    background-color: var(--color-block);
}

.phone-model {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 15px 0 0 24px;
    padding: 0 80px 0 0;
    color: var(--color-Black-To-White);
}

.phone-address {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    margin: 8px 0 0 24px;
}

.edit-device {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0481F4;
    margin: 8px 0 15px 24px;
}

.device-visit-time {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #BDBDBD;
    margin: 8px 0 15px 24px;
}

.header-row {
    margin-bottom: 165px;
}

.save-btn {
    width: 104%;
    margin-bottom: 18px;
}

.btn_svg {
    height: 50px;
    background: none;
    border: none;
}
</style>

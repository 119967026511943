<template>
    <body id=app :class="mode">
    <div class="container-fluid">
        <router-view :mode="mode" @detectSystemTheme="detectSystemTheme" @lightTheme="lightTheme" @darkTheme="darkTheme"
                     @cancel="cancel"/>
    </div>

    <logo-spinner style="z-index: 9999" v-if="$root.showOverlay"/>
    </body>
</template>

<script>
import logoSpinner from "@/assets/spinner/logoSpinner";
import {mapActions} from "vuex";

export default {
    name: 'App',
    components: {logoSpinner},
    data() {
        return {
            mode: localStorage.getItem('theme') || 'light',
            light: 'light',
            dark: 'dark',
        }
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchRefreshToken', 'clearTokens']),
        lightTheme() {
            this.mode = this.light
        },
        darkTheme() {
            this.mode = this.dark
        },
        detectSystemTheme() {
            this.mode = window.matchMedia("(prefers-color-scheme: dark)").matches ? this.dark : this.light
        },
        cancel() {
            this.mode = localStorage.getItem('theme')
            this.$router.back()
        },
    },
    async created() {
        if (localStorage.getItem('isLogin') === null) {
            await this.fetchUser()
                .catch(() => {
                    this.fetchRefreshToken()
                        .then(() => {
                            location.reload()
                            this.fetchUser()
                        })
                        .catch(() => {
                            this.clearTokens()
                        })
                })
        }
    },
    mounted() {
        if (localStorage.getItem('theme') === null) {
            localStorage.setItem('system', 'true');
        }

        if (localStorage.getItem('system') === 'true') {
            const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
            if (darkThemeMq.matches) {
                localStorage.setItem('theme', 'dark');
                this.mode = localStorage.getItem('theme');
            } else {
                localStorage.setItem('theme', 'light');
                this.mode = localStorage.getItem('theme');
            }
        }

        this.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ru'
    }
}
</script>

<style>

.light {
    background-color: #F8FAFF; /* done  theme back color */
    --color-container: #F4F6FB; /* done theme back container color */
    --color-header: white; /* done block back color */
    --color-block: white; /* done block back color */
    --color-black-to-lightGray: #000000; /* color black to light gray */
    --color-light-dark-gray: #F4F6FB;
    --color-white-blue: #BDBDBD;
    --color-65B-to-5e7: #65B1F9;
    --white-to-dark: white; /* white color to dark mode color */
    --bg-F2F8FF-to-252335: #F2F8FF;
    --bg-button-row: #F4F6FB; /* done btn row bg color */
    --bg-buttonWhite-dark: white; /* bg button white to dark mode */
    --bg-button-row-lighter: #F4F6FB; /* done btn row bg color lighter in dark mode*/
    --bg-button-row-lighter2: #f1f8fe; /* done btn row bg color lighter in dark mode 2*/
    --bg-book-liner: rgba(23, 77, 132, 0.7); /* done  back-book color */
    --bg-input-checkbox: #e2f1ff;
    --bg-feedback-avatar: #97CAFB;
    --input-checkbox-uncheck: #97cafb;
    --border-bd-to-4f: #BDBDBD;
    --btn-focus-bg: white; /*done btn after click color */
    --color-swiper: rgba(244, 246, 251, 1); /*done color swiper */
    --color-text: #333333; /* done text color */
    --color-logo: #333;
    --color-book-text: #A5A6A7;
    --color-blue-to-gray: #2B2C43;
    --color-Blacktext-To-Blue: #000000; /* done text black color to blue */
    --color-Black-To-White: #000000; /* color black to white */
    --color-text-to-blue: #828282; /* done text color gray to blue */
    --color-textGray-to-gold: #828282; /* done text color gray to gold */
    --color-textDarkGray-To-Blue: #4F4F4F; /*done text dark gray to blue */
    --color-textDarkGray-to-lightGray: #4F4F4F; /*done text dark gray to light gray */
    --color-lightGray-to-lightBlue: #9597A1; /*done light gray to light Blue */
    --color-textGray-To-lightGray: #828282; /* color gray to light blue */
    --color-textGray-To-white: #828282;
    --color-textDarkGray-To-white: #4F4F4F;
    --home-input-bg: #F8FAFF;
    --human-img-bg: #e5f0fa;
    /*for svg*/
    --blueToFade: #0481F4; /*color forward blue to fade blue */
    --blueToWhite: #0481F4; /*color blue to white */
    --blueToFaded: #0481F4; /* color blue to so faded blue*/
    --fadeGrayToWhite: #BDBDBD; /* color faded gray to white color */
    --fadedBlueToFadeBlue: #C7E2FD; /*color so faded to fade blue */
    --fadedBlueToDark: #D4E8FE; /*color so faded blue to darkGray */
    --svg-week-fadeBlue: #CBE5FD; /* color week days svg color faded blue to dark mode */
    --bg-progress: #F4F6FB; /* background color of progress */
    --bg-chart: #EDF3F9; /* background color of apex chart */
    --bg-progress-next-days: #F4F6FB;
    --profile-finished-books-bg: #CBE5FD;
    /*for range*/
    --range-fadeBlueToGray: #C3E2FF;
    --day-calendar: #c7c7c7; /* color of calendar unselectable day */
    --color-input: #F4F6FB; /* color input lightBlue to dark */
    --liner: linear-gradient(180deg, rgba(224, 239, 255, 0) -210.86%, #FFFFFF 93.75%); /*color gradient */
    --liner-gray: linear-gradient(270deg, #0085FF 0%, rgba(255, 255, 255, 0) 64.06%);
    --show-more: linear-gradient(90deg, rgba(0, 0, 0, 0) 7%, rgba(255, 255, 255, 0.8758782201405152) 22%);
    --linear-login-page: linear-gradient(180deg, rgba(255,255,255,0.6005252442773985) 0%, rgba(255,255,255,0.7629902302717962) 20%, rgba(255,255,255,0.8498249641653537) 24%, rgba(255,255,255,0.8974440117844013) 28%, rgba(255,255,255,0.9394608185070903) 30%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 100%);

    /*  TargetModalImage.vue svg colors */

    --moon: #DBDBDB; /*  dark #3C70B0 */
    --cloud: #EBEBEB; /*  dark #1D3D64 */
    --mountain: #DBDBDB; /*  dark #6F8CAE */
    --circle: #DBDBDB; /*  dark #0481F4 */

    /*  TargetModalImage.vue svg colors */
}

.dark {
    background-color: #1F1D2B; /* done  theme back color */
    --color-container: #1F1D2B; /* done theme back container color */
    --color-header: #252836; /* done block back color */
    --color-block: #252836; /* done block back color */
    --color-black-to-lightGray: #BDBDBD; /* color black to light gray */
    --color-light-dark-gray: #2D3141;
    --color-white-blue: #0481F4;
    --color-65B-to-5e7: #5e7998;
    --white-to-dark: #1F1D2B; /* white color to dark mode color */
    --bg-F2F8FF-to-252335: #252335;
    --bg-button-row: #252836; /* done btn row bg color */
    --bg-buttonWhite-dark: #2F3244; /* bg button white to dark mode */
    --bg-button-row-lighter: #2D3141; /* done btn row bg color lighter in dark mode*/
    --bg-button-row-lighter2: #2F3344; /* done btn row bg color lighter in dark mode 2*/
    --bg-book-liner: rgba(37, 40, 54, 0.7); /* done  back-book color */
    --bg-input-checkbox: #1f1d2b;
    --input-checkbox-uncheck: #18375f;
    --border-bd-to-4f: #4F4F4F;
    --bg-feedback-avatar: rgba(151, 202, 251, 0.37);
    --btn-focus-bg: #1F1D2B; /*done btn after click color */
    --color-swiper: rgba(244, 246, 251, 0.1); /*done color swiper */
    --color-text: white; /* done text color */
    --color-logo: #65B1F9;
    --color-book-text: white;
    --color-blue-to-gray: #F2F2F2;
    --color-Blacktext-To-Blue: #0481F4; /* done text black color to blue */
    --color-Black-To-White: white; /* color black to white */
    --color-text-to-blue: #65B1F9; /* done text color gray to blue */
    --color-textGray-to-gold: #FFCF25; /* done text color gray to gold */
    --color-textDarkGray-To-Blue: #65B1F9; /*done text dark gray to blue */
    --color-textDarkGray-to-lightGray: rgba(255, 255, 255, 0.8); /*done text dark gray to light gray */
    --color-lightGray-to-lightBlue: #65B1F9; /*done light gray to light Blue */
    --color-textGray-To-lightGray: #BDBDBD; /* color gray to light blue */
    --color-textGray-To-white: white;
    --color-textDarkGray-To-white: white;
    --home-input-bg: #1F1D2B;
    --human-img-bg: #39475d;
    /*for svg*/
    --blueToFade: #65B1F9; /*color forward blue to faded blue */
    --blueToWhite: white; /*color blue to white */
    --blueToFadeGray: #BDBDBD; /*color blue to white */
    --blueToFaded: #97CAFB; /* color blue to so faded blue*/
    --fadeGrayToWhite: white; /* color faded gray to white color */
    --fadedBlueToFadeBlue: #65B1F9; /*color so faded blue to fade blue */
    --fadedBlueToDark: #31374B; /*color so faded blue to darkGray */
    --svg-week-fadeBlue: #303445; /* color week days svg color faded blue to dark mode */
    --bg-progress: #363A4E; /* background color of progress */
    --bg-chart: #363A4E; /* background color of apex chart */
    --bg-progress-next-days: #474C65;
    --profile-finished-books-bg: #323746;
    /*for range*/
    --range-fadeBlueToGray: #767676;
    --day-calendar: #767676; /* color of calendar unselectable day */
    --color-input: #1F1D2B;
    --liner: linear-gradient(180deg, rgba(224, 239, 255, 0) -210.86%, #1F1D2B 93.75%); /*color gradient */
    --liner-gray: linear-gradient(270deg, #0085FF 0%, #252836 52.66%);
    --show-more: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(37, 40, 54, 0.9883177570093458) 59%);
    --linear-login-page: linear-gradient(180deg, rgba(37,40,54,0.6033263647255778) 0%, rgba(37,40,54,0.760189109823617) 20%, rgba(37,40,54,0.84) 24%, rgba(37,40,54,0.89) 28%, rgba(37,40,54,0.93) 30%, rgba(37,40,54,1) 45%, rgba(37,40,54,1) 100%);
    /*  TargetModalImage.vue svg colors */
    --moon: #3C70B0;
    --cloud: #1D3D64;
    --mountain: #6F8CAE;
    --circle: #0481F4;
    /*  TargetModalImage.vue svg colors */
}

.hooper-indicator.is-active {
    background-color: #65B1F9 !important;
    width: 11px !important;
    height: 4px !important;
    border-radius: 4px !important;
}

.hooper-indicator {
    width: 4px !important;
    height: 4px !important;
    background-color: #97CAFB !important;
    border-radius: 50% !important;
}

.container-fluid {
    background-color: var(--color-container);
    font-family: Montserrat, 'sans-serif';
}

</style>

<template>
    <div class="row" ref="scroll">
        <div class="col container-col">

            <div class="row">
                <div class="col fixed-top">
                    <div class="row header-name-row">
                        <div class="col-2 header-block ps-2">
                            <go-back-button class="arrow-back"/>
                        </div>
                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{ $t('allCategories') }}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center">
                <div class="col-4 col-sm mt-3 d-flex justify-content-center" v-for="category in categories"
                     :key="category.id" @click="$router.push('/category-books/' + category.id)">
                    <div class="category">
                        <div class="photo d-flex justify-content-center">
                            <img v-if="category.image" :src="localhost + category.image.contentUrl" alt="">
                            <img v-else src="../../assets/profile-2.png" alt="rasm"/>
                        </div>
                        <div class="category-name">{{ category.name }}</div>
                    </div>
                </div>
            </div>
            <FooterMenu/>

        </div>
    </div>
</template>

<script>
import GoBackButton from '@/components/modals/GoBackButton.vue'
import FooterMenu from '@/components/footer/footer.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'categories',
    data () {
        return {
            localhost: process.env.VUE_APP_API_URL,
            page: 1,
            categories: []
        }
    },
    computed: {
        ...mapGetters(['getCategories'])
    },
    methods: {
        ...mapActions(['fetchCategories']),
        onScroll () {
            if ((this.$refs.scroll.scrollHeight - window.scrollY) === document.querySelector('html').clientHeight) {
                if (this.getCategories.length) {
                    this.page++
                    const names = this.categories.map(obj => obj.name)
                    this.fetchCategories(this.page)
                        .then(() => {
                            for (let category of this.getCategories) {
                                if (!names.includes(category.name)) {
                                    this.categories.push(category)
                                }
                            }
                        })
                }
            }
        },
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll, true)
    },
    mounted () {
        window.addEventListener('scroll', this.onScroll, true)
        if(!this.getCategories.length) {
            this.fetchCategories()
                .then(() => {
                    if (this.getCategories.length) {
                        this.categories.push(...this.getCategories)
                    }
                })
        } else {
            this.categories = this.getCategories
        }
    },
    components: { FooterMenu, GoBackButton }
}
</script>

<style scoped>
.container-col {
    font-family: 'Montserrat', sans-serif !important;
    border-radius: 0 0 15px 15px;
    margin-top: 157px;
    background-color: var(--color-container);
}

.header-name-row {
    height: 147px;
    border-radius: 0 0 15px 15px;
    background-color: var(--color-header);
}

.header-block {
    border: 15px;
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.category {
    background-color: var(--color-header);
    width: 120px;
    border-radius: 12px;
    padding: 12px 13px 9px 13px;
}

.category-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
    color: var(--color-textDarkGray-To-white) !important;
}

.photo > img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
}

.col-sm {
    max-width: 140px !important;
}

@media only screen and (max-width: 395px) {
    .category {
        width: 110px;
    }

    .photo > img {
        width: 84px;
        height: 84px;
    }
}
</style>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "setting-security",
    computed: {
        ...mapGetters(['getUser', 'getAccessToken']),
    },
    data() {
        return {
            phone: '',
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
            isLoading: false,
            isLoadSpinner: false,
            isShowAlert: false,
            isShowPassword: false,
            isShowPassword1: false,
            isShowPassword2: false,
            isCorrectOldPassword: false,
            correctPasswordIsActive: false,
            errorPassword: false,
        }
    },
    methods: {
        ...mapActions(["fetchToken", 'pushChangePassword', 'createVerification']),
        checkAuth() {
            if (this.oldPassword.length >= 6) {
                this.isLoadSpinner = true

                this.fetchToken({phone: this.getUser.phone, password: this.oldPassword})
                    .then(() => {
                        this.correctPasswordIsActive = false
                        this.isCorrectOldPassword = true
                        this.isLoadSpinner = false
                    })
                    .catch(() => {
                        this.correctPasswordIsActive = true
                        this.isCorrectOldPassword = false
                        this.isLoadSpinner = false
                    })
            }
        },
        comparePassword() {
            this.errorPassword = !((this.newPassword === '' || this.repeatPassword === '') || (this.newPassword === this.repeatPassword));
        },
        changePassword() {
            if (this.newPassword === this.repeatPassword && this.newPassword.length > 5) {
                this.isLoading = true

                this.pushChangePassword({id: this.getUser.id, password: this.newPassword})
                    .then(() => {
                        this.fetchToken({phone: this.getUser.phone, password: this.newPassword})
                            .then(() => {this.$router.push('/setting')})
                    })
                    .catch(() => {
                        this.isLoading = false
                        this.isShowAlert = true
                    })
            }
        },
        sendVerificationCode() {
            this.createVerification({phoneNumber: this.getUser.phone})
                .then(() => {this.$router.push('/reset-password-verification')})
        }
    }
}
</script>

<template>
    <div class="row content pb-3">
        <div class="col-12 align-self-start">
            <div class="row header__row py-5">
                <div class="col-3 text-center">
                    <svg @click="$router.back()" width="29" height="24" viewBox="0 0 29 24" fill="none">
                        <path class="svg"
                              d="M7.8175 18.6375C7.6275 18.6375 7.4375 18.5675 7.2875 18.4175L1.2175 12.3475C0.9275 12.0575 0.9275 11.5775 1.2175 11.2875L7.2875 5.2175C7.5775 4.9275 8.0575 4.9275 8.3475 5.2175C8.6375 5.5075 8.6375 5.9875 8.3475 6.2775L2.8075 11.8175L8.3475 17.3575C8.6375 17.6475 8.6375 18.1275 8.3475 18.4175C8.2075 18.5675 8.0075 18.6375 7.8175 18.6375Z"
                              fill="var(--color-text)"/>
                        <path class="svg"
                              d="M27.748 12.5675H2.91797C2.50797 12.5675 2.16797 12.2275 2.16797 11.8175C2.16797 11.4075 2.50797 11.0675 2.91797 11.0675H27.748C28.158 11.0675 28.498 11.4075 28.498 11.8175C28.498 12.2275 28.158 12.5675 27.748 12.5675Z"
                              fill="var(--color-text)"/>
                    </svg>
                </div>
                <div class="col-6 text-center">
                    {{ $t('security.security') }}
                </div>
                <div class="col-3"></div>
            </div>
            <div class="row mt-3">
                <div class="col px-3">
                    <form class="p-3 menu__block">
                        <div v-if="isShowAlert" class="alert alert-warning">{{$t('statisticsPage.targetSetError') }}</div>

                        <div v-if="correctPasswordIsActive" class="uncorrectedPassword">{{ $t('security.passwordWrong') }}</div>

                        <div class="input__block">
                            <input
                                @keyup="checkAuth"
                                v-model="oldPassword"
                                :type="isShowPassword ? 'text' : 'password'"
                                :placeholder="$t('security.oldPassword')"
                                class="form-control form__input form__input-one"
                                :class="{'input__error' : correctPasswordIsActive}"
                                :disabled="isCorrectOldPassword"
                            />
                            <span v-if="isLoadSpinner" class="input__spinner spinner-grow spinner-grow-sm"></span>
                            <svg @click="isShowPassword = false" v-if="isShowPassword" width="17" height="17" fill="#828282" class="input__eye bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                            </svg>
                            <svg @click="isShowPassword = true" v-else class="input__eye" width="19" height="19" fill="#828282" viewBox="0 0 1024 1024">
                                <path d="M512 637.207c-171.753 0-343.273-52.363-465.455-156.858a34.91 34.91 0 1 1 46.546-53.062c215.505 186.182 623.244 186.182 837.818 0a34.91 34.91 0 0 1 46.546 53.062C855.273 584.844 683.753 637.207 512 637.207z"/>
                                <path d="M384.465 791.273a28.625 28.625 0 0 1-6.283 0 34.676 34.676 0 0 1-29.091-42.124l23.273-137.309a34.91 34.91 0 1 1 69.818 12.102L418.909 761.25a34.91 34.91 0 0 1-34.444 30.022z m523.171-69.818a34.444 34.444 0 0 1-24.669-10.473L768.233 594.618a34.91 34.91 0 1 1 49.57-48.873L932.539 662.11a34.91 34.91 0 0 1 0 49.338 36.305 36.305 0 0 1-24.902 10.008z m-791.272 0a36.305 36.305 0 0 1-23.273-10.008 34.91 34.91 0 0 1 0-49.338l114.734-116.364A34.91 34.91 0 1 1 256 594.618L141.265 710.982a34.444 34.444 0 0 1-24.901 10.473z m524.8 69.818a34.91 34.91 0 0 1-34.211-28.858l-23.273-137.31a34.91 34.91 0 1 1 67.956-13.265l23.273 137.31a34.676 34.676 0 0 1-28.16 40.494 28.625 28.625 0 0 1-5.585 1.629z"/>
                            </svg>
                        </div>

                        <div v-if="isCorrectOldPassword" class="input__block">
                            <input
                                @input="comparePassword"
                                v-model="newPassword"
                                :type="isShowPassword1 ? 'text' : 'password'"
                                :placeholder="$t('security.newPassword')"
                                class="form-control form__input my-2"
                            />
                            <svg @click="isShowPassword1 = false" v-if="isShowPassword1" width="17" height="17" fill="#828282" class="input__eye bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                            </svg>
                            <svg @click="isShowPassword1 = true" v-else class="input__eye" width="19" height="19" fill="#828282" viewBox="0 0 1024 1024">
                                <path d="M512 637.207c-171.753 0-343.273-52.363-465.455-156.858a34.91 34.91 0 1 1 46.546-53.062c215.505 186.182 623.244 186.182 837.818 0a34.91 34.91 0 0 1 46.546 53.062C855.273 584.844 683.753 637.207 512 637.207z"/>
                                <path d="M384.465 791.273a28.625 28.625 0 0 1-6.283 0 34.676 34.676 0 0 1-29.091-42.124l23.273-137.309a34.91 34.91 0 1 1 69.818 12.102L418.909 761.25a34.91 34.91 0 0 1-34.444 30.022z m523.171-69.818a34.444 34.444 0 0 1-24.669-10.473L768.233 594.618a34.91 34.91 0 1 1 49.57-48.873L932.539 662.11a34.91 34.91 0 0 1 0 49.338 36.305 36.305 0 0 1-24.902 10.008z m-791.272 0a36.305 36.305 0 0 1-23.273-10.008 34.91 34.91 0 0 1 0-49.338l114.734-116.364A34.91 34.91 0 1 1 256 594.618L141.265 710.982a34.444 34.444 0 0 1-24.901 10.473z m524.8 69.818a34.91 34.91 0 0 1-34.211-28.858l-23.273-137.31a34.91 34.91 0 1 1 67.956-13.265l23.273 137.31a34.676 34.676 0 0 1-28.16 40.494 28.625 28.625 0 0 1-5.585 1.629z"/>
                            </svg>
                        </div>

                        <div v-if="isCorrectOldPassword" class="input__block">
                            <input
                                @input="comparePassword"
                                v-model="repeatPassword"
                                :type="isShowPassword2 ? 'text' : 'password'"
                                :placeholder="$t('security.repeatNewPassword')"
                                class="form-control form__input"
                            />
                            <svg @click="isShowPassword2 = false" v-if="isShowPassword2" width="17" height="17" fill="#828282" class="input__eye bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                            </svg>
                            <svg @click="isShowPassword2 = true" v-else class="input__eye" width="19" height="19" fill="#828282" viewBox="0 0 1024 1024">
                                <path d="M512 637.207c-171.753 0-343.273-52.363-465.455-156.858a34.91 34.91 0 1 1 46.546-53.062c215.505 186.182 623.244 186.182 837.818 0a34.91 34.91 0 0 1 46.546 53.062C855.273 584.844 683.753 637.207 512 637.207z"/>
                                <path d="M384.465 791.273a28.625 28.625 0 0 1-6.283 0 34.676 34.676 0 0 1-29.091-42.124l23.273-137.309a34.91 34.91 0 1 1 69.818 12.102L418.909 761.25a34.91 34.91 0 0 1-34.444 30.022z m523.171-69.818a34.444 34.444 0 0 1-24.669-10.473L768.233 594.618a34.91 34.91 0 1 1 49.57-48.873L932.539 662.11a34.91 34.91 0 0 1 0 49.338 36.305 36.305 0 0 1-24.902 10.008z m-791.272 0a36.305 36.305 0 0 1-23.273-10.008 34.91 34.91 0 0 1 0-49.338l114.734-116.364A34.91 34.91 0 1 1 256 594.618L141.265 710.982a34.444 34.444 0 0 1-24.901 10.473z m524.8 69.818a34.91 34.91 0 0 1-34.211-28.858l-23.273-137.31a34.91 34.91 0 1 1 67.956-13.265l23.273 137.31a34.676 34.676 0 0 1-28.16 40.494 28.625 28.625 0 0 1-5.585 1.629z"/>
                            </svg>
                        </div>

                        <div class="uncorrectedPassword" v-if="errorPassword">{{$t('security.passwordsNotEquals') }}</div>
                    </form>

                    <div v-if="isCorrectOldPassword" class="row">
                        <div class="col">
                            <button @click="changePassword" class="submit__btn py-3 mt-4 w-100">
                                <span>{{ $t('save') }}</span>
                                <span v-if="isLoading" class="spinner-grow spinner-grow-sm"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-5">
                <div class="col">
                    <div class="p-3 menu__block">
                        <div class="col-10 mx-auto py-3">
                            <span class="reset__password-text">
                                {{ $t('security.forgotPasswordInfo') }}
                                <span style="color: #177EE6"> {{ $t('security.resetPassword') }} </span>
                                <span v-if="$i18n.locale === 'uz'">tugmasini bosing.</span>
                            </span>
                        </div>
                        <div @click="sendVerificationCode" class="col">
                            <button class="submit__btn py-3 w-100">{{ $t('security.buttonResetPassword') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.content {
    background-color: var(--color-container);
    border-radius: 15px;
    min-height: 100vh;
}

.header__row {
    border-radius: 0 0 15px 15px;
    background-color: var(--color-header);
    color: var(--color-text);
}

.menu__block {
    border-radius: 1rem;
    background-color: var(--color-block);
}

.alert {
    border-radius: 12px;
}

.uncorrectedPassword {
    color: #EB5757;
    font-size: 0.7rem;
    font-weight: 500;
}

.input__block {
    position: relative;
}

.form__input {
    height: 58px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 0.9rem;
    padding-left: 1.6rem;
    border: 1px solid #0481F4;
    background-color: var(--color-block);
    color: #828282;
}

.input__error, .input__error:focus {
    border-color: #ff0000 !important;
    outline: none;
    box-shadow: none;
}

.input__spinner {
    position: absolute;
    right: 15%;
    top: 40%;
    color: #828282;
}

.input__eye {
    position: absolute;
    right: 7%;
    top: 40%;
}

.form__input-one {
    border-color: var(--border-bd-to-4f);
    color: #bdbdbd;
}

.submit__btn {
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 12px;
    background-color: #177ee6;
    color: white;
    vertical-align: middle !important;
}

.reset__password-text {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0;
    color: var(--color-Black-To-White);
}

</style>

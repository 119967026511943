import fetchModels from "../../mixin/store/api/fetchModels";

export default {
    actions: {
        fetchTopReaders(context, data) {
            return fetchModels(context, 'user_total_read_times?page=1&order[readTime]=desc', data, 'updateTopReaders')
        }
    },
    mutations: {
        updateTopReaders(state, data) {
            state.topReaders = data
        }
    },
    state: {
        topReaders: {
            models: [],
            totalItems: 0
        },
    },
    getters: {
        getTopReaders(state) {
            return state.topReaders.models
        }
    }
}

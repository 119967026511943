import fetchModels from "../../mixin/store/api/fetchModels";

export default {
    actions: {
        fetchAudioBooks(context, page) {
            return fetchModels(context, 'audio_books', page, 'updateAudioBooks');
        },
        fetchSearchAudioBooks(context, data) {
            return fetchModels(context, `audio_books/search?name=${data}`, null,'updateSearchAudioBooks');
        },
        fetchSimilarAudioBooks(context, id) {
            return fetchModels(context, `audio_books/similar/${id}`, null, 'updateSimilarAudioBooks')
        }
    },
    mutations: {
        updateAudioBooks(state, data) {
            state.audioBooks = data
        },
        updateSearchAudioBooks(state, data) {
            state.searchAudioBooks = data
        },
        updateSimilarAudioBooks (state, data) {
            state.similarAudioBooks = data
        }
    },
    state: {
        audioBooks: {
          models: [],
          totalItems: 0,
        },
        searchAudioBooks: {
            models: [],
            totalItems: 0,
        },
        similarAudioBooks: {
            models: [],
            totalItems: 0,
        },
        countOfAudioBooks: 0
    },
    getters: {
        getAudioBooks(state) {
            return state.audioBooks.models
        },
        getSearchAudioBooks(state) {
            return state.searchAudioBooks.models
        },
        getSimilarAudioBooks(state) {
            state.similarAudioBooks.models.map((el) => {
                if(el.coverSourceUrl) {
                    el.coverSourceUrl = el.coverSourceUrl.replace('cover', 'cover_100')
                }
            })
            return state.similarAudioBooks.models
        }
    },
}

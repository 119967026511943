import localToken from "../local/localToken";
import axios from "../../plugins/vuex/axios"
import changeModel from "../../mixin/store/api/changeModel";
import pushModel from "@/mixin/store/api/pushModel";

export default {
    actions: {
        fetchToken(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("users/auth", data)
                    .then(response => {
                        context.commit('updateTokens', response.data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchRefreshToken(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        '/users/auth/refreshToken',
                        {refreshToken: ctx.getters.getRefreshToken}
                    )
                    .then(response => {
                        ctx.commit('updateTokens', response.data)
                        resolve()
                    })
                    .catch((reason) => {
                        console.log(reason)
                        reject(reason)
                    })
            })
        },
        pushChangePassword(context, data) {
            return changeModel(context, `users/${data.id}/password`, data)
        },
        pushChangePasswordByCheckPhone(context, data) {
            return pushModel(context, 'users/change_password', data)
        },
        clearTokens(ctx) {
            ctx.commit('clearTokens')
            // ctx.commit('clearRoles')
        }
    },
    mutations: {
        updateTokens(state, data) {
            state.accessToken = data.accessToken
            state.refreshToken = data.refreshToken

            localToken.setAccess(data.accessToken)
            localToken.setRefresh(data.refreshToken)
        },
        clearTokens(state) {
            state.accessToken = ''
            state.refreshToken = ''

            localToken.clear()
        }
    },
    state: {
        accessToken: localToken.getAccess(),
        refreshToken: localToken.getRefresh(),
    },
    getters: {
        isAuthenticated(state, getters) {
            return getters.getAccessToken.length > 0
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
    },
}

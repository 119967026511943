<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "login_sixth",
    computed: {
        ...mapGetters(['getCheckCodeStatus', 'getVerification'])
    },
    mounted() {
        this.isSmallWidth330 = this.$refs.content.clientWidth < 330
        this.isSmallHeight740 = this.$refs.content.clientHeight < 740

        setTimeout(() => {this.isResend = true}, 60000)

        if (localStorage.getItem('password') && localStorage.getItem('phone') !== null) {
            this.form.password = localStorage.getItem('password')
            this.form.phone = localStorage.getItem('phone').split(/\W+/).join('')
            this.cutOffPhoneNumber = localStorage.getItem('phone').slice(4, 19)
        } else {
            this.$router.push('/login-fifth')
        }
    },
    data() {
        return {
            form: {
                password: '',
                phone: ''
            },
            smsCode: '',
            cutOffPhoneNumber: '',
            isResend: false,
            isSmallHeight740: false,
            isSmallWidth330: false,
            isLoading: false,
            isLoading1: false,
            isError: false,
        }
    },
    methods: {
        ...mapActions([
            'checkVerificationCode',
            'authUser',
            'fetchToken',
            'createVerification',
            'createDevice',
            'pushUserLocation'
        ]),
        checkSmsCode() {
            this.isLoading = true

            this.checkVerificationCode({phone: this.form.phone, smsCode: this.smsCode})
                .then(() => {
                    this.isLoading = false

                    if (this.getCheckCodeStatus) {
                        this.$root.showOverlay = true

                        this.authUser({phone: this.form.phone, password: this.form.password})
                            .then(() => {
                                this.fetchToken({phone: this.form.phone, password: this.form.password})
                                    .then(() => {
                                        localStorage.removeItem('phone')
                                        localStorage.removeItem('password')
                                        localStorage.setItem('notification-sale', 'true')
                                        localStorage.setItem('notification-recommended-books', 'true')
                                        localStorage.setItem('notification-game-contest', 'true')

                                        try {
                                            window.bookToken(this.$store.getters.getAccessToken)
                                            this.$router.push('/')
                                        } catch {
                                            this.createDevice({
                                                operationSystem: 0,
                                                region: "1Books",
                                                name: "Linux Fedora, Gnome 43",
                                                ipAddress: "10.05.19.88",
                                                city: "Namangan"
                                            })
                                            this.pushUserLocation({
                                                latitude: "40.992340",
                                                longitude: "71.672704"
                                            })
                                            this.$router.push('/')
                                        }
                                    })
                                    .catch(() => {
                                        this.$root.showOverlay = false
                                    })
                            })
                            .catch(() => {
                                this.$root.showOverlay = false
                            })
                    } else {
                        this.isError = true
                    }
                })
                .catch(() => {
                    this.isLoading = false
                    this.isError = true
                    if (localStorage.getItem('password') && localStorage.getItem('phone') !== null) {
                        this.isResend = true
                    }
                })
        },
        resendCode() {
            this.isError = false
            this.isLoading1 = true

            this.createVerification({phoneNumber: this.form.phone})
                .then(() => {
                    this.smsCode = ''
                    this.isLoading1 = false
                    this.isResend = false
                })

            setTimeout(() => {this.isResend = true}, 60000)
        }
    }
}
</script>

<template>
    <div class="row page__row" :class="isSmallHeight740 ? 'pt-3' : 'pt-5'" ref="content">
        <div class="col-12">
            <div class="row ps-2">
                <svg class="col mx-auto" width="187" height="196" viewBox="0 0 187 196" fill="none">
                    <path d="M90.5 181C140.482 181 181 140.482 181 90.5C181 40.5182 140.482 0 90.5 0C40.5182 0 0 40.5182 0 90.5C0 140.482 40.5182 181 90.5 181Z" fill="var(--bg-F2F8FF-to-252335)"/>
                    <g filter="url(#filter0_d_2_13895)">
                        <path d="M120.207 50H58.7933C55.0415 50 52 52.9985 52 56.6975V125.303C52 129.001 55.0415 132 58.7933 132H120.207C123.959 132 127 129.001 127 125.303V56.6975C127 52.9985 123.959 50 120.207 50Z" fill="#0071DA"/>
                    </g>
                    <path d="M118.105 49.0459H56.5194C52.757 49.0459 49.707 52.0959 49.707 55.8583V125.641C49.707 129.403 52.757 132.453 56.5194 132.453H118.105C121.868 132.453 124.918 129.403 124.918 125.641V55.8583C124.918 52.0959 121.868 49.0459 118.105 49.0459Z" fill="#0481F4"/>
                    <path d="M88.0532 65.1698C95.5977 65.1698 101.714 59.0538 101.714 51.5092C101.714 43.9647 95.5977 37.8486 88.0532 37.8486C80.5086 37.8486 74.3926 43.9647 74.3926 51.5092C74.3926 59.0538 80.5086 65.1698 88.0532 65.1698Z" fill="#3EA2FF"/>
                    <path d="M88.0531 50.9912C90.1287 50.9912 91.8112 49.2872 91.8112 47.1853C91.8112 45.0833 90.1287 43.3794 88.0531 43.3794C85.9775 43.3794 84.2949 45.0833 84.2949 47.1853C84.2949 49.2872 85.9775 50.9912 88.0531 50.9912Z" fill="white"/>
                    <path d="M91.2738 51.6461C91.0784 51.5522 90.8636 51.506 90.6469 51.5111C90.4301 51.5163 90.2177 51.5728 90.0271 51.6759C89.415 51.9895 88.7372 52.153 88.0496 52.153C87.3619 52.153 86.6841 51.9895 86.072 51.6759C85.8814 51.5728 85.669 51.5163 85.4523 51.5111C85.2355 51.506 85.0207 51.5522 84.8253 51.6461C83.8447 52.1326 82.9831 52.8289 82.3017 53.6855C81.6203 54.5422 81.1357 55.5383 80.8822 56.6032C80.8319 56.8081 80.8285 57.0216 80.8722 57.2279C80.9159 57.4343 81.0056 57.6281 81.1346 57.7949C81.2636 57.9618 81.4286 58.0974 81.6173 58.1916C81.806 58.2858 82.0135 58.3363 82.2244 58.3391H93.9045C94.1158 58.3374 94.3239 58.2877 94.5132 58.1938C94.7025 58.0999 94.868 57.9643 94.9972 57.7972C95.1264 57.63 95.216 57.4357 95.2593 57.2289C95.3025 57.0221 95.2982 56.8082 95.2467 56.6032C94.9912 55.5351 94.5024 54.5367 93.8156 53.6796C93.1287 52.8226 92.2607 52.1281 91.2738 51.6461Z" fill="white"/>
                    <path d="M67.4492 73.9038L108.097 73.8501" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M71.0449 83.1848L102.983 83.1431" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path opacity="0.1" d="M71.329 96.3529C64.3973 96.156 58.1516 92.887 55.5805 91.348C55.2067 91.1204 54.7774 91 54.3397 91C53.902 91 53.4728 91.1204 53.0989 91.348C50.5279 92.887 44.2822 96.156 37.3505 96.3529C36.7165 96.3716 36.1151 96.6383 35.6756 97.0956C35.2361 97.5529 34.9936 98.1645 35.0001 98.7987C35.0538 105.152 36.8434 122.219 52.9498 133.314C53.355 133.593 53.8356 133.743 54.3278 133.743C54.82 133.743 55.3005 133.593 55.7058 133.314C71.8122 122.219 73.6018 105.152 73.6674 98.7987C73.674 98.1665 73.4331 97.5567 72.9962 97.0997C72.5592 96.6427 71.9609 96.3747 71.329 96.3529Z" fill="#134175"/>
                    <path d="M69.9408 95.0032C63.0091 94.8063 56.7634 91.5373 54.1924 89.9982C53.8167 89.7756 53.3882 89.6582 52.9516 89.6582C52.515 89.6582 52.0864 89.7756 51.7108 89.9982C49.1457 91.5373 42.9 94.8063 35.9623 95.0032C35.6474 95.0086 35.3367 95.0763 35.0481 95.2023C34.7595 95.3284 34.4987 95.5104 34.2807 95.7377C34.0628 95.9651 33.892 96.2334 33.7783 96.5271C33.6645 96.8208 33.61 97.1341 33.6179 97.4489C33.6537 103.832 35.4433 120.869 51.5497 131.97C51.9559 132.25 52.4375 132.4 52.9307 132.4C53.4239 132.4 53.9055 132.25 54.3117 131.97C70.4181 120.869 72.2077 103.802 72.2673 97.4489C72.274 96.8187 72.0348 96.2106 71.6003 95.754C71.166 95.2973 70.5706 95.028 69.9408 95.0032Z" fill="url(#paint0_linear_2_13895)"/>
                    <path d="M67.847 96.9768C61.7683 96.8098 56.2921 93.9404 54.0432 92.5923C53.7139 92.3964 53.3377 92.293 52.9545 92.293C52.5713 92.293 52.1952 92.3964 51.8659 92.5923C49.611 93.9404 44.1109 96.8098 38.0621 96.9768C37.5062 96.9939 36.9791 97.2285 36.5943 97.6301C36.2095 98.0316 35.9975 98.5681 36.0041 99.1243C36.0577 104.696 37.6028 119.657 51.7466 129.386C52.0988 129.625 52.5144 129.752 52.9396 129.752C53.3649 129.752 53.7804 129.625 54.1327 129.386C68.2705 119.657 69.8215 104.696 69.8752 99.1243C69.8819 98.5731 69.6741 98.0409 69.2956 97.6401C68.9171 97.2394 68.3977 97.0015 67.847 96.9768Z" fill="url(#paint1_linear_2_13895)"/>
                    <path d="M52.9534 118.364C58.1225 118.364 62.3129 114.173 62.3129 109.004C62.3129 103.835 58.1225 99.6445 52.9534 99.6445C47.7842 99.6445 43.5938 103.835 43.5938 109.004C43.5938 114.173 47.7842 118.364 52.9534 118.364Z" fill="white"/>
                    <path d="M52.9519 119.043C50.9648 119.043 49.0223 118.454 47.3702 117.35C45.7181 116.245 44.4305 114.676 43.6704 112.84C42.9102 111.004 42.7116 108.984 43.0997 107.035C43.4878 105.086 44.4452 103.297 45.8507 101.892C47.2562 100.487 49.0467 99.5309 50.9957 99.144C52.9448 98.757 54.9648 98.9568 56.8003 99.7181C58.6358 100.479 60.2043 101.768 61.3074 103.421C62.4105 105.073 62.9987 107.016 62.9975 109.003C62.9943 111.666 61.9347 114.219 60.0513 116.101C58.1679 117.983 55.6146 119.041 52.9519 119.043ZM52.9519 100.33C51.2364 100.33 49.5594 100.838 48.1331 101.791C46.7067 102.745 45.595 104.099 44.9385 105.684C44.282 107.269 44.1103 109.013 44.4449 110.695C44.7796 112.378 45.6057 113.923 46.8187 115.136C48.0317 116.349 49.5772 117.176 51.2597 117.51C52.9422 117.845 54.6862 117.673 56.2711 117.017C57.856 116.36 59.2106 115.248 60.1637 113.822C61.1168 112.396 61.6255 110.719 61.6255 109.003C61.6239 106.703 60.7095 104.498 59.0833 102.872C57.457 101.246 55.2518 100.331 52.9519 100.33Z" fill="url(#paint2_linear_2_13895)"/>
                    <path d="M52.9535 109.338C54.4229 109.338 55.614 108.147 55.614 106.677C55.614 105.208 54.4229 104.017 52.9535 104.017C51.4841 104.017 50.293 105.208 50.293 106.677C50.293 108.147 51.4841 109.338 52.9535 109.338Z" fill="url(#paint3_linear_2_13895)"/>
                    <path d="M52.9519 114.569C52.8018 114.569 52.6531 114.539 52.5145 114.482C52.3759 114.424 52.25 114.34 52.1441 114.233C52.0382 114.127 51.9544 114 51.8975 113.862C51.8406 113.723 51.8117 113.574 51.8125 113.424V106.683C51.8334 106.394 51.9628 106.124 52.1747 105.927C52.3867 105.73 52.6654 105.62 52.9549 105.62C53.2443 105.62 53.5231 105.73 53.735 105.927C53.9469 106.124 54.0764 106.394 54.0972 106.683V113.424C54.0972 113.727 53.9766 114.019 53.7618 114.234C53.547 114.448 53.2557 114.569 52.9519 114.569Z" fill="url(#paint4_linear_2_13895)"/>
                    <g filter="url(#filter1_d_2_13895)">
                        <path d="M143.235 135.931L94.7835 136C92.6977 136 91.0061 133.387 91 130.164V110.911C91 107.682 92.6856 105.069 94.7713 105.063L143.223 105C145.302 105 146.994 107.607 147 110.83V130.089C147.006 133.312 145.314 135.925 143.235 135.931Z" fill="#EC950F"/>
                    </g>
                    <rect x="90" y="104" width="56" height="32" rx="5" fill="#FFCF25"/>
                    <path d="M140.559 111.95C140.419 111.818 140.254 111.715 140.074 111.647C139.894 111.579 139.702 111.547 139.51 111.553C139.318 111.559 139.129 111.603 138.953 111.682C138.778 111.762 138.62 111.875 138.489 112.016L129.099 122.055L125.616 117.88C125.358 117.608 125.007 117.444 124.633 117.423C124.26 117.402 123.892 117.524 123.605 117.765C123.319 118.005 123.135 118.347 123.092 118.718C123.049 119.09 123.149 119.464 123.373 119.765L127.715 124.943C127.789 125.092 127.888 125.227 128.008 125.342C128.28 125.595 128.638 125.735 129.01 125.736H129.064C129.408 125.736 129.741 125.615 130.006 125.396C130.127 125.289 130.232 125.164 130.316 125.026L140.636 113.996C140.893 113.712 141.028 113.34 141.013 112.957C140.999 112.575 140.836 112.214 140.559 111.95Z" fill="white"/>
                    <path d="M95.8997 119.12L112.208 118.967" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M96 111H117" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M96 127H117" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <defs>
                        <filter id="filter0_d_2_13895" x="44" y="46" width="91" height="98" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="4"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_13895"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_13895" result="shape"/>
                        </filter>
                        <filter id="filter1_d_2_13895" x="51" y="85" width="136" height="111" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="20"/>
                            <feGaussianBlur stdDeviation="20"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_13895"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_13895" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_2_13895" x1="52.9423" y1="89.6582" x2="52.9423" y2="132.4" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#56A6FF"/>
                            <stop offset="1" stop-color="#3B93F5"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_2_13895" x1="52.9396" y1="92.293" x2="52.9396" y2="129.752" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#89C1FF"/>
                            <stop offset="1" stop-color="#65ADFD"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_2_13895" x1="52.9519" y1="98.9517" x2="52.9519" y2="119.043" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#56A6FF"/>
                            <stop offset="1" stop-color="#3B93F5"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_2_13895" x1="52.9535" y1="104.017" x2="52.9535" y2="109.338" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#56A6FF"/>
                            <stop offset="1" stop-color="#3B93F5"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_2_13895" x1="52.9549" y1="105.62" x2="52.9549" y2="114.569" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#449AF9"/>
                            <stop offset="1" stop-color="#3B93F5"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            <div class="row mt-3">
                <div class="col text-center main__text">{{ $t('verificationCode') }}</div>
            </div>

            <div class="row" :class="isSmallHeight740 ? ' my-2' : ' my-3'">
                <div class="mx-auto main__description" :class="isSmallWidth330 ? 'col-12' : 'col-9'">
                    {{$t('security.sendCodeToPhone')}}
                    <span class="main__description-phone">{{ cutOffPhoneNumber }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col" :class="{'mx-2' : !isSmallWidth330}">
                    <span class="main__description">{{ 'verification code is: ' + getVerification }}</span>
                    <input
                        @focus="isError = false"
                        type="tel"
                        placeholder="- - - - - -"
                        maxlength="6"
                        min="6"
                        class="input__form py-3"
                        v-model="smsCode"
                    >
                    <div v-if="isError" class="errorMessage">{{ $t('security.codeIncorrect') }}</div>

                    <button @click="checkSmsCode" class="form__button py-3 mt-3" type="submit">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
                        <span v-else>{{ $t('next') }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="isResend" class="col" :class="{'mx-2' : !isSmallWidth330}">
            <div class="text__again mb-1">{{ $t('security.againSendCode') }}</div>

            <button @click="resendCode" class="form__button py-3" type="submit">
                <span v-if="isLoading1" class="spinner-border spinner-border-sm"></span>
                <span v-else>{{ $t('send') }}</span>
            </button>
        </div>
    </div>
</template>

<style scoped>
.page__row {
    background-color: var(--white-to-dark);
    min-height: 100vh;
}

.main__text {
    color: var(--color-text);
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.main__description, .main__description-phone {
    color: var(--color-textDarkGray-To-white);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.main__description-phone {
    color: #0481f4;
    font-weight: 500;
}

.input__form {
    border: 1px solid #0481f4;
    border-radius: 12px;
    width: 100%;
    text-align: center;
    color: var(--color-textDarkGray-To-white);
    font-weight: 400;
    background-color: var(--white-to-dark);
}

.input__form:focus {
    outline: none;
}

.input__form::placeholder {
    color: var(--color-textDarkGray-To-Blue);
    transform: scaleX(2);
}

.errorMessage {
    color: #EB5757;
    font-size: .9rem;
}

.text__again {
    font-size: 1rem;
    color: #0481f4;
    text-align: center;
}

.form__button {
    width: 100%;
    border: 1px solid #0481f4;
    border-radius: 12px;
    background-color: #0481F4;
    color: white;
    outline: none;
    font-size: 1rem;
    font-weight: 500;
}

</style>

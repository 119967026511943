import changeOrPushmodel from "@/mixin/store/api/changeOrPushmodel";

export default {
    actions: {
        changeOrCreateTarget(context, data) {
            return changeOrPushmodel(context, 'targets', data, 'updateTarget')
        }
    },
    mutations: {
        updateTarget(state, data) {
            state.target = data
        }
    },
    state: {
        target: {
            id: null,
            howManyBooks: null,
            howManyDays: null,
            howManyMinute: null
        }
    },
    getters: {
        getTarget(state) {
            return state.target
        }
    },
}

<template>
    <div class="wrapper">
        <div class="audio-book_container">
            <div class="audio-book-bg">
                <div class="audio-book-bg__img">
                    <img v-if="getAudioBookId.image" :src="localhost + getAudioBookId.image.contentUrl" alt="book">
                    <img v-else-if="getAudioBookId.coverSourceUrl" :src="changeHeightImg(getAudioBookId.coverSourceUrl)"
                         alt="book">
                    <div class="audio-book-bg__icons">
                        <div class="audio-book-bg__goBack">
                            <button class="goBack_btn" @click="$router.back()">
                                <svg width="29" height="26" viewBox="0 0 29 26" fill="none">
                                    <path
                                        d="M7.8175 19.8437C7.6275 19.8437 7.4375 19.7692 7.2875 19.6095L1.2175 13.1466C0.9275 12.8379 0.9275 12.3268 1.2175 12.018L7.2875 5.55519C7.5775 5.24642 8.0575 5.24642 8.3475 5.55519C8.6375 5.86395 8.6375 6.37502 8.3475 6.68379L2.8075 12.5823L8.3475 18.4809C8.6375 18.7897 8.6375 19.3007 8.3475 19.6095C8.2075 19.7692 8.0075 19.8437 7.8175 19.8437Z"
                                        fill="white"/>
                                    <path
                                        d="M27.748 13.3809H2.91797C2.50797 13.3809 2.16797 13.0189 2.16797 12.5824C2.16797 12.1458 2.50797 11.7838 2.91797 11.7838H27.748C28.158 11.7838 28.498 12.1458 28.498 12.5824C28.498 13.0189 28.158 13.3809 27.748 13.3809Z"
                                        fill="white"/>
                                </svg>
                            </button>
                        </div>
                        <div class="audio-book-bg__instagram">
                            <svg @click="sendAudioBookDataToAndroid" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <path
                                    d="M2.5 16.2875V18.75C2.5 25 5 27.5 11.25 27.5H18.75C25 27.5 27.5 25 27.5 18.75V11.25C27.5 5 25 2.5 18.75 2.5H11.25C5 2.5 2.5 5 2.5 11.25"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M15 19.375C17.4162 19.375 19.375 17.4162 19.375 15C19.375 12.5838 17.4162 10.625 15 10.625C12.5838 10.625 10.625 12.5838 10.625 15C10.625 17.4162 12.5838 19.375 15 19.375Z"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.0451 8.75H22.0596" stroke="white" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="audio-book-bg__cloud">
                            <svg @click="share1booksOtherApp" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <path d="M15 18.75V4.52499" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M19.1875 7.3125L15 3.125L10.8125 7.3125" stroke="white" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M10.9246 26.875C5.33711 26.875 3.09961 24.6375 3.09961 19.05V18.8875C3.09961 13.8625 4.91211 11.55 9.33711 11.1375"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M20.5492 11.125C25.0492 11.5125 26.8867 13.825 26.8867 18.8875V19.05C26.8867 24.6375 24.6492 26.875 19.0617 26.875H16.2617"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div class="audio-book__name" v-if="!isShowPlaceholder">
                        {{ getAudioBookId.name }}
                    </div>
                </div>
            </div>
            <div class="book">
                <div class="book__body">
                    <div class="book__img" :class="{'placeholder': isShowPlaceholder, 'placeholder-wave': isShowPlaceholder}" >
                        <img
                            v-if="getAudioBookId.image"
                            :src="localhost + getAudioBookId.image.contentUrl"
                            alt="book"
                        >
                        <img
                            v-else-if="getAudioBookId.coverSourceUrl"
                            :src="changeHeightImg(getAudioBookId.coverSourceUrl)"
                            @error="setDefaultImg"
                            alt="book"
                        >
                        <div class="book__icons">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <rect width="16" height="16" rx="8" fill="white"/>
                                <path
                                    d="M4.14588 10.7708C3.97505 10.7708 3.83338 10.6291 3.83338 10.4583V8.08329C3.81255 6.95413 4.23338 5.88746 5.01671 5.08746C5.80005 4.29163 6.85005 3.85413 7.97921 3.85413C10.2875 3.85413 12.1667 5.73329 12.1667 8.04163V10.4166C12.1667 10.5875 12.025 10.7291 11.8542 10.7291C11.6834 10.7291 11.5417 10.5875 11.5417 10.4166V8.04163C11.5417 6.07913 9.94588 4.47913 7.97921 4.47913C7.01671 4.47913 6.12505 4.84996 5.46255 5.52496C4.79588 6.20413 4.44171 7.10829 4.45838 8.07496V10.4541C4.45838 10.6291 4.32088 10.7708 4.14588 10.7708Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M5.47504 8.1875H5.42087C4.54587 8.1875 3.83337 8.9 3.83337 9.775V10.5583C3.83337 11.4333 4.54587 12.1458 5.42087 12.1458H5.47504C6.35004 12.1458 7.06254 11.4333 7.06254 10.5583V9.775C7.06254 8.9 6.35004 8.1875 5.47504 8.1875Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M10.5792 8.1875H10.525C9.65 8.1875 8.9375 8.9 8.9375 9.775V10.5583C8.9375 11.4333 9.65 12.1458 10.525 12.1458H10.5792C11.4542 12.1458 12.1667 11.4333 12.1667 10.5583V9.775C12.1667 8.9 11.4542 8.1875 10.5792 8.1875Z"
                                    fill="#0481F4"/>
                            </svg>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <rect width="16" height="16" rx="8" fill="white"/>
                                <path
                                    d="M7.68754 5.49999V11.2958C7.68754 11.5917 7.38754 11.7958 7.11671 11.6833C6.34171 11.3583 5.37921 11.075 4.69587 10.9833L4.56671 10.9667C4.16254 10.9167 3.83337 10.5458 3.83337 10.1417V5.18749C3.83337 4.68749 4.23754 4.28333 4.73754 4.28333H4.76671C5.55004 4.34999 6.70421 4.71666 7.46671 5.12916C7.60421 5.20416 7.68754 5.34166 7.68754 5.49999Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M11.2625 4.28333H11.2375C11.1375 4.29166 11.0292 4.30416 10.9167 4.32499C10.4042 4.40833 9.79583 4.58333 9.25 4.79999C8.99167 4.90416 8.75 5.01666 8.5375 5.12916C8.4 5.20416 8.3125 5.34583 8.3125 5.49999V11.2958C8.3125 11.5917 8.6125 11.7958 8.88333 11.6833C9.65833 11.3583 10.6208 11.075 11.3042 10.9833L11.4333 10.9667C11.8375 10.9167 12.1667 10.5458 12.1667 10.1417V5.18749C12.1667 4.68749 11.7625 4.28333 11.2625 4.28333ZM11.0292 8.72083C11.0292 8.88333 10.9125 8.95416 10.7708 8.87499L10.3292 8.62916C10.2833 8.60416 10.2083 8.60416 10.1583 8.62916L9.71667 8.87499C9.575 8.95416 9.45833 8.88333 9.45833 8.72083V7.44166C9.45833 7.24999 9.61667 7.09166 9.80833 7.09166H10.6833C10.875 7.09166 11.0333 7.24999 11.0333 7.44166V8.72083H11.0292Z"
                                    fill="#0481F4"/>
                            </svg>
                        </div>
                    </div>
                    <div class="book__info book-info" :class="{'placeholder-wave': isShowPlaceholder}">
                        <div class="book-info__author">
                            <div class="book-info__label label">{{ $t('author') }}:</div>
                            <div class="book-info__value value" :class="{'placeholder': isShowPlaceholder}">{{
                                    getAudioBookId.author ? getAudioBookId.author : '-'
                                }}
                            </div>
                        </div>
                        <div class="book-info__reader">
                            <div class="book-info__label label">{{ $t('reader') }}:</div>
                            <div class="book-info__value value" :class="{'placeholder': isShowPlaceholder}">{{
                                    getAudioBookId.reader ? getAudioBookId.reader : '-'
                                }}
                            </div>
                        </div>
                        <div class="book-info__price">
                            <div class="book-info__label label">{{ $t('price.price') }}:</div>
                            <div class="book-info__value value" :class="{'placeholder': isShowPlaceholder}">{{ getAudioBookId.price }} {{
                                    $t('price.currency')
                                }}
                            </div>
                        </div>
                        <div class="book__stars star" :class="{'placeholder': isShowPlaceholder}">
                            <div class="star" v-for="n in 5" :key="n"
                                 :class="{book__star_filled: Math.floor(getAudioBookId.votesTotalCount / getAudioBookId.votesTotalUser) >= n}">
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.23584 1.22045L8.32146 3.40673C8.4695 3.70486 8.86427 4.00299 9.19324 4.05268L11.1589 4.38393C12.4172 4.59924 12.7133 5.51019 11.8086 6.42114L10.2789 7.96975C10.0239 8.22647 9.87587 8.73164 9.95811 9.09602L10.394 11.009C10.7394 12.5162 9.94166 13.1042 8.61754 12.3175L6.77527 11.216C6.43807 11.0173 5.89526 11.0173 5.55806 11.216L3.70757 12.3092C2.38345 13.0959 1.58568 12.5079 1.9311 11.0007L2.367 9.08773C2.44924 8.73164 2.3012 8.22647 2.04625 7.96147L0.524733 6.42942C-0.37995 5.51847 -0.0838721 4.59924 1.17446 4.39221L3.14009 4.06096C3.46907 4.00299 3.86384 3.71314 4.01188 3.41501L5.0975 1.22874C5.68143 0.0362225 6.65191 0.0362224 7.23584 1.22045Z"
                                        fill="#E4E4E4"/>
                                </svg>
                            </div>
                            <span class="star__value">{{
                                    getAudioBookId.votesTotalCount > 0 ? (getAudioBookId.votesTotalCount / getAudioBookId.votesTotalUser).toFixed(1) : ''
                                }}</span>
                        </div>
                        <div class="book__detail detail" :class="{'placeholder': isShowPlaceholder}">
                            <div class="detail__hour detail-value">
                                {{ getAudioBookDurationTimeInHourAndMin }} <span></span>
                            </div>
                            <div class="detail__size detail-value">
                                {{ Math.floor(getAudioBookId.size / (1024 ** 2)) }}{{ $t('time.mb') }} <span></span>
                            </div>
                            <div class="detail__year detail-value">
                                {{ new Date(getAudioBookId.writtenDate).getFullYear() }} <span></span>
                            </div>
                            <div class="detail__age detail-value">
                                {{ getAudioBookId.adult }}+
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listen-favorite" :class="{'placeholder-wave': isShowPlaceholder}">
                <button
                    :class="{'placeholder': isShowPlaceholder}"
                    class="listen__btn"
                    v-if="getIsPurchasedAudioBook || getAudioBookId.price === 0"
                    @click="$router.push('/listen/' + getAudioBookId.id)"
                    :disabled="isShowPlaceholder"
                >
                    <span>{{ $t('listen') }}</span>
                </button>
                <button
                    class="listen__btn"
                    :disabled="isShowPlaceholder"
                    :class="{'placeholder': isShowPlaceholder}"
                    v-else @click="buyAudioBook"
                >
                    <span> {{ $t('buy') }} </span>
                </button>
                <button
                    class="favorite__btn"
                    v-if="getFavoriteAudioBookCurrentUser.length === 0"
                    @click="addToFavorite"
                    :class="{'placeholder': isShowPlaceholder}"
                    :disabled="isShowPlaceholder"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M14.5 11.4H9.5C9.09 11.4 8.75 11.06 8.75 10.65C8.75 10.24 9.09 9.90002 9.5 9.90002H14.5C14.91 9.90002 15.25 10.24 15.25 10.65C15.25 11.06 14.91 11.4 14.5 11.4Z"
                            fill="white"/>
                        <path
                            d="M12 13.96C11.59 13.96 11.25 13.62 11.25 13.21V8.21002C11.25 7.80002 11.59 7.46002 12 7.46002C12.41 7.46002 12.75 7.80002 12.75 8.21002V13.21C12.75 13.62 12.41 13.96 12 13.96Z"
                            fill="white"/>
                        <path
                            d="M19.0703 22.75C18.5603 22.75 18.0003 22.6 17.4603 22.29L12.5803 19.58C12.2903 19.42 11.7203 19.42 11.4303 19.58L6.55031 22.29C5.56031 22.84 4.55031 22.9 3.78031 22.44C3.01031 21.99 2.57031 21.08 2.57031 19.95V5.86C2.57031 3.32 4.64031 1.25 7.18031 1.25H16.8303C19.3703 1.25 21.4403 3.32 21.4403 5.86V19.95C21.4403 21.08 21.0003 21.99 20.2303 22.44C19.8803 22.65 19.4803 22.75 19.0703 22.75ZM12.0003 17.96C12.4703 17.96 12.9303 18.06 13.3003 18.27L18.1803 20.98C18.6903 21.27 19.1603 21.33 19.4603 21.15C19.7603 20.97 19.9303 20.54 19.9303 19.95V5.86C19.9303 4.15 18.5303 2.75 16.8203 2.75H7.18031C5.47031 2.75 4.07031 4.15 4.07031 5.86V19.95C4.07031 20.54 4.24031 20.98 4.54031 21.15C4.84031 21.32 5.31031 21.27 5.82031 20.98L10.7003 18.27C11.0703 18.06 11.5303 17.96 12.0003 17.96Z"
                            fill="white"/>
                    </svg>
                </button>
                <button
                    class="favorite__btn"
                    v-else @click="deleteFromFavorite"
                    :class="{'placeholder': isShowPlaceholder}"
                    :disabled="isShowPlaceholder"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M16.8203 1.91003H7.18031C5.06031 1.91003 3.32031 3.65003 3.32031 5.77003V19.86C3.32031 21.66 4.61031 22.42 6.19031 21.55L11.0703 18.84C11.5903 18.55 12.4303 18.55 12.9403 18.84L17.8203 21.55C19.4003 22.43 20.6903 21.67 20.6903 19.86V5.77003C20.6803 3.65003 18.9503 1.91003 16.8203 1.91003ZM15.6203 9.03003L11.6203 13.03C11.4703 13.18 11.2803 13.25 11.0903 13.25C10.9003 13.25 10.7103 13.18 10.5603 13.03L9.06031 11.53C8.77031 11.24 8.77031 10.76 9.06031 10.47C9.35031 10.18 9.83031 10.18 10.1203 10.47L11.0903 11.44L14.5603 7.97003C14.8503 7.68003 15.3303 7.68003 15.6203 7.97003C15.9103 8.26003 15.9103 8.74003 15.6203 9.03003Z"
                            fill="white"/>
                    </svg>
                </button>
            </div>
            <div class="book-rate" :class="{'placeholder-wave': isShowPlaceholder}" v-show="this.getUserVoteAudioBook === null">
                <div class="book-rate__body" :class="{'placeholder': isShowPlaceholder}">
                    <div class="book-rate__text">
                        {{ $t('rateTheBook') }}
                    </div>
                    <div class="book-rate__stars">
                        <div class="book-rate__star" v-for="n in 5" :key="n" @click="vote.rating = n">
                            <svg v-if="n >= vote.rating + 1" width="24" height="24" viewBox="0 0 24 24" fill=""
                                 data-bs-toggle="modal"
                                 data-bs-target="#modal-rate">
                                <path
                                    d="M4.92366 21.6791C4.4963 21.258 4.03746 20.3987 4.42833 18.7341L5.17518 15.4687C5.25063 15.15 5.06976 14.5927 4.8278 14.3823L2.32947 12.159C0.834752 10.8264 0.918793 9.74392 1.09168 9.21231C1.26457 8.6807 1.85888 7.76412 3.85173 7.59396L6.87643 7.34557C7.18734 7.31501 7.63068 7.0218 7.77715 6.74495L9.32491 3.73541C10.0987 2.22356 11.1154 2.00399 11.6528 2.00005C12.1902 1.99611 13.203 2.22204 13.9779 3.74376L15.3982 6.5053C15.5274 6.75892 15.9396 7.0529 16.2158 7.10037L19.9678 7.68104C21.688 7.94422 22.225 8.85245 22.3843 9.36042C22.5436 9.86838 22.6361 10.9143 21.3934 12.1325L18.6833 14.7829C18.4796 14.9753 18.3206 15.4644 18.358 15.7399L18.8048 18.8197C19.0434 20.5009 18.3561 21.2696 17.9199 21.584C17.4838 21.8983 16.5251 22.3013 15.0203 21.5204L12.0107 19.9726C11.7338 19.8261 11.1966 19.8442 10.929 20.0017L8.31697 21.5553C6.59903 22.572 5.58493 22.1764 5.12991 21.8403C5.06601 21.8054 4.99488 21.7493 4.92366 21.6791ZM5.90201 13.3349C6.51456 13.9386 6.82622 14.9758 6.63424 15.7974L5.88739 19.0628C5.66796 19.9978 5.813 20.4917 6.01202 20.6317C6.1969 20.7717 6.72005 20.7538 7.54388 20.274L10.1559 18.7204C10.867 18.298 11.9557 18.2617 12.6869 18.6453L15.6964 20.1931C16.2714 20.4929 16.7739 20.5529 17.0412 20.3671C17.3086 20.1812 17.4039 19.6856 17.3143 19.0357L16.8675 15.9559C16.7559 15.2001 17.0883 14.236 17.6289 13.7017L20.339 11.0513C20.8164 10.5811 21.0534 10.1127 20.9521 9.80228C20.8508 9.49189 20.3965 9.25477 19.731 9.1465L15.979 8.56584C15.2357 8.45107 14.404 7.85614 14.0525 7.1728L12.6321 4.41126C12.3309 3.83362 11.9678 3.48979 11.6425 3.49217C11.3173 3.49455 10.952 3.83661 10.6522 4.41158L9.1044 7.42114C8.72796 8.15931 7.8202 8.76701 7.0004 8.82959L3.97574 9.07802C3.01462 9.15577 2.58541 9.44885 2.51636 9.67564C2.44019 9.8954 2.62067 10.3962 3.33242 11.0274L5.83069 13.2506C5.85217 13.2858 5.88064 13.3139 5.90201 13.3349Z"
                                    fill="#4F4F4F"/>
                            </svg>
                            <svg v-else width="24" height="24" viewBox="0 0 13 13" fill="none" data-bs-toggle="modal"
                                 data-bs-target="#modal-rate">
                                <path
                                    d="M7.23584 1.22045L8.32146 3.40673C8.4695 3.70486 8.86427 4.00299 9.19324 4.05268L11.1589 4.38393C12.4172 4.59924 12.7133 5.51019 11.8086 6.42114L10.2789 7.96975C10.0239 8.22647 9.87587 8.73164 9.95811 9.09602L10.394 11.009C10.7394 12.5162 9.94166 13.1042 8.61754 12.3175L6.77527 11.216C6.43807 11.0173 5.89526 11.0173 5.55806 11.216L3.70757 12.3092C2.38345 13.0959 1.58568 12.5079 1.9311 11.0007L2.367 9.08773C2.44924 8.73164 2.3012 8.22647 2.04625 7.96147L0.524733 6.42942C-0.37995 5.51847 -0.0838721 4.59924 1.17446 4.39221L3.14009 4.06096C3.46907 4.00299 3.86384 3.71314 4.01188 3.41501L5.0975 1.22874C5.68143 0.0362225 6.65191 0.0362224 7.23584 1.22045Z"
                                    fill="#FFCF25"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="modal-rate" tabindex="-1" data-bs-backdrop="static"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('addComment') }}</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                        @click="vote.rating = 0" ref="close-btn"></button>
                            </div>
                            <div class="modal-body">
                                <textarea v-model="vote.comment" class="form-control" id="message-text"
                                          placeholder="....." rows="4"/>
                            </div>
                            <div class="modal-footer">
                                <button @click="sendVote" type="button" class="btn btn-primary">{{
                                        $t('send')
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="book-categories" :class="{'placeholder-wave': isShowPlaceholder}">
                <div
                    class="book-category"
                    :class="{'placeholder': isShowPlaceholder}"
                    v-for="category in getAudioBookId.categories"
                    :key="category.id"
                >
                    <span>#{{ category.name }}</span>
                </div>
            </div>
            <div class="book-description" :class="{'placeholder-wave': isShowPlaceholder}">
                <div class="book-description__text" :class="{'placeholder': isShowPlaceholder}">
                    <span>{{ description }}</span>
                </div>
                <button @click="showAll" class="book-description__open">{{ $t('showAll') }}</button>
            </div>
            <hr class="line">
            <div class="book-copyright" :class="{'placeholder-wave': isShowPlaceholder}">
                <div class="book-copyright__right">
                    <div class="book-copyright__owner copyright-item" :class="{'placeholder': isShowPlaceholder}">
                        <div class="owner__label copyright-label">{{ $t('copyrightHolder') }}:</div>
                        <div class="owner__value copyright-value">{{ getAudioBookId.copyright }}</div>
                    </div>
                    <div class="book-copyright__owner copyright-item" :class="{'placeholder': isShowPlaceholder}">
                        <div class="owner__label copyright-label">{{ $t('language.language') }}:</div>
                        <div class="owner__value copyright-value">Русский</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="similar-books">
            <div class="similar-books__title">
                {{ $t('similarBooks') }}
            </div>
            <div class="similar-books__body">
                <div class="similar-books__book similar-book" v-for="similarAudioBook in getSimilarAudioBooks"
                     :key="similarAudioBook.id" @click="pushTo(similarAudioBook.id)">
                    <div class="similar-book__img">
                        <img v-if="similarAudioBook.image" :src="localhost + similarAudioBook.image.contentUrl"
                             alt="book">
                        <img v-else :src="similarAudioBook.coverSourceUrl" alt="book" @error="setDefaultImg">
                        <div class="similar-book__icons">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="16" height="16" rx="8" fill="white"/>
                                <path
                                    d="M4.14588 10.7708C3.97505 10.7708 3.83338 10.6291 3.83338 10.4583V8.08329C3.81255 6.95413 4.23338 5.88746 5.01671 5.08746C5.80005 4.29163 6.85005 3.85413 7.97921 3.85413C10.2875 3.85413 12.1667 5.73329 12.1667 8.04163V10.4166C12.1667 10.5875 12.025 10.7291 11.8542 10.7291C11.6834 10.7291 11.5417 10.5875 11.5417 10.4166V8.04163C11.5417 6.07913 9.94588 4.47913 7.97921 4.47913C7.01671 4.47913 6.12505 4.84996 5.46255 5.52496C4.79588 6.20413 4.44171 7.10829 4.45838 8.07496V10.4541C4.45838 10.6291 4.32088 10.7708 4.14588 10.7708Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M5.47504 8.1875H5.42087C4.54587 8.1875 3.83337 8.9 3.83337 9.775V10.5583C3.83337 11.4333 4.54587 12.1458 5.42087 12.1458H5.47504C6.35004 12.1458 7.06254 11.4333 7.06254 10.5583V9.775C7.06254 8.9 6.35004 8.1875 5.47504 8.1875Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M10.5792 8.1875H10.525C9.65 8.1875 8.9375 8.9 8.9375 9.775V10.5583C8.9375 11.4333 9.65 12.1458 10.525 12.1458H10.5792C11.4542 12.1458 12.1667 11.4333 12.1667 10.5583V9.775C12.1667 8.9 11.4542 8.1875 10.5792 8.1875Z"
                                    fill="#0481F4"/>
                            </svg>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="16" height="16" rx="8" fill="white"/>
                                <path
                                    d="M7.68754 5.49999V11.2958C7.68754 11.5917 7.38754 11.7958 7.11671 11.6833C6.34171 11.3583 5.37921 11.075 4.69587 10.9833L4.56671 10.9667C4.16254 10.9167 3.83337 10.5458 3.83337 10.1417V5.18749C3.83337 4.68749 4.23754 4.28333 4.73754 4.28333H4.76671C5.55004 4.34999 6.70421 4.71666 7.46671 5.12916C7.60421 5.20416 7.68754 5.34166 7.68754 5.49999Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M11.2625 4.28333H11.2375C11.1375 4.29166 11.0292 4.30416 10.9167 4.32499C10.4042 4.40833 9.79583 4.58333 9.25 4.79999C8.99167 4.90416 8.75 5.01666 8.5375 5.12916C8.4 5.20416 8.3125 5.34583 8.3125 5.49999V11.2958C8.3125 11.5917 8.6125 11.7958 8.88333 11.6833C9.65833 11.3583 10.6208 11.075 11.3042 10.9833L11.4333 10.9667C11.8375 10.9167 12.1667 10.5458 12.1667 10.1417V5.18749C12.1667 4.68749 11.7625 4.28333 11.2625 4.28333ZM11.0292 8.72083C11.0292 8.88333 10.9125 8.95416 10.7708 8.87499L10.3292 8.62916C10.2833 8.60416 10.2083 8.60416 10.1583 8.62916L9.71667 8.87499C9.575 8.95416 9.45833 8.88333 9.45833 8.72083V7.44166C9.45833 7.24999 9.61667 7.09166 9.80833 7.09166H10.6833C10.875 7.09166 11.0333 7.24999 11.0333 7.44166V8.72083H11.0292Z"
                                    fill="#0481F4"/>
                            </svg>
                        </div>
                    </div>
                    <div class="similar-book__about">
                        <div class="similar-book__name">
                            {{ similarAudioBook.name }}
                        </div>
                        <div class="similar-book__author">
                            {{ similarAudioBook.author ? similarAudioBook.author : '-' }}
                        </div>
                        <div class="similar-book__stars">
                            <div class="similar-book__star" v-for="n in 5" :key="n"
                                 :class="{book__star_filled: Math.floor(similarAudioBook.votesTotalCount / similarAudioBook.votesTotalUser) >= n}">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.92688 1.66536L7.74109 3.30506C7.85212 3.52866 8.1482 3.75226 8.39493 3.78952L9.86915 4.03796C10.8129 4.19945 11.035 4.88266 10.3565 5.56587L9.20915 6.72733C9.01793 6.91987 8.9069 7.29874 8.96858 7.57203L9.2955 9.00677C9.55457 10.1372 8.95625 10.5782 7.96315 9.98811L6.58145 9.16205C6.32855 9.01298 5.92145 9.01298 5.66855 9.16205L4.28068 9.9819C3.28758 10.5719 2.68926 10.131 2.94833 9.00056L3.27525 7.56582C3.33693 7.29874 3.2259 6.91987 3.03468 6.72112L1.89355 5.57208C1.21504 4.88887 1.4371 4.19945 2.38085 4.04417L3.85507 3.79573C4.1018 3.75226 4.39788 3.53487 4.50891 3.31127L5.32312 1.67157C5.76107 0.777182 6.48893 0.777182 6.92688 1.66536Z"
                                        fill="#C6C6C6"/>
                                </svg>
                            </div>
                            <div class="similar-book__star-value">
                                {{
                                    similarAudioBook.votesTotalCount > 0 ? (similarAudioBook.votesTotalCount / similarAudioBook.votesTotalUser).toFixed(1) : ''
                                }}
                            </div>
                        </div>
                        <div class="similar-book__categories">
                            <div class="similar-book__category__item"
                                 v-for="(category, index) in similarAudioBook.categories" :key="index">
                                <div class="similar-book__category" v-if="index === 0">
                                    #{{ category.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="book-feedback" v-if="votes !== null">
            <div class="book-feedback__head">
                <div class="book-feedback__title">{{ $t('reviews') }}</div>
                <div class="book-feedback__count">
                    {{ $t('total') }}: {{ getCountAudioBookComments }}
                </div>
            </div>
            <div class="feedback" v-for="(feedback, index) in votes" :key="index">
                <div class="feedback__info">
                    <div class="feedback-avatar">
                        <div class="feedback-avatar__img">
                            <img v-if="feedback.user.image" :src="localhost + feedback.user.image.contentUrl" alt="">
                            <svg v-else-if="feedback.user.gender === 0" width="38" height="40" viewBox="0 0 40 45"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.7477 26.3793V26.4498C14.7286 27.9187 13.4905 29.0234 11.0333 29.764C13.9949 31.9119 16.9565 33.63 19.9157 33.63C22.8749 33.63 25.8362 31.9098 28.7978 29.7623C26.3573 29.0214 25.1194 27.9155 25.0841 26.4445C25.0841 26.1822 25.0862 25.8099 25.0877 25.1107C25.0877 24.9998 25.0877 24.886 25.0877 24.7694C25.2639 24.7945 25.2498 24.7786 25.0877 24.7342C25.0926 22.8948 25.1003 20.4211 25.1113 17.9076C27.4259 14.9153 26.5755 11.4068 25.8517 11.4946C24.9618 11.6004 17.2576 4.31016 15.78 3.93714C14.3024 3.56411 10.5488 4.76957 9.90256 8.13279C9.25628 11.496 8.98586 19.9712 11.4366 23.3549C12.1338 24.3186 13.2318 24.6947 14.7307 24.4832C14.7328 25.1883 14.7371 25.5818 14.7473 26.379L14.7477 26.3793Z"
                                    fill="url(#paint0_linear_2_5379)"/>
                                <path opacity="0.228"
                                      d="M14.7461 24.4913C16.8028 24.2832 18.7881 23.6232 20.5601 22.5585C20.5601 22.5585 18.115 25.799 14.7461 26.4245L14.7461 24.4913Z"
                                      fill="#DB844C"/>
                                <path
                                    d="M36.8521 34.1986C38.3841 37.3107 39.2659 44.4888 39.2659 44.4888H0.642578C0.642578 44.4888 1.52402 37.31 3.05631 34.1986C4.58861 31.0871 13.5324 28.1998 13.5324 28.1998C15.2565 31.7369 24.7179 31.7365 26.3697 28.1998C26.3697 28.1998 35.3184 31.086 36.8521 34.1986Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M27.1239 17.3084C28.2366 14.639 32.6946 9.20335 28.8515 5.82039C27.56 -0.461814 20.4218 0.233819 15.6574 1.68326C12.4553 2.65778 10.0373 4.58284 9.52047 3.13305C6.29052 5.82039 7.90814 8.44884 10.0373 9.20335C11.9702 9.88806 15.2382 10.571 20.7952 9.92825C21.7877 9.81331 21.58 12.8451 22.1103 13.172C22.906 13.6638 23.5237 10.5717 25.7065 11.5C27.8893 12.4284 26.5936 16.7337 24.2518 16.7337C23.4444 16.7337 23.0407 18.9479 25.2207 19.995C26.8009 20.7717 26.4011 19.0427 27.1239 17.3084Z"
                                    fill="#192734"/>
                                <defs>
                                    <linearGradient id="paint0_linear_2_5379" x1="9.45703" y1="3.87195" x2="9.45703"
                                                    y2="33.63" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFE2CF"/>
                                        <stop offset="1" stop-color="#FFE2CF"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <svg v-else width="46" height="47" viewBox="0 0 46 47" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.7477 28.5788V28.6493C14.7287 30.1182 13.4905 31.2229 11.0333 31.9636C13.9949 34.1115 16.9566 35.8296 19.9158 35.8296C22.875 35.8296 25.8363 34.1094 28.7979 31.9618C26.3574 31.2209 25.1195 30.115 25.0842 28.644C25.0842 28.3817 25.0864 28.0094 25.0878 27.3102C25.0878 27.1993 25.0878 27.0855 25.0878 26.9689C25.2641 26.994 25.2499 26.9781 25.0878 26.9337C25.0927 25.0943 25.1005 22.6206 25.1114 20.107C27.4261 17.1147 26.5756 13.6062 25.8518 13.694C24.9619 13.7998 17.2577 6.50951 15.78 6.13648C14.3024 5.76345 10.5488 6.96892 9.90256 10.3322C9.25628 13.6954 8.98585 22.1707 11.4366 25.5544C12.1338 26.5181 13.2318 26.8942 14.7308 26.6826C14.7329 27.3878 14.7371 27.7813 14.7473 28.5785L14.7477 28.5788Z"
                                    fill="url(#paint0_linear_2_5462)"/>
                                <path opacity="0.228"
                                      d="M14.7461 26.6908C16.8028 26.4827 18.7882 25.8227 20.5601 24.7579C20.5601 24.7579 18.115 27.9985 14.7461 28.624L14.7461 26.6908Z"
                                      fill="#DB844C"/>
                                <path
                                    d="M36.8524 36.3979C38.3843 39.5102 39.2662 46.6883 39.2662 46.6883H0.642578C0.642578 46.6883 1.52402 39.5094 3.05633 36.3979C4.58864 33.2864 13.5325 30.3992 13.5325 30.3992C15.2566 33.9362 24.7181 33.9359 26.3699 30.3992C26.3699 30.3992 35.3187 33.2854 36.8524 36.3979Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M30.3126 10.9878C30.3126 10.9878 29.7326 8.41919 29.4163 7.40271C27.6203 1.63082 20.4534 -0.215694 15.972 0.680589C12.3869 1.57686 10.5943 2.47315 8.35353 6.50643C5.66461 11.436 9.79097 25.3284 9.5204 14.573C9.24982 3.81758 13.3896 14.7778 20.9016 11.436C21.8941 11.3211 21.58 15.0444 22.1103 15.3712C22.906 15.863 23.5238 12.7709 25.7066 13.6993C27.8894 14.6276 26.5937 18.933 24.2518 18.933C23.4444 18.933 23.0407 21.1471 25.2207 22.1943C26.801 22.971 29.5898 12.7221 30.3126 10.9878Z"
                                    fill="#192734"/>
                                <path
                                    d="M31.6584 19.0545C31.2998 17.6204 30.6126 13.0792 30.3138 10.9879H29.4176C29.0591 10.9879 28.9695 11.2867 28.9695 11.4361V12.3323L27.625 16.8138L25.3843 19.9508L24.9362 20.8471L24.488 21.2952L23.5918 19.9508C23.5918 21.7433 24.1064 29.0132 24.4881 34.7395C24.9362 41.4616 23.1437 47.885 24.0399 49.0801C26.2807 48.3332 34.885 43.1646 35.2435 42.8061C35.6916 42.3579 39.7249 41.4616 40.1731 41.4616C40.5316 41.4616 43.6088 38.7728 45.1027 37.4283C43.9076 36.9802 40.8901 35.4565 38.3805 32.9469C33.4509 26.2248 32.1065 20.8471 31.6584 19.0545Z"
                                    fill="#192734"/>
                                <defs>
                                    <linearGradient id="paint0_linear_2_5462" x1="9.45703" y1="6.07129" x2="9.45703"
                                                    y2="35.8296" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFE2CF"/>
                                        <stop offset="1" stop-color="#FFE2CF"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="feedback__about">
                        <div class="feedback__name">{{ getFullName(feedback.user) }}</div>
                        <div class="feedback__date">{{ feedback.createdAt.slice(0, 10) }}</div>
                        <div class="feedback__stars">
                            <div class="feedback-star" v-for="n in 5" :key="n"
                                 :class="{book__star_filled: feedback.rating >= n}">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.92688 1.66536L7.74109 3.30506C7.85212 3.52866 8.1482 3.75226 8.39493 3.78952L9.86915 4.03796C10.8129 4.19945 11.035 4.88266 10.3565 5.56587L9.20915 6.72733C9.01793 6.91987 8.9069 7.29874 8.96858 7.57203L9.2955 9.00677C9.55457 10.1372 8.95625 10.5782 7.96315 9.98811L6.58145 9.16205C6.32855 9.01298 5.92145 9.01298 5.66855 9.16205L4.28068 9.9819C3.28758 10.5719 2.68926 10.131 2.94833 9.00056L3.27525 7.56582C3.33693 7.29874 3.2259 6.91987 3.03468 6.72112L1.89355 5.57208C1.21504 4.88887 1.4371 4.19945 2.38085 4.04417L3.85507 3.79573C4.1018 3.75226 4.39788 3.53487 4.50891 3.31127L5.32312 1.67157C5.76107 0.777182 6.48893 0.777182 6.92688 1.66536Z"
                                        fill="#C6C6C6"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="feedback__text">{{ feedback.comment }}</div>
            </div>
            <button class="feedback__show-more" @click="showMoreFeedback"
                    v-if="getCountAudioBookComments > 10 && getCountAudioBookComments !== votes.length">
                {{ $t('showMore') }}
            </button>
        </div>
        <div class="book-payMe">
            <form method="POST" action="https://checkout.paycom.uz" class="d-none">
                <!-- Use https://test.checkout.paycom.uz URL for testing -->

                <!-- Payme Cashbox ID  -->
                <input type="hidden" name="merchant" :value="transaction.login"/>

                <!-- Cost with tiyin -->
                <input type="hidden" name="amount" :value="transaction.amount"/>

                <!-- Payment data -->
                <input type="hidden" name="account[transactionId]" :value="transaction.id"/>

                <!-- === OPTIONAL DATA === -->
                <!-- Language. By default 'ru'. Available options: ru|uz|en -->
                <input type="hidden" name="lang" :value="transaction.lang"/>

                <!--
                    Currency. By default '860'. Available options: 643|840|860|978
                    643 - RUB
                    840 - USD
                    860 - UZS
                    978 - EUR
                -->
                <input type="hidden" name="currency" value="860"/>

                <!--
                    URL to redirecting after payment. By default, payme redirects to URL of Referer header value.
                    URL may contain that will be replaced by Payme:
                    :transaction - id of transaction. Can be null if payme couldn't create transaction
                    :account.{field} - field of account object
                    For example: https://your-service.com/payme/:transaction
                -->
                <!--
                    <input type="hidden" name="callback" value="{{ REDIRECT_URL }}"/>
                -->

                <!-- Redirect timeout after successful payment in milliseconds  -->
                <input type="hidden" name="callback_timeout" value="15"/>
                <input type="hidden" name="detail" :value="transaction.detail"/>

                <!--
                    Payment description. You can also specify descriptions in few
                    languages by using description object like name="description[{lang}]".
                    As {lang} you can use ru, en or uz
                -->
                <input type="hidden" name="description" :value="transaction.PAYME_DESCRIPTION"/>

                <button type="submit" ref="transactionSubmit">Pay with <b>Payme</b></button>
            </form>
        </div>
        <notify-success :is-show-success="isShowSuccess" :success-message-text="notifyMessage"/>
        <FooterMenu class="fixed-bottom" @clickFooterHome="clearSessionStorage"/>
    </div>
</template>

<script>
import FooterMenu from "../../components/footer/footer.vue";
import {mapActions, mapGetters} from "vuex";
import NotifySuccess from "../library/notify-success.vue";

export default {
    name: "audio-book",
    components: {NotifySuccess, FooterMenu},
    computed: {
        ...mapGetters([
            'getAudioBookId',
            'getSimilarAudioBooks',
            'getUserVoteAudioBook',
            'getAudioBookVotes',
            'getCountAudioBookComments',
            'getIsPurchasedAudioBook',
            'getOrderTransaction',
            'getFavoriteAudioBookCurrentUser'
        ]),
        getAudioBookDurationTimeInHourAndMin() {
            const seconds = this.getAudioBookId.duration;
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);

            return `${hours}${this.$t('time.h')} ${minutes}${this.$t('time.m')}`
        }
    },
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
            countOfVotes: 0,
            transaction: {
                url: process.env.VUE_APP_PAYME_URL,
                login: process.env.VUE_APP_PAYME_LOGIN,
                amount: null,
                id: null,
                lang: localStorage.getItem('language') ?? 'uz',
                PAYME_DESCRIPTION: 'Audio kitobni sotib olish',
                detail: null
            },
            detailDto: {
                items: [
                    {
                        count: 1,
                        title: 'Audio kitob sotib olish',
                        price: null,
                        code: '10315002001000000',
                        package_code: '195815',
                        vat_percent: 0
                    }
                ],
            },
            vote: {
                rating: 0,
                comment: '',
                audioBook: '/api/audio_books/' + this.$route.params.id
            },
            votes: [],
            votePage: 1,
            description: '',
            notifyMessage: '',
            isShowSuccess: true,
            isShowPlaceholder: false
        }
    },
    methods: {
        ...mapActions([
            'fetchAudioBookId',
            'fetchSimilarAudioBooks',
            'pushVote',
            'fetchUserVoteByAudioBook',
            'fetchVotesByAudioBookId',
            'fetchCountCommentAudioBook',
            'checkAudioBookPurchasedOrNot',
            'pushOrderTransaction',
            'fetchFavoriteAudioBookCurrentUser',
            'pushFavoriteAudioBook',
            'deleteFavorite'
        ]),
        sendAudioBookDataToAndroid() {
            let imageUrl = ''

            if (this.getAudioBookId.image) {
                imageUrl = this.localhost + this.getAudioBookId.image.contentUrl
            } else {
                imageUrl = this.getAudioBookId.coverSourceUrl
            }

            try {
                window.pushBookDataToAndroid(imageUrl, this.getAudioBookId.name)
            } catch {
                console.log('share book to instagram')
            }
        },
        share1booksOtherApp() {
            try {
                window.shareLink(process.env.VUE_APP_FRONT_URL + this.$route.path)
            } catch {
                console.log('share book to other apps')
            }
        },
        buyAudioBook() {
            this.pushOrderTransaction({type: 2, typeId: parseInt(this.$route.params.id)})
                .then(() => {
                    this.transaction.amount = parseInt(this.getOrderTransaction.amount)
                    this.transaction.id = this.getOrderTransaction.id
                    this.transaction.detail = btoa(JSON.stringify(this.detailDto))
                })
                .then(() => {
                    this.$refs.transactionSubmit.click()
                })
        },
        changeHeightImg(url) {
            return url.replace('cover', 'cover_200')
        },
        clearSessionStorage() {
            if (sessionStorage.getItem('isSearched') !== undefined) {
                sessionStorage.removeItem('isSearched')
            }

            if (sessionStorage.getItem('foundedBooks') !== undefined) {
                sessionStorage.removeItem('foundedBooks')
            }

            if (sessionStorage.getItem('searchedBookText') !== undefined) {
                sessionStorage.removeItem('searchedBookText')
            }
        },
        getFullName(user) {
            let fullName = ''

            if (user.givenName !== null) {
                fullName += user.givenName + ' '
            }

            if (user.familyName !== null) {
                fullName += user.familyName
            }

            if (fullName.length > 0) {
                return fullName
            }

            return 'Anonymous'
        },
        pushTo(id) {
            this.$router.push('/audio-book/' + id);
            this.isShowPlaceholder = true
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        sendVote() {
            this.pushVote(this.vote).then(() => {
                this.fetchUserVoteByAudioBook(this.$route.params.id)
                    .then(() => {
                        this.fetchAudioBookId(this.$route.params.id)
                    })

                let closeBtn = document.querySelector('.btn-close')
                closeBtn.click()
            })
        },
        setDefaultImg(event) {
            event.target.src = this.localhost + '/book.png'
        },
        showAll() {
            this.description = this.getAudioBookId.description
            let showMoreBtn = document.querySelector('.book-description__open')
            showMoreBtn.classList.remove('open')
        },
        showMore(string) {
            if (string.length > 200) {
                let showMoreBtn = document.querySelector('.book-description__open')
                showMoreBtn.classList.add('open')

                return string.slice(0, 200)
            }
        },
        showMoreFeedback() {
            this.votePage++
            this.fetchVotesByAudioBookId({id: this.$route.params.id, page: this.votePage}).then(() => {
                this.votes.push(...this.getAudioBookVotes)
            })
        },
        async addToFavorite() {
            await this.pushFavoriteAudioBook({audioBook: this.getAudioBookId['@id']})
            await this.fetchFavoriteAudioBookCurrentUser(this.$route.params.id)
            this.notifyMessage = this.$t('bookAddedToFavorite')
            this.isShowSuccess = !this.isShowSuccess
        },
        async deleteFromFavorite() {
            await this.deleteFavorite(this.getFavoriteAudioBookCurrentUser[0].id)
            await this.fetchFavoriteAudioBookCurrentUser(this.$route.params.id)
            this.notifyMessage = this.$t('bookRemovedFromFavorite')
            this.isShowSuccess = !this.isShowSuccess
        },
        async getData() {
            this.isShowPlaceholder = true
            await this.fetchAudioBookId(this.$route.params.id)
            this.description = this.showMore(this.getAudioBookId.description)

            if (this.getAudioBookId.price) {
                this.checkAudioBookPurchasedOrNot(this.$route.params.id)
            }

            this.isShowPlaceholder = false

            await this.fetchFavoriteAudioBookCurrentUser(this.$route.params.id)
            await this.fetchSimilarAudioBooks(this.$route.params.id)
            await this.fetchUserVoteByAudioBook(this.$route.params.id).catch(() => {})
            await this.fetchVotesByAudioBookId({id: this.$route.params.id, page: 1})
            this.votes.push(...this.getAudioBookVotes)
            await this.fetchCountCommentAudioBook(this.$route.params.id)
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === undefined || to.name !== 'Home') {
            if (sessionStorage.getItem('isSearched') !== undefined) {
                sessionStorage.removeItem('isSearched')
            }

            if (sessionStorage.getItem('foundedBooks') !== undefined) {
                sessionStorage.removeItem('foundedBooks')
            }
        }
        this.$refs["close-btn"].click()
        next()
    },
    mounted() {
        try {
            window.bookId(this.$route.params.id)
        } catch (e) {
            console.log(e)
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.getData()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },
    beforeDestroy() {
        this.$store.commit('clearAudioBookId')
    },
    watch: {
        '$route.params.id'() {
            this.getData()
            this.votes = []
            this.votePage = 1
        }
    },
}
</script>

<style scoped>
.wrapper {
    font-family: Montserrat, 'sans-serif';
    font-size: 14px;
}

.audio-book_container {
    margin: 0 -12px;
    overflow: hidden;
    background: var(--color-block);
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}

.placeholder {
    border-radius: 3px;
}

.audio-book-bg {
    min-height: 312px;
    position: relative;
    overflow: hidden;
}

.audio-book-bg__img img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: blur(10px);
}

.audio-book-bg:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-book-liner);
    z-index: 1;
}

.audio-book-bg__icons {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 68px 36px 0 36px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.audio-book-bg__goBack {
    flex: 1 1 auto;
}

.goBack_btn {
    border: none;
    background: transparent;
}

.audio-book__name {
    position: relative;
    z-index: 2;
    padding: 124px 10px 151px 10px;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.book {
    position: relative;
    z-index: 2;
    margin-top: -121px;
    padding: 0 10px 0 36px;
}

.book__body {
    display: flex;
    gap: 31px;
}

.book__img {
    flex-shrink: 0;
    width: 116px;
    height: 173px;
    position: relative;
}

.book__img.placeholder img {
    display: none;
}

.book__icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    z-index: 2;
    padding: 7px;
}

.book__icons svg {
    margin-right: 3px;
}

.book__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    z-index: 2;
}

.book__img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    z-index: 1;
    filter: drop-shadow(0px 0px 40px rgba(31, 52, 106, 0.2));
}

.book__img.placeholder:after {
    display: none;
}

.book__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
}

.label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.6;
}

.value::-webkit-scrollbar {
    display: none;
}

.value {
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    overflow: auto;
    position: relative;
}

.value.placeholder {
    width: 100%;
}

.book__stars {
    margin-top: 6px;
    display: flex;
    gap: 7px;
}

.book__stars.placeholder .star {
    display: none;
}

.book__star_filled svg path {
    fill: #FFCF25;
}

.star__value {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-textGray-to-gold);;
    display: flex;
    align-items: center;
}

.book__detail {
    flex: 1 1 100%;
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
}

.book__detail.placeholder {
    min-width: 100%;
}

.book__detail.placeholder .detail-value {
    visibility: hidden;
}

.detail-value {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-text-to-blue);;
}

.detail-value span {
    display: inline-block;
    width: 1px;
    height: 9px;
    background: #828282;
    margin: 0 5px;
}

.listen-favorite {
    margin-top: 34px;
    display: flex;
    gap: 16px;
    padding: 0 13px;
}

.listen__btn.placeholder span {
    display: none;
}

.listen__btn {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 0;
    background: #177EE6;
    border-radius: 12px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: none;
}

.favorite__btn {
    width: 60px;
    background: #177EE6;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.favorite__btn.placeholder svg {
    display: none;
}

.book-rate {
    margin-top: 21px;
    padding: 0 20px;
}

.book-rate__body {
    display: flex;
    justify-content: space-between;
}

.book-rate__body.placeholder  div {
    display: none;
}

.book-rate__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-text-to-blue);
    display: flex;
    align-items: center;
}

.book-rate__stars {
    display: flex;
    gap: 12px;
}

.modal-footer button {
    background: #177EE6;
}

.rate_filled svg {
    fill: #FFCF25;
}

.book-categories {
    margin-top: 22px;
    padding: 0 25px 23px 25px;
    display: inline-flex;
    gap: 8px;
    flex-wrap: wrap;
}

.book-category {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    min-height: 20px;
    background: #177EE6;
    border-radius: 10px;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
}

.book-category.placeholder span {
    display: none;
}

.book-description {
    padding: 0 35px;
    margin-bottom: 35px;
    position: relative;
}

.book-description.placeholder-wave .book-description__open {
    display: none;
}

.book-description__text {
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: var(--color-text);
}

.book-description__text.placeholder span {
    display: none;
}

.book-description__text.placeholder {
    min-height: 100px;
}

.book-description__open {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0481F4;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 35px;
    width: 100%;
    display: none;
    justify-content: flex-end;
    background: var(--show-more);
    border: none;
}

.book-description__open.open {
    display: flex;
}

.line {
    margin: 0 33px;
    opacity: 0.2;
    background: #97CAFB;
}

.book-copyright {
    margin-top: 11px;
    padding: 0 19px 24px 19px;
}

.book-copyright__right {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.copyright-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyright-item.placeholder .copyright-value {
    display: none;
}

.copyright-item.placeholder .copyright-label {
    display: none;
}

.copyright-label {
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text-to-blue);
}

.copyright-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0481F4;
    text-align: end;
}

.similar-books {
    margin-bottom: 17px;
}

.similar-book {
    min-width: 245px;
    border-radius: 12px;
}

.similar-books__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: var(--color-text);
    padding: 20px 0 16px 0;
}

.similar-books__body {
    display: flex;
    gap: 14px;
    overflow: auto;
    position: relative;
}

.similar-books__body::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
}

.similar-books__book {
    background: var(--color-block);
    display: flex;
    gap: 35px;
    padding: 18px 17px;
}

.similar-book__img {
    flex: 0 0 auto;
    max-width: 66px;
    max-height: 99px;
    width: 66px;
    height: 99px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    position: relative;
}

.similar-book__img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.similar-book__icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 4px;
}

.similar-book__icons svg {
    margin-right: 2px;
}

.similar-book__about {
    overflow: hidden;
}

.similar-book__name {
    display: -webkit-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.similar-book__name::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
}

.similar-book__author {
    font-size: 10px;
    line-height: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-text-to-blue);
}

.similar-book__stars {
    padding-top: 9px;
    display: flex;
    gap: 2px;
}

.similar-book__star-value {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--color-textGray-to-gold);;
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.similar-book__categories {
    display: inline-flex;
    gap: 5px;
    flex-wrap: wrap;
    align-self: flex-end;
}

.similar-book__category {
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #0481F4;
    border-radius: 10px;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
}

.book-feedback {
    background: var(--color-block);
    margin: 0 -12px;
    padding: 20px 19px 78px 20px;
    border-radius: 18px;
}

.book-feedback__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.book-feedback__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
}

.book-feedback__count {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #BDBDBD;
}

.feedback {
    margin-bottom: 26px;
}

.feedback__info {
    display: flex;
    gap: 17px;
}

.feedback__about {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.feedback-avatar__img {
    width: 50px;
    height: 50px;
    background: #97CAFB;
    border-radius: 71.048px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
}

.feedback-avatar__img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.feedback__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);
}

.feedback__date {
    font-size: 10px;
    line-height: 12px;
    color: var(--color-text-to-blue);
}

.feedback__stars {
    display: flex;
    gap: 2px;
}

.feedback__text {
    font-size: 10px;
    line-height: 12px;
    word-wrap: anywhere;
    display: flex;
    align-items: center;
    color: var(--color-text);
}

.feedback__show-more {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #0481F4;
    background: var(--show-more);
    border: none;
    display: inline-flex;
    width: 100%;
    justify-content: center;
}
</style>
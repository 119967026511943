<template>
    <div class="row" ref="scroll">
        <div class="col container-col">

            <div class="row header">
                <div class="col fixed-top">
                    <div class="row header-name-row">
                        <div class="col-2 header-block">
                            <go-back-button class="arrow-back"/>
                        </div>
                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{ $t('search.books') }}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>
            <div class="book__container">
                <div class="row d-flex justify-content-center" v-for="(item, index) in allBooks" :key="index">
                    <div class="col-11 center-block">
                    <div class="row book-row">
                        <div class="col-2">
                            <div class="book-row__img">
                                <img
                                    v-if="item.image"
                                    :src="localhost + item.image.contentUrl"
                                    alt="rasm" class="book-img"
                                >
                                <img
                                    v-else-if="item.coverSourceUrl"
                                    :src="changeHeightImg(item.coverSourceUrl)"
                                    @error="setDefaultImg"
                                    alt="rasm" class="book-img"
                                >
                                <div class="book-row__svg" v-if="item['@type'] === 'AudioBook'">
                                    <svg width="13" height="13" viewBox="0 0 16 16" fill="none" class="head-phone">
                                        <rect width="16" height="16" rx="8" fill="white"/>
                                        <path
                                            d="M4.14527 10.7709C3.97444 10.7709 3.83277 10.6292 3.83277 10.4584V8.08335C3.81194 6.95419 4.23277 5.88752 5.0161 5.08752C5.79944 4.29169 6.84944 3.85419 7.9786 3.85419C10.2869 3.85419 12.1661 5.73335 12.1661 8.04169V10.4167C12.1661 10.5875 12.0244 10.7292 11.8536 10.7292C11.6828 10.7292 11.5411 10.5875 11.5411 10.4167V8.04169C11.5411 6.07919 9.94527 4.47919 7.9786 4.47919C7.0161 4.47919 6.12444 4.85002 5.46194 5.52502C4.79527 6.20419 4.4411 7.10835 4.45777 8.07502V10.4542C4.45777 10.6292 4.32027 10.7709 4.14527 10.7709Z"
                                            fill="#0481F4"/>
                                        <path
                                            d="M5.47565 8.1875H5.42148C4.54648 8.1875 3.83398 8.9 3.83398 9.775V10.5583C3.83398 11.4333 4.54648 12.1458 5.42148 12.1458H5.47565C6.35065 12.1458 7.06315 11.4333 7.06315 10.5583V9.775C7.06315 8.9 6.35065 8.1875 5.47565 8.1875Z"
                                            fill="#0481F4"/>
                                        <path
                                            d="M10.5792 8.1875H10.525C9.65 8.1875 8.9375 8.9 8.9375 9.775V10.5583C8.9375 11.4333 9.65 12.1458 10.525 12.1458H10.5792C11.4542 12.1458 12.1667 11.4333 12.1667 10.5583V9.775C12.1667 8.9 11.4542 8.1875 10.5792 8.1875Z"
                                            fill="#0481F4"/>
                                    </svg>
                                </div>
                                <div class="book-row__svg" v-else>
                                    <svg width="13" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="16" height="16" rx="8" fill="white"/>
                                        <path d="M7.68815 5.49999V11.2958C7.68815 11.5917 7.38815 11.7958 7.11732 11.6833C6.34232 11.3583 5.37982 11.075 4.69648 10.9833L4.56732 10.9667C4.16315 10.9167 3.83398 10.5458 3.83398 10.1417V5.18749C3.83398 4.68749 4.23815 4.28333 4.73815 4.28333H4.76732C5.55065 4.34999 6.70482 4.71666 7.46732 5.12916C7.60482 5.20416 7.68815 5.34166 7.68815 5.49999Z" fill="#0481F4"/>
                                        <path d="M11.2625 4.28333H11.2375C11.1375 4.29166 11.0292 4.30416 10.9167 4.32499C10.4042 4.40833 9.79583 4.58333 9.25 4.79999C8.99167 4.90416 8.75 5.01666 8.5375 5.12916C8.4 5.20416 8.3125 5.34583 8.3125 5.49999V11.2958C8.3125 11.5917 8.6125 11.7958 8.88333 11.6833C9.65833 11.3583 10.6208 11.075 11.3042 10.9833L11.4333 10.9667C11.8375 10.9167 12.1667 10.5458 12.1667 10.1417V5.18749C12.1667 4.68749 11.7625 4.28333 11.2625 4.28333ZM11.0292 8.72083C11.0292 8.88333 10.9125 8.95416 10.7708 8.87499L10.3292 8.62916C10.2833 8.60416 10.2083 8.60416 10.1583 8.62916L9.71667 8.87499C9.575 8.95416 9.45833 8.88333 9.45833 8.72083V7.44166C9.45833 7.24999 9.61667 7.09166 9.80833 7.09166H10.6833C10.875 7.09166 11.0333 7.24999 11.0333 7.44166V8.72083H11.0292Z" fill="#0481F4"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="col ps-0" style="min-width: 140px" @click="pushRelationRoute(item)">
                            <div class="row">
                                <div class="col book-name">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col book-author">
                                    {{ item.author }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 star-row">
                                    <span class="download-count" v-if="item.votesTotalCount !== 0">
                                        <img src="../../components/images/star-gold.png" alt="" class="star">
                                        {{ (item.votesTotalCount / item.votesTotalUser).toFixed(1) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col book-price-row d-flex justify-content-end">
                            <span class="book-price">{{ item.price }} {{ $t('price.currency') }}</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <FooterMenu />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GoBackButton from "@/components/modals/GoBackButton.vue";
import FooterMenu from "@/components/footer/footer.vue";

export default {
    name: "category-books",
    components: {
        GoBackButton,
        FooterMenu,
    },
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
            page: 1,
            allBooks: []
        }
    },
    computed: {
        ...mapGetters(['getBooksByCategory'])
    },
    methods: {
        ...mapActions(['fetchBooksByCategory']),
        pushRelationRoute(item) {
            this.$router.push(item['@type'] === 'Book' ? '/book/' + item.id : '/audio-book/' + item.id)
        },
        onScroll() {
            if ((this.$refs.scroll.scrollHeight - window.scrollY) === document.querySelector('html').clientHeight) {
                if (this.getBooksByCategory.length) {
                    this.page++
                    this.fetchBooksByCategory({id: this.$route.params.id, page: this.page})
                        .then(() => {
                            this.allBooks = this.allBooks.concat(this.getBooksByCategory.sort((a, b) => b.viewsCount - a.viewsCount))
                        })
                }
            }
        },
        changeHeightImg(url) {
            return url.replace('cover', 'cover_100')
        },
        setDefaultImg(event) {
            event.target.src = this.localhost + '/book.png'
        },
    },
    beforeDestroy () {
        window.removeEventListener("scroll", this.onScroll, true)
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll, true)
        this.fetchBooksByCategory({id: this.$route.params.id, page: this.page})
            .then(() => {
                this.allBooks = this.allBooks.concat(this.getBooksByCategory.sort((a, b) => b.viewsCount - a.viewsCount))
            })
    }
}
</script>

<style scoped>
.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.header-block {
    border: 15px;
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.container-col {
    background-color: var(--color-container);
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

.book__container {
    padding-bottom: 80px;
}

.center-block {
    border-radius: 12px;
    background-color: var(--color-block);
    margin-bottom: 8px;
}

.header {
    margin-bottom: 160px;
}

.book-name {
    font-weight: 500;
    font-size: 14px;
    margin-top: 13px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-text);
}

.book-author {
    color: var(--color-lightGray-to-lightBlue);
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.star-row {
    width: 105px !important;
    padding-left: 4px;
    margin-left: 9px;
}

.star {
    padding-right: 6px;
}

.download-count {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #828282;
    font-size: 12px;
    line-height: 15px;
    font-style: normal;
    margin-top: 6px;
}

.book-price-row {
    display: inline;
}

.book-price {
    color: #0481F4;
    margin: 36px 0 0 0;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}

.col-2 {
    width: 71px !important;
}

.book-img {
    margin-top: 9px;
    width: 43px;
    height: 64px;
    border-radius: 5px;
}

.book-row {
    border-radius: 12px;
    background: var(--color-header);
    height: 86px;
    border: 1px solid var(--color-header);
}
.book-row__img {
    position: relative;
}
.book-row__svg {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding: 1px;
    height: 100%;
}
</style>
import axios from "../../../plugins/vuex/axios";
import urlPage from "../urlPage";
import commitModel from "./commitModel";

export default function (context, url, page, mutationName, firstOnly = false) {
    return new Promise((resolve, reject) => {
        if (url.match(/\?/)) {
            url += '&'
        } else if (page !== 0) {
            url += '?'
        }

        axios.get('/' + url + urlPage(page))
            .then(response => {
                commitModel(context, response, mutationName, firstOnly)
                resolve()
            })
            .catch(() => {
                reject()
            })
    })
}
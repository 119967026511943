import fetchModels from "@/mixin/store/api/fetchModels";
import fetchModel from "@/mixin/store/api/fetchModel";

export default {
    actions: {
        fetchBanners(context) {
            return fetchModels(context, 'banners', null, 'updateBanners')
        },
        fetchBannerAudioBook(context, id) {
            return fetchModel(context, 'banners/audio-book', id, 'updateBannerAudioBook')
        },
        fetchBannerBook(context, id) {
            return fetchModel(context, 'banners/book', id, 'updateBannerBook')
        },
    },
    mutations: {
        updateBanners(state, data) {
            state.banners = data
        },
        updateBannerAudioBook(state, data) {
            state.bannerAudioBook = data.audioBook
        },
        updateBannerBook(state, data) {
            state.bannerBook = data.book
        }
    },
    state: {
        banners: {
            models: [],
            totalItems: 0
        },
        bannerAudioBook: {},
        bannerBook: {}
    },
    getters: {
        getBanners(state) {
            return state.banners.models
        },
        getBannerAudioBook(state) {
            return state.bannerAudioBook
        },
        getBannerBook(state) {
            return state.bannerBook
        }
    }
}
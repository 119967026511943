import Vue from "vue";
import VueRouter from "vue-router";
import store from "./vuex/store";

Vue.use(VueRouter)

function pushPathToAndroid(path) {
    try {
        window.pushCurrentUrlToAndroid('https://1books.kadirov.org' + path)
    } catch (e) {
        console.log(e)
    }
}

const ifNotAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        pushPathToAndroid(to.path)
        next({path: '/'})
    } else {
        if (to.path.match(/\/login-/g) !== null) {
            localStorage.setItem('isLogin', 'true')
        }

        pushPathToAndroid(to.path)
        next()
    }
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        pushPathToAndroid(to.path)
        next()
    } else {
        pushPathToAndroid(to.path)
        next({path: '/login-first'})
    }
}

const routes = [
    {
        name: 'Home',
        path: '/',
        component: () => import('../pages/home/home'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/login-first',
        component: () => import('../pages/login/login-first'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/login-second',
        component: () => import('../pages/login/login-second'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/login-third',
        component: () => import('../pages/login/login-third'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/moment/:id',
        component: () => import('../pages/moment/moment'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'LoginFourth',
        path: '/login-fourth',
        component: () => import('../pages/login/login-fourth'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/password-recovery',
        component: () => import('../pages/login/password-recovery.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/login_fifth',
        component: () => import('../pages/login/login_fifth'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        name: 'LoginSixth',
        path: '/login_sixth',
        component: () => import('../pages/login/login_sixth'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/recommended-books',
        component: () => import('../pages/notification/recommendedBooks.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/listen/:id',
        component: () => import('../pages/home/listen'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/promotion-and-discounts',
        component: () => import('../pages/notification/promotionAndDiscounts.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categories',
        component: () => import('../pages/categories/categories'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'AudioBook',
        path: '/audio-book/:id',
        component: () => import('../pages/home/audio-book'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'Book',
        path: '/book/:id',
        component: () => import('../pages/home/book'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/read/:id',
        component: () => import('../pages/home/readBook.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/broadcast',
        component: () => import('../pages/live-stream/broadcast.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/live-audio',
        component: () => import('../pages/live-stream/live-audio.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/live-video',
        component: () => import('../pages/live-stream/live-video.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/setting',
        component: () => import('../pages/setting/setting'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'Profile',
        path: '/profile',
        component: () => import('../pages/setting/profile.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/profile-about',
        component: () => import('../pages/setting/profile-about'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/setting-language',
        component: () => import('../pages/setting/setting-language'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/devices',
        component: () => import('../pages/setting/setting-devices'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/security',
        component: () => import('../pages/setting/setting-security.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/notification',
        component: () => import('../pages/setting/setting-notification'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/buy',
        component: () => import('../pages/setting/setting-buy'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/theme',
        component: () => import('../pages/setting/setting-theme'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/subscriptions',
        component: () => import('../pages/setting/setting-ab-subs'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/about-app',
        component: () => import('../pages/setting/setting-ab-app'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'Library',
        path: '/library',
        component: () => import('../pages/library/library.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/my-shelves/:shelfId',
        component: () => import('../pages/library/my-shelves'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/saved',
        component: () => import('../pages/library/saved'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/currently-reading',
        component: () => import('../pages/library/currently-reading'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/off-line',
        component: () => import('../pages/library/off-line'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'SupportService',
        path: '/support-service',
        component: () => import('../pages/support-service/support-service'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'Search',
        path: '/search',
        component: () => import('../pages/search/search'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/my-feedback',
        component: () => import('../pages/library/my-feedback'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/my-quotes',
        component: () => import('../pages/library/my-quotes'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/finished-book',
        component: () => import('../pages/library/finished-book'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/top-readers',
        component: () => import('../pages/top-readers/top-readers'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/banner-books/:id',
        component: () => import('../pages/home/banner-books'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/banner-audio-books/:id',
        component: () => import('../pages/home/banner-audio-books'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/category-books/:id',
        component: () => import('../pages/home/category-books'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/reset-password-verification',
        component: () => import('../pages/setting/reset-password-verification'),
        beforeEnter: ifAuthenticated,
    }
]

export default new VueRouter({
    mode: 'history',
    routes
})

import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
        fetchDiscountedBooks(context) {
            return fetchModels(context, 'promotion_and_discounts', null, 'updateDiscounts')
        }
    },
    mutations: {
        updateDiscounts(state, data) {
            state.discountedBooks = data
        }
    },
    state: {
        discountedBooks: []
    },
    getters: {
        getDiscountedBooks(state) {
            return state.discountedBooks
        }
    }
}

import axios from "../../../plugins/vuex/axios";

export default function (context, url, id) {
    return new Promise((resolve, reject) => {
        axios.delete(url + '/' + id)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                if (error === 'connectionRefused') {
                    reject(error)
                }
                reject(error.response.data['hydra:description'])
            })
    })
}

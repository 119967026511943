<template>
    <div class="chat">
        <button @click="openChat()" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom3" aria-controls="offcanvasBottom">
            <svg width="27" height="26" viewBox="0 0 27 26" fill="none">
                <path d="M2.41602 14.0508V7.57246C2.41602 4.58246 4.84268 2.16663 7.83268 2.16663H18.666C21.656 2.16663 24.0827 4.58246 24.0827 7.57246V15.1341C24.0827 18.1133 21.656 20.5291 18.666 20.5291H17.041C16.7052 20.5291 16.3802 20.6916 16.1743 20.9625L14.5493 23.1183C13.8343 24.0716 12.6643 24.0716 11.9493 23.1183L10.3243 20.9625C10.151 20.7241 9.76101 20.5291 9.45768 20.5291H7.83268C4.84268 20.5291 2.41602 18.1133 2.41602 15.1341V14.0508Z" fill="var(--fadedBlueToDark)"/>
                <path d="M13.2493 13C12.6427 13 12.166 12.5125 12.166 11.9167C12.166 11.3209 12.6535 10.8334 13.2493 10.8334C13.8452 10.8334 14.3327 11.3209 14.3327 11.9167C14.3327 12.5125 13.856 13 13.2493 13Z" fill="var(--blueToFaded)"/>
                <path d="M17.5833 13C16.9767 13 16.5 12.5125 16.5 11.9167C16.5 11.3209 16.9875 10.8334 17.5833 10.8334C18.1792 10.8334 18.6667 11.3209 18.6667 11.9167C18.6667 12.5125 18.19 13 17.5833 13Z" fill="var(--blueToFaded)"/>
                <path d="M8.91732 13C8.31065 13 7.83398 12.5125 7.83398 11.9167C7.83398 11.3209 8.32148 10.8334 8.91732 10.8334C9.51315 10.8334 10.0007 11.3209 10.0007 11.9167C10.0007 12.5125 9.52398 13 8.91732 13Z" fill="var(--blueToFaded)"/>
            </svg>
        </button>

        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom3" aria-labelledby="offcanvasBottomLabel">
            <button ref="close__btn" data-bs-dismiss="offcanvas" hidden="hidden"></button>

            <div class="offcanvas-body pt-1" ref="scrollToMe" @scroll="getOldMessages">
                <div class="row">
                    <div class="col chat-box">

                        <!-- top close btn -->
                        <div class="row">
                            <div class="col line d-flex justify-content-center">
                                <button type="button" class="swiper2" data-bs-dismiss="offcanvas"
                                        aria-label="Close">
                                    <svg width="104" height="3" viewBox="0 0 104 3" fill="none">
                                        <path d="M1 1.5H102.5" stroke="var(--color-light-dark-gray)" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <!-- top close btn end-->

                        <div v-for="message in chat.messages" :key="message.id">
                            <div class="row d-flex justify-content-center swiper2-last-row px-3 my-4"
                                 v-if="message.user.id === getUser.id">
                                <div class="col swiper2-input-border pb-2">
                                    <div class="row">
                                        <div class="col-9">
                                            <div class="row">
                                                <div class="col-12 p-0 swiper2-text-1">
                                                    {{ message.message }}
                                                </div>
                                                <div class="col-12 p-0 swiper2-time-2">
                                                    {{ message.createdAt.slice(11, 16) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col d-flex justify-content-end p-2 avatar">
                                            <img v-if="message.user.image !== null" :src="localhost + message.user.image.contentUrl"
                                                 alt="" width="40" height="41"
                                                 style="border-radius: 50%">
                                            <img v-else-if="message.user.gender === 0" src="../../components/images/page-9/man.svg" alt="" width="41"
                                                 height="41" style="border-radius: 50%; background-color: var(--human-img-bg)">
                                            <svg v-else style="border-radius: 50%; background-color: var(--human-img-bg)" width="41" height="41" viewBox="0 0 56 56" fill="none">
                                                <mask id="mask0_105_7101" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                                                    <rect x="0.101074" width="55.8989" height="55.8973" rx="27.9487" fill="white"/>
                                                </mask>
                                                <g mask="url(#mask0_105_7101)">
                                                    <path d="M13.9877 46.3638C17.5877 13.5638 13.5 20.5 14.5 15C15.5 9.49996 17.0878 12.6638 17.4878 13.8638C17.9878 15.3638 23.5 17.3638 25 21.3638C26.5 25.3638 24.5 25.3638 24.5 26.3638C24.5 27.3638 30.5122 31.6361 30.5122 34.6361C30.5122 37.6361 24 43 23.5 47C23.1 50.2 19.6545 52.0305 19.4878 53.3638L13.9877 53.8638C12.4877 65.0305 10.3877 79.1638 13.9877 46.3638Z" fill="#192734"/>
                                                    <path d="M22.1333 36.1265V36.2051C22.1121 37.8439 20.7307 39.0765 17.9891 39.9029C21.2935 42.2993 24.5979 44.2163 27.8995 44.2163C31.2011 44.2163 34.5051 42.297 37.8094 39.9009C35.0865 39.0743 33.7053 37.8404 33.666 36.1992C33.666 35.9066 33.6683 35.4911 33.6699 34.7111C33.6699 34.5873 33.6699 34.4604 33.6699 34.3303C33.8666 34.3582 33.8509 34.3405 33.6699 34.291C33.6754 32.2387 33.6841 29.4788 33.6963 26.6744C36.2788 23.3358 35.33 19.4213 34.5224 19.5192C33.5295 19.6373 24.9338 11.5034 23.2852 11.0872C21.6365 10.671 17.4486 12.016 16.7276 15.7684C16.0065 19.5208 15.7048 28.9768 18.4391 32.7521C19.217 33.8273 20.4421 34.2469 22.1145 34.0109C22.1168 34.7976 22.1215 35.2366 22.1329 36.1261L22.1333 36.1265Z" fill="url(#paint0_linear_105_7101)"/>
                                                    <path opacity="0.228" d="M22.1311 34.02C24.4258 33.7878 26.6409 33.0514 28.6179 31.8635C28.6179 31.8635 25.8898 35.479 22.1311 36.1768V34.02Z" fill="#DB844C"/>
                                                    <path d="M46.7959 44.8505C48.5051 48.3229 49.4889 56.3316 49.4889 56.3316H6.396C6.396 56.3316 7.37944 48.3221 9.08906 44.8505C10.7987 41.379 20.7775 38.1576 20.7775 38.1576C22.7011 42.104 33.2574 42.1036 35.1004 38.1576C35.1004 38.1576 45.0847 41.3778 46.7959 44.8505Z" fill="#0481F4"/>
                                                    <path d="M39.5002 16.4999C39.5002 16.4999 38.8531 13.6341 38.5002 12.5C36.4963 6.06019 28.5001 4 23.5001 5C19.5001 5.99998 17.5002 6.99998 15.0001 11.5C12 17 16.6039 32.4999 16.302 20.4999C16.0001 8.49998 20.6188 20.7285 29.0001 17C30.1075 16.8718 29.757 21.0259 30.3487 21.3905C31.2365 21.9393 31.9257 18.4894 34.3611 19.5251C36.7965 20.5609 35.3509 25.3644 32.7381 25.3644C31.8372 25.3644 31.3868 27.8349 33.8191 29.0032C35.5822 29.8698 38.6937 18.435 39.5002 16.4999Z" fill="#192734"/>
                                                    <path d="M41.0002 25.5C40.6002 23.9 39.8335 18.8333 39.5001 16.5H38.5002C38.1002 16.5 38.0002 16.8333 38.0002 17V18L36.5002 23L34.0002 26.5L33.5002 27.5L33.0002 28L32.0002 26.5C32.0002 28.5 32.5744 36.6111 33.0002 43C33.5002 50.5 31.5002 57.6667 32.5002 59C35.0002 58.1667 44.6002 52.4 45.0002 52C45.5002 51.5 50.0002 50.5 50.5002 50.5C50.9002 50.5 54.3336 47.5 56.0002 46C54.6669 45.5 51.3002 43.8 48.5002 41C43.0002 33.5 41.5002 27.5 41.0002 25.5Z" fill="#192734"/>
                                                </g>
                                                <defs>
                                                    <linearGradient id="paint0_linear_105_7101" x1="16.2305" y1="11.0145" x2="16.2305" y2="44.2163" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#FFE2CF"/>
                                                        <stop offset="1" stop-color="#FFE2CF"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row my-4 px-3" v-else>
                                <div class="col-2">
                                    <img v-if="message.user.image !== null" :src="localhost + message.user.image.contentUrl" alt=""
                                         width="40" height="41" style="border-radius: 50%">
                                    <img v-else-if="message.user.gender === 0" src="../../components/images/page-9/man.svg" alt="" width="41"
                                         height="41" style="border-radius: 50%; background-color: var(--human-img-bg)">

                                    <svg v-else style="border-radius: 50%; background-color: var(--human-img-bg)" width="41" height="41" viewBox="0 0 56 56" fill="none">
                                        <mask id="mask0_105_7101" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                                            <rect x="0.101074" width="55.8989" height="55.8973" rx="27.9487" fill="white"/>
                                        </mask>
                                        <g mask="url(#mask0_105_7101)">
                                            <path d="M13.9877 46.3638C17.5877 13.5638 13.5 20.5 14.5 15C15.5 9.49996 17.0878 12.6638 17.4878 13.8638C17.9878 15.3638 23.5 17.3638 25 21.3638C26.5 25.3638 24.5 25.3638 24.5 26.3638C24.5 27.3638 30.5122 31.6361 30.5122 34.6361C30.5122 37.6361 24 43 23.5 47C23.1 50.2 19.6545 52.0305 19.4878 53.3638L13.9877 53.8638C12.4877 65.0305 10.3877 79.1638 13.9877 46.3638Z" fill="#192734"/>
                                            <path d="M22.1333 36.1265V36.2051C22.1121 37.8439 20.7307 39.0765 17.9891 39.9029C21.2935 42.2993 24.5979 44.2163 27.8995 44.2163C31.2011 44.2163 34.5051 42.297 37.8094 39.9009C35.0865 39.0743 33.7053 37.8404 33.666 36.1992C33.666 35.9066 33.6683 35.4911 33.6699 34.7111C33.6699 34.5873 33.6699 34.4604 33.6699 34.3303C33.8666 34.3582 33.8509 34.3405 33.6699 34.291C33.6754 32.2387 33.6841 29.4788 33.6963 26.6744C36.2788 23.3358 35.33 19.4213 34.5224 19.5192C33.5295 19.6373 24.9338 11.5034 23.2852 11.0872C21.6365 10.671 17.4486 12.016 16.7276 15.7684C16.0065 19.5208 15.7048 28.9768 18.4391 32.7521C19.217 33.8273 20.4421 34.2469 22.1145 34.0109C22.1168 34.7976 22.1215 35.2366 22.1329 36.1261L22.1333 36.1265Z" fill="url(#paint0_linear_105_7101)"/>
                                            <path opacity="0.228" d="M22.1311 34.02C24.4258 33.7878 26.6409 33.0514 28.6179 31.8635C28.6179 31.8635 25.8898 35.479 22.1311 36.1768V34.02Z" fill="#DB844C"/>
                                            <path d="M46.7959 44.8505C48.5051 48.3229 49.4889 56.3316 49.4889 56.3316H6.396C6.396 56.3316 7.37944 48.3221 9.08906 44.8505C10.7987 41.379 20.7775 38.1576 20.7775 38.1576C22.7011 42.104 33.2574 42.1036 35.1004 38.1576C35.1004 38.1576 45.0847 41.3778 46.7959 44.8505Z" fill="#0481F4"/>
                                            <path d="M39.5002 16.4999C39.5002 16.4999 38.8531 13.6341 38.5002 12.5C36.4963 6.06019 28.5001 4 23.5001 5C19.5001 5.99998 17.5002 6.99998 15.0001 11.5C12 17 16.6039 32.4999 16.302 20.4999C16.0001 8.49998 20.6188 20.7285 29.0001 17C30.1075 16.8718 29.757 21.0259 30.3487 21.3905C31.2365 21.9393 31.9257 18.4894 34.3611 19.5251C36.7965 20.5609 35.3509 25.3644 32.7381 25.3644C31.8372 25.3644 31.3868 27.8349 33.8191 29.0032C35.5822 29.8698 38.6937 18.435 39.5002 16.4999Z" fill="#192734"/>
                                            <path d="M41.0002 25.5C40.6002 23.9 39.8335 18.8333 39.5001 16.5H38.5002C38.1002 16.5 38.0002 16.8333 38.0002 17V18L36.5002 23L34.0002 26.5L33.5002 27.5L33.0002 28L32.0002 26.5C32.0002 28.5 32.5744 36.6111 33.0002 43C33.5002 50.5 31.5002 57.6667 32.5002 59C35.0002 58.1667 44.6002 52.4 45.0002 52C45.5002 51.5 50.0002 50.5 50.5002 50.5C50.9002 50.5 54.3336 47.5 56.0002 46C54.6669 45.5 51.3002 43.8 48.5002 41C43.0002 33.5 41.5002 27.5 41.0002 25.5Z" fill="#192734"/>
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_105_7101" x1="16.2305" y1="11.0145" x2="16.2305" y2="44.2163" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#FFE2CF"/>
                                                <stop offset="1" stop-color="#FFE2CF"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div
                                            class="col-12 swiper2-name"
                                            v-if="message.user.givenName !== null & message.user.familyName !== null"
                                        >
                                            {{ message.user.givenName + ' ' + message.user.familyName }}
                                        </div>
                                        <div class="col-12 swiper2-name" v-else>
                                            No name
                                        </div>
                                        <div class="col-12 swiper2-comment-text">
                                            {{ message.message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col swiper2-time">
                                    {{ message.createdAt.slice(11, 16) }}
                                </div>
                            </div>
                        </div>

                        <!-- Chat send -->
                        <div class="row fixed-bottom">
                            <div class="col">
                                <div class="row chat-bottom-row py-3">
                                    <div class="col input-box">
                                        <input @keyup.enter="sendMessage" type="text" class="form-control"
                                               v-model="message" :placeholder="$t('message')">
                                    </div>
                                    <div class="col d-flex justify-content-center send-btn"
                                         @click="sendMessage">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path
                                                d="M24.0937 11.3467L12.6804 5.64C5.01369 1.8 1.86702 4.94666 5.70702 12.6133L6.86702 14.9333C7.20035 15.6133 7.20035 16.4 6.86702 17.08L5.70702 19.3867C1.86702 27.0533 5.00035 30.2 12.6804 26.36L24.0937 20.6533C29.2137 18.0933 29.2137 13.9067 24.0937 11.3467ZM19.787 17H12.587C12.0404 17 11.587 16.5467 11.587 16C11.587 15.4533 12.0404 15 12.587 15H19.787C20.3337 15 20.787 15.4533 20.787 16C20.787 16.5467 20.3337 17 19.787 17Z"
                                                fill="#0481F4"/>
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {EventSourcePolyfill} from "event-source-polyfill";

export default {
    name: "listen-chats",
    props: {
        audioBook: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            message: '',
            chat: {
                mercureUrl: process.env.VUE_APP_MERCURE_URL,
                lastMessageId: null,
                isLastPage: false,
                messages: [],
            },
            eventSource: null,
            jwt: process.env.VUE_APP_MERCURE_JWT
        }
    },
    computed: {
        ...mapGetters(['getUser', "getCreatedChat", 'getMessages'])
    },
    methods: {
        ...mapActions(['fetchUser', 'pushMessage', 'createChat', 'fetchMessages']),
        closeModal() {
            this.$refs['close__btn'].click()
        },
        sendMessage() {
            if (this.message !== '') {
                this.pushMessage({chat: this.audioBook.chat['@id'], message: this.message})
                    .then(() => {
                        this.message = ''
                    })
            }
        },
        openChat() {
            if (this.audioBook.chat === null) {
                this.createChat({audioBook: this.audioBook['@id']})
                    .then(() => {
                        this.connectMercure(this.getCreatedChat.id)
                    })
            } else {
                this.fetchMessages('chat=' + this.audioBook.chat.id)
                    .then(async () => {
                        this.chat.lastMessageId = this.getMessages[this.getMessages.length - 1].id
                        this.chat.messages = this.getMessages.reverse()

                        await this.connectMercure(this.audioBook.chat.id)
                        await this.$refs.scrollToMe.scrollTo(0, this.$refs.scrollToMe.scrollHeight + 100)
                    })
            }
        },
        connectMercure(chatId) {
            const url = new URL(this.chat.mercureUrl)
            url.searchParams.append('topic', 'chat/' + chatId)
            this.eventSource = new EventSourcePolyfill(url, {
                headers: {
                    Authorization: 'Bearer ' + this.jwt
                }
            })

            this.eventSource.addEventListener('message', async (event) => {
                await this.addNewMessageToArray(event)

                if (this.$refs.scrollToMe.scrollHeight !== undefined) {
                    this.$refs.scrollToMe.scrollTop = this.$refs.scrollToMe.scrollHeight
                }
            })
        },
        addNewMessageToArray(event) {
            this.chat.messages.push(JSON.parse(event.data))
        },
        getOldMessages() {
            if (this.audioBook.chat !== null && this.$refs.scrollToMe.scrollTop === 0 && !this.chat.isLastPage) {
                this.fetchMessages('chat=' + this.audioBook.chat.id + '&id[lt]=' + this.chat.lastMessageId)
                    .then(async () => {
                        this.chat.lastMessageId = this.getMessages[this.getMessages.length - 1].id

                        await this.unshiftOldMessages()

                        if (this.getMessages.length < 10) {
                            this.chat.isLastPage = true
                        }

                        this.$refs.scrollToMe.scrollTo(0, 900)
                    })
            }
        },
        unshiftOldMessages() {
            this.chat.messages.unshift(...this.getMessages.reverse())
        },
    },
    mounted() {
        this.fetchUser()
        this.$parent.$on('parent-route-leave', this.closeModal);

        let modal = document.getElementById('offcanvasBottom3')
        modal.addEventListener('hide.bs.offcanvas', () => {
            if (this.eventSource !== null) {
                this.eventSource.close()
            }
        })
    }
}
</script>

<style scoped>

.chat > button {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
}

.offcanvas {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 80vh;
    overflow: scroll;
    background: var(--color-block);
}

.offcanvas-bottom {
    border: none;
}

.chat-box {
    margin-bottom: 70px;
}

.swiper2 {
    background-color: var(--color-header);
    padding: 0;
    border: none;
    height: 10px;
}

.swiper2-input-border {
    border-radius: 12px 0 12px 12px;
    background-color: var(--bg-button-row-lighter);
}

.swiper2-text-1 {
    font-size: 14px;
    line-height: 20px;
    margin: 11px 0 0 17px;
    overflow-wrap: break-word;
    color: var(--color-Black-To-White);
}

.swiper2-time-2 {
    font-size: 10px;
    line-height: 12px;
    color: #9597A1;
    margin: 10px 0 0 17px;
}

.swiper2-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-Blacktext-To-Blue);
}

.swiper2-comment-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--color-Black-To-White);
    margin-top: 6px;
}

.swiper2-time {
    font-size: 10px;
    color: #9597A1;
}

.chat-bottom-row {
    background-color: var(--color-header);
}

.input-box {
    min-width: 85%;
    padding-left: 30px;
}

.send-btn {
    min-width: 15%;
    margin-left: -25px;
    margin-top: 10px;
}

.form-control {
    height: 52px;
    border-radius: 40px;
    padding-left: 23px;
    background-color: var(--color-input) !important;
    color: var(--color-text);
    border: none;
    box-shadow: none;
    font-size: 14px;
}

.form-control::placeholder {
    line-height: 20px;
    color: #97CAFB;
}

</style>
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import uz from './uz.json'
import ru from './ru.json'

Vue.use(VueI18n);

const loadLocaleMessages = {
    en: en,
    uz: uz,
    ru: ru
}

export default new VueI18n({
    locale: localStorage.getItem('locale') || 'uz',
    fallbackLocale: 'uz',
    messages: loadLocaleMessages
})

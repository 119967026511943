import fetchModel from "../../mixin/store/api/fetchModel";
import pushModel from "@/mixin/store/api/pushModel";
import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
        fetchAudioBookId(context, audioBookId) {
            return fetchModel(context, 'audio-book/get', audioBookId, 'updateAudioBook');
        },
        checkAudioBookPurchasedOrNot(context, id) {
            return fetchModels(context, 'bought_audio_books?audioBook=' + id, null, 'updateBoughtAudioBook')
        },
        sendSavedCurrentTime(context, data) {
            return pushModel(context, 'audio_book_times', data, 'updateSavedCurrentTime')
        },
        fetchAudioBookChapter(context, id) {
            return fetchModel(context, 'audio_book/bought-book', id, 'updateAudioBookChapter')
        }
    },
    mutations: {
        updateAudioBook(state, data) {
            state.audioBook = data
        },
        updateSavedCurrentTime(state, data) {
            state.savedCurrentTime = data
        },
        updateAudioBookChapter(state, data) {
            state.audioBookChapter = data
        },
        updateBoughtAudioBook(state, data) {
            if (data['totalItems']) {
                state.isPurchasedAudioBook = true
            }
        },
        clearAudioBookId(state) {
            state.audioBook = {}
        }
    },
    state: {
        audioBook: {},
        savedCurrentTime: null,
        audioBookChapter: {
            "@id": null,
            image: null,
            name: null,
            author: null,
            audioBookChapters: [],
            coverSourceUrl: null
        },
        isPurchasedAudioBook: false
    },
    getters: {
        getAudioBookId(state) {
            return state.audioBook
        },
        getSavedCurrentTime(state) {
            return state.savedCurrentTime
        },
        getAudioBookChapter(state) {
            return state.audioBookChapter
        },
        getIsPurchasedAudioBook(state) {
            return state.isPurchasedAudioBook
        }
    },
}

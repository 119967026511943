<template>
    <div class="row container-row">
        <div class="col container-col">

            <div class="modal fade" id="exampleModal" data-bs-backdrop="static" tabindex="-1"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{$t('addComment')}}</h5>
                            <button type="button" ref="close-btn" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="modal-stars">
                                <svg v-for="n in 5" :key="n" width="30" height="30" class="star"
                                     :class="{filled: n <= vote.rating}"
                                     @click="rate(n)"
                                     viewBox="0 0 13 13">
                                    <path d="M7.23584 1.22052L8.32146 3.40679C8.4695 3.70492 8.86427 4.00305 9.19324 4.05274L11.1589 4.38399C12.4172 4.59931 12.7133 5.51025 11.8086 6.4212L10.2789 7.96981C10.0239 8.22653 9.87587 8.7317 9.95811 9.09608L10.394 11.0091C10.7394 12.5163 9.94166 13.1042 8.61754 12.3175L6.77527 11.2161C6.43807 11.0173 5.89526 11.0173 5.55806 11.2161L3.70757 12.3092C2.38345 13.096 1.58568 12.508 1.9311 11.0008L2.367 9.0878C2.44924 8.7317 2.3012 8.22653 2.04625 7.96153L0.524733 6.42948C-0.37995 5.51853 -0.0838721 4.59931 1.17446 4.39227L3.14009 4.06102C3.46907 4.00305 3.86384 3.7132 4.01188 3.41507L5.0975 1.2288C5.68143 0.0362835 6.65191 0.0362834 7.23584 1.22052Z" fill="#e7e8ea"/>
                                </svg>
                            </div>
                            <textarea v-model="vote.comment" class="form-control" placeholder="....." rows="4"/>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="sendVote()" class="btn btn-primary" data-bs-dismiss="modal">
                                {{$t('send')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col fixed-top">
                    <div class="row header-name-row ps-0">
                        <div class="col-2 header-block ps-2">
                            <go-back-button class="arrow-back" />
                        </div>
                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{$t('library.myVotes')}}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>

            <div class="book__container">
                <div v-for="(item, index) in this.getVotes" :key="index" class="row d-flex justify-content-center content">
                    <div class="col-11 center-block">
                        <div v-if="item.book !== null" class="row book__body">
                            <div class="col-2 book-img p-0" @click="pushRelatedBook(item)">
                                <img
                                    v-if="item.book.image !== null"
                                    :src="localhost + item.book.image.contentUrl"
                                    alt="" class="img-book"
                                >
                                <img
                                    v-else-if="item.book.coverSourceUrl"
                                    :src="changeHeightImg(item.book.coverSourceUrl)"
                                    @error="setDefaultImg"
                                    alt="" class="img-book"
                                >
                                <div class="logos">
                                <span class="tiny-headphone read-logo ps-0">
                                    <svg width="8" height="8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="16" height="16" rx="8" fill="white"/>
                                        <path d="M7.68815 5.49999V11.2958C7.68815 11.5917 7.38815 11.7958 7.11732 11.6833C6.34232 11.3583 5.37982 11.075 4.69648 10.9833L4.56732 10.9667C4.16315 10.9167 3.83398 10.5458 3.83398 10.1417V5.18749C3.83398 4.68749 4.23815 4.28333 4.73815 4.28333H4.76732C5.55065 4.34999 6.70482 4.71666 7.46732 5.12916C7.60482 5.20416 7.68815 5.34166 7.68815 5.49999Z" fill="#0481F4"/>
                                        <path d="M11.2625 4.28333H11.2375C11.1375 4.29166 11.0292 4.30416 10.9167 4.32499C10.4042 4.40833 9.79583 4.58333 9.25 4.79999C8.99167 4.90416 8.75 5.01666 8.5375 5.12916C8.4 5.20416 8.3125 5.34583 8.3125 5.49999V11.2958C8.3125 11.5917 8.6125 11.7958 8.88333 11.6833C9.65833 11.3583 10.6208 11.075 11.3042 10.9833L11.4333 10.9667C11.8375 10.9167 12.1667 10.5458 12.1667 10.1417V5.18749C12.1667 4.68749 11.7625 4.28333 11.2625 4.28333ZM11.0292 8.72083C11.0292 8.88333 10.9125 8.95416 10.7708 8.87499L10.3292 8.62916C10.2833 8.60416 10.2083 8.60416 10.1583 8.62916L9.71667 8.87499C9.575 8.95416 9.45833 8.88333 9.45833 8.72083V7.44166C9.45833 7.24999 9.61667 7.09166 9.80833 7.09166H10.6833C10.875 7.09166 11.0333 7.24999 11.0333 7.44166V8.72083H11.0292Z" fill="#0481F4"/>
                                    </svg>
                                </span>
                                </div>
                            </div>
                            <div class="col-8 p-0 ">
                                <div class="row">
                                    <div class="col book-name">
                                        {{item.book.name}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col book-author">
                                        {{item.book.author}}
                                    </div>
                                </div>
                                <div class="row d-inline-flex">
                                    <div class="col my-mark-text">
                                        {{$t('library.myVote')}}
                                        <svg v-for="n in 5" :key="n" width="13" height="13" class="star" :class="{'filled': n <= item.rating}" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.23584 1.22052L8.32146 3.40679C8.4695 3.70492 8.86427 4.00305 9.19324 4.05274L11.1589 4.38399C12.4172 4.59931 12.7133 5.51025 11.8086 6.4212L10.2789 7.96981C10.0239 8.22653 9.87587 8.7317 9.95811 9.09608L10.394 11.0091C10.7394 12.5163 9.94166 13.1042 8.61754 12.3175L6.77527 11.2161C6.43807 11.0173 5.89526 11.0173 5.55806 11.2161L3.70757 12.3092C2.38345 13.096 1.58568 12.508 1.9311 11.0008L2.367 9.0878C2.44924 8.7317 2.3012 8.22653 2.04625 7.96153L0.524733 6.42948C-0.37995 5.51853 -0.0838721 4.59931 1.17446 4.39227L3.14009 4.06102C3.46907 4.00305 3.86384 3.7132 4.01188 3.41507L5.0975 1.2288C5.68143 0.0362835 6.65191 0.0362834 7.23584 1.22052Z" fill="#e7e8ea"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="menu__btn">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <path class="blueToWhite" d="M15.1673 13.9998C15.1673 14.6532 14.6423 15.1665 14.0007 15.1665C13.359 15.1665 12.834 14.6415 12.834 13.9998C12.834 13.3582 13.359 12.8332 14.0007 12.8332C14.6423 12.8332 15.1673 13.3465 15.1673 13.9998Z" fill="#0481F4"/>
                                    <path class="blueToWhite" d="M15.1673 9.33333C15.1673 9.98667 14.6423 10.5 14.0007 10.5C13.359 10.5 12.834 9.975 12.834 9.33333C12.834 8.69167 13.359 8.16667 14.0007 8.16667C14.6423 8.16667 15.1673 8.68 15.1673 9.33333Z" fill="#0481F4"/>
                                    <path class="blueToWhite" d="M15.1673 18.6668C15.1673 19.3202 14.6423 19.8335 14.0007 19.8335C13.359 19.8335 12.834 19.3085 12.834 18.6668C12.834 18.0252 13.359 17.5002 14.0007 17.5002C14.6423 17.5002 15.1673 18.0135 15.1673 18.6668Z" fill="#0481F4"/>
                                </svg>

                                <ul class="dropdown-menu">
                                    <li> <span @click="deleteVote(item.id, 'Book')">
                                    <svg width="16" height="16" fill="currentColor" class="bi bi-trash mb-1" viewBox="0 0 16 16">
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                        {{ $t('delete') }}
                                    </span>
                                    </li>
                                    <li>
                                        <span
                                            @click="
                                                detectBook(item.book.id);
                                                vote.comment = item.comment;
                                                vote.rating = item.rating
                                            "
                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                        >
                                    <svg width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                        {{ $t('change') }}
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div v-else-if="item.audioBook !== null" class="row book__body">
                            <div class="col-2 book-img p-0" @click="pushRelatedBook(item)">
                                <img
                                    v-if="item.audioBook.image !== null"
                                    :src="localhost + item.audioBook.image.contentUrl"
                                    alt="" class="img-book"
                                >
                                <img
                                    v-else-if="item.audioBook.coverSourceUrl"
                                    :src="changeHeightImg(item.audioBook.coverSourceUrl)"
                                    @error="setDefaultImg"
                                    alt="" class="img-book"
                                >
                                <div class="logos">
                                <span class="tiny-headphone">
                                    <svg width="8" height="8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="16" height="16" rx="8" fill="white"/>
                                        <path d="M4.14527 10.7709C3.97444 10.7709 3.83277 10.6292 3.83277 10.4584V8.08335C3.81194 6.95419 4.23277 5.88752 5.0161 5.08752C5.79944 4.29169 6.84944 3.85419 7.9786 3.85419C10.2869 3.85419 12.1661 5.73335 12.1661 8.04169V10.4167C12.1661 10.5875 12.0244 10.7292 11.8536 10.7292C11.6828 10.7292 11.5411 10.5875 11.5411 10.4167V8.04169C11.5411 6.07919 9.94527 4.47919 7.9786 4.47919C7.0161 4.47919 6.12444 4.85002 5.46194 5.52502C4.79527 6.20419 4.4411 7.10835 4.45777 8.07502V10.4542C4.45777 10.6292 4.32027 10.7709 4.14527 10.7709Z" fill="#0481F4"/>
                                        <path d="M5.47565 8.1875H5.42148C4.54648 8.1875 3.83398 8.9 3.83398 9.775V10.5583C3.83398 11.4333 4.54648 12.1458 5.42148 12.1458H5.47565C6.35065 12.1458 7.06315 11.4333 7.06315 10.5583V9.775C7.06315 8.9 6.35065 8.1875 5.47565 8.1875Z" fill="#0481F4"/>
                                        <path d="M10.5792 8.1875H10.525C9.65 8.1875 8.9375 8.9 8.9375 9.775V10.5583C8.9375 11.4333 9.65 12.1458 10.525 12.1458H10.5792C11.4542 12.1458 12.1667 11.4333 12.1667 10.5583V9.775C12.1667 8.9 11.4542 8.1875 10.5792 8.1875Z" fill="#0481F4"/>
                                    </svg>
                                </span>
                                    <span class="tiny-headphone read-logo ps-2">
                                    <svg width="8" height="8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="16" height="16" rx="8" fill="white"/>
                                        <path d="M7.68815 5.49999V11.2958C7.68815 11.5917 7.38815 11.7958 7.11732 11.6833C6.34232 11.3583 5.37982 11.075 4.69648 10.9833L4.56732 10.9667C4.16315 10.9167 3.83398 10.5458 3.83398 10.1417V5.18749C3.83398 4.68749 4.23815 4.28333 4.73815 4.28333H4.76732C5.55065 4.34999 6.70482 4.71666 7.46732 5.12916C7.60482 5.20416 7.68815 5.34166 7.68815 5.49999Z" fill="#0481F4"/>
                                        <path d="M11.2625 4.28333H11.2375C11.1375 4.29166 11.0292 4.30416 10.9167 4.32499C10.4042 4.40833 9.79583 4.58333 9.25 4.79999C8.99167 4.90416 8.75 5.01666 8.5375 5.12916C8.4 5.20416 8.3125 5.34583 8.3125 5.49999V11.2958C8.3125 11.5917 8.6125 11.7958 8.88333 11.6833C9.65833 11.3583 10.6208 11.075 11.3042 10.9833L11.4333 10.9667C11.8375 10.9167 12.1667 10.5458 12.1667 10.1417V5.18749C12.1667 4.68749 11.7625 4.28333 11.2625 4.28333ZM11.0292 8.72083C11.0292 8.88333 10.9125 8.95416 10.7708 8.87499L10.3292 8.62916C10.2833 8.60416 10.2083 8.60416 10.1583 8.62916L9.71667 8.87499C9.575 8.95416 9.45833 8.88333 9.45833 8.72083V7.44166C9.45833 7.24999 9.61667 7.09166 9.80833 7.09166H10.6833C10.875 7.09166 11.0333 7.24999 11.0333 7.44166V8.72083H11.0292Z" fill="#0481F4"/>
                                    </svg>
                                </span>
                                </div>
                            </div>
                            <div class="col-8 p-0">
                                <div class="row">
                                    <div class="col book-name">
                                        {{item.audioBook.name}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col book-author">
                                        {{item.audioBook.author}}
                                    </div>
                                </div>
                                <div class="row d-inline-flex">
                                    <div class="col my-mark-text">
                                        {{$t('library.myVote')}}
                                        <svg v-for="n in 5" :key="n" width="13" height="13" class="star" :class="{'filled': n <= item.rating}" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.23584 1.22052L8.32146 3.40679C8.4695 3.70492 8.86427 4.00305 9.19324 4.05274L11.1589 4.38399C12.4172 4.59931 12.7133 5.51025 11.8086 6.4212L10.2789 7.96981C10.0239 8.22653 9.87587 8.7317 9.95811 9.09608L10.394 11.0091C10.7394 12.5163 9.94166 13.1042 8.61754 12.3175L6.77527 11.2161C6.43807 11.0173 5.89526 11.0173 5.55806 11.2161L3.70757 12.3092C2.38345 13.096 1.58568 12.508 1.9311 11.0008L2.367 9.0878C2.44924 8.7317 2.3012 8.22653 2.04625 7.96153L0.524733 6.42948C-0.37995 5.51853 -0.0838721 4.59931 1.17446 4.39227L3.14009 4.06102C3.46907 4.00305 3.86384 3.7132 4.01188 3.41507L5.0975 1.2288C5.68143 0.0362835 6.65191 0.0362834 7.23584 1.22052Z" fill="#e7e8ea"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="menu__btn">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <path class="blueToWhite" d="M15.1673 13.9998C15.1673 14.6532 14.6423 15.1665 14.0007 15.1665C13.359 15.1665 12.834 14.6415 12.834 13.9998C12.834 13.3582 13.359 12.8332 14.0007 12.8332C14.6423 12.8332 15.1673 13.3465 15.1673 13.9998Z" fill="#0481F4"/>
                                    <path class="blueToWhite" d="M15.1673 9.33333C15.1673 9.98667 14.6423 10.5 14.0007 10.5C13.359 10.5 12.834 9.975 12.834 9.33333C12.834 8.69167 13.359 8.16667 14.0007 8.16667C14.6423 8.16667 15.1673 8.68 15.1673 9.33333Z" fill="#0481F4"/>
                                    <path class="blueToWhite" d="M15.1673 18.6668C15.1673 19.3202 14.6423 19.8335 14.0007 19.8335C13.359 19.8335 12.834 19.3085 12.834 18.6668C12.834 18.0252 13.359 17.5002 14.0007 17.5002C14.6423 17.5002 15.1673 18.0135 15.1673 18.6668Z" fill="#0481F4"/>
                                </svg>

                                <ul class="dropdown-menu">
                                    <li> <span @click="deleteVote(item.id, 'AudioBook')">
                                    <svg width="16" height="16" fill="currentColor" class="bi bi-trash mb-1" viewBox="0 0 16 16">
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                        {{ $t('delete') }}
                                    </span>
                                    </li>
                                    <li>
                                        <span
                                            @click="
                                                detectAudio(item.audioBook.id);
                                                vote.comment = item.comment;
                                                vote.rating = item.rating
                                            "
                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                        >
                                    <svg width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                        {{ $t('change') }}
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="row" v-if="item.comment.length > 0">
                            <div class="col comment-col p-0">
                                <div class="comment">{{item.comment}}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col date p-0">
                                {{correctDateAndShow(item)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <notify-success :success-message-text="successMessageText" :is-show-success="isShowSuccess"/>

            <!--footer row begin -->
            <Footer/>
        </div>
    </div>
</template>

<script>
import Footer from "../../components/footer/footer.vue";
import GoBackButton from "@/components/modals/GoBackButton.vue";
import {mapActions, mapGetters} from "vuex";
import NotifySuccess from "./notify-success.vue";

export default {
    name: "my-feedback",
    components: {NotifySuccess, GoBackButton, Footer},
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
            vote: {
                comment: '',
                rating: 0,
                audioOrSimpleBookId: null,
                audio: false,
                book: false
            },
            isShowSuccess: false,
            successMessageText: ''
        }
    },
    computed: {
        ...mapGetters(['getVotes'])
    },
    methods: {
        ...mapActions(['fetchVotes', 'deleteBook', 'changeVote']),
        correctDateAndShow(item) {
            return item.createdAt.slice(8, 10) + '.' + item.createdAt.slice(5, 7) + '.' + item.createdAt.slice(0, 4)
        },
        setDefaultImg(event) {
            event.target.src = this.localhost + "/book.png"
        },
        pushRelatedBook(item) {
            this.$router.push(item.audioBook === null ? '/book/' + item.book.id : '/audio-book/' + item.audioBook.id)
        },
        rate(value) {
            this.vote.rating = value
        },
        deleteVote(id, type) {
            this.deleteBook(id)
                .then(() => {
                    this.fetchVotes();
                    this.$store.commit(type === 'Book' ? 'clearUserVoteBook' : 'clearUserVoteAudioBook')
                    this.successMessageText = this.$t('deleted')
                    this.isShowSuccess = !this.isShowSuccess
                })
        },
        detectBook(id) {
            this.vote.audioOrSimpleBookId = id
            this.vote.audio = false
            this.vote.book = true
        },
        detectAudio(id) {
            this.vote.audioOrSimpleBookId = id
            this.vote.book = false
            this.vote.audio = true
        },
        sendVote() {
            this.$root.showOverlay = true
            if(this.vote.book) {
                this.changeVote({
                    rating: this.vote.rating,
                    comment: this.vote.comment,
                    book: '/api/books/' + this.vote.audioOrSimpleBookId
                })
                    .then(() => {
                        this.fetchVotes()
                        this.successMessageText = this.$t('commentChange')
                        this.isShowSuccess = !this.isShowSuccess
                        this.cleanModal()
                    })
                    .finally(() => {
                        this.$root.showOverlay = false
                    })
            } else {
                this.changeVote({
                    rating: this.vote.rating,
                    comment: this.vote.comment,
                    audioBook: '/api/audio_books/' + this.vote.audioOrSimpleBookId
                })
                    .then(() => {
                        this.fetchVotes()
                        this.successMessageText = this.$t('commentChange')
                        this.isShowSuccess = !this.isShowSuccess
                        this.cleanModal()
                    })
                    .finally(() => {
                        this.$root.showOverlay = false
                    })
            }
        },
        changeHeightImg(url) {
            return url.replace('cover', 'cover_100')
        },
        cleanModal() {
            this.vote = {
                comment: '',
                rating: 0,
                audioOrSimpleBookId: null,
                audio: false,
                book: false
            }
        }
    },
    mounted() {
        this.fetchVotes()
        let closeBtn = document.querySelector('.btn-close')
        if(closeBtn) {
            closeBtn.addEventListener('click', () => {
                this.cleanModal()
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$refs["close-btn"].click()
        next()
    },
}
</script>

<style scoped>
div.stars{
    width: 100%;
    display: inline-block;
}

input.star{
    display: none;
}

label.star {
    float: right;
    padding: 6px;
    font-size: 36px;
    color: #444;
    transition: all .2s;
}

input.star:checked ~ label.star:before {
    content:'\f005';
    color: #FD4;
    transition: all .25s;
}

input.star-5:checked ~ label.star:before {
    color:#FE7;
    text-shadow: 0 0 20px #952;
}

input.star-1:checked ~ label.star:before {
    color: #F62;
}

label.star:hover{
    transform: rotate(-15deg) scale(1.3);
}

label.star:before{
    content:'\f006';
    font-family: FontAwesome;
}

.container-row {
    background-color: var(--color-container);
}

.container-col {
    border-radius: 0 0 15px 15px;
    margin-top: 147px;
    min-height: calc(100vh - 147px);
    background-color: var(--color-container);
}

.blueToWhite {
    fill: var(--blueToWhite);
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.header-block {
    border: 15px;
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.book__container {
    padding-bottom: 85px;
}

.center-block {
    margin-top: 20px;
    border-radius: 15px;
    background-color: var(--color-block);
}

.book-img {
    margin: 11px 0 0 13px;
    width: 60px;
}

.img-book {
    position: absolute;
    width: 43px;
    height: 64px;
    border-radius: 5px;
}

.tiny-headphone {
    position: absolute;
}

.read-logo {
    margin-left: 1px;
}

.logos {
    margin: 44px 0 0 3px;
}

.book-name {
    margin-top: 13px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.book-author {
    font-size: 12px;
    line-height: 15px;
    color: var(--color-lightGray-to-lightBlue);
    margin-top: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.my-mark-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-top: 6px;
    color: var(--color-black-to-lightGray);
}

.filled path {
    fill: #FFCF25;
}

.star {
    margin-left: 5px;
}

.comment-col {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    border-radius: 5px;
    background-color: var(--color-input);
    color: var(--color-Black-To-White);
    margin: 22px 13px 0 13px;
}

.comment {
    margin: 13px;
}

.date {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    margin: 13px 0 16px 25px;
}

.dropdown-menu {
    border: 1px solid var(--bg-progress);
    background-color: var(--color-block);
    color: var(--color-text);
    padding: 10px;
}

.modal-stars {
    display: flex;
    padding: 10px 0;
}

.book__body {
    position: relative;
}

.menu__btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
}
</style>

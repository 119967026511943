import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
        fetchFinishedBooks(context) {
            return fetchModels(context, 'finished_books', null, 'updateFinishedBooks')
        }
    },
    mutations: {
        updateFinishedBooks(state, data) {
            state.finishedBooks = data
        }
    },
    state: {
        finishedBooks: {
            models: null,
            totalItems: 0
        }
    },
    getters: {
        getFinishedBooks(state) {
            return state.finishedBooks.models
        }
    }
}
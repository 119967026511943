<template>
    <div class="row">
        <div class="col main-row">
            <div class="row">
                <div class="col first-block">
                    <div class="row header-row">
                        <div class="col-4 d-flex justify-content-start ps-4">
                            <svg @click="isClickedPage = true; $router.push('/top-readers')" class="svg" fill="none" height="30"
                                 viewBox="0 0 30 30" width="30">
                                <path d="M15 8.05005V12.2125" stroke="#333333" stroke-linecap="round"
                                      stroke-miterlimit="10"
                                      stroke-width="1.5"/>
                                <path
                                    d="M25.7378 18.9625C26.6503 20.4875 25.9253 22.4625 24.2378 23.025C18.2628 25.0125 11.8003 25.0125 5.82528 23.025C4.02528 22.425 3.33778 20.6 4.32528 18.9625L5.91278 16.3125C6.35028 15.5875 6.70028 14.3 6.70028 13.4625V10.8375C6.70028 6.225 10.4253 2.5 15.0253 2.5C19.6003 2.5 23.3503 6.25 23.3503 10.825V13.45C23.3503 13.675 23.3753 13.925 23.4128 14.1875"
                                    stroke="#333333" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                <path
                                    d="M19.1629 23.5249C19.1629 25.8124 17.2879 27.6874 15.0004 27.6874C13.8629 27.6874 12.8129 27.2124 12.0629 26.4624C11.3129 25.7124 10.8379 24.6624 10.8379 23.5249"
                                    stroke="#333333" stroke-miterlimit="10" stroke-width="1.5"/>
                            </svg>
                        </div>
                        <div class="col-4 d-flex justify-content-center header-center-text mt-1">
                            {{ $t('statisticsPage.profile') }}
                        </div>
                        <div class="col-4 d-flex justify-content-end pe-4">
                            <router-link to="/setting">
                                <svg @click="isClickedPage = true" class="svg" fill="none" height="30" viewBox="0 0 30 30" width="30">
                                    <path
                                        d="M18.75 15C18.75 12.925 17.075 11.25 15 11.25C12.925 11.25 11.25 12.925 11.25 15C11.25 17.075 12.925 18.75 15 18.75C15.5125 18.75 16.0125 18.65 16.4625 18.45"
                                        stroke="#333333" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-miterlimit="10"
                                        stroke-width="1.5"/>
                                    <path
                                        d="M8.59961 25.7249L9.96211 26.5124C10.9496 27.0999 12.2246 26.7499 12.8121 25.7624L12.9496 25.5249C14.0746 23.5624 15.9246 23.5624 17.0621 25.5249L17.1996 25.7624C17.7871 26.7499 19.0621 27.0999 20.0496 26.5124L22.2121 25.2749C23.3496 24.6249 23.7371 23.1624 23.0871 22.0374C21.9496 20.0749 22.8746 18.4749 25.1371 18.4749C26.4371 18.4749 27.5121 17.4124 27.5121 16.0999V13.8999C27.5121 12.5999 26.4496 11.5249 25.1371 11.5249C23.8746 11.5249 23.0246 11.0249 22.7246 10.2374C22.4871 9.6249 22.5871 8.8249 23.0871 7.9624C23.7371 6.8249 23.3496 5.3749 22.2121 4.7249L21.1996 4.1499"
                                        stroke="#333333" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-miterlimit="10"
                                        stroke-width="1.5"/>
                                    <path
                                        d="M17.05 4.47504C15.925 6.43754 14.075 6.43754 12.9375 4.47504L12.8 4.23754C12.225 3.25004 10.95 2.90004 9.9625 3.48754L7.8 4.72504C6.6625 5.37504 6.275 6.83754 6.925 7.97504C8.0625 9.92504 7.1375 11.525 4.875 11.525C3.575 11.525 2.5 12.5875 2.5 13.9V16.1C2.5 17.4 3.5625 18.475 4.875 18.475C7.1375 18.475 8.0625 20.075 6.925 22.0375"
                                        stroke="#333333" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-miterlimit="10"
                                        stroke-width="1.5"/>
                                </svg>
                            </router-link>
                        </div>
                    </div>

                    <!--                    <div class="row d-flex justify-content-center">-->
                    <!--                        <div class="col-11 button-row">-->
                    <!--                            <div class="row">-->
                    <!--                                <div class="col-6">-->
                    <!--                                    <button class="book-button">Книга</button>-->
                    <!--                                </div>-->
                    <!--                                <div class="col-6">-->
                    <!--                                    <button class="audio-button" @click="$router.push('/progress-audio')">Аудио</button>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="row">
                        <div class="col-12 d-flex justify-content-center profile-name">
                            <span v-if="this.user.givenName !== null">
                                {{ this.user.givenName + ' ' + this.user.familyName }}
                            </span>
                            <svg class="ms-1" fill="none" height="10" viewBox="0 0 10 10" width="10">
                                <path
                                    d="M10 1.85501V6.64501C10 8.02501 8.88 9.14501 7.5 9.14501H2.5C2.27 9.14501 2.05 9.11501 1.835 9.05501C1.525 8.97001 1.425 8.57501 1.655 8.34501L6.97 3.03001C7.08 2.92001 7.245 2.89501 7.4 2.92501C7.56 2.95501 7.735 2.91001 7.86 2.79001L9.145 1.50001C9.615 1.03001 10 1.18501 10 1.85501Z"
                                    fill="#FFCF25"/>
                                <path
                                    d="M6.32 2.68001L1.085 7.91501C0.845 8.15501 0.445 8.09501 0.285 7.79501C0.1 7.45501 0 7.06001 0 6.64501V1.85501C0 1.18501 0.385 1.03001 0.855 1.50001L2.145 2.79501C2.34 2.98501 2.66 2.98501 2.855 2.79501L4.645 1.00001C4.84 0.80501 5.16 0.80501 5.355 1.00001L6.325 1.97001C6.515 2.16501 6.515 2.48501 6.32 2.68001Z"
                                    fill="#FFCF25"/>
                                <path
                                    d="M0 1.85501V6.64501C0 8.02501 1.12 9.14501 2.5 9.14501H7.5C7.73 9.14501 7.95 9.115 8.165 9.055C8.475 8.97 8.575 8.575 8.345 8.345L3.03 3.03C2.92 2.92 2.755 2.895 2.6 2.925C2.44 2.955 2.265 2.91 2.14 2.79L0.855 1.50001C0.385 1.03001 0 1.18501 0 1.85501Z"
                                    fill="#FFCF25"/>
                                <path
                                    d="M3.68 2.68L8.915 7.915C9.155 8.155 9.555 8.095 9.715 7.795C9.9 7.455 10 7.06001 10 6.64501V1.85501C10 1.18501 9.615 1.03001 9.145 1.50001L7.855 2.795C7.66 2.985 7.34 2.985 7.145 2.795L5.355 1.00001C5.16 0.80501 4.84 0.80501 4.645 1.00001L3.675 1.97C3.485 2.165 3.485 2.485 3.68 2.68Z"
                                    fill="#FFCF25"/>
                                <path
                                    d="M4.75 3.75001H5.5C5.58333 3.83334 5.8 4.00001 6 4.00001C6.25 4.00001 6 4.25001 6.25 4.75001C6.5 5.25001 6 5.00001 5.5 5.25001C5 5.50001 5 5.25001 4.75 5.25001C4.55 5.25001 4.16667 4.91668 4 4.75001L4.75 3.75001Z"
                                    fill="#FFCF25"/>
                            </svg>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col d-flex justify-content-center account">
                            +{{ this.user.phone }}
                        </div>
                    </div>

                    <!--                    <div class="row">-->
                    <!--                        <div class="col d-flex justify-content-center text-5-row">-->
                    <!--                    <span style="color: orange;padding-right: 3px;">-->
                    <!--                        Premium-->
                    <!--                    </span>-->
                    <!--                            <span class="text2-row-5">-->
                    <!--                        истекает через:-->
                    <!--                    </span>-->
                    <!--                            <router-link to="/#">29 дней</router-link>-->
                    <!--                        </div>-->
                    <!--                    </div>-->


                    <div class="row">
                        <div class="col d-flex justify-content-center mt-4">

                            <apexchart :options="chartOptions" :series="series" type="donut"></apexchart>

                            <div v-if="this.user.filePath !== null" class="user-img">
                                <img alt="-" class="chart-img" :src="localhost + '/media/' + this.user.filePath">
                            </div>
                            <div v-else-if="this.user.gender === 0" class="man-img">
                                <img alt="man-image" class="chart-man-img" src="../../components/images/page-9/man2.png">
                            </div>
                            <div v-else class="woman-img">
                                <img alt="woman-image" class="chart-woman-img" src="../../components/images/page-9/women2.png">
                            </div>
                        </div>
                    </div>

                    <div class="row row-7">
                        <div class="col d-flex justify-content-center your-goal-text-1">
                            {{ $t('statisticsPage.yourTarget') }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center your-goal-text-2">
                            {{
                                $t(
                                    'statisticsPage.readBooksInDays',
                                    {
                                        howDays: this.target.howManyDays,
                                        howBooks: this.target.howManyBooks
                                    }
                                )
                            }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col d-flex justify-content-center">
                            <button class="change_target_btn mx-2 mt-3" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                                {{ $t('changeTarget') }}
                            </button>
                            <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom"
                                 aria-labelledby="offcanvasBottomLabel">

                                <div class="offcanvas-body pb-0">
                                    <form>
                                        <div class="row">
                                            <div class="col bg-logo">
                                                <button v-show="false" ref="close-btn__target" aria-label="Close" type="button" class="btn-close text-white" data-bs-dismiss="offcanvas"></button>

                                                <div class="row">
                                                    <div class="col line d-flex justify-content-center">
                                                        <button type="button" class="swiper" data-bs-dismiss="offcanvas"
                                                                aria-label="Close">
                                                            <svg width="104" height="3" viewBox="0 0 104 3" fill="none">
                                                                <path class="swiper-svg" d="M1 1.5H102.5"
                                                                      stroke="var(--bg-progress)" stroke-width="2"
                                                                      stroke-linecap="round"/>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col d-flex justify-content-center text-1">
                                                        {{ $t('yourTarget') }}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col d-flex justify-content-center logo">
                                                        <TargetModalImage/>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col block-2">
                                                        <div class="row">
                                                            <div class="col">

                                                                <div class="row">
                                                                    <div class="col p-0 text-2">
                                                                        {{ $t('IWillRead') }}
                                                                    </div>
                                                                </div>
                                                                <div class="row d-flex justify-content-center">
                                                                    <div class="col-11 input p-0">
                                                                        <div class="row">
                                                                            <div class="col text-3">
                                                                                {{ this.target.howManyBooks }}
                                                                                {{ $t('book') }}
                                                                            </div>
                                                                            <div class="col d-flex justify-content-end">
                                                                                <div class="btn-group" role="group">
                                                                                    <button
                                                                                        @click="target.howManyBooks--"
                                                                                        type="button" class="btn">-
                                                                                    </button>
                                                                                    <span
                                                                                        class="btn__group__span"></span>
                                                                                    <button
                                                                                        @click="target.howManyBooks++"
                                                                                        type="button" class="btn">+
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="col p-0 mt-5 text-2">
                                                                        {{ $t('while') }}
                                                                    </div>
                                                                </div>

                                                                <div class="row justify-content-center">
                                                                    <div class="col-11 bg-buttons">
                                                                        <div class="row">
                                                                            <div class="col-12 d-flex justify-content-center">
                                                                                <hooper
                                                                                    :auto-play="false"
                                                                                    :transition="100"
                                                                                    :playSpeed="5000"
                                                                                    :itemsToShow="3"
                                                                                    :infiniteScroll="true"
                                                                                    :centerMode="true"
                                                                                    :initialSlide="1"
                                                                                    :hoverPause="false"
                                                                                >
                                                                                    <slide
                                                                                        class="d-flex justify-content-center"
                                                                                        v-for="(item, index) in 30"
                                                                                        :key="index"
                                                                                        :index="index"
                                                                                    >
                                                                                        <div
                                                                                            class="d-flex justify-content-center align-items-center">
                                                                                            {{ item }} {{ $t('days') }}
                                                                                        </div>
                                                                                    </slide>
                                                                                </hooper>
                                                                            </div>
                                                                        </div>
                                                                        <div class="hooper__shadow1"></div>
                                                                        <div class="hooper__shadow2"></div>
                                                                    </div>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="col p-0 mt-5 text-2">
                                                                        {{ $t('inOneDay') }}
                                                                    </div>
                                                                </div>

                                                                <div class="row justify-content-center">
                                                                    <div class="col-11 bg-buttons">
                                                                        <div class="row">
                                                                            <div class="col-12 d-flex justify-content-center">
                                                                                <hooper
                                                                                    :auto-play="false"
                                                                                    :transition="100"
                                                                                    :playSpeed="5000"
                                                                                    :itemsToShow="3"
                                                                                    :infiniteScroll="true"
                                                                                    :centerMode="true"
                                                                                    :initialSlide="1"
                                                                                    :hoverPause="false"
                                                                                >
                                                                                    <slide
                                                                                        class="slide-content d-flex justify-content-center"
                                                                                        v-for="(item, index) in this.minutes"
                                                                                        :key="index"
                                                                                        :index="index"
                                                                                    >
                                                                                        <div
                                                                                            class="d-flex justify-content-center align-items-center">
                                                                                            {{ item }}
                                                                                            {{ $t('minutes') }}
                                                                                        </div>
                                                                                    </slide>
                                                                                </hooper>
                                                                            </div>
                                                                        </div>
                                                                        <div class="hooper__shadow1"></div>
                                                                        <div class="hooper__shadow2"></div>
                                                                    </div>
                                                                </div>

                                                                <Button text="install" data-bs-dismiss="offcanvas"
                                                                        class="button-install" :onClick="pushTarget"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col ps-0 statistic-text">
                    {{ $t('statisticsPage.statistics') }}
                </div>
            </div>

            <div class="row">
                <div class="col px-3">
                    <div class="second-block p-3">

                        <div class="row">
                            <div class="col">
                                <div class="d-flex p-2 button-row-2">
                                    <button :class="{'active-button': weekButton}" @click="weekData" class="for-day">
                                        {{ $t('statisticsPage.duringTheWeek') }}
                                    </button>
                                    <button :class="{'active-button': monthButton}" @click="monthData"
                                            class="for-month">
                                        {{ $t('statisticsPage.duringTheMonth') }}
                                    </button>
                                    <button :class="{'active-button': yearButton}" @click="yearData" class="for-year">
                                        {{ $t('statisticsPage.duringTheYear') }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="row progress2-row ps-1">
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-center read-text">
                                        {{ $t('statisticsPage.read') }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col d-flex justify-content-center books-count-row">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center">
                                                <span class="books-count">{{ this.readBookDayData.finishedBook }}</span>
                                            </div>
                                            <div class="col d-flex justify-content-center">
                                                <span class="book-text">{{ $t('statisticsPage.book') }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col pe-0">
                                        <div class="vl-2"></div>
                                    </div>

                                    <div class="col d-flex justify-content-center page-count-row">
                                        <div class="row">
                                            <div class="col col-12 d-flex justify-content-center">
                                                <span class="page-count">{{ this.readBookDayData.countReadPage }}</span>
                                            </div>
                                            <div class="col d-flex justify-content-center">
                                                <span class="page-text">{{ $t('statisticsPage.page') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 d-flex justify-content-center center-vl p-0 m-0">
                                <div class="vl"></div>
                            </div>
                            <div class="col-5 pe-4">
                                <div class="row">
                                    <div class="col d-flex justify-content-center read-text">
                                        {{ $t('statisticsPage.readingSpeed') }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col d-flex justify-content-center books-count-row">
                                        <div class="row pe-4 ">
                                            <div class="col-12 d-flex justify-content-center">
                                                <span class="books-count">{{ this.readBookDayData.pageHours }}</span>
                                            </div>
                                            <div class="col d-flex justify-content-center">
                                                <span class="book-time-page-text">{{
                                                        $t('statisticsPage.pageHour')
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-1  p-0">
                                        <div class="vl-2"></div>
                                    </div>

                                    <div class="col d-flex justify-content-center page-count-row">
                                        <div class="row">
                                            <div class="col col-12 d-flex justify-content-center">
                                                <span class="page-count">{{ this.readBookDayData.readTime }}</span>
                                            </div>
                                            <div class="col d-flex justify-content-center">
                                                <span
                                                    class="book-time-page-text">{{
                                                        $t('statisticsPage.readingTime')
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col d-flex justify-content-center my-4">
                                <span class="hr-bottom-1"></span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="week__days__row">
                                    <div v-for="item in 7" :key="item" class="week__day">
                                        <svg fill="none" height="43" viewBox="0 0 39 43" width="39">
                                            <path
                                                :class="{
                                                    'prev-days': item < getCurrentDay,
                                                    'current-day': item === getCurrentDay,
                                                    'next-days': item > getCurrentDay,
                                                    'border-week-day': calcIsTarget(item)
                                                }"
                                                class="svg-week"
                                                d="M16 1.02073C18.1658 -0.229704 20.8342 -0.229704 23 1.02073L35.4856 8.22927C37.6514 9.4797 38.9856 11.7906 38.9856 14.2915V28.7085C38.9856 31.2094 37.6514 33.5203 35.4856 34.7707L23 41.9793C20.8342 43.2297 18.1658 43.2297 16 41.9793L3.51443 34.7707C1.34862 33.5203 0.0144291 31.2094 0.0144291 28.7085V14.2915C0.0144291 11.7906 1.34862 9.4797 3.51443 8.22927L16 1.02073Z"
                                                fill="#CBE5FD"
                                            />
                                        </svg>
                                        <span
                                            :class="{
                                                'text-white': item === getCurrentDay ||
                                                (item >= getCurrentDay && isDarkTheme)
                                            }"
                                            class="day__text"
                                        >
                                            {{ weekDayName(item) }}
                                        </span>
                                        <div v-if="calcIsTarget(item)" class="selected-week-day">
                                            <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
                                                <circle cx="8" cy="8" fill="#0481F4" r="7" stroke="var(--color-block)"
                                                        stroke-width="2"/>
                                                <path d="M5 8L7.00342 10L11 6" stroke="var(--color-block)"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex justify-content-center">
                                <span class="hr-bottom-2"></span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <span class="progress-2-top-text">{{ getProgressText }}</span>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-3 row-statistic d-flex justify-content-center">
                                <div class="row">
                                    <div class="col bg-progress p-0">
                                        <div class="col percentage">
                                            <span>{{ this.readBookDayData.morning }}%</span>
                                        </div>

                                        <div class="load-bar">
                                            <strong :style="{height: this.readBookDayData.morning + '%'}"
                                                    class="load__bar__height"></strong>
                                        </div>
                                        <div class="sun-fog">
                                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
                                                <path
                                                    d="M18.4807 12.25C18.7607 12.25 19.0007 12.01 18.9807 11.73C18.7107 8.1 15.6907 5.25 12.0007 5.25C8.3107 5.25 5.2907 8.1 5.0207 11.73C5.0007 12.01 5.2407 12.25 5.5207 12.25H18.4807Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M20 15.75H4C3.59 15.75 3.25 15.41 3.25 15C3.25 14.59 3.59 14.25 4 14.25H20C20.41 14.25 20.75 14.59 20.75 15C20.75 15.41 20.41 15.75 20 15.75Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M18 18.75H6C5.59 18.75 5.25 18.41 5.25 18C5.25 17.59 5.59 17.25 6 17.25H18C18.41 17.25 18.75 17.59 18.75 18C18.75 18.41 18.41 18.75 18 18.75Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M15 21.75H9C8.59 21.75 8.25 21.41 8.25 21C8.25 20.59 8.59 20.25 9 20.25H15C15.41 20.25 15.75 20.59 15.75 21C15.75 21.41 15.41 21.75 15 21.75Z"
                                                    fill="#0481F4"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex justify-content-center day-part">
                                            {{ $t('statisticsPage.morning') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 row-statistic d-flex justify-content-center">
                                <div class="row">
                                    <div class="col bg-progress p-0">
                                        <div class="col percentage">
                                            <span>{{ this.readBookDayData.afternoon }}%</span>
                                        </div>

                                        <div class="load-bar">
                                            <strong :style="{height: this.readBookDayData.afternoon + '%'}"
                                                    class="load__bar__height"></strong>
                                        </div>
                                        <div class="sun-fog">
                                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
                                                <path
                                                    d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M12 22.96C11.45 22.96 11 22.55 11 22V21.92C11 21.37 11.45 20.92 12 20.92C12.55 20.92 13 21.37 13 21.92C13 22.47 12.55 22.96 12 22.96ZM19.14 20.14C18.88 20.14 18.63 20.04 18.43 19.85L18.3 19.72C17.91 19.33 17.91 18.7 18.3 18.31C18.69 17.92 19.32 17.92 19.71 18.31L19.84 18.44C20.23 18.83 20.23 19.46 19.84 19.85C19.65 20.04 19.4 20.14 19.14 20.14ZM4.86 20.14C4.6 20.14 4.35 20.04 4.15 19.85C3.76 19.46 3.76 18.83 4.15 18.44L4.28 18.31C4.67 17.92 5.3 17.92 5.69 18.31C6.08 18.7 6.08 19.33 5.69 19.72L5.56 19.85C5.37 20.04 5.11 20.14 4.86 20.14ZM22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z"
                                                    fill="#0481F4"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex justify-content-center day-part ">
                                            {{ $t('statisticsPage.afternoon') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 row-statistic d-flex justify-content-center">
                                <div class="row">
                                    <div class="col bg-progress p-0">
                                        <div class="col percentage">
                                            <span>{{ this.readBookDayData.evening }}%</span>
                                        </div>

                                        <div class="load-bar">
                                            <strong :style="{height: this.readBookDayData.evening + '%'}"
                                                    class="load__bar__height"></strong>
                                        </div>
                                        <div class="sun-fog">
                                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
                                                <path
                                                    d="M19.7991 12H4.19907C3.97907 12 3.77907 11.85 3.70907 11.64C0.959073 2.55003 14.8391 -0.939966 16.4491 8.82003C18.1091 9.03003 19.4191 9.97003 20.2291 11.23C20.4391 11.56 20.1891 12 19.7991 12Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M20 15.78H4C3.59 15.78 3.25 15.44 3.25 15.03C3.25 14.62 3.59 14.28 4 14.28H20C20.41 14.28 20.75 14.62 20.75 15.03C20.75 15.44 20.41 15.78 20 15.78Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M18 18.78H6C5.59 18.78 5.25 18.44 5.25 18.03C5.25 17.62 5.59 17.28 6 17.28H18C18.41 17.28 18.75 17.62 18.75 18.03C18.75 18.44 18.41 18.78 18 18.78Z"
                                                    fill="#0481F4"/>
                                                <path
                                                    d="M15 21.78H9C8.59 21.78 8.25 21.44 8.25 21.03C8.25 20.62 8.59 20.28 9 20.28H15C15.41 20.28 15.75 20.62 15.75 21.03C15.75 21.44 15.41 21.78 15 21.78Z"
                                                    fill="#0481F4"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex justify-content-center day-part ">
                                            {{ $t('statisticsPage.evening') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 row-statistic d-flex justify-content-center">
                                <div class="row">
                                    <div class="col bg-progress p-0">
                                        <div class="col percentage">
                                            <span>{{ this.readBookDayData.night }}%</span>
                                        </div>

                                        <div class="load-bar">
                                            <strong :style="{height: this.readBookDayData.night + '%'}"
                                                    class="load__bar__height"></strong>
                                        </div>
                                        <div class="sun-fog">
                                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
                                                <path
                                                    d="M21.5307 15.93C21.3707 15.66 20.9207 15.24 19.8007 15.44C19.1807 15.55 18.5507 15.6 17.9207 15.57C15.5907 15.47 13.4807 14.4 12.0107 12.75C10.7107 11.3 9.91068 9.40999 9.90068 7.36999C9.90068 6.22999 10.1207 5.12999 10.5707 4.08999C11.0107 3.07999 10.7007 2.54999 10.4807 2.32999C10.2507 2.09999 9.71068 1.77999 8.65068 2.21999C4.56068 3.93999 2.03068 8.03999 2.33068 12.43C2.63068 16.56 5.53068 20.09 9.37068 21.42C10.2907 21.74 11.2607 21.93 12.2607 21.97C12.4207 21.98 12.5807 21.99 12.7407 21.99C16.0907 21.99 19.2307 20.41 21.2107 17.72C21.8807 16.79 21.7007 16.2 21.5307 15.93Z"
                                                    fill="#0481F4"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex justify-content-center day-part ">
                                            {{ $t('statisticsPage.night') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col progress-b-row ">
                                <span class="progress-2-top-text">{{ $t('statisticsPage.booksReadThisYear') }}</span>
                            </div>
                        </div>

                        <div v-if="finishedBooks.length === 0" class="row mb-1">
                            <div class="col has-finished-books">{{ $t('statisticsPage.finishedBooks') }}</div>
                        </div>

                        <div class="finished__books">
                            <div
                                class="finished__book"
                                v-for="index in 12"
                                :key="index"
                            >
                                <div v-if="index <= finishedBooks.length" class="finished__book__img">
                                    <img
                                        v-if="finishedBooks[index - 1].image !== null"
                                        :src="localhost + '/media/' + finishedBooks[index - 1].image"
                                        alt="..." class="img" height="78" width="53"
                                    >
                                    <img
                                        v-else-if="finishedBooks[index - 1].coverSourceUrl"
                                        :src="changeHeightImg(finishedBooks[index - 1].coverSourceUrl)"
                                        @error="setDefaultImg"
                                        alt="..." class="img" height="78" width="53"
                                    >
                                    <div class="selected-book">
                                        <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
                                            <circle cx="8" cy="8" fill="#0481F4" r="7" stroke="var(--color-block)"
                                                    stroke-width="2"/>
                                            <path d="M5 8L7.00342 10L11 6" stroke="var(--color-block)"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>

                                <div v-else class="finished__book__index">{{ index }}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer
                @clickFooterHome="isClickedFooter = true"
                @clickFooterSupport="isClickedFooter = true"
                @clickFooterLibrary="isClickedFooter = true"
                @clickFooterSearch="isClickedFooter = true"
                @clickFooterProfile="isClickedFooter = true"
            />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Footer from "../../components/footer/footer.vue";
import Button from "../../components/button/Button.vue";
import {Hooper, Slide} from 'hooper';
import 'hooper/dist/hooper.css';
import TargetModalImage from "@/components/svgImages/TargetModalImage.vue";

export default {
    name: "profile",
    components: {
        TargetModalImage,
        Button,
        Footer,
        Hooper,
        Slide,
    },
    computed: {
        ...mapGetters([
            'getTarget',
            'getUserProfileInfo',
            'getReadBookWeekData',
            'getReadBookWeeklyDayData',
            'getReadBookMonthlyDayData',
            'getReadBookYearlyDayData',
        ]),
        getProgressText() {
            if (this.weekButton) {
                return this.$t('statisticsPage.booksReadThisWeek')
            } else if (this.monthButton) {
                return this.$t('statisticsPage.booksReadThisMonth')
            }

            return this.$t('statisticsPage.booksReadThisYear')
        },
        getCurrentDay() {
            return new Date().getDay();
        },
        isDarkTheme() {
            return localStorage.getItem('theme') === 'dark'
        }
    },
    data() {
        return {
            isClickedFooter: false,
            isClickedPage: false,
            target: {
                id: null,
                howManyBooks: 0,
                howManyDays: 0,
                howManyMinute: 0,
            },
            user: {
                givenName: null,
                familyName: null,
                phone: null,
                gender: null,
                filePath: null,
            },
            finishedBooks: [],
            weekButton: true,
            monthButton: false,
            yearButton: false,
            localhost: process.env.VUE_APP_API_URL,
            readBookWeekData: {},
            readBookDayData: {},
            minutes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200],

            // Progress bar variables
            series: [],
            chartOptions: {
                tooltip: {
                    enabled: false
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none'
                        }
                    }
                },
                chart: {
                    pushToMemory: {
                        enabled: false
                    },
                    width: 350,
                },
                stroke: {
                    show: false,
                    width: 0
                },
                legend: {
                    show: false
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        startAngle: -120,
                        endAngle: 120,
                        offsetY: 10,
                        donut: {
                            size: '60%',
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val) => val ? val.toFixed(0) + '%' : '',
                    style: {
                        colors: ['#ffffff', 'var(--bg-chart)'],
                        fontSize: '20px',
                        fontWeight: 'normal',
                    },
                    dropShadow: {
                        enabled: false,
                    }
                },
                fill: {
                    colors: ['#0481F4', 'var(--bg-chart)'],
                },
                responsive: [{
                    breakpoint: 2000,
                    options: {
                        chart: {
                            width: 350
                        },
                    }
                }]
            },
        }
    },
    methods: {
        ...mapActions([
            'changeOrCreateTarget',
            'fetchUserProfileInfo',
            'fetchReadBookWeekData',
            'fetchReadBookWeeklyDayData',
            'fetchReadBookMonthlyDayData',
            'fetchReadBookYearlyDayData',
        ]),
        setDefaultImg(event) {
            event.target.src = this.localhost + "/book.png"
        },
        pushTarget() {
            const day = document.getElementsByClassName('is-current')[0].innerText
            const minute = document.getElementsByClassName('is-current')[1].innerText
            this.target.howManyDays = parseInt(day.match(/\d+/)[0])
            this.target.howManyMinute = parseInt(minute.match(/\d+/)[0])

            this.changeOrCreateTarget(this.target)
                .then(() => {
                    this.target.id = this.getTarget.id
                    this.target.howManyMinute = this.getTarget.howManyMinute
                    this.target.howManyBooks = this.getTarget.howManyBooks
                    this.target.howManyDays = this.getTarget.howManyDays

                    localStorage.setItem('target', JSON.stringify(this.target))
                })
        },
        weekDayName(item) {
            switch (item) {
                case 1:
                    return this.$t('weekNames.monday')
                case 2:
                    return this.$t('weekNames.tuesday')
                case 3:
                    return this.$t('weekNames.wednesday')
                case 4:
                    return this.$t('weekNames.thursday')
                case 5:
                    return this.$t('weekNames.friday')
                case 6:
                    return this.$t('weekNames.saturday')
                case 7:
                    return this.$t('weekNames.sunday')
            }
        },
        calcIsTarget(item) {
            switch (item) {
                case 1:
                    return this.readBookWeekData.monday > this.target.howManyMinute
                case 2:
                    return this.readBookWeekData.tuesday > this.target.howManyMinute
                case 3:
                    return this.readBookWeekData.wednesday > this.target.howManyMinute
                case 4:
                    return this.readBookWeekData.thursday > this.target.howManyMinute
                case 5:
                    return this.readBookWeekData.friday > this.target.howManyMinute
                case 6:
                    return this.readBookWeekData.saturday > this.target.howManyMinute
                case 7:
                    return this.readBookWeekData.sunday > this.target.howManyMinute
            }
        },
        weekData() {
            this.fetchReadBookWeeklyDayData()
                .then(() => {
                    this.weekButton = true
                    this.monthButton = false
                    this.yearButton = false
                    this.readBookDayData = this.getReadBookWeeklyDayData
                })
        },
        monthData() {
            this.fetchReadBookMonthlyDayData()
                .then(() => {
                    this.weekButton = false
                    this.monthButton = true
                    this.yearButton = false
                    this.readBookDayData = this.getReadBookMonthlyDayData
                })
        },
        yearData() {
            this.fetchReadBookYearlyDayData()
                .then(() => {
                    this.weekButton = false
                    this.monthButton = false
                    this.yearButton = true
                    this.readBookDayData = this.getReadBookYearlyDayData
                })
        },
        changeHeightImg(url) {
            return url.replace('cover', 'cover_100')
        },
        isVisitedToday() {
            let date = new Date().toLocaleDateString()

            if (!localStorage.getItem('lastVisitedDay')) {
                localStorage.setItem('lastVisitedDay', date)
                return false
            }

            if (localStorage.getItem('lastVisitedDay') === date) {
                return true
            } else {
                localStorage.setItem('lastVisitedDay', date)
                return false
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        switch (from.name) {
            case "Home":
            case "SupportService":
            case "Library":
            case "Search":
                sessionStorage.setItem('fromPagePath', from.path)
                break
        }

        next()
    },
    beforeRouteLeave(to, from, next) {
        this.$refs["close-btn__target"].click()

        if (this.isClickedFooter || this.isClickedPage) {
            next()

            this.isClickedFooter = false
            this.isClickedPage = false
        } else if (
            sessionStorage.getItem('fromPagePath') === null ||
            sessionStorage.getItem('fromPagePath') === undefined
        ) {
            to.name === 'Home' ? next() : this.$router.push('/')
        } else {
            if (
                to.name === 'Home' ||
                to.name === 'SupportService' ||
                to.name === 'Library' ||
                to.name === 'Search'
            ) {
                next()
            } else {
                this.$router.push(sessionStorage.getItem('fromPagePath'))
            }

            sessionStorage.removeItem('fromPagePath')
        }
    },
    created() {
        let isVisitedToday = this.isVisitedToday()

        if (
            !localStorage.getItem('finishedBooks') ||
            !localStorage.getItem('target') ||
            !localStorage.getItem('user') ||
            !isVisitedToday
        ) {
            this.fetchUserProfileInfo()
                .then(() => {
                    localStorage.setItem('finishedBooks', JSON.stringify(this.getUserProfileInfo.finishedBooks))
                    localStorage.setItem('target', JSON.stringify(this.getUserProfileInfo.target))
                    localStorage.setItem('user', JSON.stringify(this.getUserProfileInfo.user))

                    this.user = JSON.parse(localStorage.getItem('user'))

                    if (localStorage.getItem('target') !== 'null') {
                        this.target = JSON.parse(localStorage.getItem('target'))
                    }

                    this.finishedBooks = JSON.parse(localStorage.getItem('finishedBooks'))
                })
        } else {
            this.user = JSON.parse(localStorage.getItem('user'))

            if (localStorage.getItem('target') !== 'null') {
                this.target = JSON.parse(localStorage.getItem('target'))
            }

            this.finishedBooks = JSON.parse(localStorage.getItem('finishedBooks'))
        }

        this.fetchReadBookWeekData()
            .then(() => {
                this.readBookWeekData = this.getReadBookWeekData

                if (this.getReadBookWeekData.progressTarget !== 0) {
                    this.series.push(this.readBookWeekData.progressTarget, 100 - this.readBookWeekData.progressTarget)
                } else {
                    this.series.push(100)
                }
            })
        this.fetchReadBookWeeklyDayData()
            .then(() => {
                this.readBookDayData = this.getReadBookWeeklyDayData
            })
    }
}
</script>

<style scoped>
.main-row {
    background-color: var(--color-container);
}

.change_target_btn {
    width: 100% !important;
    height: 65px;
    border: none;
    border-radius: 12px;
    background-color: #0481F4;
    color: white;
}

.offcanvas-bottom {
    height: 89vh;
    background-color: var(--white-to-dark);
    border-radius: 16px 16px 0 0;
}

.block-2 {
    background-color: var(--bg-button-row);
    border-radius: 16px 16px 0 0;
}

.swiper {
    border: none;
    background-color: var(--white-to-dark);
}

.swiper-svg {
    fill: var(--color-swiper);
}

.text-1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-textDarkGray-To-white);
    margin-top: 9px;
}

.text-2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
    margin: 20px 0 16px 30px;
}

.text-3 {
    display: flex;
    align-items: center;
}

.bg-buttons {
    background-color: var(--bg-buttonWhite-dark);
    border-radius: 12px;
    padding: 16px 14px 16px 14px;
    position: relative;
}

.button-install {
    margin: 15px 0 18px 0;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked + .btn {
    color: white !important;
    background: #0481F4 !important;
}

.logo {
    margin-top: 34px;
}

.input {
    border-radius: 18px;
    height: 68px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--blueToWhite);
    background-color: var(--bg-buttonWhite-dark) !important;
    padding: 16px 16px 0 22px !important;
}

.btn-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    border-radius: 6px;
    background-color: #177EE6;
    width: 63px;
    color: white;
}

.btn:focus {
    box-shadow: none;
}

.btn__group__span {
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 75%;
    z-index: 2;
}

.prev-days {
    fill: var(--svg-week-fadeBlue) !important;
}

.current-day {
    fill: #0481F4 !important;
}

.next-days {
    fill: var(--bg-progress-next-days) !important;
}

.svg-week {
    fill: #F4F6FB;
}

.svg > path {
    stroke: var(--color-text);
}

.header-row {
    margin-top: 62px;
    color: var(--color-textDarkGray-To-white);
}

.button-row {
    background-color: var(--bg-button-row-lighter);
    margin-top: 42px;
    border-radius: 6px;
}

.button-row-2 {
    background-color: var(--bg-button-row-lighter);
    border-radius: 6px;
}

.book-button, .audio-button, .for-day, .for-month, .for-year {
    height: 31px;
    width: 100%;
    color: var(--color-textGray-To-lightGray);
    background-color: var(--bg-button-row-lighter);
    border: none;
    font-size: 12px;
}

.for-day {
    border-radius: 6px 0 0 6px;
}

.for-year {
    border-radius: 0 6px 6px 0;
}

.active-button {
    color: #0481F4;
    background-color: var(--btn-focus-bg);
    border-radius: 4px;
}

/* AudioBook va Oddiy Book ga ajratilganda ishlatiladi
/*.audio-button:focus, .book-button:focus {*/
/*    color: #0481F4;*/
/*    background-color: var(--btn-focus-bg);*/
/*    border-radius: 4px;*/
/*}*/

.profile-name {
    margin-top: 29px;
    font-weight: 500;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    color: var(--color-text);
}

.account, .text-5-row {
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    line-height: 15px;
}

.account {
    color: #828282;
    margin-top: 10px;
}

.text-5-row {
    margin-top: 4px;
}

.text2-row-5 {
    color: #828282;
    padding-right: 4px;
}

.row-7 {
    margin-top: -40px;
}

.your-goal-text-1 {
    font-size: 14px;
    color: #0481F4;
    line-height: 17px;
    font-weight: 500;
}

.your-goal-text-2 {
    font-size: 12px;
    color: var(--color-textGray-To-lightGray);
    font-weight: 500;
    margin-top: 4px;
}

.first-block {
    border-radius: 0 0 16px 16px;
    background-color: var(--color-block);
    padding-bottom: 23px;
}

.statistic-text {
    margin: 20px 0 16px 20px;
    color: var(--color-text);
}

.second-block {
    background-color: var(--color-block);
    border-radius: 12px;
    margin-bottom: 110px;
}

.progress-2-top-text {
    color: var(--color-text);
}

.has-finished-books {
    color: #0481F4;
    font-size: 13px;
}

.vl {
    border-left: 1px solid #C3E2FF;
    height: 75px;
    margin-top: 7px;
}

.vl-2 {
    border-left: 1px solid #C3E2FF;
    height: 46px;
}

.read-text {
    font-size: 10px;
    padding-bottom: 22px;
    line-height: 12px;
    color: var(--color-text);
}

.center-vl {
    min-width: 10px;
}

.progress2-row {
    margin-top: 31px;
}

.books-count, .page-count {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    color: #0481F4;
}

.books-count-row {
    min-width: 25px;
}

.page-count-row {
    min-width: 30px;
}

.book-text, .page-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--color-textGray-To-lightGray);
}

.book-time-page-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--color-textGray-To-lightGray);
    text-align: center;
    min-width: 65px;
}

.hr-bottom-1 {
    border-bottom: 1px solid #C3E2FF;
    width: 87%;
}

.hr-bottom-2 {
    margin: 23px 0 18px 0;
    border-bottom: 1px solid #C3E2FF;
    width: 85%;
}

.week__days__row {
    display: flex;
}

.week__day {
    position: relative;
    display: flex;
    flex: 1 0 14.25%;
    align-items: center;
    justify-content: center;
}

.day__text {
    position: absolute;
    color: #0481F4;
    font-size: 12px;
}

.load-bar {
    width: 3px;
    height: 150px;
    background-color: #97CAFB;
    position: relative;
    border-radius: 3px
}

.load__bar__height {
    width: 100%;
    display: block;
    font-size: 12px;
    background-color: #0481F4;
    position: absolute;
    bottom: 0;
    border-radius: 3px;
}

.bg-progress {
    max-width: 55px;
    border-radius: 10px;
    padding: 10px 26px 9px 26px !important;
    background-color: var(--bg-progress);
}

.sun-fog {
    margin-left: -11px !important;
    margin-top: 11px !important;
}

.percentage {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-text);
    margin-left: -11px;
    margin-bottom: 7px;
}

.row-statistic {
    margin-top: 14px;
}

.day-part {
    padding-top: 11px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-text);
}

.progress-b-row {
    margin: 34px 0 15px 0;
}

.selected-week-day {
    position: absolute;
    top: 29px;
}

.user-img {
    position: absolute;
    margin-left: 2px;
    margin-top: 83px;
}

.chart-img {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: fill;
}

.man-img {
    position: absolute;
    margin-left: auto;
    margin-top: 60px;
}

.chart-man-img {
    width: 180px;
    object-fit: cover;
}

.woman-img {
    position: absolute;
    margin-left: auto;
    margin-top: 30px;
}

.chart-woman-img {
    width: 240px;
    object-fit: cover;
}

.img {
    border-radius: 5px;
}

.border-week-day {
    stroke-width: 1px;
    stroke: #0481F4;
}

.hooper {
    height: auto;
    color: #0481F4;
    font-size: 12px;
}

.hooper-slide {
    background-color: var(--bg-button-row);
    border-radius: 6px;
    padding: 6px;
}

.hooper-slide.is-current {
    background-color: #0481F4;
    color: white;
    margin: 0 5px;
}

.hooper__shadow1, .hooper__shadow2 {
    width: 25%;
    height: 100%;
    position: absolute;
    border-radius: 12px;
}

.hooper__shadow1 {
    left: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
}

.hooper__shadow2 {
    right: 0;
    top: 0;
    background: linear-gradient(270deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
}

.finished__books {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.finished__book {
    z-index: 9;
    position: relative;
    display: flex;
    flex: 1 0 14.6%;
    align-items: center;
    justify-content: center;
    color: #0481F4;
    width: 53px;
    height: 78px;
    background: var(--profile-finished-books-bg);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.finished__book__img {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    height: 100%;
    width: 100%;
}

.finished__book__img:before {
    z-index: 11;
    content: '';
    background: rgba(50, 151, 245, 0.6);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.finished__book__img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.selected-book {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -5px;
    margin-right: -4px;
}

.finished__book__index {
    color: var(--blueToWhite);
}

</style>

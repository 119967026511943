<template>
    <div class="speed" @click="changeSpeed">
        {{ speed }}x
    </div>
</template>

<script>
export default {
    name: "listen-speed",
    data() {
        return {
            speed: 1
        }
    },
    methods: {
        changeSpeed() {
            if( this.speed !== 2) {
                this.speed += 0.25
            } else {
                this.speed = 1
            }
            this.$emit('onChangeSpeed', this.speed)
        }
    }
}
</script>

<style scoped>
.speed {
    font-size: 14px;
    font-weight: 500;
    color: var(--blueToFaded);
}
</style>
<template>
    <div class="wrapper">
        <listen-header
            :is-active-text="isActiveText"
            @onChangeTextRead="isActiveText = !isActiveText"
            :book-name="getAudioBookId.name"
            :book-author="getAudioBookId.author"
            :book-img="getAudioBookId.image ? localhost + getAudioBookId.image.contentUrl: getAudioBookId.coverSourceUrl"
            :current-time="sliderValue"
            :audio-book-chapter="this.getAudioBookChapter.audioBookChapters[currentAudio] ? this.getAudioBookChapter.audioBookChapters[currentAudio]['@id'] : null"
        />
        <listen-main
            :is-active-text="isActiveText"
            :book-name="getAudioBookId.name"
            :book-author="getAudioBookId.author"
            :book-img="getAudioBookId.image ? localhost + getAudioBookId.image.contentUrl: getAudioBookId.coverSourceUrl"
            :text="text"
        />
        <listen-range
            :duration-seconds="durationSeconds ? durationSeconds : 0"
            @onChangePosition="setPos"
            :slider-value="sliderValue"
        />
        <listen-player
            @onClickNext="nextAudio"
            @onClickPrev="prevAudio"
            @onClickNextTime="nextTime"
            @onClickPrevTime="prevTime"
            @onStopAudio="stopAudio"
            @onPlayAudio="playAudio"
            :is-playing="isPlaying"
        />
        <div class="footer">
            <listen-speed @onChangeSpeed="changeSpeed"/>
            <listen-bookmarks
                :audio-book-chapters="getAudioBookChapter.audioBookChapters"
                :audio-book-bookmarks="getUserBookmarks"
                :current-audio="currentAudio"
                @onChangeChapter="changeAudio"
                @onSelectBookmark="setBookmarkAudio"
            />
            <listen-chats :audio-book="getAudioBookId"/>
            <listen-save/>
            <listen-sleep/>
<!--            <listen-settings/>-->
        </div>
        <audio
            ref="audio"
            :src="localhost + audio"
            v-on:timeupdate="rangeUpdate"
            preload="auto"
            @loadeddata="setAudioProp(0)"
            @ended="nextAudio"
            hidden
        >
        </audio>
    </div>
</template>
<script>

import ListenRange from "../../components/listen-components/listen-range.vue";
import ListenHeader from "../../components/listen-components/listen-header.vue";
import ListenMain from "../../components/listen-components/listen-main.vue";
import ListenPlayer from "../../components/listen-components/listen-player.vue";
import ListenBookmarks from "../../components/listen-components/listen-bookmarks.vue";
import ListenSave from "../../components/listen-components/listen-save.vue";
import ListenSleep from "../../components/listen-components/listen-sleep.vue";
import ListenChats from "../../components/listen-components/listen-chats.vue";
import ListenSpeed from "../../components/listen-components/listen-speed.vue";
// import ListenSettings from "../../listen-components/listen-settings";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "listen",
    components: {
        ListenSpeed,
        ListenChats,
        ListenSleep,
        ListenSave,
        ListenBookmarks,
        ListenPlayer,
        ListenMain,
        ListenHeader,
        ListenRange
        // ListenSettings,
    },
    computed: {
        ...mapGetters(['getAudioBookId', 'getAudioBookChapter', 'getUserBookmarks'])
    },
    data() {
        return {
            isActiveText: false,
            text: '',
            isPlaying: false,
            sliderValue: 0,
            durationSeconds: 0,
            localhost: process.env.VUE_APP_API_URL,
            currentAudio: 0,
            audio: null,
        }
    },
    methods: {
        ...mapActions(['fetchAudioBookId', 'fetchAudioBookChapter', 'fetchUserBookmarks']),
        playAudio() {
            this.$refs.audio.play()
            this.isPlaying = true
        },
        stopAudio() {
            this.$refs.audio.pause()
            this.isPlaying = false
        },
        changeSpeed(speedValue) {
            this.$refs.audio.playbackRate = speedValue
        },
        nextAudio() {
            if (this.getAudioBookChapter.audioBookChapters.length - 1 !== this.currentAudio) {
                this.changeAudio(this.currentAudio + 1)
            }
        },
        prevAudio() {
            if (this.currentAudio !== 0) {
                this.changeAudio(this.currentAudio - 1)
            }
        },
        nextTime() {
            this.sliderValue += 15
            this.$refs.audio.currentTime = this.sliderValue
        },
        prevTime() {
            this.sliderValue -= 15
            this.$refs.audio.currentTime = this.sliderValue
        },
        setPos(time) {
            this.sliderValue = time
            this.$refs.audio.currentTime = time
        },
        setBookmarkAudio(bookmark) {
            for (let i = 0; this.getAudioBookChapter.audioBookChapters.length > i; i++) {
                if (this.getAudioBookChapter.audioBookChapters[i].id === bookmark.audioBookChapter.id) {
                    this.sliderValue = bookmark.time
                    this.changeAudio(i)

                    setTimeout(() => {
                        this.setPos(bookmark.time)
                    }, 1000)
                }
            }
        },
        setAudioProp() {
            this.durationSeconds = this.$refs.audio.duration
        },
        rangeUpdate() {
            this.sliderValue = this.$refs.audio.currentTime
        },
        changeAudio(index) {
            this.currentAudio = index
            this.audio = this.getAudioBookChapter.audioBookChapters[this.currentAudio].mediaObject.contentUrl
            this.text = this.getAudioBookChapter.audioBookChapters[this.currentAudio].text

            this.$refs.audio.autoplay = this.isPlaying;
        }
    },
    mounted() {
        this.durationSeconds = this.$refs.audio.duration
        this.fetchAudioBookId(this.$route.params.id)
        this.fetchAudioBookChapter(this.$route.params.id).then(() => {
            this.audio = this.getAudioBookChapter.audioBookChapters[this.currentAudio].mediaObject.contentUrl
            this.text = this.getAudioBookChapter.audioBookChapters[this.currentAudio].text
        })
        this.fetchUserBookmarks(this.$route.params.id)
    },
    beforeRouteLeave(to, from, next) {
        this.$emit('parent-route-leave');
        next()
    }
}
</script>

<style scoped>
.wrapper {
    margin: 0 -12px;
    min-height: 100vh;
    display: flex;
    padding: 60px 39px 29px 39px;
    flex-direction: column;
}

.footer {
    display: flex;
    justify-content: space-between;
}
</style>
import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
        fetchUserProfileInfo(context) {
            return fetchModels(context, 'users/profile-info', 0, 'updateUserProfile')
        }
    },
    mutations: {
        updateUserProfile(state, data) {
            state.userProfile = data
        }
    },
    state: {
        userProfile: {
            user: {
                givenName: null,
                familyName: null,
                phone: null,
                gender: null,
                filePath: null,
            },
            target: {
                id: null,
                howManyMinute: null,
                howManyDays: null,
                howManyBooks: null,
            },
            finishedBooks: []
        }
    },
    getters: {
        getUserProfileInfo(state) {
            return state.userProfile
        }
    }
}

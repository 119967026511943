<template>
    <div class="row all">
        <div class="col container-col">

            <div class="row">
                <div class="col fixed-top">
                    <div class="row header-name-row ps-2">
                        <GoBackButton/>

                        <div class="col-8 header-name d-flex justify-content-center p-0">
                            {{ $t('topReaders') }}
                        </div>
                        <div class="col-2"><!-- don't delete (Abdullokh) --> </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="this.getTopReaders.length > 0">
                <div class="col colored-container">

                    <div class="row input-3 mx-auto" v-for="(reader, index) in this.getTopReaders" :key="reader.id">
                        <div class="col-2 img-col">
                            <img
                                v-if="reader.user.image !== null && reader.user.image.contentUrl !== null"
                                :src="localhost + reader.user.image.contentUrl"
                                width="60" height="60" alt="..." class="profile-photo-3"
                            >
                            <img
                                v-else-if="reader.user.gender === 0"
                                src="../../components/images/page-9/man.svg"
                                style="background-color: var(--human-img-bg)"
                                width="60" height="60" alt="..." class="profile-photo-3"
                            >
                            <img
                                v-else
                                src="../../components/images/page-9/woman.svg"
                                style="background-color: var(--human-img-bg)"
                                width="60" height="60" alt="..." class="profile-photo-3"
                            >
                        </div>

                        <div class="col">
                            <div class="row">
                                <div class="col profile-name d-flex">
                                    {{ 1 + index }}.
                                    <span v-if="reader.user.givenName === null" class="mx-1">
                                        {{reader.user.gender === 0 ? 'John' : 'Jane'}}
                                    </span>
                                    <span v-else class="mx-1">{{reader.user.givenName}}</span>
                                    {{reader.user.familyName === null ? 'Doe' : reader.user.familyName}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col spend-time">
                                    <svg v-if="index === 0" width="13" height="13" viewBox="0 0 13 13" fill="none" class="me-1 star">
                                        <path d="M7.23584 1.22039L8.32146 3.40667C8.4695 3.7048 8.86427 4.00293 9.19324 4.05261L11.1589 4.38387C12.4172 4.59918 12.7133 5.51013 11.8086 6.42108L10.2789 7.96969C10.0239 8.22641 9.87587 8.73158 9.95811 9.09595L10.394 11.0089C10.7394 12.5161 9.94166 13.1041 8.61754 12.3174L6.77527 11.216C6.43807 11.0172 5.89526 11.0172 5.55806 11.216L3.70757 12.3091C2.38345 13.0958 1.58568 12.5079 1.9311 11.0007L2.367 9.08767C2.44924 8.73158 2.3012 8.22641 2.04625 7.96141L0.524733 6.42936C-0.37995 5.51841 -0.0838721 4.59918 1.17446 4.39215L3.14009 4.0609C3.46907 4.00293 3.86384 3.71308 4.01188 3.41495L5.0975 1.22868C5.68143 0.0361615 6.65191 0.0361613 7.23584 1.22039Z" fill="#FFCF25"/>
                                    </svg>
                                    <svg v-if="index === 1" width="13" height="13" viewBox="0 0 13 13" fill="none" class="me-1 star">
                                        <path d="M7.23584 1.22052L8.32146 3.40679C8.4695 3.70492 8.86427 4.00305 9.19324 4.05274L11.1589 4.38399C12.4172 4.59931 12.7133 5.51025 11.8086 6.4212L10.2789 7.96981C10.0239 8.22653 9.87587 8.7317 9.95811 9.09608L10.394 11.0091C10.7394 12.5163 9.94166 13.1042 8.61754 12.3175L6.77527 11.2161C6.43807 11.0173 5.89526 11.0173 5.55806 11.2161L3.70757 12.3092C2.38345 13.096 1.58568 12.508 1.9311 11.0008L2.367 9.0878C2.44924 8.7317 2.3012 8.22653 2.04625 7.96153L0.524733 6.42948C-0.37995 5.51853 -0.0838721 4.59931 1.17446 4.39227L3.14009 4.06102C3.46907 4.00305 3.86384 3.7132 4.01188 3.41507L5.0975 1.2288C5.68143 0.0362835 6.65191 0.0362834 7.23584 1.22052Z" fill="#a2a2a2"/>
                                    </svg>
                                    <svg v-if="index === 2" width="13" height="13" viewBox="0 0 13 13" fill="none" class="me-1 star">
                                        <path d="M7.23584 1.22052L8.32146 3.40679C8.4695 3.70492 8.86427 4.00305 9.19324 4.05274L11.1589 4.38399C12.4172 4.59931 12.7133 5.51025 11.8086 6.4212L10.2789 7.96981C10.0239 8.22653 9.87587 8.7317 9.95811 9.09608L10.394 11.0091C10.7394 12.5163 9.94166 13.1042 8.61754 12.3175L6.77527 11.2161C6.43807 11.0173 5.89526 11.0173 5.55806 11.2161L3.70757 12.3092C2.38345 13.096 1.58568 12.508 1.9311 11.0008L2.367 9.0878C2.44924 8.7317 2.3012 8.22653 2.04625 7.96153L0.524733 6.42948C-0.37995 5.51853 -0.0838721 4.59931 1.17446 4.39227L3.14009 4.06102C3.46907 4.00305 3.86384 3.7132 4.01188 3.41507L5.0975 1.2288C5.68143 0.0362835 6.65191 0.0362834 7.23584 1.22052Z" fill="#d3ab66"/>
                                    </svg>
                                    <span style="color: #828282">{{ reader.readTime }} {{ $t('minutes') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-end p-0 cup">
                            <svg v-if="index === 0" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M13.1251 21.2917H10.5001C9.21679 21.2917 8.16679 22.3417 8.16679 23.6251V23.9167H7.00012C6.52179 23.9167 6.12512 24.3134 6.12512 24.7917C6.12512 25.2701 6.52179 25.6667 7.00012 25.6667H21.0001C21.4785 25.6667 21.8751 25.2701 21.8751 24.7917C21.8751 24.3134 21.4785 23.9167 21.0001 23.9167H19.8335V23.6251C19.8335 22.3417 18.7835 21.2917 17.5001 21.2917H14.8751V18.6201C14.5835 18.6551 14.2918 18.6667 14.0001 18.6667C13.7085 18.6667 13.4168 18.6551 13.1251 18.6201V21.2917Z" fill="url(#paint0_linear_2_11753)"/>
                                <path d="M21.5602 13.58C22.3302 13.2884 23.0068 12.81 23.5435 12.2734C24.6285 11.0717 25.3402 9.63671 25.3402 7.95671C25.3402 6.27671 24.0218 4.95837 22.3418 4.95837H21.6885C20.9302 3.40671 19.3435 2.33337 17.5002 2.33337H10.5002C8.65682 2.33337 7.07016 3.40671 6.31182 4.95837H5.65849C3.97849 4.95837 2.66016 6.27671 2.66016 7.95671C2.66016 9.63671 3.37182 11.0717 4.45682 12.2734C4.99349 12.81 5.67016 13.2884 6.44016 13.58C7.65349 16.5667 10.5701 18.6667 14.0001 18.6667C17.4301 18.6667 20.3468 16.5667 21.5602 13.58ZM17.3135 9.85837L16.5902 10.745C16.4735 10.8734 16.3918 11.13 16.4035 11.305L16.4735 12.4484C16.5202 13.1484 16.0185 13.51 15.3652 13.2534L14.3035 12.8334C14.1402 12.775 13.8602 12.775 13.6968 12.8334L12.6352 13.2534C11.9818 13.51 11.4802 13.1484 11.5268 12.4484L11.5968 11.305C11.6085 11.13 11.5268 10.8734 11.4102 10.745L10.6868 9.85837C10.2318 9.32171 10.4302 8.72671 11.1068 8.55171L12.2152 8.27171C12.3902 8.22504 12.6002 8.06171 12.6935 7.91004L13.3118 6.95337C13.6968 6.35837 14.3035 6.35837 14.6885 6.95337L15.3068 7.91004C15.4002 8.06171 15.6102 8.22504 15.7852 8.27171L16.8935 8.55171C17.5702 8.72671 17.7685 9.32171 17.3135 9.85837Z" fill="url(#paint1_linear_2_11753)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_2_11753" x1="24.5001" y1="30.5001" x2="6.50012" y2="2.50006" gradientUnits="userSpaceOnUse">
                                        <stop offset="1" stop-color="#FFCE22"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_2_11753" x1="24.5001" y1="30.5001" x2="6.50012" y2="2.50006" gradientUnits="userSpaceOnUse">
                                        <stop offset="1" stop-color="#FFCE22"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <svg v-if="index === 1" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M13.1251 21.2917H10.5001C9.21679 21.2917 8.16679 22.3417 8.16679 23.6251V23.9167H7.00012C6.52179 23.9167 6.12512 24.3134 6.12512 24.7917C6.12512 25.2701 6.52179 25.6667 7.00012 25.6667H21.0001C21.4785 25.6667 21.8751 25.2701 21.8751 24.7917C21.8751 24.3134 21.4785 23.9167 21.0001 23.9167H19.8335V23.6251C19.8335 22.3417 18.7835 21.2917 17.5001 21.2917H14.8751V18.6201C14.5835 18.6551 14.2918 18.6667 14.0001 18.6667C13.7085 18.6667 13.4168 18.6551 13.1251 18.6201V21.2917Z" fill="url(#paint0_linear_2_11781)"/>
                                <path d="M21.5602 13.58C22.3302 13.2884 23.0068 12.81 23.5435 12.2734C24.6285 11.0717 25.3402 9.63671 25.3402 7.95671C25.3402 6.27671 24.0218 4.95837 22.3418 4.95837H21.6885C20.9302 3.40671 19.3435 2.33337 17.5002 2.33337H10.5002C8.65682 2.33337 7.07016 3.40671 6.31182 4.95837H5.65849C3.97849 4.95837 2.66016 6.27671 2.66016 7.95671C2.66016 9.63671 3.37182 11.0717 4.45682 12.2734C4.99349 12.81 5.67016 13.2884 6.44016 13.58C7.65349 16.5667 10.5701 18.6667 14.0001 18.6667C17.4301 18.6667 20.3468 16.5667 21.5602 13.58ZM17.3135 9.85837L16.5902 10.745C16.4735 10.8734 16.3918 11.13 16.4035 11.305L16.4735 12.4484C16.5202 13.1484 16.0185 13.51 15.3652 13.2534L14.3035 12.8334C14.1402 12.775 13.8602 12.775 13.6968 12.8334L12.6352 13.2534C11.9818 13.51 11.4802 13.1484 11.5268 12.4484L11.5968 11.305C11.6085 11.13 11.5268 10.8734 11.4102 10.745L10.6868 9.85837C10.2318 9.32171 10.4302 8.72671 11.1068 8.55171L12.2152 8.27171C12.3902 8.22504 12.6002 8.06171 12.6935 7.91004L13.3118 6.95337C13.6968 6.35837 14.3035 6.35837 14.6885 6.95337L15.3068 7.91004C15.4002 8.06171 15.6102 8.22504 15.7852 8.27171L16.8935 8.55171C17.5702 8.72671 17.7685 9.32171 17.3135 9.85837Z" fill="url(#paint1_linear_2_11781)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_2_11781" x1="24.5001" y1="30.5001" x2="6.50012" y2="2.50006" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#A2A2A2" offset="1"/>
                                        <stop offset="1" stop-color="#E4E4E4"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_2_11781" x1="24.5001" y1="30.5001" x2="6.50012" y2="2.50006" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#A2A2A2" offset="1"/>
                                        <stop offset="1" stop-color="#E4E4E4"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <svg v-if="index === 2" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M13.1251 21.2917H10.5001C9.21679 21.2917 8.16679 22.3417 8.16679 23.6251V23.9167H7.00012C6.52179 23.9167 6.12512 24.3134 6.12512 24.7917C6.12512 25.2701 6.52179 25.6667 7.00012 25.6667H21.0001C21.4785 25.6667 21.8751 25.2701 21.8751 24.7917C21.8751 24.3134 21.4785 23.9167 21.0001 23.9167H19.8335V23.6251C19.8335 22.3417 18.7835 21.2917 17.5001 21.2917H14.8751V18.6201C14.5835 18.6551 14.2918 18.6667 14.0001 18.6667C13.7085 18.6667 13.4168 18.6551 13.1251 18.6201V21.2917Z" fill="url(#paint0_linear_2_11809)"/>
                                <path d="M21.5602 13.58C22.3302 13.2884 23.0068 12.81 23.5435 12.2734C24.6285 11.0717 25.3402 9.63671 25.3402 7.95671C25.3402 6.27671 24.0218 4.95837 22.3418 4.95837H21.6885C20.9302 3.40671 19.3435 2.33337 17.5002 2.33337H10.5002C8.65682 2.33337 7.07016 3.40671 6.31182 4.95837H5.65849C3.97849 4.95837 2.66016 6.27671 2.66016 7.95671C2.66016 9.63671 3.37182 11.0717 4.45682 12.2734C4.99349 12.81 5.67016 13.2884 6.44016 13.58C7.65349 16.5667 10.5701 18.6667 14.0001 18.6667C17.4301 18.6667 20.3468 16.5667 21.5602 13.58ZM17.3135 9.85837L16.5902 10.745C16.4735 10.8734 16.3918 11.13 16.4035 11.305L16.4735 12.4484C16.5202 13.1484 16.0185 13.51 15.3652 13.2534L14.3035 12.8334C14.1402 12.775 13.8602 12.775 13.6968 12.8334L12.6352 13.2534C11.9818 13.51 11.4802 13.1484 11.5268 12.4484L11.5968 11.305C11.6085 11.13 11.5268 10.8734 11.4102 10.745L10.6868 9.85837C10.2318 9.32171 10.4302 8.72671 11.1068 8.55171L12.2152 8.27171C12.3902 8.22504 12.6002 8.06171 12.6935 7.91004L13.3118 6.95337C13.6968 6.35837 14.3035 6.35837 14.6885 6.95337L15.3068 7.91004C15.4002 8.06171 15.6102 8.22504 15.7852 8.27171L16.8935 8.55171C17.5702 8.72671 17.7685 9.32171 17.3135 9.85837Z" fill="url(#paint1_linear_2_11809)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_2_11809" x1="24.5001" y1="30.5001" x2="6.50012" y2="2.50006" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#AE5606" offset="1"/>
                                        <stop offset="1" stop-color="#FFC7A0"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_2_11809" x1="24.5001" y1="30.5001" x2="6.50012" y2="2.50006" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#AE5606" offset="1"/>
                                        <stop offset="1" stop-color="#FFC7A0"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
import Footer from "../../components/footer/footer.vue";
import {mapActions, mapGetters} from "vuex";
import GoBackButton from "@/components/modals/GoBackButton.vue";

export default {
    name: "top-readers",
    components: {GoBackButton, Footer},
    computed: {
        ...mapGetters(['getTopReaders'])
    },
    methods: {
        ...mapActions(['fetchTopReaders'])
    },
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
        }
    },
    mounted() {
        this.fetchTopReaders()
    }
}
</script>

<style scoped>
.container-col {
    font-family: 'Montserrat', sans-serif !important;
}

.all {
    background-color: var(--color-container);
    padding-bottom: 90px;
    margin-top: 150px;
    min-height: calc(100vh - 150px);
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.colored-container {
    background-color: var(--color-container) !important;
}

.cup {
    margin: 29px 29px 0 0;
    display: flex;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.profile-photo-3 {
    border-radius: 50%;
    margin: 14px 0 0 15px;
}

.input-3 {
    height: 86px;
    border-radius: 12px;
    width: 97%;
    margin-top: 8px;
    background-color: var(--color-block);
}

.img-col {
    width: 90px;
    padding: 0;
}

.profile-name {
    margin-top: 25px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: var(--color-Black-To-White);
}

.spend-time {
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    margin-top: 4px;
}

.star {
    margin-top: -4px;
}

</style>

import pushModel from '../../mixin/store/api/pushModel'
import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
        fetchMessages(context, url) {
            return fetchModels(context, 'chat_messages?order[id]=desc&' + url, 1,'updateMessages')
        },
        pushMessage(context, data) {
            return pushModel(context, 'chat_messages', data)
        },
        createChat(context, data) {
            return pushModel(context, 'chats', data, 'updateCreatedChat')
        },
    },
    mutations: {
        updateCreatedChat(state, data) {
            state.createdChat = data
        },
        updateMessages(state, data) {
            state.messages = data
        },
    },
    state: {
        createdChat: {
            "@id": null,
            id: null,
        },
        messages: {
            models: [],
            totalItems: 0,
        },
    },
    getters: {
        getCreatedChat(state) {
            return state.createdChat
        },
        getMessages(state) {
            return state.messages.models
        }
    },
}

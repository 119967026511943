<template>
    <div class="row content__row" ref="content">
        <div class="col">
            <div class="row">
                <div class="col-12 text-end" :class="bodyClientHeight < 740 ? 'my-4' : 'my-5'">
                    <router-link to="/login-fourth" :class="bodyClientWidth < 320 ? 'me-2' : 'me-3'" class="cancel">{{ $t('login.skip') }}</router-link>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <img src="../../components/images/login-first-logo.png" alt="" class="logo__img">
                </div>
            </div>

            <div class="row">
                <div class="col text-one">
                    {{ $t('login.weAreHere') }}
                </div>
            </div>

            <div class="row">
                <span class="text-two"> {{ $t('login.in1books') }} </span>
            </div>

            <div class="row">
                <div class="col-12 fixed-bottom d-flex justify-content-end">
                    <router-link to="/login-second" class="button">
                        <svg width="50" height="50" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                        </svg>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "login-first",
    data() {
        return {
            bodyClientWidth: 0,
            bodyClientHeight: 0,
        }
    },
    mounted() {
        this.bodyClientWidth = this.$refs.content.clientWidth
        this.bodyClientHeight = this.$refs.content.clientHeight
    }
}
</script>
<style scoped>
.content__row {
    height: 100vh;
    background-color: var(--white-to-dark);
}

.cancel {
    color: var(--color-textGray-To-lightGray);
    text-decoration-line: none !important;
}

.logo__img {
    animation: zoomIn;
    animation-duration: 0.7s;
    width: 100%;
    height: auto;
}

.text-one {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 46px;
    font-weight: bold;
    color: var(--color-text);
}

.text-two {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    margin-top: 12px;
    color: var(--color-textGray-To-lightGray);
    align-items: center !important;
}

.button {
    width: 50px;
    height: 50px;
    margin: 0 15px 30px 0;
    animation: pulse;
    animation-duration: 0.7s;
}

svg {
    border-radius: 150px;
    border: 2px none #0481F4;
    border-right-style: solid;
    padding: 3px;
    margin-top: 10px;
}

</style>

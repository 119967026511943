<template>
    <div id="playerWrapper" ref="playerWrapper" class="row live-video__row">
        <div class="col p-0">
            <div class="player-wrapper">
                <div ref="player_id" id="player_id"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Ovenplayer from "ovenplayer/dist/ovenplayer";

export default {
    name: "live-video",
    mounted() {
        Ovenplayer.create('player_id', {
            type: "webrtc",
            file: "wss://stream.daniil.moscow:3334/app/stream",
            mute: true,
            autoStart: true,
            controls: true,
        })
    }
}
</script>

<style scoped>

.live-video__row {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: black;
}

</style>
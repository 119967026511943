import axios from "../../../plugins/vuex/axios";

/**
 * @param context
 * @param url
 * @param data
 * @param mutationName
 * @returns {Promise<unknown>}
 */
export default function (context, url, data, mutationName = null) {
    return new Promise((resolve, reject) => {
        axios.post('/' + url, data)
            .then(response => {
                if (mutationName !== null) {
                    context.commit(mutationName, response.data)
                }
                resolve()
            })
            .catch((error) => {
                if (error === 'connectionRefused') {
                    reject(error)
                }
                reject(error.response.data['hydra:description'])
            })
    })
}

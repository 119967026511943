import Vue from "vue";
import Vuex from "vuex"
import device from "../../store/modules/device";
import authenticate from "../../store/modules/authenticate";
import category from "../../store/modules/category";
import pushTarget from "../../store/modules/pushTarget";
import favorites from "../../store/modules/favorites";
import book from "../../store/modules/book";
import bookId from "../../store/modules/bookId";
import AudioBookId from "../../store/modules/audioBookId";
import user from "../../store/modules/user";
import city from "../../store/modules/city";
import mediaObject from "../../store/modules/mediaObject";
import savedAudioBook from "../../store/modules/savedAudioBook";
import supportService from "../../store/modules/supportService";
import userLocation from "../../store/modules/userLocation";
import audioBook from "../../store/modules/audioBook";
import recentRequest from "../../store/modules/recentRequest";
import topReaders from "../../store/modules/topReaders";
import moment from "../../store/modules/moment";
import quote from "../../store/modules/quote";
import verification from "../../store/modules/verification";
import banner from "../../store/modules/banner";
import myShelf from "../../store/modules/myShelf";
import vote from "../../store/modules/vote";
import saved from "../../store/modules/saved";
import readingBook from "../../store/modules/readingBook";
import finishedBook from "../../store/modules/finishedBook";
import chat from '../../store/modules/chat'
import readBook from "../../store/modules/readBook";
import order from "../../store/modules/order";
import notification from '@/store/modules/notification'
import recommendedBooks from "@/store/modules/recommendedBooks/recommendedBooks";
import audiobookTime from "../../store/modules/audiobookTime";
import fetchDiscountedBooks from "@/store/modules/promotionAndDiscount/fetchDiscountedBooks";
import userProfileInfo from "@/store/modules/profile/userProfileInfo";

Vue.use(Vuex)
// TODO audioBook va moment modullarda nimadir neto. Shular sabab options figuralik qavsi tagida chiziq bor.
export default new Vuex.Store({
    modules: {
        audioBook,
        AudioBookId,
        audiobookTime,
        authenticate,
        banner,
        book,
        bookId,
        category,
        chat,
        city,
        device,
        favorites,
        fetchDiscountedBooks,
        finishedBook,
        mediaObject,
        moment,
        myShelf,
        notification,
        order,
        pushTarget,
        quote,
        recentRequest,
        readingBook,
        readBook,
        recommendedBooks,
        saved,
        savedAudioBook,
        supportService,
        topReaders,
        user,
        userLocation,
        userProfileInfo,
        verification,
        vote,
    }
})

import pushModel from "../../mixin/store/api/pushModel";

export default {
    actions: {
        pushToSupport(context, data) {
            return pushModel(context, 'tech_supports', data, 'updateSupport');
        },
    },
    mutations: {
        updateSupport(state, data) {
            state.support = data
        }
    },
    state: {
        support: {
            models: [],
            totalItems: 0,
        },
    },
    getters: {
        getSupport(state) {
            return state.support.models
        },
    },
}
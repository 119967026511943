import fetchModels from "../../mixin/store/api/fetchModels";
import pushModel from "@/mixin/store/api/pushModel";
import deleteModel from "@/mixin/store/api/deleteModel";

export default {
    actions: {
        deleteBook(context, id) {
            return deleteModel(context, 'votes', id)
        },
        fetchVotes(context) {
            return fetchModels(context, 'votes/current-user', null, 'updateVotes')
        },
        fetchVotesByAudioBookId(context, data) {
            return fetchModels(context, 'votes/audio-book?audioBook=' + data.id, data.page, 'updateAudioBookVotes')
        },
        fetchVotesByBookId(context, data) {
            return fetchModels(context, 'votes/book?book=' + data.id, data.page, 'updateBookVotes')
        },
        fetchCountCommentAudioBook(context, audioBookId) {
            return fetchModels(context, 'votes/audio-book_comment-count?audioBook=' + audioBookId, null, 'updateCountAudioBookComments')
        },
        fetchCountCommentBook(context, bookId) {
            return fetchModels(context, 'votes/book_comment-count?book=' + bookId, null, 'updateCountBookComments')
        },
        fetchUserVoteByBook(context, bookId) {
            return fetchModels(context, 'votes/user_by_book?book=' + bookId, null, 'updateUserVoteByBook')
        },
        fetchUserVoteByAudioBook(context, audioBookId) {
            return fetchModels(context, 'votes/user_by_audio_book?audioBook=' + audioBookId, null, 'updateUserVoteByAudioBook')
        },
        pushVote(context, data) {
            return pushModel(context, 'votes', data)
        },
        changeVote(context, data) {
            return pushModel(context, 'votes/change', data)
        }
    },
    mutations: {
        updateVotes(state, data) {
            state.votes = data
        },
        updateUserVoteByBook(state, data) {
            state.userVoteBook = data
        },
        updateUserVoteByAudioBook(state, data) {
            state.userVoteAudioBook = data
        },
        updateAudioBookVotes(state, data) {
            state.audioBookVotes = data
        },
        updateCountAudioBookComments(state, data) {
            state.countAudioBookComments = data
        },
        updateBookVotes(state, data) {
            state.bookVotes = data
        },
        updateCountBookComments(state, data) {
            state.countBookComments = data
        },
        clearUserVoteBook(state) {
            state.userVoteBook = null
        },
        clearUserVoteAudioBook(state) {
            state.userVoteAudioBook = null
        }
    },

    state: {
        userVoteBook: null,
        userVoteAudioBook: null,
        votes: {
            models: [],
            totalItems: 0
        },
        audioBookVotes: {
            models: [],
            totalItems: 0
        },
        bookVotes: {
            models: [],
            totalItems: 0
        },
        countAudioBookComments: 0,
        countBookComments: 0
    },

    getters: {
        getUserVoteBook(state) {
            return state.userVoteBook
        },
        getUserVoteAudioBook(state) {
            return state.userVoteAudioBook
        },
        getVotes(state) {
            return state.votes.models
        },
        getAudioBookVotes(state) {
            return state.audioBookVotes.models
        },
        getCountAudioBookComments(state) {
            return state.countAudioBookComments
        },
        getBookVotes(state) {
            return state.bookVotes.models
        },
        getCountBookComments(state) {
            return state.countBookComments
        },
    }
}

<template>
    <div class="row">
        <div class="col container-col">

            <div class="row header-name-row">
                <div class="col pt-3 pb-2 d-flex justify-content-center">
                    <div class="form-input mx-1">
                        <input
                            @focusin="showBeforeSearched = true; isSearching = false"
                            @focusout="checkSearching"
                            @input="search()"
                            v-model="text"
                            :class="{'search': isSearching}"
                            class="ps-3 search-input"
                            type="text"
                            :placeholder="$t('home.search')"
                        >
                        <span class="icon">
                            <img src="../../components/images/Search.png" class="fa fa-envelope" aria-hidden="true" alt="">
                        </span>
                    </div>
                </div>

                <div v-if="text.length < 3 && showBeforeSearched">
                    <div class="row">
                        <div class="col p-0 mb-3 ms-1 text-1">
                            {{ $t('search.recentRequest') }}
                        </div>
                    </div>

                    <div class="row pe-0" v-for="request in getRecentRequest" :key="request.id">
                        <div class="col mb-3 ms-1 text-2" @click="text = request.request; search()">
                          {{ request.request }}
                        </div>
                        <div class="col-2 d-flex justify-content-center pe-0">
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.49935 16.1147C4.29893 16.1147 0.884766 12.7005 0.884766 8.50008C0.884766 4.29966 4.29893 0.885498 8.49935 0.885498C12.6998 0.885498 16.1139 4.29966 16.1139 8.50008C16.1139 12.7005 12.6998 16.1147 8.49935 16.1147ZM8.49935 1.948C4.88685 1.948 1.94727 4.88758 1.94727 8.50008C1.94727 12.1126 4.88685 15.0522 8.49935 15.0522C12.1118 15.0522 15.0514 12.1126 15.0514 8.50008C15.0514 4.88758 12.1118 1.948 8.49935 1.948Z" fill="#0481F4"/>
                                <path d="M11.1271 11.2837C11.035 11.2837 10.9429 11.2625 10.8579 11.2058L8.66206 9.89541C8.11664 9.56958 7.71289 8.85416 7.71289 8.22375V5.31958C7.71289 5.02916 7.95372 4.78833 8.24414 4.78833C8.53456 4.78833 8.77539 5.02916 8.77539 5.31958V8.22375C8.77539 8.47875 8.98789 8.85416 9.20747 8.98166L11.4033 10.2921C11.6583 10.4408 11.7362 10.7667 11.5875 11.0217C11.4812 11.1917 11.3041 11.2837 11.1271 11.2837Z" fill="#0481F4"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div v-if="text.length < 3 && !showBeforeSearched">
                    <div class="row">
                        <div class="col text-1 text-3">
                            {{ $t('search.recommendationsForToday') }}
                        </div>
                    </div>

                    <div class="row p-0">
                        <div class="col p-0 me-0">
                            <!--recommended books row begin -->
                            <div class="wrapper ps-2">
                                <div v-for="recommendsBook in todayRecommendBooks" :key="recommendsBook.id">
                                    <div
                                        @click="$router.push('/book/' + recommendsBook.id)"
                                        class="row profiles ms-2"
                                        ref="bookImage"
                                        :style="{'background': `center / cover no-repeat  url(${recommendsBook.image ? localhost + recommendsBook.image.contentUrl: changeHeightImg(recommendsBook.coverSourceUrl)}), center / contain no-repeat url(https://smartmobilestudio.com/wp-content/uploads/2012/06/leather-book-preview-300x252.png)`}"
                                    >
                                        <div class="col book-pc">
                                            <div class="row">
                                                <div class="col book-name">
                                                    {{ recommendsBook.name }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col book-author">
                                                    {{ recommendsBook.author }}
                                                </div>
                                            </div>
                                            <div class="row"
                                                 v-if="recommendsBook.votesTotalCount && recommendsBook.votesTotalUser">
                                                <div class="col ranking-height p-0">
                                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg" class="gold-star"
                                                         v-for="star in Math.floor(recommendsBook.votesTotalCount / recommendsBook.votesTotalUser)"
                                                         :key="star.index">
                                                        <path
                                                            d="M3.46344 0.832678L3.87055 1.65253C3.92606 1.76433 4.0741 1.87613 4.19747 1.89476L4.93458 2.01898C5.40645 2.09972 5.51748 2.44133 5.17823 2.78294L4.60457 3.36366C4.50897 3.45993 4.45345 3.64937 4.48429 3.78601L4.64775 4.50338C4.77729 5.06859 4.47812 5.28908 3.98158 4.99405L3.29073 4.58102C3.16428 4.50649 2.96072 4.50649 2.83427 4.58102L2.14034 4.99095C1.64379 5.28597 1.34463 5.06548 1.47416 4.50028L1.63762 3.78291C1.66847 3.64937 1.61295 3.45993 1.51734 3.36056L0.946775 2.78604C0.607519 2.44444 0.718548 2.09972 1.19042 2.02209L1.92753 1.89787C2.0509 1.87613 2.19894 1.76744 2.25445 1.65564L2.66156 0.835784C2.88054 0.388591 3.24446 0.388591 3.46344 0.832678Z"
                                                            fill="#FFCF25"/>
                                                    </svg>
                                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none"
                                                         v-for="emptyStar in 5 - (Math.floor(recommendsBook.votesTotalCount / recommendsBook.votesTotalUser))"
                                                         :key="emptyStar.index"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.46344 0.832678L3.87055 1.65253C3.92606 1.76433 4.0741 1.87613 4.19747 1.89476L4.93458 2.01898C5.40645 2.09972 5.51748 2.44133 5.17823 2.78294L4.60457 3.36366C4.50897 3.45993 4.45345 3.64937 4.48429 3.78601L4.64775 4.50338C4.77729 5.06859 4.47812 5.28908 3.98158 4.99405L3.29073 4.58102C3.16428 4.50649 2.96072 4.50649 2.83427 4.58102L2.14034 4.99095C1.64379 5.28597 1.34463 5.06548 1.47416 4.50028L1.63762 3.78291C1.66847 3.64937 1.61295 3.45993 1.51734 3.36056L0.946775 2.78604C0.607519 2.44444 0.718548 2.09972 1.19042 2.02209L1.92753 1.89787C2.0509 1.87613 2.19894 1.76744 2.25445 1.65564L2.66156 0.835784C2.88054 0.388591 3.24446 0.388591 3.46344 0.832678Z"
                                                            fill="#C6C6C6"/>
                                                        <path
                                                            d="M3.46344 0.832678L3.87055 1.65253C3.92606 1.76433 4.0741 1.87613 4.19747 1.89476L4.93458 2.01898C5.40645 2.09972 5.51748 2.44133 5.17823 2.78294L4.60457 3.36366C4.50897 3.45993 4.45345 3.64937 4.48429 3.78601L4.64775 4.50338C4.77729 5.06859 4.47812 5.28908 3.98158 4.99405L3.29073 4.58102C3.16428 4.50649 2.96072 4.50649 2.83427 4.58102L2.14034 4.99095C1.64379 5.28597 1.34463 5.06548 1.47416 4.50028L1.63762 3.78291C1.66847 3.64937 1.61295 3.45993 1.51734 3.36056L0.946775 2.78604C0.607519 2.44444 0.718548 2.09972 1.19042 2.02209L1.92753 1.89787C2.0509 1.87613 2.19894 1.76744 2.25445 1.65564L2.66156 0.835784C2.88054 0.388591 3.24446 0.388591 3.46344 0.832678Z"
                                                            fill="white"/>
                                                    </svg>
                                                </div>
                                                <div class="col vote">
                                                    {{ (recommendsBook.votesTotalCount / recommendsBook.votesTotalUser).toFixed(1) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!--recommended books row end -->
                        </div>
                    </div>
                </div>
            </div>

            <div
                :class="{'mt-3' : index === 0}"
                class="row justify-content-center"
                v-for="(book, index) in allSearchingBooks"
                :key="index"
            >
                <div
                    class="col-11 bg-white mt-2 card_book"
                    @click="pushRelatedBook(book)"
                >
                    <div class="row">
                        <div class="col-2 img_content">
                            <img v-if="book.image" :src="localhost + book.image.contentUrl" class="search-book-img" alt="..." width="43" height="64">
                            <img v-else-if="book.coverSourceUrl" :src="changeHeightImg(book.coverSourceUrl)" class="search-book-img" alt="..." width="43" height="64">
                            <img v-else src="../../components/images/default_book.png" class="search-book-img" alt="..." width="43" height="64">
                            <svg v-if="book['@type'] === 'AudioBook'" width="13" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="head-phone">
                                <rect width="16" height="16" rx="8" fill="white"/>
                                <path
                                    d="M4.14527 10.7709C3.97444 10.7709 3.83277 10.6292 3.83277 10.4584V8.08335C3.81194 6.95419 4.23277 5.88752 5.0161 5.08752C5.79944 4.29169 6.84944 3.85419 7.9786 3.85419C10.2869 3.85419 12.1661 5.73335 12.1661 8.04169V10.4167C12.1661 10.5875 12.0244 10.7292 11.8536 10.7292C11.6828 10.7292 11.5411 10.5875 11.5411 10.4167V8.04169C11.5411 6.07919 9.94527 4.47919 7.9786 4.47919C7.0161 4.47919 6.12444 4.85002 5.46194 5.52502C4.79527 6.20419 4.4411 7.10835 4.45777 8.07502V10.4542C4.45777 10.6292 4.32027 10.7709 4.14527 10.7709Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M5.47565 8.1875H5.42148C4.54648 8.1875 3.83398 8.9 3.83398 9.775V10.5583C3.83398 11.4333 4.54648 12.1458 5.42148 12.1458H5.47565C6.35065 12.1458 7.06315 11.4333 7.06315 10.5583V9.775C7.06315 8.9 6.35065 8.1875 5.47565 8.1875Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M10.5792 8.1875H10.525C9.65 8.1875 8.9375 8.9 8.9375 9.775V10.5583C8.9375 11.4333 9.65 12.1458 10.525 12.1458H10.5792C11.4542 12.1458 12.1667 11.4333 12.1667 10.5583V9.775C12.1667 8.9 11.4542 8.1875 10.5792 8.1875Z"
                                    fill="#0481F4"/>
                            </svg>
                            <svg v-if="book['@type'] === 'Book'" width="13" height="13" viewBox="0 0 16 16" fill="none" class="head-phone" xmlns="http://www.w3.org/2000/svg">
                                <rect width="16" height="16" rx="8" fill="white"/>
                                <path
                                    d="M7.68815 5.50011V11.2959C7.68815 11.5918 7.38815 11.7959 7.11732 11.6834C6.34232 11.3584 5.37982 11.0751 4.69648 10.9834L4.56732 10.9668C4.16315 10.9168 3.83398 10.5459 3.83398 10.1418V5.18761C3.83398 4.68761 4.23815 4.28345 4.73815 4.28345H4.76732C5.55065 4.35011 6.70482 4.71678 7.46732 5.12928C7.60482 5.20428 7.68815 5.34178 7.68815 5.50011Z"
                                    fill="#0481F4"/>
                                <path
                                    d="M11.2625 4.28345H11.2375C11.1375 4.29178 11.0292 4.30428 10.9167 4.32511C10.4042 4.40845 9.79583 4.58345 9.25 4.80011C8.99167 4.90428 8.75 5.01678 8.5375 5.12928C8.4 5.20428 8.3125 5.34595 8.3125 5.50011V11.2959C8.3125 11.5918 8.6125 11.7959 8.88333 11.6834C9.65833 11.3584 10.6208 11.0751 11.3042 10.9834L11.4333 10.9668C11.8375 10.9168 12.1667 10.5459 12.1667 10.1418V5.18761C12.1667 4.68761 11.7625 4.28345 11.2625 4.28345ZM11.0292 8.72095C11.0292 8.88345 10.9125 8.95428 10.7708 8.87511L10.3292 8.62928C10.2833 8.60428 10.2083 8.60428 10.1583 8.62928L9.71667 8.87511C9.575 8.95428 9.45833 8.88345 9.45833 8.72095V7.44178C9.45833 7.25011 9.61667 7.09178 9.80833 7.09178H10.6833C10.875 7.09178 11.0333 7.25011 11.0333 7.44178V8.72095H11.0292Z"
                                    fill="#0481F4"/>
                            </svg>
                        </div>
                        <div class="col-7 px-3 my-2">
                            <p class="search-book-name">
                                {{ book.name }}
                            </p>
                            <p class="search-book-author">
                                {{ book.author }}
                            </p>
                            <div class="row">
                                <div class="col-8 star-row-2 pe-0">
                                    <span>
                                        <svg v-if="book.isFavoriteBook" width="12" height="14" viewBox="0 0 12 14"
                                             fill="none" xmlns="http://www.w3.org/2000/svg" class="archive"
                                             style="margin-right: 4px;">
                                            <path
                                                d="M9.21289 0.273438H2.78622C1.37289 0.273438 0.212891 1.43344 0.212891 2.84677V12.2401C0.212891 13.4401 1.07289 13.9468 2.12622 13.3668L5.37956 11.5601C5.72622 11.3668 6.28622 11.3668 6.62622 11.5601L9.87955 13.3668C10.9329 13.9534 11.7929 13.4468 11.7929 12.2401V2.84677C11.7862 1.43344 10.6329 0.273438 9.21289 0.273438ZM8.41289 5.0201L5.74622 7.68677C5.64622 7.78677 5.51956 7.83344 5.39289 7.83344C5.26622 7.83344 5.13956 7.78677 5.03956 7.68677L4.03956 6.68677C3.84622 6.49344 3.84622 6.17344 4.03956 5.9801C4.23289 5.78677 4.55289 5.78677 4.74622 5.9801L5.39289 6.62677L7.70622 4.31344C7.89955 4.1201 8.21955 4.1201 8.41289 4.31344C8.60622 4.50677 8.60622 4.82677 8.41289 5.0201Z"
                                                fill="#0481F4"/>
                                        </svg>
                                        <svg v-if="book.isSavedBook" width="16" height="16" viewBox="0 0 16 16"
                                             fill="none" xmlns="http://www.w3.org/2000/svg" class="download"
                                             style="margin-right: 4px;">
                                            <path
                                                d="M10.794 1.33325H5.20732C2.78065 1.33325 1.33398 2.77992 1.33398 5.20659V10.7866C1.33398 13.2199 2.78065 14.6666 5.20732 14.6666H10.7873C13.214 14.6666 14.6607 13.2199 14.6607 10.7933V5.20659C14.6673 2.77992 13.2207 1.33325 10.794 1.33325ZM5.64732 7.98659C5.84065 7.79325 6.16065 7.79325 6.35398 7.98659L7.50065 9.13325V5.00659C7.50065 4.73325 7.72732 4.50659 8.00065 4.50659C8.27398 4.50659 8.50065 4.73325 8.50065 5.00659V9.13325L9.64732 7.98659C9.84065 7.79325 10.1607 7.79325 10.354 7.98659C10.5473 8.17992 10.5473 8.49992 10.354 8.69325L8.35398 10.6933C8.30732 10.7399 8.25398 10.7733 8.19398 10.7999C8.13398 10.8266 8.06732 10.8399 8.00065 10.8399C7.93398 10.8399 7.87398 10.8266 7.80732 10.7999C7.74732 10.7733 7.69398 10.7399 7.64732 10.6933L5.64732 8.69325C5.45398 8.49992 5.45398 8.18659 5.64732 7.98659Z"
                                                fill="#0481F4"/>
                                        </svg>
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" class="star" v-if="book.vote">
                                            <path
                                                d="M7.23584 1.22039L8.32146 3.40667C8.4695 3.7048 8.86427 4.00293 9.19324 4.05261L11.1589 4.38387C12.4172 4.59918 12.7133 5.51013 11.8086 6.42108L10.2789 7.96969C10.0239 8.22641 9.87587 8.73158 9.95811 9.09595L10.394 11.0089C10.7394 12.5161 9.94166 13.1041 8.61754 12.3174L6.77527 11.216C6.43807 11.0172 5.89526 11.0172 5.55806 11.216L3.70757 12.3091C2.38345 13.0958 1.58568 12.5079 1.9311 11.0007L2.367 9.08767C2.44924 8.73158 2.3012 8.22641 2.04625 7.96141L0.524733 6.42936C-0.37995 5.51841 -0.0838721 4.59918 1.17446 4.39215L3.14009 4.0609C3.46907 4.00293 3.86384 3.71308 4.01188 3.41495L5.0975 1.22868C5.68143 0.0361615 6.65191 0.0361613 7.23584 1.22039Z"
                                                fill="#FFCF25"/>
                                        </svg>
                                    </span>
                                    <span class="star_text" v-if="book.vote">{{ book.vote.toFixed(1) }}</span>
                                </div>

                            </div>
                        </div>
                        <div class="col-3 search-book-king">
                            <span v-if="book['@type'] === 'AudioBook'" class="button-start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                                </svg>
                            </span>
                            <span v-else>
                                {{ book.price }} {{ $t('price.currency') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center" v-if="text.length < 3 && !showBeforeSearched">
                <div class="col-11 pb-4 center-block">
                    <div class="row">
                        <div class="col-2 logo-width p-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" class="logo">
                                <path class="blueToWhite"
                                      d="M2.74982 18.65C2.33982 18.65 1.99982 18.31 1.99982 17.9V12.2C1.94982 9.49005 2.95982 6.93005 4.83982 5.01005C6.71982 3.10005 9.23982 2.05005 11.9498 2.05005C17.4898 2.05005 21.9998 6.56005 21.9998 12.1V17.8C21.9998 18.21 21.6598 18.55 21.2498 18.55C20.8398 18.55 20.4998 18.21 20.4998 17.8V12.1C20.4998 7.39005 16.6698 3.55005 11.9498 3.55005C9.63982 3.55005 7.49982 4.44005 5.90982 6.06005C4.30982 7.69005 3.45982 9.86005 3.49982 12.18V17.89C3.49982 18.31 3.16982 18.65 2.74982 18.65Z"
                                      fill="#0481F4"/>
                                <path class="blueToWhite"
                                      d="M5.94 12.45H5.81C3.71 12.45 2 14.16 2 16.26V18.14C2 20.24 3.71 21.95 5.81 21.95H5.94C8.04 21.95 9.75 20.24 9.75 18.14V16.26C9.75 14.16 8.04 12.45 5.94 12.45Z"
                                      fill="#0481F4"/>
                                <path class="blueToWhite"
                                      d="M18.19 12.45H18.06C15.96 12.45 14.25 14.16 14.25 16.26V18.14C14.25 20.24 15.96 21.95 18.06 21.95H18.19C20.29 21.95 22 20.24 22 18.14V16.26C22 14.16 20.29 12.45 18.19 12.45Z"
                                      fill="#0481F4"/>
                            </svg>
                        </div>

                        <div class="col-6 d-flex justify-content-start row-text-1 p-0">
                            <div>
                                {{ $t('search.audiobooks') }}
                            </div>
                        </div>

                        <div class="col d-flex justify-content-end p-0 price-number">
                            {{ countOfAudioBooks }}
                        </div>
                    </div>

                    <!-- hr row start -->
                    <div class="row d-flex justify-content-center">
                        <div class="col  hr-width">
                            <div class="hr"></div>
                        </div>
                    </div>
                    <!-- hr row end -->

                    <!-- 3 -->
                    <div class="row">
                        <div class="col-2 logo-width p-0">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" class="logo">
                                <path class="blueToWhite"
                                      d="M22 5.06014V16.9501C22 17.9201 21.21 18.8101 20.24 18.9301L19.93 18.9701C18.29 19.1901 15.98 19.8701 14.12 20.6501C13.47 20.9201 12.75 20.4301 12.75 19.7201V5.81014C12.75 5.44014 12.96 5.10014 13.29 4.92014C15.12 3.93014 17.89 3.05014 19.77 2.89014H19.83C21.03 2.89014 22 3.86014 22 5.06014Z"
                                      fill="#0481F4"/>
                                <path class="blueToWhite"
                                      d="M10.7102 4.92014C8.88023 3.93014 6.11023 3.05014 4.23023 2.89014H4.16023C2.96023 2.89014 1.99023 3.86014 1.99023 5.06014V16.9501C1.99023 17.9201 2.78023 18.8101 3.75023 18.9301L4.06023 18.9701C5.70023 19.1901 8.01023 19.8701 9.87023 20.6501C10.5202 20.9201 11.2402 20.4301 11.2402 19.7201V5.81014C11.2402 5.43014 11.0402 5.10014 10.7102 4.92014ZM5.00023 7.95014H7.25023C7.66023 7.95014 8.00023 8.29014 8.00023 8.70014C8.00023 9.12014 7.66023 9.45014 7.25023 9.45014H5.00023C4.59023 9.45014 4.25023 9.12014 4.25023 8.70014C4.25023 8.29014 4.59023 7.95014 5.00023 7.95014ZM8.00023 12.4501H5.00023C4.59023 12.4501 4.25023 12.1201 4.25023 11.7001C4.25023 11.2901 4.59023 10.9501 5.00023 10.9501H8.00023C8.41023 10.9501 8.75023 11.2901 8.75023 11.7001C8.75023 12.1201 8.41023 12.4501 8.00023 12.4501Z"
                                      fill="#0481F4"/>
                            </svg>
                        </div>

                        <div class="col d-flex justify-content-start row-text-1 p-0">
                            {{ $t('search.books') }}
                        </div>

                        <div class="col d-flex justify-content-end price-number p-0">
                            {{ countOfBooks }}
                        </div>
                    </div>

                </div>
            </div>

            <Footer/>
        </div>
    </div>
</template>

<script>
import Footer from '../../components/footer/footer.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'search',
    components: {Footer},
    computed: {
        ...mapGetters([
            'getSearchedAllBooks',
            'getRecentRequest',
            'getCountOfAllBooks',
            'getTodayRecommendsBooks'
        ])
    },
    data () {
        return {
            text: '',
            books: [],
            audioBooks: [],
            localhost: process.env.VUE_APP_API_URL,
            isSearching: false,
            countOfAudioBooks: 0,
            countOfBooks: 0,
            todayRecommendBooks: [],
            showBeforeSearched: false,
            allSearchingBooks: []
        }
    },
    methods: {
        ...mapActions([
            'fetchAllBooksCount',
            'fetchRecentRequest',
            'fetchSearchAnyBooks',
            'pushRequest',
            'fetchBooks',
            'fetchTodayRecommendsBooks'
        ]),
        checkSearching() {
            if (!this.isSearching) {
                this.showBeforeSearched = false
            }
        },
        search () {
            this.allSearchingBooks = []
            if (this.text.length > 2) {
                this.fetchSearchAnyBooks(this.text).then(() => {
                        this.allSearchingBooks = this.getSearchedAllBooks
                    })
                this.isSearching = true
            } else {
                this.isSearching = false
            }
        },
        pushRelatedBook(book) {
            this.pushRequest({ request: book.name })
            this.$router.push(book['@type'] === 'Book' ? '/book/' + book.id : '/audio-book/' + book.id)
        },
        changeHeightImg(url) {
            return url.replace('cover', 'cover_100')
        },
        isVisitedTodayToSearch() {
            let date = new Date().toLocaleDateString()

            if(!localStorage.getItem('lastVisitedDayToSearch')) {
                localStorage.setItem('lastVisitedDayToSearch', date)
                return false
            }

            if(localStorage.getItem('lastVisitedDayToSearch') === date) {
                return true
            } else {
                localStorage.setItem('lastVisitedDayToSearch', date)
                return false
            }
        }
    },
    mounted() {
        if(!this.getRecentRequest.length) {
            this.fetchRecentRequest()
        }

        if (
            !localStorage.getItem('countBooks') ||
            !localStorage.getItem('countAudioBooks') ||
            !this.isVisitedTodayToSearch()
        ) {
            this.fetchAllBooksCount().then(() => {
                this.countOfAudioBooks = this.getCountOfAllBooks.audioBooksCount
                localStorage.setItem('countAudioBooks', JSON.stringify(this.getCountOfAllBooks.audioBooksCount))
                this.countOfBooks = this.getCountOfAllBooks.booksCount
                localStorage.setItem('countBooks', JSON.stringify(this.getCountOfAllBooks.booksCount))
            })
        } else {
            this.countOfAudioBooks = localStorage.getItem('countAudioBooks')
            this.countOfBooks = localStorage.getItem('countBooks')
        }

        if(!localStorage.getItem('todayRecommendBooks') || !this.isVisitedTodayToSearch()) {
            this.fetchTodayRecommendsBooks().then(() => {
                this.todayRecommendBooks = this.getTodayRecommendsBooks;
                localStorage.setItem('todayRecommendBooks', JSON.stringify(this.getTodayRecommendsBooks))
            })
        } else {
            this.todayRecommendBooks = JSON.parse(localStorage.getItem('todayRecommendBooks'))
        }
    }
}
</script>

<style scoped>
.blueToWhite {
    fill: var(--blueToWhite);
}

.container-col {
    font-family: 'Montserrat', sans-serif !important;
    background-color: var(--color-container);
    height: 100vh;
}

.header-name-row {
    border-radius: 15px;
    background-color: var(--color-header);
}

.search-input::placeholder {
    font-size: 14px;
}

.form-input {
    margin-top: 35px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
}

.form-control {
    background-color: var(--color-input) !important;
}

.search {
    color: black;
}

.icon {
    position: absolute;
    left: 90%;
    font-size: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

input {
    border: none;
    outline: none;
    font-size: 18px;
    font-family: Montserrat, 'sans-serif';
    font-weight: 400;
    line-height: 30px;
    display: block;
    width: 100%;
    height: 51px;
    border-radius: 12px;
    background-color: var(--home-input-bg) !important;
    color: var(--blueToWhite);
}

.text-1 {
    padding: 0 0 0 13px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
}

.text-2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--blueToFade);
    margin-left: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-3 {
    margin-top: 35px;
}

/* scrollable row */
.profiles {
    border-radius: 6px;
    background-repeat: no-repeat;
    width: 92px;
    height: 135px;
}

.book-pc {
    font-family: 'Montserrat', sans-serif !important;
    background: linear-gradient(180deg, rgba(0, 52, 101, 0) 0%, #001F3C 100%);
    width: 90.82px;
    height: 135px;
    border-radius: 6px;
}

.book-name {
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: white;
    margin-top: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.book-author {
    font-weight: 500;
    font-size: 6px;
    line-height: 7px;
    color: white;
    margin-top: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.gold-star {
    margin-right: 1px;
}

.ranking-height {
    margin: -6px 0 0 9.5px;
    min-width: 36px;
}

.wrapper {
    display: flex;
    overflow-x: scroll;
    user-select: none;
    width: 100%;
    gap: 20px;
    margin: 12px 0 0 0;
}

.wrapper::-webkit-scrollbar {
    width: 0;
}

/* scrollable row */
.logo-width {
    width: 70px;
}

.logo {
    margin: 27px 0 0 30px;
}

.row-text-1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
    margin: 31px 0 0 0;
}

.price-number {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0481F4;
    margin: 30px 29px 0 0;
}

.hr {
    border-bottom: 1px solid #C4E2FF;
    width: 100%;
}

.hr-width {
    padding-left: 26px !important;
    padding-right: 30px !important;
    margin-top: 27px;
}

.center-block {
    border-radius: 15px;
    margin-top: 20px;
    background-color: var(--color-block);
    margin-bottom: 120px;
}

.search-book-name {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.search-book-author {
    font-family: 'Montserrat', sans-serif !important;
    margin-top: 2px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #9597A1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.search-book-img {
    object-fit: cover;
    border-radius: 5px !important;
}

.search-book-king {
    margin: auto;
    text-align: center;
    font-size: 14px;
    color: #046FF4;
    font-weight: 500;
}

.card_book {
    border-radius: 12px;
}

.img_content {
    padding: 11px;
}

.head-phone {
    margin: 45px 0 0 -40px;
}

.star_text {
    margin-left: 4px;
    font-size: 12px;
    color: #828282;
}

.vote {
    color: #FFFFFF;
    font-size: 7px;
    margin-left: -10px;
    margin-top: 3px;
}
</style>

import axios from "axios";
import store from "./store";
import router from "../router";

axios.custom = {
    apiPath: '/api',
    entityId: (entity, id) => {
        return axios.custom.apiPath + '/' + entity + '/' + id;
    },
    changeLang: (lang) => {
        axios.defaults.headers.post['Accept-Language'] = lang
    },
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL + axios.custom.apiPath + '/'
axios.defaults.headers.post['Content-Type'] = 'application/ld+json'

axios
    .interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.headers.common['Authorization'] = 'Bearer ' + store.getters.getAccessToken

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response === undefined) {
            return Promise.reject('connectionRefused');
        }

        if (error.response.status === 401 && (error.config.url !== '/api/users/auth' || error.config.url !== '/api/users/auth/refreshToken')) {
            store.dispatch('fetchRefreshToken')
                .catch(() => {
                    store.dispatch('clearTokens').then(() => {
                        if (router.history.current.path !== '/login-fourth') {
                            router.push('/login-fourth')
                        }
                    })
                })
        }

        return Promise.reject(error);
    });

export default axios
export default function (context, response, mutationName, firstOnly = false) {
    let data;

    if (firstOnly) {
        data = response.data['hydra:member'][0]
    } else if (typeof response.data['hydra:member'] === 'undefined') {
        data = response.data
    } else {
        data = {
            models: response.data['hydra:member'],
            totalItems: response.data['hydra:totalItems']
        }
    }

    context.commit(mutationName, data)
}
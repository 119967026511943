import pushModel from "@/mixin/store/api/pushModel";
import fetchModels from "@/mixin/store/api/fetchModels";

export default {
    actions: {
        pushReadBook(context, data) {
            return pushModel(context, 'read_books', data)
        },
        fetchReadBookWeekData(context) {
            return fetchModels(context, 'week_data/about_read', null, 'updateReadBookWeekData')
        },
        fetchReadBookWeeklyDayData(context) {
            return fetchModels(context, 'weekly_day_data/about_read', null, 'updateReadBookWeeklyDayData')
        },
        fetchReadBookMonthlyDayData(context) {
            return fetchModels(context, 'monthly_day_data/about_read', null, 'updateReadBookMonthlyDayData')
        },
        fetchReadBookYearlyDayData(context) {
            return fetchModels(context, 'yearly_day_data/about_read', null, 'updateReadBookYearlyDayData')
        }
    },
    mutations: {
        updateReadBookWeekData(state, data) {
            state.readBookWeekData = data
        },
        updateReadBookWeeklyDayData(state, data) {
            state.readBookWeeklyDayData = data
        },
        updateReadBookMonthlyDayData(state, data) {
            state.readBookMonthlyDayData = data
        },
        updateReadBookYearlyDayData(state, data) {
            state.readBookYearlyDayData = data
        }
    },
    state: {
        readBookWeekData: null,
        readBookWeeklyDayData: null,
        readBookMonthlyDayData: null,
        readBookYearlyDayData: null
    },
    getters: {
        getReadBookWeekData(state) {
            return state.readBookWeekData
        },
        getReadBookWeeklyDayData(state) {
            return state.readBookWeeklyDayData
        },
        getReadBookMonthlyDayData(state) {
            return state.readBookMonthlyDayData
        },
        getReadBookYearlyDayData(state) {
            return state.readBookYearlyDayData
        }
    }
}

import fetchModel from "@/mixin/store/api/fetchModel";
import changeOrPushmodel from "@/mixin/store/api/changeOrPushmodel";
import pushModel from "../../mixin/store/api/pushModel";

export default {
    actions: {
        fetchUser(context) {
            return fetchModel(context, 'users/about_me', null, 'updateUser')
        },
        authUser(context, data) {
            return changeOrPushmodel(context, 'users', data)
        },
        fetchPhoneNumber(context, data) {
            return pushModel(context, 'users/phone_number', data, 'updatePhoneNumber')
        },
        fetchUserRole(context) {
            return fetchModel(context, 'users/is_admin', null, 'updateUserRole')
        }
    },
    mutations: {
        updateUser(state, model) {
            state.user = model
        },
        clearRole(state) {
            state.user.roles = []
        },
        updatePhoneNumber(state, data) {
            state.phone = data
        },
        updateUserRole(state, data) {
            state.userRole = data
        }
    },
    state: {
        user: {
            id: null,
            phone: '',
            roles: [],
            givenName: '',
            familyName: '',
            gender: null,
            birthday: null,
            city: '',
            image: {}
        },
        phone: null,
        userRole: {
            isAdmin: false
        }
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getPhoneNumber(state) {
            return state.phone
        },
        getUserRole(state) {
            return state.userRole
        }
    }
}

import pushModel from "../../mixin/store/api/pushModel";

export default {
    actions: {
        pushOrderTransaction(context, data) {
            return pushModel(context, 'order_transactions', data, 'updateOrderTransaction');
        },
    },
    mutations: {
        updateOrderTransaction(state, data) {
            state.orderTransaction = data
        }
    },
    state: {
        orderTransaction: null
    },
    getters: {
        getOrderTransaction(state) {
            return state.orderTransaction
        },
    },
}
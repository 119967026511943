<template>
    <div class="row">
        <div class="col container-col">

            <!-- header start -->
            <div class="row header">
                <div class="col fixed-top">

                    <div class="row header-name-row ps-2">
                        <div class="col-1 header-block ps-0">
                            <go-back-button class="arrow-back"/>
                        </div>

                        <div class="col header-name d-flex justify-content-center p-0">
                            {{ $t('search.books') }}
                        </div>

                        <div class="col-1"><!-- don't delete (Abdullokh) --> </div>

                    </div>
                </div>
            </div>
            <!-- header end -->

            <div class="row body d-flex justify-content-center" v-for="(item, index) in getBannerBook" :key="index">
                <div class="col-11 center-block">
                    <div class="row book-row" @click="$router.push(`/book/${item.id}`)">
                        <div class="col-2">
                            <img v-if="item.image" :src="localhost + item.image.contentUrl" alt="rasm" class="book-img">
                            <img v-else-if="item.coverSourceUrl" :src="changeHeightImg(item.coverSourceUrl)" alt="rasm" class="book-img">
                            <img v-else src="../../components/images/default_book.png" alt="...">
                        </div>
                        <div class="col-8 ps-0 pe-5">
                            <div class="row">
                                <div class="col book-name pe-4">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="row pe-3">
                                <div class="col book-author">
                                    {{ item.author }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8 star-row" v-if="item.votesTotalUser > 0">
                                    <svg fill="none" height="13" viewBox="0 0 13 13" width="19" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.23584 1.22039L8.32146 3.40667C8.4695 3.7048 8.86427 4.00293 9.19324 4.05261L11.1589 4.38387C12.4172 4.59918 12.7133 5.51013 11.8086 6.42108L10.2789 7.96969C10.0239 8.22641 9.87587 8.73158 9.95811 9.09595L10.394 11.0089C10.7394 12.5161 9.94166 13.1041 8.61754 12.3174L6.77527 11.216C6.43807 11.0172 5.89526 11.0172 5.55806 11.216L3.70757 12.3091C2.38345 13.0958 1.58568 12.5079 1.9311 11.0007L2.367 9.08767C2.44924 8.73158 2.3012 8.22641 2.04625 7.96141L0.524733 6.42936C-0.37995 5.51841 -0.0838721 4.59918 1.17446 4.39215L3.14009 4.0609C3.46907 4.00293 3.86384 3.71308 4.01188 3.41495L5.0975 1.22868C5.68143 0.0361615 6.65191 0.0361613 7.23584 1.22039Z" fill="#FFCF25"/>
                                    </svg>
                                    <div>
                                            {{ (item.votesTotalCount / item.votesTotalUser).toFixed(1) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col book-price-row d-flex justify-content-end">
                            <span class="book-price">{{ item.price }} {{ $t('price.currency') }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <FooterMenu/>

        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GoBackButton from "@/components/modals/GoBackButton.vue";
import FooterMenu from "@/components/footer/footer.vue";

export default {
    name: "banner-books",
    components: {
        FooterMenu,
        GoBackButton
    },
    data() {
        return {
            localhost: process.env.VUE_APP_API_URL,
        }
    },
    computed: {
        ...mapGetters(['getBannerBook'])
    },
    methods: {
        ...mapActions(['fetchBannerBook']),
        changeHeightImg(url) {
            return url.replace('cover', 'cover_100')
        },
    },
    mounted() {
        this.fetchBannerBook(this.$route.params.id)
    }
}
</script>

<style scoped>
.container-col {
    padding-bottom: 90px;
    font-family: 'Montserrat', sans-serif;
}

.header-name-row {
    height: 147px;
    border-radius: 15px;
    background-color: var(--color-header);
}

.header-block {
    border: 15px;
}

.arrow-back {
    margin-top: 65px;
}

.header-name {
    margin-top: 67px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    color: var(--color-text);
}

.center-block, .center-block-2 {
    border-radius: 12px;
    background-color: var(--color-block);
    margin-bottom: 8px;
}

.header {
    margin-bottom: 167px;
    font-weight: 700;
}

.book-name {
    font-weight: 500;
    font-size: 14px;
    margin-top: 13px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-text);
}

.book-author {
    font-size: 12px;
    color: var(--color-lightGray-to-lightBlue);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.star-row {
    width: 105px !important;
    padding-left: 4px;
    margin-left: 9px;
    margin-top: 5px;
    gap: 5px;
    color: var(--color-textGray-to-gold);
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    align-items: flex-start;
}

.book-price {
    color: #0481F4;
    margin: 36px 0 0 0;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
}

.col-2 {
    width: 71px !important;
}

.book-img {
    margin-top: 9px;
    width: 43px;
    height: 64px;
    border-radius: 5px;
}

.book-row {
    border-radius: 12px;
    background: var(--color-header);
    border: 1px solid var(--color-header);
    margin-bottom: 8px;
}

</style>
